import React from 'react';
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

const solidStyles = css`
  font-size: 14px;
  background-color: var(--accent);
  transition: .2s;
  font-family: inherit;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
  padding: 13px 20px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  min-width: 140px;
  font-weight: 600;

  &:hover {
    background-color: var(--accent-hover);
    color: #fff;
  }
  
  ${props => props.$fluid && css`
    width: 100%;
  `}
  
  ${props => props.disabled && css`
    background-color: var(--gray-300) !important;
    pointer-events: none;
  `}
  
  ${props => props.$withIcon && css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  `}
  
  ${props => props.$red && css`
    background-color: var(--error);
    
    &:hover {
      background-color: var(--error-hover);
    }
  `}
  
  ${props => props.$green && css`
    background-color: var(--success);
    
    &:hover {
      background-color: var(--success-hover);
    }
  `}
  
  ${props => props.$yellow && css`
    background-color: var(--warning);
    
    &:hover {
      background-color: var(--warning-hover);
    }
  `}

  ${props => props.$customColor && css`
    background-color: ${props.$customColor};
    
    &:hover {
      opacity: .8;
      background-color: ${props.$customColor};
    }
  `}
  
  ${props => props.$small && css`
    padding: 8px 15px;
    min-width: 120px;
  `}
`;

const outlineStyles = css`
  font-size: 14px;
  color: var(--accent);
  transition: .2s;
  font-family: inherit;
  -webkit-appearance: none;
  border: 2px solid;
  cursor: pointer;
  border-radius: 8px;
  background: #fff;
  padding: 11px 18px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  min-width: 140px;
  font-weight: 600;


  &:hover {
    background-color: var(--accent-100);
    color: var(--accent);
  }

  ${props => props.$fluid && css`
    width: 100%;
  `}
  ${props => props.$gray && css`
    border-color: var(--gray-300);
    color: var(--gray-400);
  `}

  ${props => props.$red && css`
    color: var(--error);
    
    &:hover {
      color: var(--error-hover);
      background-color: var(--error-100);
    }
  `}

  ${props => props.$yellow && css`
    color: var(--warning);
    
    &:hover {
      color: var(--warning-hover);
      background-color: var(--warning-100);
    }
  `}

  ${props => props.$blue && css`
    color: var(--blue);
    
    &:hover {
      color: var(--blue-hover);
      background-color: var(--blue-100);
    }
  `}

  ${props => props.$customColor && css`
    color: ${props.$customColor};
    background-color: #fff;
    
    &:hover {
      opacity: .8;
      color: ${props.$customColor};
      background-color: #fff;
    }
  `}

  ${props => props.$small && css`
    padding: 7px 15px;
    min-width: 120px;
  `}

  ${props => props.$withIcon && css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    i {
      font-size: 20px;
      
      &:first-child {
        margin-right: 8px;
      }
      
      &:last-child {
        margin-left: 8px;
      }
    }
  `}

  ${props => props.disabled && css`
    background-color: var(--gray-100) !important;
    border-color: var(--gray-300);
    color: var(--gray-300);
    pointer-events: none;
  `}
`

const BtnEl = styled.button`${solidStyles}`;
const LinkEl = styled(Link)`${solidStyles}`;
const AEl = styled.a`${solidStyles}`

const OutlineBtnEl = styled.button`${outlineStyles}`;
const OutlineLinkEl = styled(Link)`${outlineStyles}`;
const OutlineAEl = styled.a`${outlineStyles}`;

const Button = ({children, variant, ...props}) => {
    const {to} = props;

    if (to) {
        if (variant === "outline") {
            return <OutlineLinkEl {...props}>{children}</OutlineLinkEl>
        }

        return (
            <LinkEl {...props}>{children}</LinkEl>
        )
    }

    if (props.href) {
        if (variant === "outline") {
            return <OutlineAEl {...props}>{children}</OutlineAEl>
        }

        return (
            <AEl {...props}>{children}</AEl>
        )
    }

    if (variant === "outline") {
        return <OutlineBtnEl {...props}>{children}</OutlineBtnEl>
    }

    return (
        <BtnEl {...props}>{children}</BtnEl>
    );
};

export default Button;