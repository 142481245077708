import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../components/WhiteBlock";
import Button from "../../components/Button";
import routePaths from "../../navigation/general/routePaths";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserRoleNotDefined = () => {
    const {t} = useTranslation()

    useEffect(() => {
        localStorage.removeItem('token')
    }, [])

    return (
        <Wrapper>
            <WhiteBlock className="text-center" style={{
                padding: 60,
                width: 600
            }}>
                <h1 className="headline-1" style={{marginBottom: 20}}>{t('pages.user_role_not_defined.title')}</h1>
                <div className="lead-1 mb-20">
                    {t('pages.user_role_not_defined.message')}
                </div>
                <div className="row row--multiline justify-center">
                    <div className="col-auto">
                        <Button to={routePaths.login.index}>{t('pages.user_role_not_defined.use_another_account')}</Button>
                    </div>
                </div>
            </WhiteBlock>
        </Wrapper>
    );
};

export default UserRoleNotDefined;