import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/sealerOperator/routePaths";
import StreetEditInformation from "./StreetEditInformation";
import StreetEditHouses from "./StreetEditHouses";
import StreetEditSectors from "./StreetEditSectors";
import StreetEditControllers from "./StreetEditControllers";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/sealerOperator/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";

const StreetEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.streets.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.streets.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.streets.edit.houses(id)}>{t('labels.houses')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.streets.edit.sectors(id)}>{t('labels.sectors')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.streets.edit.controllers(id)}>{t('labels.controllers')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.streets.edit.index()} to={routePaths.addresses.children.streets.edit.information()}/>
                <Route path={routePaths.addresses.children.streets.edit.information()} component={StreetEditInformation}/>
                <Route path={routePaths.addresses.children.streets.edit.houses()} component={StreetEditHouses}/>
                <Route path={routePaths.addresses.children.streets.edit.sectors()} component={StreetEditSectors}/>
                <Route path={routePaths.addresses.children.streets.edit.controllers()} component={StreetEditControllers}/>
            </Switch>
        </>
    );
};

export default StreetEdit;