import React, { useState } from 'react';
import styled from 'styled-components'

const Div = styled.div`
    display:flex;
    align-items:center;
    color: #6C6D6D;
    font-size:20px;
    margin-bottom:32px;
    h2{
        color: #585757;
        font-weight: 700;
        font-size: 24px;
        margin: 0 14px;
    }
    i{
        cursor:pointer;
    }
`

export const Month = {
    1: "Январь",
    2: "Февраль",
    3: "Март",
    4: "Апрель",
    5: "Май",
    6: "Июнь",
    7: "Июль",
    8: "Август",
    9: "Сентябрь",
    10: "Октябрь",
    11: "Ноябрь",
    12: "Декабрь",
}
const MonthFilter = ({setFilterData, params}) => {
    const date = new Date();
    const [monthCount, setMonthCount] = useState(date.getMonth()+1)
    const [year, setYear] =useState(date.getFullYear())
    const query = new URLSearchParams(window.location.search);

    const nextMonth = () => {
        if(monthCount >= 12) {
            setYear(year+1);
            setMonthCount(1);
            query.set('date', `${year+1}-1`)
        }else{
            query.set('date', `${year}-${monthCount+1}`)
            setMonthCount(monthCount+1)
        }
        setFilterData(query)
    }

    const prevMonth = () => {
        if(monthCount <= 1){
            setYear(year-1);
            setMonthCount(12)
            query.set('date', `${year-1}-12`)
        }else{
            query.set('date', `${year}-${monthCount-1}`)
            setMonthCount(monthCount-1)
        }
        setFilterData(query)
    }

    return (
        <Div>
            <i className="icon-chevron-left" onClick={prevMonth}></i>
            <h2>{Month[`${monthCount}`]}, {year}</h2>
            <i className="icon-chevron-right" onClick={nextMonth}></i>
        </Div>
    );
};

export default MonthFilter;