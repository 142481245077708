import React from 'react';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/operator/routePaths";
import CompanyEditInformation from "./CompanyEditInformation";
import CompanyEditOperators from "./CompanyEditOperators";
import CompanyEditControllers from "./CompanyEditControllers";
import CompanyEditSectors from "./CompanyEditSectors";
// import CompanyEditCities from "./CompanyEditCities";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import CompaniesServices from "../../../services/operator/companiesServices";
import {useLangContext} from "../../../i18n/ProvideLang";
import CompanyEditSealers from './CompanyEditSealers';
import CompanyEditSealerOperators from './CompanyEditSealerOperators';
import CompanyEditAnalytics from './CompanyEditAnalytics';
import CompanyEditAddresses from './CompanyEditAddresses';

const CompanyEdit = () => {
    const {t} = useTranslation();

    const {info} = useFetchInfo(CompaniesServices.view);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.company.information}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.operators}>{t('labels.operators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.controllers}>{t('labels.controllers')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.sealers}>{t('labels.sealers')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.sealers_operators}>{t('labels.sealer_operators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.analytics}>{t('labels.analytics')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.addresses}>{t('labels.addresses')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.company.sectors}>{t('labels.sectors')}</NavLink>
                {/*<NavLink activeClassName='active' exact to={routePaths.company.cities}>{t('labels.cities')}</NavLink>*/}
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.company.index} to={routePaths.company.information}/>
                <Route exact path={routePaths.company.information} component={CompanyEditInformation}/>
                <Route exact path={routePaths.company.operators} component={CompanyEditOperators}/>
                <Route exact path={routePaths.company.controllers} component={CompanyEditControllers}/>
                <Route path={routePaths.company.sealers} component={CompanyEditSealers}/>
                <Route path={routePaths.company.sealers_operators} component={CompanyEditSealerOperators}/>
                <Route path={routePaths.company.analytics} component={CompanyEditAnalytics}/>
                <Route exact path={routePaths.company.sectors} component={CompanyEditSectors}/>
                <Route path={routePaths.company.addresses} component={CompanyEditAddresses}/>
                {/*<Route exact path={routePaths.company.cities} component={CompanyEditCities}/>*/}
            </Switch>
        </>
    );
};

export default CompanyEdit;