import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class AddressesServices {
    static cities = {
        index: (queryString) => {
            return getData(http, `/addresses/cities${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/cities/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/cities/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/cities/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/cities/${id}`)
        },

        districts: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/cities/${id}/districts${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/cities/${id}/districts/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/cities/${parentId}/districts/detach`, {district_id: id})
            }
        }
    }

    static streets = {
        index: (queryString) => {
            return getData(http, `/addresses/streets${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/streets/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/streets/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/streets/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/streets/${id}`)
        },

        houses: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/streets/${id}/houses${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/streets/${id}/houses/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/streets/${parentId}/houses/detach`, {house_id: id})
            }
        },

        sectors: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/streets/${id}/sectors${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/streets/${id}/sectors/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/streets/${parentId}/sectors/detach`, {sector_id: id})
            }
        },

        controllers: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/streets/${id}/controllers${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/streets/${id}/controllers/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/streets/${parentId}/controllers/detach`, {user_id: id})
            }
        }
    }

    static houses = {
        index: (queryString) => {
            return getData(http, `/addresses/houses${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/houses/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/houses/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/houses/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/houses/${id}`)
        },

        entrances: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/houses/${id}/entrances${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/houses/${id}/entrances/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/houses/${parentId}/entrances/detach`, {entrance_id: id})
            }
        },

        apartments: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/houses/${id}/apartments${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/houses/${id}/apartments/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/houses/${parentId}/apartments/detach`, {apartment_id: id})
            }
        },

        bankbooks: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/houses/${id}/bankbooks${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/houses/${id}/bankbooks/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/houses/${parentId}/bankbooks/detach`, {bankbook_id: id})
            }
        },

        sectors: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/houses/${id}/sectors${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/houses/${id}/sectors/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/houses/${parentId}/sectors/detach`, {sector_id: id})
            }
        },

        controllers: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/houses/${id}/controllers${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/houses/${id}/controllers/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/houses/${parentId}/controllers/detach`, {user_id: id})
            }
        }
    }

    static districts = {
        index: (queryString) => {
            return getData(http, `/addresses/districts${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/districts/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/districts/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/districts/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/districts/${id}`)
        },

        microdistricts: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/districts/${id}/microdistricts${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/districts/${id}/microdistricts/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/districts/${parentId}/microdistricts/detach`, {district_id: id})
            }
        },

        streets: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/districts/${id}/streets${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/districts/${id}/streets/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/districts/${parentId}/streets/detach`, {street_id: id})
            }
        }
    }

    static microdistricts = {
        index: (queryString) => {
            return getData(http, `/addresses/microdistricts${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/microdistricts/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/microdistricts/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/microdistricts/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/microdistricts/${id}`)
        },

        streets: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/microdistricts/${id}/streets${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/microdistricts/${id}/streets/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/microdistricts/${parentId}/streets/detach`, {street_id: id})
            }
        }
    }

    static entrances = {
        index: (queryString) => {
            return getData(http, `/addresses/entrances${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/entrances/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/entrances/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/entrances/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/entrances/${id}`)
        },

        floors: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/entrances/${id}/floors${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/entrances/${id}/floors/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/entrances/${parentId}/floors/detach`, {floor_id: id})
            }
        },

        apartments: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/entrances/${id}/apartments${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/entrances/${id}/apartments/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/entrances/${parentId}/apartments/detach`, {apartment_id: id})
            }
        }
    }

    static floors = {
        index: (queryString) => {
            return getData(http, `/addresses/floors${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/floors/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/floors/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/floors/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/floors/${id}`)
        },

        apartments: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/floors/${id}/apartments${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/floors/${id}/apartments/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/floors/${parentId}/apartments/detach`, {apartment_id: id})
            }
        }
    }

    static apartments = {
        index: (queryString) => {
            return getData(http, `/addresses/apartments${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/addresses/apartments/store`, data)
        },

        view: (id) => {
            return getData(http, `/addresses/apartments/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/addresses/apartments/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/addresses/apartments/${id}`)
        },

        bankbooks: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/apartments/${id}/bankbooks${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/apartments/${id}/bankbooks/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/apartments/${parentId}/bankbooks/detach`, {bankbook_id: id})
            }
        },

        controllers: {
            index: ({id}, queryString) => {
                return getData(http, `/addresses/apartments/${id}/controllers${queryString ? `?${queryString}` : ""}`)
            },
            attach: (id, data) => {
                return postData(http, `/addresses/apartments/${id}/controllers/attach`, data)
            },
            detach: ({parentId, id}) => {
                return postData(http, `/addresses/apartments/${parentId}/controllers/detach`, {user_id: id})
            }
        }
    }
}