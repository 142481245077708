import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useInfo} from "./Application";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import WhiteBlock from "../../../components/WhiteBlock";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import ApplicationsServices from "../../../services/admin/applicationsServices";
import {useParams} from "react-router-dom";
import AttachServiceModal from "./components/AttachServiceModal";
import EditAttachedServiceModal from "./components/EditAttachedServiceModal";

const ApplicationServices = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useInfo()

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.applications.index,
                title: t('sidebar.applications.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info.number
            },
            {
                path: null,
                title: t('labels.services')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const [serviceModal, setServiceModal] = useState();
    const [serviceEditModal, setServiceEditModal] = useState();
    const [selectedService, setSelectedService] = useState();

    const {items, loading, pageCount, params, handlePageClick, fetchItems} = useFetchItems(ApplicationsServices.services.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, price, amount}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        price,
                        amount ? amount : 0,
                        Number(price)*Number(amount ? amount : 0)
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: ['ID', t('labels.services'), t('labels.price_tng'), t('labels.amount'), t('labels.total_price_tng'), ''],
        sizes: ['10%', '35%', '15%', '15%', '15%', '']
    }

    const handleServiceEdit = (id) => {
        setSelectedService(items.find(item => String(item.id) === String(id)));
        setServiceEditModal(true);
    }

    return (
        <WhiteBlock>
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        editCallback={handleServiceEdit}
                        removeMethod={childId => ApplicationsServices.services.detach({parentId: id, id: childId})}
                        removeModalContent={{
                            title: t('modals.remove_service.title'),
                            yes: t('labels.yes'),
                            no: t('labels.cancel')
                        }}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small $customColor={'#1FA2FF'} $withIcon variant="outline" onClick={() => setServiceModal(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {info && serviceModal &&
            <AttachServiceModal
                isOpen={serviceModal}
                closeModal={() => setServiceModal(false)}
                onRequestClose={() => setServiceModal(false)}
                method={ApplicationsServices.services.attach}
                successCallback={fetchItems}
                applicationId={id}
                laboratoryId={info.laboratory?.id}
            />
            }
            {info && serviceEditModal &&
            <EditAttachedServiceModal
                isOpen={serviceEditModal}
                closeModal={() => setServiceEditModal(false)}
                onRequestClose={() => setServiceEditModal(false)}
                method={ApplicationsServices.services.update}
                successCallback={fetchItems}
                selectedService={selectedService}
                applicationId={id}
                laboratoryId={info.laboratory?.id}
            />
            }
        </WhiteBlock>
    );
};

export default ApplicationServices;