import React, {useEffect, useState} from 'react';
import HeadingWithButtons from '../../../../components/HeadingWithButtons';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from '../../../../components/ItemsTable';
import Pagination from "../../../../components/Pagination";
import TablePreloader from '../../../../components/TablePreloader';
import TaskControllerFilter from './TaskControllerFilter';
import TaskServices from "../../../../services/admin/tasksServices";
import useFetchItems from '../../../../hooks/useFetchItems';
import routePaths from "../../../../navigation/admin/routePaths";


const TaskController = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('labels.tasks_settings')
            }
        ])
    }, [setBreadcrumbs, t])
    
    const {items, loading, pageCount, params, heading, handlePageClick, fetchItems, setFilterData} = useFetchItems(TaskServices.controllers.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, tasks, companyTasks}) => {
                let obe = {
                    id,
                    fields: [
                        id,
                        name,
                    ]
                }
                for(let key in tasks){
                    obe.fields.push(
                        <>
                            <div>{tasks[key] === null ? "—" : tasks[key]}</div>
                            <span className="gray">{companyTasks[key] === null ? "—" : companyTasks[key]}</span>
                        </>
                    )
                }
                return obe;
            })

            setFormattedItems(newItems);
        }
    }, [items]);



    const tableConfig = {
        head: [t('labels.id'), t('labels.title'), ],
        sizes: ['auto']
    }

    for(let key in heading){
        tableConfig.head.push(heading[key]);
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('labels.tasks_settings_controller')}</h1>
            </HeadingWithButtons>
            <TaskControllerFilter setFilterData={setFilterData} params={params}></TaskControllerFilter>
            <WhiteBlock>
                {formattedItems
                    ? <>
                    <div className="overflow-div">
                        <ItemsTable
                            {...{config: tableConfig, fetchItems, loading}}
                            vAlign="top"
                            items={formattedItems}
                            editLink={routePaths.tasks.children.controllers.edit}
                        />
                    </div>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </>
                    : <TablePreloader/>}
            </WhiteBlock>
        </>
    )
};

export default TaskController;