import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  padding: ${props => props.$padding ? props.$padding : "24px"};
  border-radius: 8px;
  box-shadow: var(--shadow);
  width: 100%;
  max-width: 100%;
  position: relative;
  border: 1px solid #F2F2F2;
  overflow-x: auto;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const WhiteBlock = ({children, padding, ...props}) => {
    return (
        <Container {...props} $padding={padding}>
            {children}
        </Container>
    );
};

export default WhiteBlock;