import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useFetchItems from "../../../hooks/useFetchItems";
import SecondaryLink from "../../../components/SecondaryLink";
import WhiteBlock from "../../../components/WhiteBlock";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import DevicesServices from "../../../services/admin/devicesServices";
import AddIndicationModal from "./components/AddIndicationModal";

const DeviceEditIndications = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useFetchInfo(DevicesServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.devices.index,
                title: t('sidebar.devices.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            },
            {
                path: null,
                title: t('labels.indications_history')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(DevicesServices.indications.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, value, created_at}) => {
                return {
                    id,
                    fields: [
                        id,
                        <SecondaryLink to={routePaths.indications.edit.information(id)}>{value}</SecondaryLink>,
                        created_at
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.indication'), t('labels.date')],
        sizes: ['10%', '40%', '40%']
    }

    return (
        <WhiteBlock> 
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small $customColor={'#1FA2FF'} $withIcon variant="outline"
                                            onClick={() => setAttachModal(true)}><i
                                        className="icon-clip"/><span>{t('labels.add_indications')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {attachModal &&
                <AddIndicationModal
                    isOpen={attachModal}
                    closeModal={() => setAttachModal(false)}
                    onRequestClose={() => setAttachModal(false)}
                    method={DevicesServices.indications.add}
                    successCallback={fetchItems}
                    id={id}
                />
            }
        </WhiteBlock>
    );
};

export default DeviceEditIndications;