import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,.5);
  z-index:100;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`
const Div = styled.div`
    height:100px;
`


const Preloader = ({children, ...props}) => {

    return (
        <Container {...props}>
            <Div>
                <div className="isLoading-white"></div>
            </Div>
            {children}
        </Container>
    );
};

export default Preloader;