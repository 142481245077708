import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/CallOperator/routePaths";
import DeviceEditInformation from "./DeviceEditInformation";
import DeviceEditIndications from "./DeviceEditIndications";
import DeviceEditNotifications from "./DeviceEditNotifications";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import DevicesServices from "../../../services/callOperator/devicesServices";
import DeviceEditDocuments from './DeviceEditDocuments';
import DeviceEditHistories from './DeviceEditHistories';

const DeviceEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(DevicesServices.view, id);

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info?.number ? " - " + info?.number : ""}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.devices.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.devices.edit.indications(id)}>{t('labels.indications_history')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.devices.edit.notifications(id)}>{t('labels.notifications')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.devices.edit.documents(id)}>{t('labels.documents')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.devices.edit.histories(id)}>{t('labels.verification_history')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.devices.edit.index()} to={routePaths.devices.edit.information()}/>
                <Route path={routePaths.devices.edit.information()} component={DeviceEditInformation}/>
                <Route path={routePaths.devices.edit.indications()} component={DeviceEditIndications}/>
                <Route path={routePaths.devices.edit.notifications()} component={DeviceEditNotifications}/>
                <Route path={routePaths.devices.edit.documents()} component={DeviceEditDocuments}/>
                <Route path={routePaths.devices.edit.histories()} component={DeviceEditHistories}/>
            </Switch>
        </>
    );
};

export default DeviceEdit;