import React from 'react';
import styled, {css} from "styled-components";

const El = styled.textarea`
  width: 100% !important;
  min-height: 50px;
  height: 150px;
  font-family: inherit;
  border: 1px solid var(--gray-300);
  -webkit-appearance: none;
  border-radius: 4px;
  transition: .2s;
  font-size: 14px;
  padding: 15px 20px;

  &:focus {
    outline: none;
    border-color: var(--accent)
  }

  ${props => props.$error && css`
    border-color: var(--error);
    
    &:focus {
      border-color: var(--error);
    }
  `}
`;

const TextArea = React.forwardRef((props, ref) => {
    return (
        <El ref={ref} {...props}/>
    );
});

export default TextArea;