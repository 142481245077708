import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import routePaths from "../../../navigation/admin/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomSelect from "../../../components/CustomSelect";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/admin/dictionaries";
import IndicationsServices from "../../../services/admin/indicationsServices";
import FilesUploader from "../../../components/FilesUploader";
import IndicationCreatePreparedData from "./components/IndicationCreatePreparedData";
import Utils from "../../../services/utils";
import InputMessage from "../../../components/InputMessage";
import AsyncPaginateSelect from "../../../components/AsyncPaginateSelect";

const IndicationCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.indications.index,
                title: t('sidebar.indications.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    

    const companyId = watch('company_id', null);
    const bankbookId = watch('bankbook_id', null);
    const deviceId = watch('device_id', null);
    const images = watch('images', null)

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [devices, setDevices] = useState();
    const [devicesLoading, setDevicesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        try {
            const res = await Dictionaries.companies();

            setCompanies(res.content);
        } catch (e) {
            toast.error(e.message)
        }

        setCompaniesLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchDevices = useCallback(async () => {
        if (bankbookId) {
            setDevicesLoading(true);

            const res = await Dictionaries.devices({queryString: `bankbook_id=${bankbookId}`});

            if (res.statusCode === 200) {
                setDevices(res.content);

                if (!res.content.some(item => item.value === deviceId)) {
                    setValue('device_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDevicesLoading(false);
        } else {
            setValue('device_id', null)
        }
    }, [setValue, bankbookId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchDevices();
    }, [fetchDevices]);

    const {onSubmit, loading} = useStore(IndicationsServices.store);

    const customSubmit = (data) => {
        const {images, value, device_id} = data;

        const newData = {
            images: images ? Utils.transformFiles(images) : [],
            value,
            device_id
        };

        onSubmit(newData)
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(customSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled={state?.company_id}
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook') + " *"} key={companyId}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <AsyncPaginateSelect onChange={onChange}
                                                             loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `company_id=${companyId}`})}
                                                             value={value}
                                                             isDisabled={!!!companyId || state?.bankbook_id}
                                                             $error={errors.bankbook_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bankbook_id && errors.bankbook_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.device') + " *"}>
                        <Controller name="device_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={devices} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!bankbookId ? devicesLoading : false}
                                                      isDisabled={!!!bankbookId || devicesLoading || state?.device_id}
                                                      $error={errors.device_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.device_id && errors.device_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    {deviceId && <IndicationCreatePreparedData id={deviceId} {...{register, setValue}}/>}
                    <FormGroup label={t('labels.current_indication') + (images && images.length ? "" : " *")}>
                        <Input type="text" {...register('value', {required: !images || !images.length})} $error={errors.value} disabled={!deviceId}/>
                        {errors.value && errors.value.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.photo')}>
                        <Controller name="images" control={control} render={({field: {onChange, value}}) => (
                            <FilesUploader onChange={onChange} value={value} maxFiles={3} accept="image/*">
                                {t('labels.files_uploader_text', {formats: '.jpg, .png', size: '20MB'})}
                            </FilesUploader>
                        )}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default IndicationCreate;