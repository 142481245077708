import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/minAdmin/routePaths";
import HouseEditInformation from "./HouseEditInformation";
import HouseEditApartments from "./HouseEditApartments";
import HouseEditBankbooks from "./HouseEditBankbooks";
import HouseEditSectors from "./HouseEditSectors";
import HouseEditControllers from "./HouseEditControllers";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/minAdmin/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";

const HouseEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.houses.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')}{info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.apartments(id)}>{t('labels.apartments')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.bankbooks(id)}>{t('labels.bankbooks')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.sectors(id)}>{t('labels.sectors')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.controllers(id)}>{t('labels.controllers')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.houses.edit.index()} to={routePaths.addresses.children.houses.edit.information()}/>
                <Route path={routePaths.addresses.children.houses.edit.information()} component={HouseEditInformation}/>
                <Route path={routePaths.addresses.children.houses.edit.apartments()} component={HouseEditApartments}/>
                <Route path={routePaths.addresses.children.houses.edit.bankbooks()} component={HouseEditBankbooks}/>
                <Route path={routePaths.addresses.children.houses.edit.sectors()} component={HouseEditSectors}/>
                <Route path={routePaths.addresses.children.houses.edit.controllers()} component={HouseEditControllers}/>
            </Switch>
        </>
    );
};

export default HouseEdit;