import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import CustomLink from "./CustomLink";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  overflow: hidden;
  height: ${props => props.height};
  margin-bottom: 5px;
  
  ${props => props.isLess && css`
    height: unset;
  `}
`;

const Expand = ({height, children, ...props}) => {
    const [isLess, setIsLess] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const ref = useRef();

    const {t} = useTranslation();

    useEffect(() => {
        if (ref.current.clientHeight <= height) {
            setIsLess(true);
        }
    }, [height])

    const handleClick = (e) => {
        e.stopPropagation();
        setIsCollapsed(!isCollapsed);
    }

    return (
        <>
            <Container isLess={isLess} height={isCollapsed ? height + "px" : "unset"} {...props}>
                <div ref={ref}>
                    {children}
                </div>
            </Container>
            {!isLess && <CustomLink size="small" onClick={handleClick}>{isCollapsed ? t('labels.expand') : t('labels.roll_up')}</CustomLink>}
        </>
    );
};

export default Expand;