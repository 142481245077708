import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/operator/routePaths";
import TabLinks from "../../../components/TabLinks";
import SectorEditInformation from "./SectorEditInformation";
import SectorEditControllers from "./SectorEditControllers";
import SectorEditStreets from "./SectorEditStreets";
import SectorEditHouses from "./SectorEditHouses";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";
import SectorsServices from "../../../services/operator/sectorsServices";
import SectorEditOperators from "./SectorEditOperators";

const SectorEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(SectorsServices.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.sectors.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.sectors.edit.controllers(id)}>{t('labels.controllers')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.sectors.edit.operators(id)}>{t('labels.operators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.sectors.edit.streets(id)}>{t('labels.streets')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.sectors.edit.houses(id)}>{t('labels.houses')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.sectors.edit.index()} to={routePaths.sectors.edit.information()}/>
                <Route path={routePaths.sectors.edit.information()} component={SectorEditInformation}/>
                <Route path={routePaths.sectors.edit.controllers()} component={SectorEditControllers}/>
                <Route path={routePaths.sectors.edit.operators()} component={SectorEditOperators}/>
                <Route path={routePaths.sectors.edit.streets()} component={SectorEditStreets}/>
                <Route path={routePaths.sectors.edit.houses()} component={SectorEditHouses}/>
            </Switch>
        </>
    );
};

export default SectorEdit;