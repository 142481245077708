import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/admin/routePaths";
import CompanyEditInformation from "./CompanyEditInformation";
import CompanyEditOperators from "./CompanyEditOperators";
import CompanyEditControllers from "./CompanyEditControllers";
import CompanyEditSectors from "./CompanyEditSectors";
// import CompanyEditCities from "./CompanyEditCities";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import CompaniesServices from "../../../services/admin/companiesServices";
import {useLangContext} from "../../../i18n/ProvideLang";
import CompanyEditSealers from './CompanyEditSealers';
import CompanyEditSealerOperators from './CompanyEditSealerOperators';
import CompanyEditAnalytics from './CompanyEditAnalytics';
import CompanyEditAddresses from './CompanyEditAddresses';

const CompanyEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(CompaniesServices.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.operators(id)}>{t('labels.operators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.controllers(id)}>{t('labels.controllers')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.sealers(id)}>{t('labels.sealers')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.sealers_operators(id)}>{t('labels.sealer_operators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.analytics(id)}>{t('labels.analytics')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.addresses(id)}>{t('labels.addresses')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.companies.edit.sectors(id)}>{t('labels.sectors')}</NavLink>
                {/*<NavLink activeClassName='active' exact to={routePaths.companies.edit.cities(id)}>{t('labels.cities')}</NavLink>*/}
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.companies.edit.index()} to={routePaths.companies.edit.information()}/>
                <Route path={routePaths.companies.edit.information()} component={CompanyEditInformation}/>
                <Route path={routePaths.companies.edit.operators()} component={CompanyEditOperators}/>
                <Route path={routePaths.companies.edit.controllers()} component={CompanyEditControllers}/>
                <Route path={routePaths.companies.edit.sealers()} component={CompanyEditSealers}/>
                <Route path={routePaths.companies.edit.sealers_operators()} component={CompanyEditSealerOperators}/>
                <Route path={routePaths.companies.edit.analytics()} component={CompanyEditAnalytics}/>
                <Route path={routePaths.companies.edit.sectors()} component={CompanyEditSectors}/>
                <Route path={routePaths.companies.edit.addresses()} component={CompanyEditAddresses}/>
                {/*<Route path={routePaths.companies.edit.cities()} component={CompanyEditCities}/>*/}
            </Switch>
        </>
    );
};

export default CompanyEdit;