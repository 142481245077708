import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import NotificationsServices from "../../../services/admin/notificationsServices";
import {useHistory, useParams} from "react-router-dom";
import WhiteBlock from "../../../components/WhiteBlock";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import TablePreloader from "../../../components/TablePreloader";
import FormGroup from "../../../components/FormGroup";
import {useLangContext} from "../../../i18n/ProvideLang";

const NotificationDetail = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {id} = useParams();

    const history = useHistory();

    const {info, loading} = useFetchInfo(NotificationsServices.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.notifications.index,
                title: t('sidebar.notifications.title')
            },
            {
                path: null,
                title: t('labels.detail') + " - " + info[`name_${lang}`]
            }
        ])
    }, [setBreadcrumbs, t, info, lang]);

    const [formattedUsers, setFormattedUsersLoading] = useState();

    useEffect(() => {
        if (info?.users) {
            const newItems = info.users.map(({id, name, phone, email}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        phone,
                        email
                    ]
                }
            })

            setFormattedUsersLoading(newItems);
        }
    }, [info]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.name'), t('labels.phone_number'), t('labels.email')],
        sizes: ['10%', '30%', '30%', '30%']
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.detail')} {info && " - " + info[`name_${lang}`]}</h1>
            <WhiteBlock>
                <h3 className="headline-6 blue">{t('labels.receivers')}</h3>
                {formattedUsers
                    ? <>
                        <ItemsTable
                            {...{config: tableConfig, loading}}
                            items={formattedUsers}
                        />
                    </>
                    : <TablePreloader height={132}/>}
            </WhiteBlock>
            <WhiteBlock>
                <FormGroup label={t('labels.name_kk')}>
                    {info?.name_kk ? info.name_kk : t('labels.loading')}
                </FormGroup>
                <FormGroup label={t('labels.name_ru')}>
                    {info?.name_ru ? info.name_ru : t('labels.loading')}
                </FormGroup>
                <FormGroup label={t('labels.description_kk')}>
                    {info?.description_kk ? info.description_kk : t('labels.loading')}
                </FormGroup>
                <FormGroup label={t('labels.description_ru')}>
                    {info?.description_ru ? info.description_ru : t('labels.loading')}
                </FormGroup>
                <div className="text-right">
                    <Button onClick={() => history.goBack()}>{t('labels.back')}</Button>
                </div>
            </WhiteBlock>
        </>
    );
};

export default NotificationDetail;