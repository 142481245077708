import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/minAdmin/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import BankbooksServices from "../../../services/minAdmin/bankbooksServices";
import BankbooksFilter from "./components/BankbooksFilter";
import SecondaryLink from "../../../components/SecondaryLink";
import Expand from "../../../components/Expand";

const Bankbooks = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.bankbooks.title')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(BankbooksServices.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, user, devices, company, address}) => {
                return {
                    id,
                    fields: [
                        id,
                        number,
                        <React.Fragment>
                            {user?.name}
                            {user?.phone && <>
                                <br/>
                                <SecondaryLink href={`tel:${user.phone}`}>{user.phone}</SecondaryLink>
                            </>}
                        </React.Fragment>,
                        devices?.map(item => item.number).join(', '),
                        company?.name,
                        <Expand height={50}>{address}</Expand>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.account_number'), t('labels.data'), t('labels.devices'), t('labels.resource_company'),  t('labels.address'), ''],
        sizes: ['5%', '14%', '17%', '17%', '17%', '17%', '']
    }


    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.bankbooks.title')}</h1>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button $small $withIcon variant="outline" to={routePaths.bankbooks.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
                    </div>
                </div>
            </HeadingWithButtons>
            <BankbooksFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.bankbooks.edit.information}
                                    removeMethod={BankbooksServices.delete}
                                    vAlign={"top"}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Bankbooks;