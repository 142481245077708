import React, {useEffect, useRef, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/CallOperator/routePaths";
import WhiteBlock from "../../../components/WhiteBlock";
import Button from "../../../components/Button";
import {Controller, useForm} from "react-hook-form";
import InputMessage from "../../../components/InputMessage";
import FormGroup from "../../../components/FormGroup";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import Dictionaries from "../../../services/callOperator/dictionaries";
import Input from "../../../components/Input";
import SelectBankbookModal from "./components/SelectBankbookModal";
import AddServiceModal from "./components/AddServiceModal";
import ItemsTable from "../../../components/ItemsTable";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import ActionBtn from "../../../components/ActionBtn";
import styled, {css} from "styled-components";
import ApplicationsServices from "../../../services/callOperator/applicationsServices";
import {toast} from "react-toastify";
import TextArea from "../../../components/TextArea";
import {useHistory} from "react-router-dom";
import InputMask from 'react-input-mask'

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const Buttons = styled.div`
  display: inline-flex;
  justify-content: flex-end;

  & > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  ${props => props.buttonsCount && css`
    width: ${24 * props.buttonsCount + 20 * (props.buttonsCount - 1) + "px"}
  `}
`;

const ApplicationCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();
    
    const history = useHistory()

    const [loading, setLoading] = useState()

    const [bankbookModalIsOpen, setBankbookModalIsOpen] = useState()
    const [serviceModal, setServiceModal] = useState();

    const [editServiceInfo, setEditServiceInfo] = useState()

    const [bankbookInfo, setBankbookInfo] = useState();
    const [services, setServices] = useState([]);
    const companyId = watch('company_id', undefined)
    const cityId = watch('city_id', undefined)
    const laboratoryId = watch('laboratory_id', undefined)
    const prevLaboratoryId = usePrevious(laboratoryId)

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.applications.index,
                title: t('sidebar.applications.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    useEffect(() => {
        if (bankbookInfo) {
            setValue('phone', '')
            for (let key in bankbookInfo) {
                if (bankbookInfo.hasOwnProperty(key)) {
                    bankbookInfo[key] && setValue(key, String(bankbookInfo[key]))
                }
            }
        }
    }, [bankbookInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevLaboratoryId !== laboratoryId) {
            setServices([])
        }
    }, [laboratoryId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleEdit = (e, id) => {
        e.preventDefault();
        setEditServiceInfo(services.find(item => item.id === id));
        setServiceModal(true)
    }

    const handleRemove = (e, id) => {
        e.preventDefault();
        const removeIndex = services.findIndex(item => item.id === id)
        const newArr = [...services];
        newArr.splice(removeIndex, 1);
        setServices(newArr)
    }

    const customSubmit = async data => {
        setLoading(true)
        let newData;
        const newServices = services.map(({id, amount}) => {
            return {
                id,
                count: amount,
                laboratory_id: laboratoryId
            }
        })

        if (bankbookInfo) {
            newData = {
                bankbook_id: bankbookInfo.id,
                company_id: bankbookInfo.company_id,
                description: data.description,
                services: newServices
            }
        } else {
            const {company_id, name, address, type, description, phone} = data;
            newData = {
                company_id, name, address, type, phone, description,
                services: newServices
            }
        }

        const res = await ApplicationsServices.store(newData)

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            history.push(routePaths.applications.detail.index(res.content[0].id))
        }

        setLoading(false)
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <form onSubmit={handleSubmit(customSubmit)}>
                <WhiteBlock>
                    <div className="mb-20">
                        <div className="row justify-space-between align-items-center">
                            <div className="col-auto">
                                <h2 className="headline-5">{t('labels.subscriber_information')}</h2>
                            </div>
                            <div className="col-auto">
                                {!bankbookInfo
                                    ? <Button $small variant="outline" onClick={(e) => {
                                        e.preventDefault()
                                        setBankbookModalIsOpen(true);
                                    }}>{t('labels.choose_subscriber')}</Button>
                                    : <Button $small variant="outline" $red onClick={(e) => {
                                        e.preventDefault()
                                        setBankbookInfo(null)
                                    }}>{t('labels.unbind_subscriber')}</Button>
                                }
                            </div>
                        </div>
                    </div>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.companies}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.company_id && !bankbookInfo}
                                            isDisabled={bankbookInfo}
                                        />
                                    )}
                                    rules={{required: !bankbookInfo}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.fullName') + " *"}>
                        <Input type="text" {...register('name', {required: !bankbookInfo})} $error={errors.name && !bankbookInfo} disabled={bankbookInfo}/>
                        {errors.name && errors.name.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.address') + " *"}>
                        <Input type="text" {...register('address', {required: !bankbookInfo})} $error={errors.address && !bankbookInfo} disabled={bankbookInfo}/>
                        {errors.address && errors.address.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.subscriber_type') + " *"}>
                        <Controller name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookUserTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.type && !bankbookInfo}
                                            isDisabled={bankbookInfo}
                                        />
                                    )}
                                    rules={{required: !bankbookInfo}}
                        />
                        {errors.type && errors.type.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number') + " *"}>
                        <Controller defaultValue="" name="phone" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <InputMask onChange={onChange} value={value}
                                                   mask="+7(999)999-99-99" maskChar="_"
                                                   disabled={bankbookInfo}>
                                            {
                                                inputProps => (
                                                    <Input type="tel" $error={errors.phone && !bankbookInfo} disabled={bankbookInfo} {...inputProps}/>
                                                )
                                            }
                                        </InputMask>
                                    )}
                                    rules={{required: !bankbookInfo, pattern: {
                                        value: /(?:\+|\d)[\d\-\(\) ]{9,}\d/g
                                    }}}
                        />
                        {errors.phone && errors.phone.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                        {errors.phone && errors.phone.type === 'pattern' &&
                        <InputMessage $error>{t('messages.phone_pattern_error')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.description')}>
                        <TextArea {...register('description')}/>
                    </FormGroup>
                </WhiteBlock>
                <WhiteBlock className={loading ? "isLoading" : ""}>
                    <h2 className="headline-5">{t('labels.services')}</h2>
                    <FormGroup label={t('labels.city')}>
                        <Controller key={'cities_of_' + companyId} name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.cities}
                                            onChange={onChange}
                                            value={value}
                                            data={companyId ? {
                                                queryString: `company_id=${companyId}`
                                            } : null}
                                            $error={errors.city_id}
                                            isDisabled={!companyId}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.laboratory')}>
                        <Controller key={'labs_of_' + cityId} name="laboratory_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.laboratories}
                                            onChange={onChange}
                                            value={value}
                                            data={cityId ? {
                                                queryString: `city_id=${cityId}`
                                            } : null}
                                            $error={errors.laboratory_id}
                                            isDisabled={!cityId}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.laboratory_id && errors.laboratory_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="mb-20">
                        <HeadingWithButtons>
                            <h3 className="label-2" style={{color: "var(--secondary)", flexGrow: 1, marginBottom: 0}}>{t('labels.services_list')}</h3>
                            <Button variant="outline" $small $withIcon $yellow disabled={!laboratoryId} onClick={(e) => {
                                e.preventDefault();
                                setServiceModal(true)
                            }}><i className="icon-plus"/><span>{t('labels.add_service')}</span></Button>
                        </HeadingWithButtons>
                    </div>
                    {services.length
                        ? <ItemsTable
                            items={services.map(({id, name, price, amount}) => {
                                return {
                                    id,
                                    fields: [
                                        id,
                                        name,
                                        price,
                                        amount ? amount : 0,
                                        Number(price)*Number(amount ? amount : 0),
                                        <Buttons buttonsCount={2}>
                                            <ActionBtn className="icon-pencil" onClick={e => handleEdit(e, id)}/>
                                            <ActionBtn className="icon-trash" onClick={e => handleRemove(e, id)}
                                                       color="red"/>
                                        </Buttons>
                                    ]
                                }
                            })}
                            config={{
                                head: ['ID', t('labels.services'), t('labels.price_tng'), t('labels.amount'), t('labels.total_price_tng'), ''],
                                sizes: ['10%', '35%', '15%', '15%', '15%', '']
                            }}
                        />
                        : t('messages.no_data_available')
                    }
                </WhiteBlock>
                <div className="text-right">
                    <Button variant="outline">{t('labels.make_a_request')}</Button>
                </div>
            </form>

            <SelectBankbookModal
                isOpen={bankbookModalIsOpen}
                closeModal={() => setBankbookModalIsOpen(false)}
                onRequestClose={() => setBankbookModalIsOpen(false)}
                setBankbookValue={setBankbookInfo}
            />

            {laboratoryId && serviceModal && <AddServiceModal
                isOpen={serviceModal}
                closeModal={() => setServiceModal(false)}
                onRequestClose={() => setServiceModal(false)}
                laboratoryId={laboratoryId}
                serviceInfo={editServiceInfo}
                addService={(service) => setServices(services => {
                    if (services.some(item => item.id === service.id)) {
                        const spliceIndex = services.findIndex(item => item.id === service.id)
                        const newServices = [...services];
                        newServices.splice(spliceIndex, 1, service);
                        return newServices
                    } else {
                        return [...services, service]
                    }
                })}
            />}
        </>
    );
};

export default ApplicationCreate;