import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/minAdmin/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import FilterTableWrapper from "../../../../components/FilterTableWrapper";
import FilterSidebar from "../../../../components/FilterSidebar";
import WhiteBlock from "../../../../components/WhiteBlock";
import Button from "../../../../components/Button";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import AddressesServices from "../../../../services/minAdmin/addressesServices";
import SecondaryLink from "../../../../components/SecondaryLink";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AttachStreetModal from "./components/AttachStreetModal";
import {useLangContext} from "../../../../i18n/ProvideLang";
import NameFilter2 from '../../../../components/filters/NameFilter2';

const MicrodistrictEditStreets = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, loading: fetchLoading} = useFetchInfo(AddressesServices.microdistricts.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.microdistricts.index,
                title: t('sidebar.addresses.children.microdistricts')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.streets')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(AddressesServices.microdistricts.streets.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name}) => {
                return {
                    id,
                    fields: [
                        id,
                        <SecondaryLink to={routePaths.addresses.children.streets.edit.information(id)}>{name}</SecondaryLink>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.street'), ''],
        sizes: ['10%', '80%', '']
    }

    return (
        <FilterTableWrapper>
            <div>
                <FilterSidebar>
                    <NameFilter2 setFilterData={setFilterData} params={params}/>
                </FilterSidebar>
            </div>
            <div>
                <WhiteBlock>
                    {formattedItems
                        ? <>
                            <ItemsTable
                                {...{config: tableConfig, fetchItems, loading}}
                                items={formattedItems}
                                removeMethod={childId => AddressesServices.microdistricts.streets.detach({parentId: id, id: childId})}
                                removeModalContent={{
                                    title: t('modals.remove_street.title'),
                                    yes: t('labels.yes'),
                                    no: t('labels.cancel')
                                }}
                                topBarRightContent={() => (
                                    <div className="row row--multiline justify-end">
                                        <div className="col-auto">
                                            <Button $small disabled={fetchLoading} $customColor={'#ED7E52'} $withIcon variant="outline" to={{pathname: routePaths.addresses.children.streets.create, state: {region_id: info?.region_id, company_id: info?.company_id, city_id: info?.city_id, district_id: info?.district_id, microdistrict_id: id}}}><i className="icon-plus"/><span>{t('labels.create')}</span></Button>
                                        </div>
                                        <div className="col-auto">
                                            <Button $small disabled={fetchLoading} $customColor={'#1FA2FF'} $withIcon variant="outline" onClick={() => setAttachModal(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                        </div>
                                    </div>
                                )}
                            />
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </>
                        : <TablePreloader/>}
                    {attachModal && !fetchLoading &&
                    <AttachStreetModal
                        isOpen={attachModal}
                        closeModal={() => setAttachModal(false)}
                        onRequestClose={() => setAttachModal(false)}
                        method={AddressesServices.microdistricts.streets.attach}
                        successCallback={fetchItems}
                        data={info}
                        id={id}
                    />
                    }
                </WhiteBlock>
            </div>
        </FilterTableWrapper>
    );
};

export default MicrodistrictEditStreets;