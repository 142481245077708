import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import routePaths from "../../../navigation/operator/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomSelect from "../../../components/CustomSelect";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/operator/dictionaries";
import IndicationsServices from "../../../services/operator/indicationsServices";
import FilesUploader from "../../../components/FilesUploader";
import IndicationCreatePreparedData from "./components/IndicationCreatePreparedData";
import Utils from "../../../services/utils";
import {useAuth} from "../../../navigation/Auth/ProvideAuth";
import InputMessage from "../../../components/InputMessage";

const IndicationCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    const {userData} = useAuth();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.indications.index,
                title: t('sidebar.indications.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    

    const companyId = userData.company_id;
    const bankbookId = watch('bankbook_id', null);
    const deviceId = watch('device_id', null);
    const images = watch('images', null)

    const [bankbooks, setBankbooks] = useState();
    const [bankbooksLoading, setBankbooksLoading] = useState(true);

    const [devices, setDevices] = useState();
    const [devicesLoading, setDevicesLoading] = useState(true);

    const fetchBankbooks = useCallback(async () => {
        if (companyId) {
            setBankbooksLoading(true);

            const res = await Dictionaries.bankbooks({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setBankbooks(res.content);

                if (!res.content.some(item => item.value === bankbookId)) {
                    setValue('bankbook_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setBankbooksLoading(false);
        } else {
            setValue('bankbook_id', null)
        }
    }, [setValue, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchDevices = useCallback(async () => {
        if (bankbookId) {
            setDevicesLoading(true);

            const res = await Dictionaries.devices({queryString: `bankbook_id=${bankbookId}`});

            if (res.statusCode === 200) {
                setDevices(res.content);

                if (!res.content.some(item => item.value === deviceId)) {
                    setValue('device_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDevicesLoading(false);
        } else {
            setValue('device_id', null)
        }
    }, [setValue, bankbookId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchBankbooks();
    }, [fetchBankbooks]);

    useEffect(() => {
        fetchDevices();
    }, [fetchDevices]);

    const {onSubmit, loading} = useStore(IndicationsServices.store);

    const customSubmit = (data) => {
        const {images, value, device_id} = data;

        const newData = {
            images: images ? Utils.transformFiles(images) : [],
            value,
            device_id
        };

        onSubmit(newData)
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(customSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.bankbook') + " *"}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={bankbooks} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!companyId ? bankbooksLoading : false}
                                                      isDisabled={!!!companyId || bankbooksLoading || state?.bankbook_id}
                                                      $error={errors.bankbook_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bankbook_id && errors.bankbook_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.device') + " *"}>
                        <Controller name="device_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={devices} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!bankbookId ? devicesLoading : false}
                                                      isDisabled={!!!bankbookId || devicesLoading || state?.device_id}
                                                      $error={errors.device_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.device_id && errors.device_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    {deviceId && <IndicationCreatePreparedData id={deviceId} {...{register, setValue}}/>}
                    <FormGroup label={t('labels.current_indication') + (images && images.length ? "" : " *")}>
                        <Input type="text" {...register('value', {required: !images || !images.length})} $error={errors.value} disabled={!deviceId}/>
                        {errors.value && errors.value.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.photo')}>
                        <Controller name="images" control={control} render={({field: {onChange, value}}) => (
                            <FilesUploader onChange={onChange} value={value} maxFiles={3} accept="image/*">
                                {t('labels.files_uploader_text', {formats: '.jpg, .png', size: '20MB'})}
                            </FilesUploader>
                        )}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default IndicationCreate;