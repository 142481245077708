import React from 'react';
import styled from "styled-components";

const OverWrapper = styled.div`
  padding: 0 24px;
`

const Wrapper = styled.div`
  overflow-x: auto;
  margin-left: -24px;
  margin-right: -24px;
  min-height: 30px;
`

const Content = styled.div`
  padding: 0 24px;
  display: table;
  width: 100%;
`

const OverflowXScrollable = ({children, ...props}) => {
    return (
        <OverWrapper>
            <Wrapper {...props}>
                <Content>
                    {children}
                </Content>
            </Wrapper>
        </OverWrapper>
    );
};

export default OverflowXScrollable;