import React from 'react';
import styled, {css} from 'styled-components'

const Container = styled.div`
  font-size: 12px;
  margin: 15px 0;
  padding: 16px 30px 16px 16px;
  color: #828282;
  background: #F5F5F5;
  border-radius: 4px;
  position:relative;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ${props => props.type === 'success' && css`
    color: #20782E;
    background: #C5FDDF;
  `}

  ${props => props.type === 'info' && css`
    color: #347797;
    background: #BEEAFF;
  `}

  ${props => props.type === 'warning' && css`
    color: #977B34;
    background: #fff3cf;
  `}

  ${props => props.type === 'danger' && css`
    color: #973434;
    background: #FFDBDB;
  `}
  
  a {
    color: var(--accent);
    transition: .2s;
    
    &:hover {
      color: var(--accent-hover);
    }
  }
`;

const HideBtn = styled.i`
  position: absolute;
  right: 10px;
  font-size: 16px;
  top: 15px;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    opacity: .75;
  }
`;

const Alert = ({children, ...props}) => {
    return (
        <Container {...props}>
            {children}
            {props.hideCallback && <HideBtn className="icon-close" onClick={props.hideCallback}/>}
        </Container>
    );
};

export default Alert;