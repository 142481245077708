import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../hooks/useUpdate.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import routePaths from "../../../navigation/admin/routePaths";
import {useLangContext} from "../../../i18n/ProvideLang";
import InputMessage from "../../../components/InputMessage";
import DictionariesServices from "../../../services/admin/dictionariesServices";
import Checkbox from "../../../components/Checkbox";

const ApplicationRejectReasonEdit = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const {info, loading: fetchLoading} = useFetchInfo(DictionariesServices.applicationRejectReason.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.dictionaries.index,
                title: t('sidebar.dictionaries.title')
            },
            {
                path: routePaths.dictionaries.children.application_reject_reason.index,
                title: t('sidebar.dictionaries.children.application_reject_reason')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            }
        ])
    }, [setBreadcrumbs, t, info, lang]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                info[key] && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const {onSubmit, loading} = useUpdate(DictionariesServices.applicationRejectReason.update, id);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.editing')}{info && " - " + info[`name_${lang}`]}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.description_kk')}>
                        <TextArea {...register('description_kk')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.description_ru')}>
                        <TextArea {...register('description_ru')}/>
                    </FormGroup>
                    <div className="mb-20">
                        <Checkbox {...register('is_published')}>{t('labels.is_published')}</Checkbox>
                    </div>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.goBack();
                }}
                method={DictionariesServices.applicationRejectReason.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default ApplicationRejectReasonEdit;