import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import routePaths from "./routePaths";
import generalRoutePaths from "../general/routePaths";
import DefaultLayout from "../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import Controllers from "../../pages/operator/Users/Controllers";
import ControllerCreate from "../../pages/operator/Users/Controllers/ControllerCreate";
import ControllerEdit from "../../pages/operator/Users/Controllers/ControllerEdit";
import CompanyEdit from "../../pages/operator/Companies/CompanyEdit";
import Sectors from "../../pages/operator/Sectors";
import SectorCreate from "../../pages/operator/Sectors/SectorCreate";
import SectorEdit from "../../pages/operator/Sectors/SectorEdit";
import Bankbooks from "../../pages/operator/Bankbooks";
import BankbookCreate from "../../pages/operator/Bankbooks/BankbookCreate";
import BankbookEdit from "../../pages/operator/Bankbooks/BankbookEdit";
import Devices from "../../pages/operator/Devices";
import DeviceCreate from "../../pages/operator/Devices/DeviceCreate";
import DeviceEdit from "../../pages/operator/Devices/DeviceEdit";
import Indications from "../../pages/operator/Indications";
import IndicationCreate from "../../pages/operator/Indications/IndicationCreate";
import IndicationEdit from "../../pages/operator/Indications/IndicationEdit";
import Notifications from "../../pages/operator/Notifications";
import NotificationCreate from "../../pages/operator/Notifications/NotificationCreate";
import NotificationDetail from "../../pages/operator/Notifications/NotificationDetail";
import Profile from "../../pages/general/Profile";
import {useAuth} from "../Auth/ProvideAuth";
import {useLangContext} from "../../i18n/ProvideLang";
import Cities from "../../pages/operator/Addresses/Cities";
import CityCreate from "../../pages/operator/Addresses/Cities/CityCreate";
import CityEdit from "../../pages/operator/Addresses/Cities/CityEdit";
import Districts from "../../pages/operator/Addresses/Districts";
import DistrictCreate from "../../pages/operator/Addresses/Districts/DistrictCreate";
import DistrictEdit from "../../pages/operator/Addresses/Districts/DistrictEdit";
import Microdistricts from "../../pages/operator/Addresses/Microdistricts";
import MicrodistrictCreate from "../../pages/operator/Addresses/Microdistricts/MicrodistrictCreate";
import MicrodistrictEdit from "../../pages/operator/Addresses/Microdistricts/MicrodistrictEdit";
import Streets from "../../pages/operator/Addresses/Streets";
import StreetCreate from "../../pages/operator/Addresses/Streets/StreetCreate";
import StreetEdit from "../../pages/operator/Addresses/Streets/StreetEdit";
import Houses from "../../pages/operator/Addresses/Houses";
import HouseCreate from "../../pages/operator/Addresses/Houses/HouseCreate";
import HouseEdit from "../../pages/operator/Addresses/Houses/HouseEdit";
import Apartments from "../../pages/operator/Addresses/Apartments";
import ApartmentCreate from "../../pages/operator/Addresses/Apartments/ApartmentCreate";
import ApartmentEdit from "../../pages/operator/Addresses/Apartments/ApartmentEdit";
import AddressesImport from "../../pages/operator/Import/Addresses";
import DevicesImport from "../../pages/operator/Import/Devices";
import IndicationsImport from "../../pages/operator/Import/Indications";
import IndicationsSettingsApp from "../../pages/operator/Settings/IndicationSettingsApp";
import TasksStatics from '../../pages/operator/Tasks/Statistic';
import TasksCompanies from '../../pages/operator/Tasks/Companies';
import TaskSearlers from '../../pages/operator/Tasks/Searlers';
import TaskController from '../../pages/operator/Tasks/Controllers';
import TaskControllerEdit from '../../pages/operator/Tasks/Controllers/TaskControllerEdit';
import TaskComponiesEdit from '../../pages/operator/Tasks/Companies/TaskComponiesEdit';
import TaskSealersEdit from '../../pages/operator/Tasks/Searlers/TaskSealersEdit';
import DevicesDeleteImport from '../../pages/operator/Import/DevicesDeleteImport';

const Routes = () => {
    const {t} = useTranslation();

    const {userData} = useAuth()
    const {lang} = useLangContext()

    const sidebarItems = [
        {
            title: userData?.company[`name_${lang}`],
            url: routePaths.company.index,
            icon: "briefcase"
        },
        {
            title: t('sidebar.users.children.controllers'),
            url: routePaths.users.children.controllers.index,
            icon: "users"
        },
        {
            title: t('sidebar.sectors.title'),
            url: routePaths.sectors.index,
            icon: "tool-crop"
        },
        {
            title: t('sidebar.addresses.title'),
            url: routePaths.addresses.index,
            icon: "marker",
            children: [
                {
                    title: t('sidebar.addresses.children.cities'),
                    url: routePaths.addresses.children.cities.index
                },
                {
                    title: t('sidebar.addresses.children.districts'),
                    url: routePaths.addresses.children.districts.index
                },
                {
                    title: t('sidebar.addresses.children.microdistricts'),
                    url: routePaths.addresses.children.microdistricts.index
                },
                {
                    title: t('sidebar.addresses.children.streets'),
                    url: routePaths.addresses.children.streets.index
                },
                {
                    title: t('sidebar.addresses.children.houses'),
                    url: routePaths.addresses.children.houses.index
                },
                {
                    title: t('sidebar.addresses.children.apartments'),
                    url: routePaths.addresses.children.apartments.index
                }
            ]
        },
        {
            title: t('sidebar.bankbooks.title'),
            url: routePaths.bankbooks.index,
            icon: "badge"
        },
        {
            title: t('sidebar.devices.title'),
            url: routePaths.devices.index,
            icon: "dashboard"
        },
        {
            title: t('sidebar.indications.title'),
            url: routePaths.indications.index,
            icon: "receipt",
            children: [
                {
                    title: t('sidebar.indications.children.all'),
                    url: routePaths.indications.children.all.index
                },
                {
                    title: t('sidebar.indications.children.on_check'),
                    url: routePaths.indications.children.on_check.index
                },
                {
                    title: t('sidebar.indications.children.approved'),
                    url: routePaths.indications.children.approved.index
                },
                {
                    title: t('sidebar.indications.children.declined'),
                    url: routePaths.indications.children.declined.index
                }
            ]
        },
        {
            title: t('sidebar.tasks.title'),
            url: routePaths.tasks.index,
            icon: "tasks",
            children: [
                {
                    title: t('sidebar.tasks.children.assignment_tasks.index'),
                    url: routePaths.tasks.children.companies.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.assignment_tasks.children.companies'),
                            url: routePaths.tasks.children.companies.index
                        },
                        {
                            title: t('sidebar.tasks.children.assignment_tasks.children.controllers'),
                            url: routePaths.tasks.children.controllers.index
                        },
                        {
                            title: t('sidebar.tasks.children.assignment_tasks.children.sealers'),
                            url: routePaths.tasks.children.sealers.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.statistics.index'),
                    url: routePaths.tasks.children.statistic.index,
                    children:[
                        {
                            title: t('sidebar.tasks.children.statistics.children.controllers'),
                            url: routePaths.tasks.children.statistic.children.controllers.index
                        },
                        {
                            title: t('sidebar.tasks.children.statistics.children.sealers'),
                            url: routePaths.tasks.children.statistic.children.sealers.index
                        },
                    ]
                }
            ]
        },
        {
            title: t('sidebar.settings.title'),
            url: routePaths.settings.index,
            icon: "settings",
            children: [
                {
                    title: t('sidebar.settings.children.indication_settings_app'),
                    url: routePaths.settings.children.indication_settings_app.index
                }
            ]
        },
        {
            title: t('sidebar.notifications.title'),
            url: routePaths.notifications.index,
            icon: "bell"
        },
        {
            title: t('sidebar.data_import.title'),
            url: routePaths.data_import.index,
            icon: "upload",
            children: [
                {
                    title: t('sidebar.data_import.children.addresses'),
                    url: routePaths.data_import.children.addresses.index
                },
                {
                    title: t('sidebar.data_import.children.devices'),
                    url: routePaths.data_import.children.devices.index
                },
                {
                    title: t('sidebar.data_import.children.indications'),
                    url: routePaths.data_import.children.indications.index
                },
                {
                    title: t('sidebar.data_import.children.devices_to_delete'),
                    url: routePaths.data_import.children.devices_to_delete.index
                }
            ]
        }
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.company.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Users*/}
                <Redirect exact from={routePaths.users.index} to={routePaths.users.children.controllers.index}/>
                {/*Users - Operators*/}
                {/*<Route exact path={routePaths.users.children.operators.index} component={Operators}/>*/}
                {/*<Route exact path={routePaths.users.children.operators.create} component={OperatorCreate}/>*/}
                {/*<Route exact path={routePaths.users.children.operators.edit()} component={OperatorEdit}/>*/}
                {/*Users - Controllers*/}
                <Route exact path={routePaths.users.children.controllers.index} component={Controllers}/>
                <Route exact path={routePaths.users.children.controllers.create} component={ControllerCreate}/>
                <Route exact path={routePaths.users.children.controllers.edit()} component={ControllerEdit}/>
                {/*Companies*/}
                <Route path={routePaths.company.index} component={CompanyEdit}/>
                {/*Sectors*/}
                <Route exact path={routePaths.sectors.index} component={Sectors}/>
                <Route exact path={routePaths.sectors.create} component={SectorCreate}/>
                <Route path={routePaths.sectors.edit.index()} component={SectorEdit}/>
                {/*Addresses*/}
                <Redirect exact from={routePaths.addresses.index} to={routePaths.addresses.children.cities.index}/>
                {/*Addresses - Cities*/}
                <Route exact path={routePaths.addresses.children.cities.index} component={Cities}/>
                <Route exact path={routePaths.addresses.children.cities.create} component={CityCreate}/>
                <Route path={routePaths.addresses.children.cities.edit.index()} component={CityEdit}/>
                {/*Addresses - Districts*/}
                <Route exact path={routePaths.addresses.children.districts.index} component={Districts}/>
                <Route exact path={routePaths.addresses.children.districts.create} component={DistrictCreate}/>
                <Route path={routePaths.addresses.children.districts.edit.index()} component={DistrictEdit}/>
                {/*Addresses - Microdistricts*/}
                <Route exact path={routePaths.addresses.children.microdistricts.index} component={Microdistricts}/>
                <Route exact path={routePaths.addresses.children.microdistricts.create} component={MicrodistrictCreate}/>
                <Route path={routePaths.addresses.children.microdistricts.edit.index()} component={MicrodistrictEdit}/>
                {/*Addresses - Streets*/}
                <Route exact path={routePaths.addresses.children.streets.index} component={Streets}/>
                <Route exact path={routePaths.addresses.children.streets.create} component={StreetCreate}/>
                <Route path={routePaths.addresses.children.streets.edit.index()} component={StreetEdit}/>
                {/*Addresses - Houses*/}
                <Route exact path={routePaths.addresses.children.houses.index} component={Houses}/>
                <Route exact path={routePaths.addresses.children.houses.create} component={HouseCreate}/>
                <Route path={routePaths.addresses.children.houses.edit.index()} component={HouseEdit}/>
                {/*Addresses - Apartments*/}
                <Route exact path={routePaths.addresses.children.apartments.index} component={Apartments}/>
                <Route exact path={routePaths.addresses.children.apartments.create} component={ApartmentCreate}/>
                <Route path={routePaths.addresses.children.apartments.edit.index()} component={ApartmentEdit}/>
                {/*Bankbooks*/}
                <Route exact path={routePaths.bankbooks.index} component={Bankbooks}/>
                <Route exact path={routePaths.bankbooks.create} component={BankbookCreate}/>
                <Route path={routePaths.bankbooks.edit.index()} component={BankbookEdit}/>
                {/*Devices*/}
                <Route exact path={routePaths.devices.index} component={Devices}/>
                <Route exact path={routePaths.devices.create} component={DeviceCreate}/>
                <Route path={routePaths.devices.edit.index()} component={DeviceEdit}/>
                {/*Indications*/}
                <Redirect exact from={routePaths.indications.index} to={routePaths.indications.children.all.index}/>
                <Route exact path={routePaths.indications.children.all.index}>
                    <Indications statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.indications.children.on_check.index}>
                    <Indications key={routePaths.indications.children.on_check.index} statusId="0" statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.indications.children.approved.index}>
                    <Indications key={routePaths.indications.children.approved.index} statusId="1" statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.indications.children.declined.index}>
                    <Indications key={routePaths.indications.children.declined.index} statusId="2" statusText={t('sidebar.indications.children.declined')}/>
                </Route>
                <Route exact path={routePaths.indications.create} component={IndicationCreate}/>
                <Route path={routePaths.indications.edit.index()} component={IndicationEdit}/>
                {/*Settings*/}
                <Redirect exact from={routePaths.settings.index} to={routePaths.settings.children.indication_settings_app.index}/>
                <Route exact path={routePaths.settings.children.indication_settings_app.index} component={IndicationsSettingsApp}/>
                {/*Notifications*/}
                <Route exact path={routePaths.notifications.index} component={Notifications}/>
                <Route exact path={routePaths.notifications.create} component={NotificationCreate}/>
                <Route exact path={routePaths.notifications.detail()} component={NotificationDetail}/>
                {/*Imports*/}
                <Redirect exact from={routePaths.data_import.index} to={routePaths.data_import.children.addresses.index}/>
                <Route exact path={routePaths.data_import.children.addresses.index} component={AddressesImport}/>
                <Route exact path={routePaths.data_import.children.devices.index} component={DevicesImport}/>
                <Route exact path={routePaths.data_import.children.indications.index} component={IndicationsImport}/>
                <Route exact path={routePaths.data_import.children.devices_to_delete.index} component={DevicesDeleteImport}/>
                {/*Tasks Companies*/}
                <Route exact path={routePaths.tasks.children.companies.index} component={TasksCompanies}/>
                <Route exact path={routePaths.tasks.children.companies.edit()} component={TaskComponiesEdit}/>

                {/*Tasks Controllers*/}
                <Route exact path={routePaths.tasks.children.controllers.index} component={TaskController}/>
                <Route exact path={routePaths.tasks.children.controllers.edit()} component={TaskControllerEdit}/>            
                
                {/*Tasks Sealers*/}
                <Route exact path={routePaths.tasks.children.sealers.index} component={TaskSearlers}/>
                <Route exact path={routePaths.tasks.children.sealers.edit()} component={TaskSealersEdit}/>     

                {/*Tasks Statistics*/}
                <Route exact path={routePaths.tasks.children.statistic.children.controllers.index}>
                    <TasksStatics key={routePaths.tasks.children.statistic.children.controllers.index} role="controller"
                                 statusText={t('sidebar.tasks.children.statistics.children.controllers')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.statistic.children.sealers.index}>
                    <TasksStatics key={routePaths.tasks.children.statistic.children.sealers.index} role="sealer"
                                 statusText={t('sidebar.tasks.children.statistics.children.sealers')}/>
                </Route>

            </Switch>
        </DefaultLayout>
    );
};

export default Routes;