import React, {useEffect, useState} from 'react';
import {Table, Tbody, Td, Th, Thead, Tr} from "./Table";
import styled, {css} from "styled-components";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import ActionBtn from "./ActionBtn";
import ConfirmActionModal from "./modals/ConfirmActionModal";
import {Checkbox} from 'antd'
import ConfirmMultipleActionsModal from "./modals/ConfirmMultipleActionsModal";
// import ActionBtnSolid from "./ActionBtnSolid";

const StyledTh = styled(Th)`
  font-weight: 600;
  color: var(--gray-400);
  text-transform: uppercase;
  font-size: 12px;
`

const StyledTd = styled(Td)`
  ${props => props.vAlign && css`
    vertical-align: ${props.vAlign};
  `}
  .gray{
    font-size: 11px;
    color: #979797;
  }
  
`

const Buttons = styled.div`
  display: inline-flex;
  justify-content: flex-end;

  & > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  ${props => props.buttonsCount && css`
    width: ${24 * props.buttonsCount + 20 * (props.buttonsCount - 1) + "px"}
  `}
`;

const CustomTr = styled(Tr)`
  ${props => props.$clickable && css`
    cursor: pointer;

    &:hover {
      background-color: var(--accent-100);
    }
  `}
`;

const TopBar = styled.div`
  margin-bottom: 20px;
`

const ItemsTable = ({
                        config,
                        items,
                        rowLink,
                        vAlign,
                        loading,
                        fetchItems,
                        editLink,
                        editLinkState = null,
                        editCallback,
                        removeMethod,
                        topBarRightContent,
                        topBarLeftContent,
                        setCheckedList: passCheckedList,
                        view,
                        setViewId,
                        unpinMethod,
                        parentId,
                        ...props
                    }) => {
    const {head, sizes} = config;
    const history = useHistory();
    const {t} = useTranslation();
    const {removeModalContent} = props;
    const {unpinMethodContent} = props;

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
    const [multipleRemoveModalIsOpen, setMultipleRemoveModalIsOpen] = useState(false);
    const [removeId, setRemoveId] = useState();

    const [unpinModalIsOpen, setUnpinModalIsOpen] = useState(false);
    const [unpinId, setUnpinId] = useState();

    const plainOptions = items.map(({id}) => String(id));
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false)

    const handleEdit = (e, id) => {
        e.stopPropagation();
        if (editLink) {
            history.push({
                pathname: editLink(id),
                state: editLinkState
            })
        }
        if (editCallback) editCallback(id);
    }

    const handleRemove = (e, id) => {
        e.stopPropagation();
        setRemoveId(id);
        setRemoveModalIsOpen(true)
    }

    const handleView = (e, id) => {
        e.stopPropagation();
        setViewId(id);
        view();
    }

    const handleUnpin = (e, id) => {
        e.stopPropagation();
        setUnpinId(id);
        setUnpinModalIsOpen(true)
    }

    const onChange = e => {
        const newVal = e.target.value;
        let newList;

        if (checkedList.includes(newVal)) {
            newList = [...checkedList];
            const index = newList.findIndex(val => val === newVal);
            newList.splice(index, 1);
            setCheckedList(newList)
        } else {
            newList = [...checkedList, newVal];
            setCheckedList(newList);
        }

        setIndeterminate(!!newList.length && newList.length < plainOptions.length)
        setCheckAll(newList.length === plainOptions.length)
    }

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    useEffect(() => {
        passCheckedList && passCheckedList(checkedList);
    }, [checkedList])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCheckedList([])
    }, [items])

    if (!items.length) {
        return <>
            {topBarRightContent && <div className="mb-20">{topBarRightContent()}</div>}
            <div className={loading ? "isLoading" : ""}>
                {t('messages.no_data_available')}
            </div>
        </>
    }

    return (<>
            <div className={loading ? "isLoading" : ""}>
                {(passCheckedList || topBarRightContent) &&
                <TopBar>
                    <div className="row row--multiline align-items-center" style={{minHeight: 62}}>
                        {passCheckedList &&
                            <>
                                <div className="col-auto">
                                    <div style={{paddingLeft: 10}}>
                                        <Checkbox
                                            indeterminate={checkedList.length ? indeterminate : false}
                                            onChange={onCheckAllChange}
                                            checked={checkAll}
                                        >
                                            {t('labels.select_all')}
                                        </Checkbox>
                                    </div>
                                </div>
                                {topBarLeftContent && checkedList.length
                                    ? <div className="col-auto">
                                        {topBarLeftContent()}
                                    </div>
                                    : ''
                                }
                                {/*{removeMethod && checkedList.length*/}
                                {/*    ? <div className="col-auto">*/}
                                {/*        <ActionBtnSolid*/}
                                {/*            className="icon-trash"*/}
                                {/*            color="red"*/}
                                {/*            onClick={() => setMultipleRemoveModalIsOpen(true)}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*    : ''*/}
                                {/*}*/}
                            </>
                        }
                        {topBarRightContent && <div className="col-auto" style={{marginLeft: "auto"}}>{topBarRightContent()}</div>}
                    </div>
                </TopBar>
                }
                <Table {...props}>
                    <Thead>
                        <Tr>
                            {passCheckedList && <StyledTd/>}
                            {head.map((item, index) => (
                                <StyledTh key={index} style={{width: sizes[index]}}>{item}</StyledTh>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {items.map(({id, fields}) => (
                            <CustomTr key={id} $clickable={!!rowLink} onClick={
                                rowLink ? () => history.push(rowLink(id)) : null
                            }>
                                {passCheckedList &&
                                <StyledTd vAlign={vAlign} onClick={e => e.stopPropagation()}>
                                    <Checkbox
                                        value={String(id)}
                                        onChange={onChange}
                                        checked={checkedList.includes(String(id))}/>
                                </StyledTd>
                                }
                                {fields.map((td, index) => (
                                    <StyledTd vAlign={vAlign} key={index}>{td}</StyledTd>
                                ))}
                                {(editLink || removeMethod || unpinMethod) &&
                                <Td style={{textAlign: 'right'}}>
                                    <Buttons buttonsCount={!!editLink + !!removeMethod + !!unpinMethod}>
                                        {(editLink || editCallback) &&
                                        <ActionBtn className="icon-pencil" onClick={e => handleEdit(e, id)}/>}
                                        {unpinMethod &&
                                        <ActionBtn className="icon-clip" onClick={e => handleUnpin(e, id)}
                                            color="lightBlue"/>}
                                        {removeMethod &&
                                        <ActionBtn className="icon-trash" onClick={e => handleRemove(e, id)}
                                                   color="red"/>}
                                    </Buttons>
                                </Td>
                                }
                                {view  &&
                                <Td style={{textAlign: 'right'}}>
                                    <Buttons buttonsCount={1}>
                                        <ActionBtn className="icon-fi-eye" 
                                            onClick={e => handleView(e, id)}
                                                />
                                    </Buttons>
                                </Td>
                                }
                            </CustomTr>
                        ))}
                    </Tbody>
                </Table>
            </div>
            {removeMethod &&
            <>
                <ConfirmActionModal
                    isOpen={removeModalIsOpen}
                    closeModal={() => setRemoveModalIsOpen(false)}
                    onRequestClose={() => setRemoveModalIsOpen(false)}
                    successCallback={() => {
                        setRemoveModalIsOpen(false);
                        fetchItems();
                    }}
                    method={removeMethod}
                    data={removeId}
                    content={removeModalContent ? removeModalContent : {
                        title: t('messages.remove_message'),
                        yes: t('labels.yes'),
                        no: t('labels.cancel')
                    }}
                    parentId={parentId}
                />
                <ConfirmMultipleActionsModal
                    isOpen={multipleRemoveModalIsOpen}
                    closeModal={() => setMultipleRemoveModalIsOpen(false)}
                    onRequestClose={() => setMultipleRemoveModalIsOpen(false)}
                    successCallback={() => {
                        setMultipleRemoveModalIsOpen(false);
                        fetchItems();
                        setCheckedList([])
                    }}
                    method={removeMethod}
                    data={checkedList}
                    content={removeModalContent ? removeModalContent : {
                        title: t('messages.multiple_remove_message'),
                        yes: t('labels.yes'),
                        no: t('labels.cancel')
                    }}
                />
            </>
            }
            {unpinMethod &&
                <ConfirmActionModal
                    isOpen={unpinModalIsOpen}
                    closeModal={() => setUnpinModalIsOpen(false)}
                    onRequestClose={() => setUnpinModalIsOpen(false)}
                    successCallback={() => {
                        setUnpinModalIsOpen(false);
                        fetchItems();
                    }}
                    method={unpinMethod}
                    data={unpinId}
                    content={unpinMethodContent ? unpinMethodContent : {
                        title: t('modals.detach_device.title'),
                        yes: t('labels.yes'),
                        no: t('labels.cancel')
                    }}
                />
            }
        </>
    );
};

export default ItemsTable;