import {useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const useUpdate = (method, id, goBack = true, callback = null) => {
    const [loading, setLoading] = useState();
    const history = useHistory();
    const onSubmit = async (data) => {
        setLoading(true)
        const res = id ? await method(id, data) : await method(data);

        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        // if (goBack && res.statusCode === 200) {
        //     history.goBack();
        // }

        if (res.statusCode === 200) {
            goBack && history.goBack();
            callback && callback(res)
        }
    }

    return {
        onSubmit,
        loading
    };
};

export default useUpdate;