import React from 'react';
import styled, {css} from "styled-components";

const Wrapper = styled.div`
  background-color: #eee;
  border-radius: 8px;
  height: 16px;
  width: 100%;
  overflow: hidden;
`

const Progress = styled.div`
  background-color: var(--success);
  height: 100%;
  width: 0;
  transition: .3s;
  
  ${props => props.$loaded && css`
    width: ${props.$loaded}%
  `}
`

const Loading = ({loaded}) => {
    return (
        <Wrapper>
            <Progress $loaded={loaded}/>
        </Wrapper>
    );
};

export default Loading;