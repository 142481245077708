import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";
import {apiAnalyticOperatorUrl} from "../CONSTANTS";

export default class IndicationsServices {
    static index = ({with_image}, queryString) => {
        return getData(http, `/indications?with_image=${with_image}${queryString ? `&${queryString}` : ""}`);
    }

    static prepare = (deviceId) => {
        return getData(http, `/indications/prepare?device_id=${deviceId}`);
    }

    static store = (data) => {
        return postData(http, `/indications/store`, data)
    }

    static view = (id) => {
        return getData(http, `/indications/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/indications/${id}`, data)
    }

    static accept = (id) => {
        return postData(http, `/indications/${id}/accept`)
    }

    static decline = (id, data) => {
        return postData(http, `/indications/${id}/decline`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/indications/${id}`)
    }

    static history = ({id}, queryString) => {
        return getData(http, `/indications/${id}/histories${queryString ? `?${queryString}` : ""}`)
    }

    static export = (queryString) => {
        return getData(http, `/indications/export${queryString ? `${queryString}` : ""}`);
    }

    static exportByPage = (queryString) => {
        return getData(http, `/indications/exportByPage${queryString ? `?${queryString}` : ""}`);
    }

    static massAccept = (queryString) => {
        return postData(http, `/indications/massAccept${queryString ? `${queryString}` : ""}`)
    }

    static exportUrl = apiAnalyticOperatorUrl + '/indications/export'

    static comments = {
        index: ({id}, queryString) => {
            return getData(http, `/indications/${id}/comments${queryString ? `?${queryString}` : ""}`)
        },
        store: (id, data) => {
            return postData(http, `/indications/${id}/comments`, data)
        },
        update: (id, data) => {
            return postData(http, `/indications/${id}/comments/update`, data)
        },
        delete: ({parentId, id}) => {
            return postData(http, `/indications/${parentId}/comments/delete`, {comment_id: id})
        }
    }
}