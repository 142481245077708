import React, {useCallback, useEffect, useState} from 'react';
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import routePaths from "../../../../navigation/CallOperator/routePaths";
import Dictionaries from "../../../../services/callOperator/dictionaries";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../../hooks/useUpdate.hook";
import FormGroup from "../../../../components/FormGroup";
import CustomSelect from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import ModelChangesServices from '../../../../services/callOperator/modelChangesServices';
import AsyncPaginateSelect from "../../../../components/AsyncPaginateSelect";


const ModelChangesDevicesEdit = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, control, setError, watch, setValue, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(ModelChangesServices.devices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.model_сhanges.children.devices.all.index,
                title: t('labels.change_devices')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            }
        ])
    }, [setBreadcrumbs, t, info])

    

    const companyId = watch('company_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const {onSubmit, loading} = useUpdate(ModelChangesServices.devices.accept, id, false);

    useEffect(() => {
        if (info) {
            const {company, bankbook, resourceType, areaType, ...rest} = {...info.item};
            for (const [key, value] of Object.entries(rest)) {
                info.item[key] && setValue(key, String(value));
                info.item[key] && setValue(key+"_change", String(value));
            }
            company && setValue('company_id', String(company.id));
            company && setValue('company_id_change', String(company.id));
            bankbook && setValue('bankbook_id', String(bankbook.id));
            bankbook && setValue('bankbook_id_change', String(bankbook.id));
            resourceType && setValue('resource_type_id', String(resourceType.id));
            resourceType && setValue('resource_type_id_change', String(resourceType.id));
            areaType && setValue('area_type', String(areaType.id));
            areaType && setValue('area_type_change', String(areaType.id));

            const {company: company2, bankbook: bankbook2, resourceType: resourceType2, areaType: areaType2} = {...info.changes};
            for (const [key, value] of Object.entries(info.changes)) {
                info.changes[key] && setValue(key+"_change", String(value));
                info.changes[key] && setError(key+"_change", { type: 'custom', message: 'custom message' });
            }
            company2 && setValue('company_id_change', String(company2.id));
            company2 && setError('company_id_change', { type: 'custom', message: 'custom message' });

            bankbook2 && setValue('bankbook_id_change', String(bankbook2.id));
            bankbook2 && setError('bankbook_id_change', { type: 'custom', message: 'custom message' });

            resourceType2 && setValue('resource_type_id_change', String(resourceType2.id));
            resourceType2 && setError('resource_type_id_change', { type: 'custom', message: 'custom message' });

            areaType2 && setValue('area_type_change', String(areaType2.id));
            areaType2 && setError('area_type_change', { type: 'custom', message: 'custom message' });

            info.change_note && setValue('change_note', String(info.change_note));
            info.reject_reason && setValue('reject_reason', String(info.reject_reason))
        }
    }, [info, setValue])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h1 className="headline-4">{t('labels.change_bankbooks')}</h1>
            <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                <div className="form-controller mb-20">
                    <div className="controller-wrapper">
                        <h2>Было</h2>
                        <FormGroup label={t('labels.device_number') + " *"}>
                            <Input type="text" {...register('number', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.resource_company') + " *"}>
                            <Controller name="company_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={companies} onChange={onChange}
                                                        value={value}
                                                        isLoading={companiesLoading}
                                                        isDisabled={true}
                                                        $error={errors.company_id}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.bankbook') + " *"} key={companyId}>
                            <Controller name="bankbook_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <AsyncPaginateSelect onChange={onChange}
                                                                loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `company_id=${companyId}`})}
                                                                value={value}
                                                                isDisabled={true}
                                                                $error={errors.bankbook_id}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.device_type') + " *"}>
                            <Controller name="resource_type_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.resourceTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.resource_type_id}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.area') + " *"}>
                            <Controller name="area_type" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.deviceAreaTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.area_type}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.manufacturer')}>
                            <Input type="text" {...register('manufacturer', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.manufacture_date')}>
                            <Input type="date" {...register('manufacture_date', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.diameter')}>
                            <Input type="text" {...register('diameter', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.last_check')}>
                            <Input type="date" {...register('last_check', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.next_check')}>
                            <Input type="date" {...register('next_check', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.seal_number')}>
                            <Input type="text" {...register('seal_number', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.seal_setting_date')}>
                            <Input type="date" {...register('seal_setting_date', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.anti_magnetic_seal_number')}>
                            <Input type="text" {...register('anti_magnetic_seal_number', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.anti_magnetic_seal_setting_date')}>
                            <Input type="date" {...register('anti_magnetic_seal_setting_date', {disabled: true})}/>
                        </FormGroup>
                    </div>
                    <div className="controller-wrapper">
                        <h2>Стало</h2>
                        <FormGroup label={t('labels.device_number') + " *"}>
                            <Input type="text" {...register('number_change', {disabled: true})} $error={errors.number_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.resource_company') + " *"}>
                            <Controller name="company_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={companies} onChange={onChange}
                                                        value={value}
                                                        isLoading={companiesLoading}
                                                        isDisabled={true}
                                                        $error={errors.company_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.bankbook') + " *"} key={companyId}>
                            <Controller name="bankbook_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <AsyncPaginateSelect onChange={onChange}
                                                                loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `company_id=${companyId}`})}
                                                                value={value}
                                                                isDisabled={true}
                                                                $error={errors.bankbook_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.device_type') + " *"}>
                            <Controller name="resource_type_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.resourceTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.resource_type_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.area') + " *"}>
                            <Controller name="area_type_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.deviceAreaTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.area_type_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.manufacturer')}>
                            <Input type="text" {...register('manufacturer_change', {disabled: true})} $error={errors.manufacturer_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.manufacture_date')}>
                            <Input type="date" {...register('manufacture_date_change', {disabled: true})} $error={errors.manufacture_date_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.diameter')}>
                            <Input type="text" {...register('diameter_change', {disabled: true})} $error={errors.diameter_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.last_check')}>
                            <Input type="date" {...register('last_check_change', {disabled: true})} $error={errors.last_check_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.next_check')}>
                            <Input type="date" {...register('next_check_change', {disabled: true})} $error={errors.next_check_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.seal_number')}>
                            <Input type="text" {...register('seal_number_change', {disabled: true})} $error={errors.seal_number_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.seal_setting_date')}>
                            <Input type="date" {...register('seal_setting_date_change', {disabled: true})} $error={errors.seal_setting_date_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.anti_magnetic_seal_number')}>
                            <Input type="text" {...register('anti_magnetic_seal_number_change', {disabled: true})} $error={errors.anti_magnetic_seal_number_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.anti_magnetic_seal_setting_date')}>
                            <Input type="date" {...register('anti_magnetic_seal_setting_date_change', {disabled: true})} $error={errors.anti_magnetic_seal_setting_date_change}/>
                        </FormGroup>
                    </div>
                </div>
                <div>
                    <FormGroup label={t('labels.change_note')}>
                        <Input type="text" {...register('change_note', {disabled: true})}/>
                    </FormGroup>
                    {info?.reject_reason && 
                        <FormGroup label={t('modals.decline_reason.title')}>
                            <Input type="text" {...register('reject_reason', {disabled: true})}/>
                        </FormGroup>
                    }
                </div> 
            </form>
        </>
    );
};

export default ModelChangesDevicesEdit;