import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm, Controller} from "react-hook-form";
import routePaths from "../../../navigation/admin/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import AvatarUploader from "../../../components/AvatarUploader";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import TextEditor from "../../../components/TextEditor";
import InputValuesGroup from "../../../components/InputValuesGroup";
import Button from "../../../components/Button";
import CompaniesServices from "../../../services/admin/companiesServices";
import InputMessage from "../../../components/InputMessage";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import Dictionaries from "../../../services/admin/dictionaries";

const CompanyCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, control, formState: {errors}} = useForm();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.companies.index,
                title: t('sidebar.companies.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const {onSubmit, loading} = useStore(CompaniesServices.store);

    const CustomSubmit = (data) => {
        const {phones, ...rest} = data;

        const newData = {
            ...rest,
            phones: phones?.filter(value => value !== '')
        }

        onSubmit(newData)
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.create')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(CustomSubmit)} className={loading ? "isLoading" : ""}>
                    <div className="mb-20">
                        <div className="row row--multiline">
                            <div className="col-md-4">
                                <FormGroup label={t('labels.logo') + " *"}>
                                    <Controller
                                        control={control}
                                        name="image"
                                        render={({field: {onChange, value}}) => (
                                            <AvatarUploader onChange={onChange} value={value} $error={errors.image}/>
                                        )}
                                        rules={{required: true}}
                                    />
                                    {errors.image && errors.image.type === 'required' &&
                                    <InputMessage $error>{t('messages.required')}</InputMessage>}
                                </FormGroup>
                            </div>
                            <div className="col-md-8">
                                <FormGroup label={t('labels.name_kk') + " *"}>
                                    <Input type="text" {...register('name_kk', {required: true})}
                                           $error={errors.name_kk}/>
                                    {errors.name_kk && errors.name_kk.type === 'required' &&
                                    <InputMessage $error>{t('messages.required')}</InputMessage>}
                                </FormGroup>
                                <FormGroup label={t('labels.name_ru') + " *"}>
                                    <Input type="text" {...register('name_ru', {required: true})}
                                           $error={errors.name_ru}/>
                                    {errors.name_ru && errors.name_ru.type === 'required' &&
                                    <InputMessage $error>{t('messages.required')}</InputMessage>}
                                </FormGroup>
                                <FormGroup label={t('labels.companyType') + " *"}>
                                    <Controller
                                        name="type"
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.companyTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.type}
                                            />
                                        )}
                                        rules={{required: true}}
                                    />
                                    {errors.type && errors.type.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <FormGroup label={t('labels.content_kk')}>
                        <Controller
                            name="description_kk"
                            control={control}
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <TextEditor
                                    onChange={onChange}
                                    value={value}
                                />
                            )}>
                        </Controller>
                    </FormGroup>
                    <FormGroup label={t('labels.content_ru')}>
                        <Controller
                            name="description_ru"
                            control={control}
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <TextEditor
                                    onChange={onChange}
                                    value={value}
                                />
                            )}>
                        </Controller>
                    </FormGroup>
                    <FormGroup label={t('labels.address_kk')}>
                        <Input type="text" {...register('address_kk')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.address_ru')}>
                        <Input type="text" {...register('address_ru')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.phones')}>
                        <Controller name="phones" control={control} render={({field: {onChange, value}}) => (
                            <InputValuesGroup register={register} value={value} onChange={onChange}/>
                        )}/>
                    </FormGroup>
                    <hr/>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default CompanyCreate;