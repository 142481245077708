import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Button from "../../../components/Button";
import ImportServices from "../../../services/operator/importServices";
import ImportResult from "./ImportResult";
import Uploader from "../../admin/Import/components/Uploader";

const AddressesImport = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const [response, setResponse] = useState()
    const [loading, setLoading] = useState()

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.data_import.title')
            },
            {
                path: null,
                title: t('sidebar.data_import.children.addresses')
            }
        ])
    }, [setBreadcrumbs, t]);

    const handleResponse = useCallback(response => {
        setResponse(response.content)
    }, [])

    return (
        <>
            <h1 className="headline-4">{t('sidebar.data_import.title')} - {t('sidebar.data_import.children.addresses')}</h1>
            <WhiteBlock>
                <FormGroup label={t('labels.choose_file')}>
                    <div className="mb-10">
                        <Uploader
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            name='file'
                            handleResponse={handleResponse}
                            setParentLoading={setLoading}
                            disabled={loading}
                            method={ImportServices.importAddresses}
                            errorsUrlMethod={ImportServices.generateErrorsFile}
                        >
                            .xlsx, .xls, <br/>
                            Не более 50 мб
                        </Uploader>
                    </div>
                    <Button variant="outline" $small $withIcon href='/uploads/sample_addresses.xlsx' target="_blank" download><i className="icon-download"/><span>Пример заполненного файла</span></Button>
                </FormGroup>
            </WhiteBlock>
            {response && <ImportResult response={response}/>}
        </>
    );
};

export default AddressesImport;