import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class SubscribersServices {
    static index = (queryString) => {
        return getData(http, `/users/subscribers${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/users/subscribers/store`, data)
    }

    static view = (id) => {
        return getData(http, `/users/subscribers/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/users/subscribers/${id}`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/users/subscribers/${id}`)
    }

    static resetPassword = (id) => {
        return postData(http, `/users/subscribers/${id}/resetPassword`)
    }

    static bankbooks = {
        index: ({id}) => {
            return getData(http, `/users/subscribers/${id}/bankbooks`)
        },
        attach: (id, data) => {
            return postData(http, `/users/subscribers/${id}/bankbooks/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/users/subscribers/${parentId}/bankbooks/detach`, {bankbook_id: id})
        }
    }

    static sendNotification = (id, data) => {
        return postData(http, `/users/subscribers/${id}/sendNotification`, data)
    }
}