import React, { useState } from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/admin/routePaths";
import HouseEditInformation from "./HouseEditInformation";
import HouseEditApartments from "./HouseEditApartments";
import HouseEditBankbooks from "./HouseEditBankbooks";
import HouseEditSectors from "./HouseEditSectors";
import HouseEditControllers from "./HouseEditControllers";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/admin/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";
import HeadingWithButtons from '../../../../components/HeadingWithButtons';
import Button from '../../../../components/Button';
import HousesActionsModal from './components/HousesActionsModal';
import HousesTaskCreateModal from './components/HousesTaskCreateModal';
import TaskSealingServices from '../../../../services/admin/tasksSealingServices';

const HouseEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.houses.view, id);
    const {lang} = useLangContext();

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [createTaskIsOpen, setCreateTaskIsOpen] = useState();

    const handleOpen = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-3">{t('labels.editing')}{info && " - " + info[`name_${lang}`]}</h1>
                <Button $small variant="outline" onClick={(e) => {handleOpen(e)}}>{t('labels.actions')}</Button>
            </HeadingWithButtons>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.apartments(id)}>{t('labels.apartments')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.bankbooks(id)}>{t('labels.bankbooks')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.sectors(id)}>{t('labels.sectors')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.houses.edit.controllers(id)}>{t('labels.controllers')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.houses.edit.index()} to={routePaths.addresses.children.houses.edit.information()}/>
                <Route path={routePaths.addresses.children.houses.edit.information()} component={HouseEditInformation}/>
                <Route path={routePaths.addresses.children.houses.edit.apartments()} component={HouseEditApartments}/>
                <Route path={routePaths.addresses.children.houses.edit.bankbooks()} component={HouseEditBankbooks}/>
                <Route path={routePaths.addresses.children.houses.edit.sectors()} component={HouseEditSectors}/>
                <Route path={routePaths.addresses.children.houses.edit.controllers()} component={HouseEditControllers}/>
            </Switch>
            <HousesActionsModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    //history.push(routePaths.model_сhanges.children.bankbooks.all.index);
                }}
                method = {() => {setCreateTaskIsOpen(true); setRemoveModalIsOpen(false)}}
                id={id}
            />
            <HousesTaskCreateModal
                isOpen={createTaskIsOpen}
                closeModal={() => setCreateTaskIsOpen(false)}
                onRequestClose={() => setCreateTaskIsOpen(false)}
                successCallback={() => {
                    setCreateTaskIsOpen(false);
                    //history.push(routePaths.model_сhanges.children.bankbooks.all.index);
                }}
                method = {TaskSealingServices.firstTime.store}
                id={id}
                info={info}
            />
        </>
    );
};

export default HouseEdit;