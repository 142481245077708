import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import routePaths from "../../../navigation/admin/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomSelect from "../../../components/CustomSelect";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/admin/dictionaries";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import DevicesServices from "../../../services/admin/devicesServices";
import InputMessage from "../../../components/InputMessage";
import AsyncPaginateSelect from "../../../components/AsyncPaginateSelect";

const DeviceCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.devices.index,
                title: t('sidebar.devices.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    

    const companyId = watch('company_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);
        setValue("bankbook_id", null)

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        const {bankbook_id, company_id} = {...state};
        bankbook_id && setValue('bankbook_id', String(bankbook_id));
        company_id && setValue('company_id', String(company_id));
    }, [state, setValue])

    const {onSubmit, loading} = useStore(DevicesServices.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.device_number') + " *"}>
                        <Input type="text" {...register('number', {required: true})} $error={errors.number}/>
                        {errors.number && errors.number.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled={state?.company_id}
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook') + " *"} key={companyId}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <AsyncPaginateSelect onChange={onChange}
                                                             loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `company_id=${companyId}`})}
                                                             value={value}
                                                             isDisabled={!!!companyId || state?.bankbook_id}
                                                             $error={errors.bankbook_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bankbook_id && errors.bankbook_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.device_type') + " *"}>
                        <Controller name="resource_type_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.resourceTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.resource_type_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.resource_type_id && errors.resource_type_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.area') + " *"}>
                        <Controller name="area_type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.deviceAreaTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.area_type}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.area_type && errors.area_type.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.modem_number')}>
                        <Input type="text" {...register('modem_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacturer')}>
                        <Input type="text" {...register('manufacturer')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacture_date')}>
                        <Input type="date" {...register('manufacture_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.diameter')}>
                        <Input type="text" {...register('diameter')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.last_check')}>
                        <Input type="date" {...register('last_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.next_check')}>
                        <Input type="date" {...register('next_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.seal_number')}>
                        <Input type="text" {...register('seal_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.seal_setting_date')}>
                        <Input type="date" {...register('seal_setting_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.anti_magnetic_seal_number')}>
                        <Input type="text" {...register('anti_magnetic_seal_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.anti_magnetic_seal_setting_date')}>
                        <Input type="date" {...register('anti_magnetic_seal_setting_date')}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default DeviceCreate;