import React from 'react';
import styled from "styled-components";
import Dropdown from "../../components/Dropdown";
import DropdownContent from "../../components/DropdownContent";
import {useLangContext} from "../../i18n/ProvideLang";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../navigation/Auth/ProvideAuth";
import AuthServices from "../../services/general/authServices";
import avatar from '../../images/avatar.svg'
import {Link} from "react-router-dom";
import routePaths from "../../navigation/general/routePaths";
import {toast} from "react-toastify";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.menuMinimized ? '72px' : '280px'};
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid var(--gray-300);
  z-index: 109;
  display: flex;
  align-items: center;
  padding: 0 40px;
  
  .container {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const ProfileDropdown = styled.div`
  flex-shrink: 0;
  margin-right: 32px;
`;

const ProfileTitle = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 10px;
  transition: .3s;
  
  &:hover {
    background-color: var(--gray-100);
  }
  
  span {
    font-size: 14px;
    font-weight: 500;
    margin-right: 12px;
  }
  
  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 12px;
    border: 2px solid #fff;
  }
  
  i {
    color: var(--gray-400)
  }
`

const Language = styled.div`
  display: flex;
  align-items: center;
  
  span:not(.divider) {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--gray-400);
    cursor: pointer;
    transition: .2s;
    
    &:hover {
      color: var(--gray-700)
    }
    
    &.active {
      color: var(--primary)
    }
  }
  
  .divider {
    font-size: 12px;
    padding: 0 8px;
    color: var(--gray-400);
  }
`

const TopBar = ({menuMinimized}) => {
    const {t} = useTranslation();
    const {lang, changeLanguage} = useLangContext();
    const {removeToken, removeInfo, userData} = useAuth();

    const userName = userData.name;

    const logOut = async () => {
        const res = await AuthServices.logOut();

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            removeToken();
            removeInfo();
        }
    }

    return (
        <Container menuMinimized={menuMinimized}>
            <div className="container">
                <ProfileDropdown>
                    <Dropdown title={
                        <ProfileTitle>
                            <span>{userName}</span>
                            <img src={avatar} alt=""/>
                            <i className="icon-chevron-down"/>
                        </ProfileTitle>
                    } menuPosition="center">
                        <DropdownContent style={{width: "100%"}}>
                            <ul>
                                <li><Link to={routePaths.profile.index}>{t('labels.profile')}</Link></li>
                                <li><span onClick={logOut}>{t('labels.logout')}</span></li>
                            </ul>
                        </DropdownContent>
                    </Dropdown>
                </ProfileDropdown>
                <Language>
                    <span className={lang === 'ru' ? 'active' : ''} onClick={() => lang !== 'ru' ? changeLanguage('ru') : null}>Рус</span>
                    <span className="divider">|</span>
                    <span className={lang === 'kk' ? 'active' : ''} onClick={() => lang !== 'kk' ? changeLanguage('kk') : null}>Қаз</span>
                </Language>
            </div>
        </Container>
    );
};

export default TopBar;