import React from 'react';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/laboratoryAdministrator/routePaths";
import LaboratoryEditInformation from "./LaboratoryEditInformation";
import LaboratoryEditServices from "./LaboratoryEditServices";
import LaboratoryEditAdministrators from "./LaboratoryEditAdministrators";
import LaboratoryEditSpecialists from "./LaboratoryEditSpecialists";
import LaboratoryEditCities from "./LaboratoryEditCities";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";
import LaboratoriesServices from "../../../services/laboratoryAdministrator/laboratoriesServices";

const LaboratoryEdit = () => {
    const {t} = useTranslation();

    const {info} = useFetchInfo(LaboratoriesServices.view);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.laboratory.information}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratory.services}>{t('labels.services')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratory.administrators}>{t('labels.administrators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratory.specialists}>{t('labels.specialists')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratory.cities}>{t('labels.cities')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.laboratory.index} to={routePaths.laboratory.information}/>
                <Route path={routePaths.laboratory.information} component={LaboratoryEditInformation}/>
                <Route path={routePaths.laboratory.services} component={LaboratoryEditServices}/>
                <Route path={routePaths.laboratory.administrators} component={LaboratoryEditAdministrators}/>
                <Route path={routePaths.laboratory.specialists} component={LaboratoryEditSpecialists}/>
                <Route path={routePaths.laboratory.cities} component={LaboratoryEditCities}/>
            </Switch>
        </>
    );
};

export default LaboratoryEdit;