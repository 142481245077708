import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/minAdmin/routePaths";
import RegionEditInformation from "./RegionEditInformation";
import RegionEditCities from "./RegionEditCities";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../../i18n/ProvideLang";
import AddressesServices from "../../../../services/minAdmin/addressesServices";

const RegionEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.regions.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.regions.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.regions.edit.cities(id)}>{t('labels.cities')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.regions.edit.index()} to={routePaths.addresses.children.regions.edit.information()}/>
                <Route path={routePaths.addresses.children.regions.edit.information()} component={RegionEditInformation}/>
                <Route path={routePaths.addresses.children.regions.edit.cities()} component={RegionEditCities}/>
            </Switch>
        </>
    );
};

export default RegionEdit;