import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalComponent from "../../../../../components/ModalComponent";
import Button from "../../../../../components/Button";

const ApartmentActionsModal = ({method, closeModal, successCallback, id, ...props}) => {
    const [loading] = useState();

    const {t} = useTranslation();

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "400px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.actions')}</h4>
                <Button onClick={()=>method()}>{t('labels.task_create')}</Button>
            </div>
        </ModalComponent>
    );
};

export default ApartmentActionsModal;