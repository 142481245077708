import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import ModalComponent from "../../../../components/ModalComponent";
import Dictionaries from "../../../../services/admin/dictionaries";
import CustomSelect from "../../../../components/CustomSelect";

const AttachHouseModal = ({method, closeModal, cityId, successCallback, companyId, id, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, control, watch, setValue, reset} = useForm();

    const streetId = watch('street_id', null);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const fetchStreets = useCallback(async () => {
        setStreetsLoading(true);

        const res = await Dictionaries.streets({queryString: `company_id=${companyId}&city_id=${cityId}`});

        if (res.statusCode === 200) {
            setStreets(res.content);
        } else {
             toast.error(res.message)
        }

        setStreetsLoading(false);
    }, [cityId]);

    const fetchHouses = useCallback(async () => {
        setValue('house_id', null);

        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `company_id=${companyId}&street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        }
    }, [setValue, streetId]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    const onSubmit = async (data) => {
        setLoading(true);

        const res = await method(id, data);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.house_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.street')}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                      value={value}
                                                      isLoading={streetsLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.house')}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={houses} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!streetId ? housesLoading || streetsLoading : false} isDisabled={!!!streetId || housesLoading || streetsLoading}/>
                                    )}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachHouseModal;