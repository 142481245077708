import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalComponent from "../ModalComponent";
import Button from "../Button";
import {toast} from "react-toastify";

const ConfirmMultipleActionsModal = ({method, successCallback, failCallback, closeModal, content, data, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const confirm = async () => {
        setLoading(true);

        const results = await Promise.all(data.map(id => method(Number(id))));

        results.forEach(res => {
            res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)
        })

        successCallback();

        setLoading(false);
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{maxWidth: 500}}>
                <h4 className="headline-4">{content && content.title ? content.title : t('messages.action_confirm')}</h4>
                {content && content.text && <div className="body-2">{content.text}</div>}
                <div className={`row row--multiline justify-end`}>
                    <div className="col-auto">
                        <Button $small $red onClick={closeModal}>{content && content.no ? content.no : t('labels.no')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button $small onClick={confirm}>{content && content.yes ? content.yes : t('labels.yes')}</Button>
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
};

export default ConfirmMultipleActionsModal;