import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import styled, {css} from "styled-components";
import logo from '../../images/logo-white1.png'
import useOutsideClick from "../../hooks/useOutsideClick.hook";

const Container = styled.div`
  width: ${props => props.menuMinimized ? "92px" : "280px"};
  height: 100vh;
  background: var(--gradient);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 110;
`;

const Top = styled.div`
  height: 123px;
  display: flex;
  padding: 24px 34px 24px 34px;
  align-items: center;
  flex-shrink: 0;
  position: relative;

  &:after {
    content: '';
    height: 1px;
    width: calc(100% - 48px);
    display: block;
    background-color: #fff;
    margin: 0 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .7;
  }
`

const Middle = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px 0;

  /* width */

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`;

const MenuBtn = styled.i`
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  transition: .15s;
  
  &:hover {
    color: rgba(255,255,255,.8)
  }
`;

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 118px;
`

const LinkStyles = css`
  display: flex;
  align-items: center;
  color: #fff;
  padding: 16px 24px;
  transition: .3s;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  &.active {
    background-color: #0c73bd;
    color: #fff;
  }

  i {
    font-size: 24px;
  }

  span {
    margin-left: 16px;
    font-weight: 600;
  }
  ${props => props.menuminimizedtitle && css`
  flex-direction:column;
  font-size: 12px;

  span{
    margin-left:0;
    text-align:center;
    margin-top:4px;
  }
`}
`

const LinkWrapper = styled(NavLink)`${LinkStyles}`;
const SpanWrapper = styled.span`
  ${LinkStyles}

  ${props => props.isActive && css`
    //background-color: #0c73bd !important;
  `}
  ${props => props.mini && css`
    font-size: 14px;
    span{
      margin-left:40px;
    }
  `}
  ${props => !props.menuMinimized && css`
    &:after {
      content: "\\e918";
      font-family: 'icomoon';
      margin-left: auto;
      font-size: 24px;
      line-height: 1;
      transform: rotate(${props => props.isActive ? '180deg' : '0'});
    }
  `}
  ${props => props.menuMinimized && css`
    span{
      margin-left:0px;
    }
  `}

  ${props => props.menuminimizedtitle && css`
    flex-direction:column;
    font-size: 12px;

    span{
      margin-left:0;
      margin-top:4px;
    }
  `}
`;

const DropDown = styled.div`
  ${props => props.menuMinimized ? css`
    background-color: var(--primary);
    position: absolute;
    left: 100%;
    margin-top: calc(-56px - ${props => props.menuScrollTop + "px"});
    display: none;
    transform: translateY(calc(-50% + 28px));
    min-width: 200px;

    ${props => props.isActive && css`
      display: block;
    `}
    
  ` : css`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    ${props => props.isActive && css`
      max-height: 1000px;
      transition: max-height 1s ease-in-out;
    `}
  `}
  .posi-relative{
    position:relative;
  }

  ${props => props.menuMinimized2 && css`
    margin-top: 0;
  `}
`

const ChildLink = styled(NavLink)`
  padding: 10px 24px 10px 64px;
  color: #fff;
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 500;
  ${props => props.$min && css`
    padding: 10px 24px 10px 84px;
  `}
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  &.active {
    background-color: #0c73bd;
    color: #fff;
  }
`

const Sidebar = ({menuMinimized, toggleMenu, sidebarItems}) => {
    const menuRef = useRef();

    const [activeItem, setActiveItem] = useState();
    const [activeItem2, setActiveItem2] = useState();
    const [menuScrollTop, setMenuScrollTop] = useState(0)

    const location = useLocation();

    useOutsideClick(menuRef, () => {
        if (menuMinimized) {
            setActiveItem(null);
        }
    })

    const handleDropdownLiClick = (e, index) => {
        e.preventDefault();
        setActiveItem(current => current === index ? '' : index)
    }

    const handleDropdownLiClick2 = (e, index) => {
        e.preventDefault();
        setActiveItem2(current => current === index ? '' : index)
    }

    const handleDropdownLinkClick = () => {
        if (menuMinimized) setActiveItem(null)
    }

    useEffect(() => {
        menuRef.current.addEventListener("scroll", (e) => {
            setMenuScrollTop(e.target.scrollTop)
        })
    }, [menuRef])

    return (
        <Container menuMinimized={menuMinimized}>
            <Top>
                <MenuBtn className="icon-apps" onClick={toggleMenu}/>
                {!menuMinimized && <Logo src={logo}/>}
            </Top>
            <Middle ref={menuRef}>
                {
                    sidebarItems.map(({title, url, icon, children}, index) => (
                        <div key={index} className={activeItem === index ? 'active' : ''}>
                            {children
                                ? <>
                                    <SpanWrapper className={location.pathname.startsWith(url) ? "active" : ""} menuminimizedtitle={menuMinimized ? 1 : 0} menuMinimized={menuMinimized} isActive={activeItem === index}
                                                 onClick={e => handleDropdownLiClick(e, index)}><i
                                        className={`icon-${icon}`}/><span>{title}</span>
                                    </SpanWrapper>
                                    <DropDown menuScrollTop={menuScrollTop} menuMinimized={menuMinimized} isActive={activeItem === index}>
                                        {
                                            children.map(({title: title2, url: url2, children: children2}, index2) => (
                                              <div key={title2} className={menuMinimized ? "posi-relative" : ""}>
                                                {children2 ? 
                                                  <>
                                                  <SpanWrapper className={location.pathname.startsWith(url2) ? "active" : ""} menuMinimized={menuMinimized} mini={true} isActive={activeItem2 === index2}
                                                      onClick={e => handleDropdownLiClick2(e, index2)}><span>{title2}</span>
                                                  </SpanWrapper>
                                                  <DropDown menuScrollTop={menuScrollTop} menuMinimized={menuMinimized} menuMinimized2={menuMinimized} isActive={activeItem2 === index2}>
                                                    {children2.map(({title: title3, url: url3},index3) => (
                                                      <ChildLink $min activeClassName='active' key={index3} to={url3} onClick={handleDropdownLinkClick} style={{padding: menuMinimized ? '10px 24px' : ''}}>{title3}</ChildLink>
                                                    ))}
                                                  </DropDown>
                                                  </> : 
                                                  <ChildLink activeClassName='active' key={index2} to={url2} onClick={handleDropdownLinkClick} style={{padding: menuMinimized ? '10px 24px' : ''}}>{title2}</ChildLink>
                                                }
                                              </div>
                                            ))
                                        }
                                    </DropDown>
                                </>
                                : <LinkWrapper menuminimizedtitle={menuMinimized ? 1 : 0} activeClassName='active' to={url} onClick={() => setActiveItem(null)} ><i className={`icon-${icon}`}/>
                                <span>{title}</span></LinkWrapper>
                            }
                        </div>
                    ))
                }
            </Middle>
        </Container>
    );
};

export default Sidebar;