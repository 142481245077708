const routePaths = {
    home: {
        index: "/"
    },
    bankbooks: {
        index: "/bankbooks",
        create: "/bankbooks/create",
        edit: {
            index: (id) => `/bankbooks/${id ? id : ":id"}/edit`,
            information: (id) => `/bankbooks/${id ? id : ":id"}/edit/information`,
            devices: (id) => `/bankbooks/${id ? id : ":id"}/edit/devices`,
            documents: (id) => `/bankbooks/${id ? id : ":id"}/edit/documents`,
        }
    },
    devices: {
        index: "/devices",
        create: "/devices/create",
        edit: {
            index: (id) => `/devices/${id ? id : ":id"}/edit`,
            information: (id) => `/devices/${id ? id : ":id"}/edit/information`,
            indications: (id) => `/devices/${id ? id : ":id"}/edit/indications`,
            notifications: (id) => `/devices/${id ? id : ":id"}/edit/notifications`,
            documents: (id) => `/devices/${id ? id : ":id"}/edit/documents`,
            histories: (id) => `/devices/${id ? id : ":id"}/edit/histories`,
        }
    },
    model_сhanges: {
        index: `/modelChanges`,
        edit: {
            index: (id) => `/modelChanges/bankbooks/${id ? id : ":id"}/edit`,
            devices: (id) => `/modelChanges/devices/${id ? id : ":id"}/edit`,
        },
        children:{
            bankbooks:{
                all: {
                    index: "/modelChanges/bankbooks/all"
                },
                on_check: {
                    index: "/modelChanges/bankbooks/on_check"
                },
                approved: {
                    index: "/modelChanges/bankbooks/approved"
                },
                declined: {
                    index: "/modelChanges/bankbooks/declined"
                }
            },
            devices:{
                all: {
                    index: "/modelChanges/devices/all"
                },
                on_check: {
                    index: "/modelChanges/devices/on_check"
                },
                approved: {
                    index: "/modelChanges/devices/approved"
                },
                declined: {
                    index: "/modelChanges/devices/declined"
                }
            },
        }
           
    },
    indications: {
        index: "/indications",
        create: "/indications/create",
        edit: {
            index: (id) => `/indications/${id ? id : ":id"}/edit`,
            information: (id) => `/indications/${id ? id : ":id"}/edit/information`,
            comments: (id) => `/indications/${id ? id : ":id"}/edit/comments`,
            history: (id) => `/indications/${id ? id : ":id"}/edit/history`,
        }
    },
    dictionaries: {
        index: "/dictionaries",
        children: {
            resource_types: {
                index: "/dictionaries/resource_types",
                create: "/dictionaries/resource_types/create",
                edit: (id) => `/dictionaries/resource_types/${id ? id : ":id"}/edit`
            },
            improvements: {
                index: "/dictionaries/improvements",
                create: "/dictionaries/improvements/create",
                edit: (id) => `/dictionaries/improvements/${id ? id : ":id"}/edit`
            },
            service_types: {
                index: "/dictionaries/service_types",
                create: "/dictionaries/service_types/create",
                edit: (id) => `/dictionaries/service_types/${id ? id : ":id"}/edit`
            }
        }
    },
    applications: {
        index: `/applications`,
        create: '/applications/create',
        detail: {
            index: (id) => `/applications/${id ? id : ":id"}`,
            information: (id) => `/applications/${id ? id : ":id"}/information`,
            services: (id) => `/applications/${id ? id : ":id"}/services`,
            specialists: (id) => `/applications/${id ? id : ":id"}/specialists`,
            history: (id) => `/applications/${id ? id : ":id"}/history`
        },
        children: {
            all: {
                index: "/applications/all"
            },
            change: {
                index: "/applications/change"
            },
            under_consideration: {
                index: '/applications/under-consideration'
            },
            rejected: {
                index: '/applications/rejected'
            },
            waiting: {
                index: '/applications/waiting'
            },
            cancelled: {
                index: '/applications/cancelled'
            },
            in_work: {
                index: '/applications/in-work'
            },
            done_payment_waiting: {
                index: '/applications/done-payment-waiting'
            },
            done_payment_approve_waiting: {
                index: '/applications/done-payment-approve-waiting'
            },
            done_paid: {
                index: '/applications/done-paid'
            },
        }
    }
}

export default routePaths;