import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../../navigation/sealerOperator/routePaths";
import useStore from "../../../../hooks/useStore.hook";
import AddressesServices from "../../../../services/sealerOperator/addressesServices";
import WhiteBlock from "../../../../components/WhiteBlock";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/sealerOperator/dictionaries";
import {useLocation} from "react-router-dom";
import InputMessage from "../../../../components/InputMessage";

const CityCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.cities.index,
                title: t('sidebar.addresses.children.cities')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const {onSubmit, loading} = useStore(AddressesServices.cities.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.region') + " *"}>
                        <Controller defaultValue={state ? String(state?.region_id) : null} name="region_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.regions}
                                            onChange={onChange}
                                            value={value}
                                            isDisabled={state?.region_id}
                                            $error={errors.region_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.region_id && errors.region_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default CityCreate;