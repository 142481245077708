export const statusColors = {
    1: '#ED7E52',
    2: '#B6250C',
    3: '#71AAFF',
    4: '#6C6D6D',
    5: '#3461A5',
    6: '#ED7E52',
    7: '#ED7E52',
    8: '#5DB075',
}

export const statuses = ["На проверке",
"Одобрено",
"Отклонено",
"Все"]

export const statusesColors = {
    1: '#ED7E52',
    2: '#B6250C',
    3: '#71AAFF',
    4: '#6C6D6D',
    5: '#3461A5',
    6: '#ED7E52',
    7: '#14A38B',
    8: '#B6250C',
}