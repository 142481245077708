import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../../hooks/useUpdate.hook";
import WhiteBlock from "../../../../components/WhiteBlock";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import AddressesServices from "../../../../services/admin/addressesServices";
import ConfirmActionModal from "../../../../components/modals/ConfirmActionModal";
import {useLangContext} from "../../../../i18n/ProvideLang";
import InputMessage from "../../../../components/InputMessage";

const RegionEditInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const {info, loading: fetchLoading} = useFetchInfo(AddressesServices.regions.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.regions.index,
                title: t('sidebar.addresses.children.regions')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {onSubmit, loading} = useUpdate(AddressesServices.regions.update, id, false);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                if (key) setValue(key, value);
            }
        }
    }, [info, setValue])

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.goBack();
                }}
                method={AddressesServices.regions.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default RegionEditInformation;