import React, {useEffect, useState} from 'react';
import Input from "./Input";
import Button from "./Button";
import {useTranslation} from "react-i18next";

const InputValuesGroup = ({value, onChange}) => {
    const {t} = useTranslation();

    const [values, setValues] = useState(['']);

    useEffect(() => {
        if (value && value.length) {
            setValues(value)
        }
    }, [value])

    const remove = (e, index) => {
        e.preventDefault();
        const copyValues = [...values];
        copyValues.splice(index, 1);
        onChange(copyValues)
    }

    const add = (e) => {
        e.preventDefault();
        setValues([...values, '']);
    }

    const onInputChange = () => {
        const names = Array.from(document.getElementsByName('input[]'));

        const combined = names.map((item) => item.value)

        onChange(combined);
    }

    return (
        <>
            <div className="mb-20">
                {values.map((item, index) => (
                    <div className="row row--multiline" key={index}>
                        <div className="col-sm-10">
                            <Input $small type="text" value={item ? item : ""} name={`input[]`} onChange={onInputChange}/>
                        </div>
                        <div className="col-sm-2">
                            <Button $small $fluid variant="outline" $red disabled={index === 0} onClick={e => remove(e, index)}>{t('labels.remove')}</Button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-right">
                <Button variant="outline" onClick={add} $small $withIcon><i className="icon-plus"/><span>{t('labels.add')}</span></Button>
            </div>
        </>
    );
};

export default InputValuesGroup;