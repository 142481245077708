import React from 'react';
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

const styles = css`
  font-size: 16px;
  color: var(--accent);
  transition: .2s;
  border: none;
  background: none;
  padding: 4px;
  cursor: pointer;
  
  &:hover {
    color: var(--accent-hover);
  }
  
  ${props => props.color === 'red' && css`
      color: var(--error);
    
      &:hover {
        color: var(--error-hover);
      }
  `}
  
  ${props => props.color === 'pink' && css`
    color: #ff5185;

    &:hover {
      color: #a80045;
    }
  `}
  
  ${props => props.color === 'blue' && css`
      color: var(--blue);
    
      &:hover {
        color: var(--blue-hover);
      }
  `}
  
  ${props => props.color === 'yellow' && css`
      color: var(--warning);
    
      &:hover {
        color: var(--warning-hover);
      }
  `}
  
  ${props => props.color === 'green' && css`
      color: var(--success);
    
      &:hover {
        color: var(--success-hover);
      }
  `}
  
  ${props => props.color === 'gray' && css`
      color: var(--gray-700);
    
      &:hover {
        color: var(--gray-800);
      }
  `}

  ${props => props.color === 'lightBlue' && css`
      color: var(--accent);
      font-size:20px;
    
      &:hover {
        color: var(--accent-hover);
      }
  `}
`;

const LinkEl = styled(Link)`${styles}`;
const ButtonEl = styled.button`${styles}`;
const SpanEl = styled.span`${styles}`;

const ActionBtn = ({children, ...props}) => {
    if (props.to) {
        return <LinkEl {...props}>{children}</LinkEl>
    }

    if (props.type === 'span') {
        return <SpanEl {...props}>{children}</SpanEl>
    }

    return <ButtonEl {...props}>{children}</ButtonEl>
};

export default ActionBtn;