import http from "./http-common"
import {getData} from "../handlers";

export default class Dictionaries {
    static regions = async () => {
        const res = await getData(http,`/dictionaries/regions`);
        return this.transformToOptions(res)
    }

    static cities = async (data) => {
        const res = await getData(http,`/dictionaries/cities${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static districts = async (data) => {
        const res = await getData(http,`/dictionaries/districts${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static microdistricts = async (data) => {
        const res = await getData(http,`/dictionaries/microdistricts${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static streets = async (data) => {
        const res = await getData(http,`/dictionaries/streets${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static houses = async (data) => {
        const res = await getData(http,`/dictionaries/houses${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static entrances = async (data) => {
        const res = await getData(http,`/dictionaries/entrances${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static floors = async (data) => {
        const res = await getData(http,`/dictionaries/floors${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static apartments = async (data) => {
        const res = await getData(http,`/dictionaries/apartments${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static laboratories = async (data) => {
        const res = await getData(http,`/dictionaries/laboratories${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static resourceTypes = async () => {
        const res = await getData(http,`/dictionaries/resourceTypes`);
        return this.transformToOptions(res)
    }

    static companyTypes = async () => {
        const res = await getData(http,`/dictionaries/companyTypes`);
        return this.transformToOptions(res)
    }

    static applicationStatuses = async () => {
        const res = await getData(http,`/dictionaries/applicationStatuses`);
        return this.transformToOptions(res)
    }

    static invoiceStatuses = async () => {
        const res = await getData(http,`/dictionaries/invoiceStatuses`);
        return this.transformToOptions(res)
    }

    static services = async () => {
        const res = await getData(http,`/dictionaries/services`);
        return this.transformToOptions(res)
    }

    static laboratoryServices = async (data) => {
        const res = await getData(http,`/dictionaries/laboratoryServices${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static bankbookSectorTypes = async (data) => {
        const res = await getData(http,`/dictionaries/bankbookSectorTypes${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static deviceAreaTypes = async (data) => {
        const res = await getData(http,`/dictionaries/deviceAreaTypes${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static bankbooksSearch = async (data) => {
        return await getData(http,`/dictionaries/bankbook/search${data?.queryString ? `?${data.queryString}` : ""}`);
    }

    static users = {
        freeLabAdministrators: async () => {
            const res = await getData(http,`/dictionaries/users/freeLaboratoryAdministrators`);
            return this.transformToOptions(res)
        },
        freeLaboratorySpecialists: async () => {
            const res = await getData(http,`/dictionaries/users/freeLaboratorySpecialists`);
            return this.transformToOptions(res)
        },
        freeCompanyOperators: async () => {
            const res = await getData(http,`/dictionaries/users/freeCompanyOperators`);
            return this.transformToOptions(res)
        },
        freeCompanyControllers: async () => {
            const res = await getData(http,`/dictionaries/users/freeCompanyControllers`);
            return this.transformToOptions(res)
        },
        companyControllers: async (data) => {
            const res = await getData(http,`/dictionaries/users/companyControllers${data?.queryString ? `?${data.queryString}` : ""}`);
            return this.transformToOptions(res)
        },
        companyOperators: async (data) => {
            const res = await getData(http,`/dictionaries/users/companyOperators${data?.queryString ? `?${data.queryString}` : ""}`);
            return this.transformToOptions(res)
        },
        labSpecialists: async (data) => {
            const res = await getData(http,`/dictionaries/users/laboratorySpecialists${data?.queryString ? `?${data.queryString}` : ""}`);
            return this.transformToOptions(res)
        },
        sealers: async (data) => {
            const res = await getData(http,`/dictionaries/users/companySealers${data?.queryString ? `?${data.queryString}` : ""}`);
            return this.transformToOptions(res)
        }
    }

    static companies = async (data) => {
        const res = await getData(http,`/dictionaries/companies${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static sectors = async (data) => {
        const res = await getData(http,`/dictionaries/sectors${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static freeSectors = async (data) => {
        const res = await getData(http,`/dictionaries/freeSectors${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static bankbooks = async ({name = "", ids = null, page = 1, queryString = ""}) => {
        const res = await getData(http,`/dictionaries/bankbooks?number=${name}&page=${page}` + (ids ? "&" + ids : "") + (queryString ? "&" + queryString : ""));
        return this.transformBankbooksToPaginateOptions(res);
    }

    static getBankbookAddress = (id) => {
        return getData(http, `/dictionaries/bankbook/getAddress?id=${id}`)
    }

    static bankbookUserTypes = async (data) => {
        const res = await getData(http,`/dictionaries/bankbookUserTypes${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static indicationStatuses = async (data) => {
        const res = await getData(http,`/dictionaries/indicationStatuses${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static devices = async (data) => {
        const res = await getData(http,`/dictionaries/devices${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static transformToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {name, id, ...rest} = item;

            return {
                label: name,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformBankbooksToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {number, id, ...rest} = item;

            return {
                label: number,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformBankbooksToPaginateOptions = ({content, ...props}) => {
        const {items, current_page, total_pages} = content;
        const newItems = items.map((item) => {
            const {number, id, ...rest} = item;

            return {
                label: number,
                value: String(id),
                ...rest
            }
        })

        const newContent = {
            hasMore: total_pages > current_page,
            options: newItems
        }

        return {
            ...props,
            content: newContent
        }
    }
}