import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import SubscribersServices from "../../../../services/admin/subscribersServices";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import Button from "../../../../components/Button";
import AttachBankBookModal from "../../components/AttachBankBookModal";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";

const SubscriberEditBankbooks = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();

    const [bankBookModal, setBankBookModal] = useState();

    const {info} = useFetchInfo(SubscribersServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: routePaths.users.children.subscribers.index,
                title: t('sidebar.users.children.subscribers')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.name ? " - " + info?.name : "")
            },
            {
                path: null,
                title: t('labels.bankbooks')
            }
        ])
    }, [setBreadcrumbs, t, info]);

    const {items, loading, pageCount, params, handlePageClick, fetchItems} = useFetchItems(SubscribersServices.bankbooks.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(item => {
                const company = item.company ? item.company.name : "";

                return {
                    id: item.id,
                    fields: [
                        item.id,
                        <span style={{color: "var(--secondary)"}}>{item.number}</span>,
                        item.address,
                        company
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: ['ID', t('labels.account_number'), t('labels.address'), t('labels.resource_company'), ''],
        sizes: ['7%', '20%', '35%', '25%', '']
    }

    return (
        <>
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        editLink={() => false}
                        removeMethod={childId => SubscribersServices.bankbooks.detach({parentId: id, id: childId})}
                        removeModalContent={{
                            title: t('modals.remove_bankbook.title'),
                            yes: t('labels.yes'),
                            no: t('labels.cancel')
                        }}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small $customColor={'#ED7E52'} $withIcon variant="outline" to={routePaths.bankbooks.create}><i className="icon-plus"/><span>{t('labels.create')}</span></Button>
                                </div>
                                <div className="col-auto">
                                    <Button $small $customColor={'#047884'} $withIcon variant="outline" onClick={() => setBankBookModal(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {bankBookModal &&
                <AttachBankBookModal
                    isOpen={bankBookModal}
                    closeModal={() => setBankBookModal(false)}
                    onRequestClose={() => setBankBookModal(false)}
                    method={SubscribersServices.bankbooks.attach}
                    successCallback={fetchItems}
                    id={id}
                />
            }
        </>
    );
};

export default SubscriberEditBankbooks;