import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import ModalComponent from "../../../../components/ModalComponent";
import Dictionaries from "../../../../services/operator/dictionaries";
import CustomSelect from "../../../../components/CustomSelect";

const AttachSectorModal = ({method, closeModal, id, successCallback, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, control, watch, setValue, reset} = useForm();

    const cityId = watch('city_id', null);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [sectors, setSectors] = useState();
    const [sectorsLoading, setSectorsLoading] = useState(true);

    const fetchCities = useCallback(async () => {
        setCitiesLoading(true);

        const res = await Dictionaries.cities({queryString: `company_id=${id}`});

        if (res.statusCode === 200) {
            setCities(res.content);
        } else {
            toast.error(res.message)
        }

        setCitiesLoading(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchSectors = useCallback(async () => {
        setValue('sector_id', null);

        if (cityId) {
            setSectorsLoading(true);

            const res = await Dictionaries.freeSectors({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setSectors(res.content);
            } else {
                toast.error(res.message)
            }

            setSectorsLoading(false);
        }
    }, [setValue, cityId]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchSectors();
    }, [fetchSectors]);

    const onSubmit = async (data) => {
        setLoading(true);

        const res = await method({sector_id: data.sector_id});

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.sector_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.sector')}>
                        <Controller name="sector_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={sectors} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? sectorsLoading || citiesLoading : false} isDisabled={!!!cityId || sectorsLoading || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachSectorModal;