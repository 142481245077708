import React from 'react';
import styled from "styled-components";

const Input = styled.input`
  display: none;

  & + span {
    cursor: pointer;
    width: 30px;
    height: 16px;
    border-radius: 8px;
    background-color: #BCBCBC;
    display: inline-block;
    padding: 1px;
    transition: .2s;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);

    &:hover {
      background-color: #979797;
    }

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #fff;
      display: block;
      transition: .2s;
    }
  }

  &:checked + span {
    background-color: var(--success);

    &:hover {
      background-color: var(--success-hover);
    }

    &:before {
      transform: translateX(100%);
    }
  }
`

const Switch = ({checked, onChange}) => {
    return (
        <label>
            <Input type="checkbox" name="" checked={checked} onChange={onChange}/>
            <span/>
        </label>
    );
};

export default Switch;