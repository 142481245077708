import React, {createContext, useContext, useState} from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import ApplicationsServices from "../../../services/laboratoryAdministrator/applicationsServices";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/laboratoryAdministrator/routePaths";
import TabLinks from "../../../components/TabLinks";
import ApplicationInformation from "./ApplicationInformation";
import ApplicationServices from "./ApplicationServices";
import ApplicationSpecialists from "./ApplicationSpecialists";
import ApplicationHistory from "./ApplicationHistory";
import ApproveModal from "./components/ApproveModal";
import DeclineModal from "./components/DeclineModal";
import Button from "../../../components/Button";
import StatusLabel from "../../../components/StatusLabel";
import {statusColors} from "../../../constants";

const infoContext = createContext(null);

const Application = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info, fetchInfo, loading} = useFetchInfo(ApplicationsServices.view, id);

    const [approveModalIsOpen, setApproveModalIsOpen] = useState();
    const [declineModalIsOpen, setDeclineModalIsOpen] = useState();

    return (
        <>
            <div className="mb-20">
                <div className="row row--multiline align-items-center">
                    <div className="col-auto">
                        <h1 className="headline-3">{t('labels.application')} {info && " №" + info.number}</h1>
                    </div>
                    <div className="col-auto">
                        {info && <StatusLabel style={{wordBreak: 'break-word'}}
                                              color={statusColors[info.status.id]}>{info.status.name}</StatusLabel>}
                    </div>
                    {info?.status?.id === 1 && <>
                        <div className="col-auto" style={{marginLeft: "auto"}}>
                            <Button $small $withIcon variant="outline" onClick={() => setApproveModalIsOpen(true)}><i className="icon-check"/><span>{t('labels.approve')}</span></Button>
                        </div>
                        <div className="col-auto">
                            <Button $small $withIcon $red variant="outline" onClick={() => setDeclineModalIsOpen(true)}><i className="icon-plus"/><span>{t('labels.decline')}</span></Button>
                        </div>
                    </>}
                </div>
            </div>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.applications.detail.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.applications.detail.services(id)}>{t('labels.services')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.applications.detail.specialists(id)}>{t('labels.specialist')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.applications.detail.history(id)}>{t('labels.changes_history')}</NavLink>
            </TabLinks>
            <infoContext.Provider value={{info, loading}}>
                <Switch>
                    <Redirect exact from={routePaths.applications.detail.index()} to={routePaths.applications.detail.information()}/>
                    <Route path={routePaths.applications.detail.information()} component={ApplicationInformation}/>
                    <Route path={routePaths.applications.detail.services()} component={ApplicationServices}/>
                    <Route path={routePaths.applications.detail.specialists()} component={ApplicationSpecialists}/>
                    <Route path={routePaths.applications.detail.history()} component={ApplicationHistory}/>
                </Switch>
            </infoContext.Provider>
            <ApproveModal
                isOpen={approveModalIsOpen}
                closeModal={() => setApproveModalIsOpen(false)}
                onRequestClose={() => setApproveModalIsOpen(false)}
                successCallback={() => {
                    setApproveModalIsOpen(false);
                    fetchInfo();
                }}
                method={ApplicationsServices.accept}
                id={id}
            />
            <DeclineModal
                isOpen={declineModalIsOpen}
                closeModal={() => setDeclineModalIsOpen(false)}
                onRequestClose={() => setDeclineModalIsOpen(false)}
                successCallback={() => {
                    setDeclineModalIsOpen(false);
                    fetchInfo();
                }}
                method={ApplicationsServices.decline}
                id={id}
            />
        </>
    );
};

export default Application;

export const useInfo = () => {
    return useContext(infoContext)
}