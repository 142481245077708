import React from 'react';
import Modal from 'react-modal'
import styled from 'styled-components'
import './ModalComponent.scss'
import Utils from "../services/utils";


const CloseBtn = styled.i`
  position:absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  
  &:hover {
    opacity: .75;
  }
`;

const ModalComponent = ({children, closeModal, disableCloseBtn, ...props}) => {
    const body = document.body;

    function afterOpenModal() {
        body.style.paddingRight = Utils.getScrollBarWidth() + "px";
        body.style.overflowY = "hidden";
    }

    function afterCloseModal() {
        body.style.removeProperty('padding-right');
        body.style.removeProperty('overflow-y');
    }

    return (
        <Modal
            {...props}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            className="ReactModal__Content--custom"
        >
            {!disableCloseBtn && <CloseBtn className="icon-close" onClick={closeModal}/>}
            {children}
        </Modal>
    );
};

export default ModalComponent;