import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Button from "../../../components/Button";
import {Controller, useForm} from "react-hook-form";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import Dictionaries from "../../../services/operator/dictionaries";
import ImportServices from "../../../services/operator/importServices";
import ImportResult from "./ImportResult";
import Uploader from "./components/Uploader";

const DevicesDeleteImport = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const [response, setResponse] = useState()
    const [loading, setLoading] = useState()

    const {control, watch} = useForm()

    const companyId = watch('company_id')

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.data_import.title')
            },
            {
                path: null,
                title: t('sidebar.data_import.children.devices')
            }
        ])
    }, [setBreadcrumbs, t]);

    const handleResponse = useCallback(response => {
        setResponse(response.content)
    }, [])

    return (
        <>
            <h1 className="headline-4">{t('sidebar.data_import.title')} - {t('sidebar.data_import.children.devices_to_delete')}</h1>
            {!response
                ? <WhiteBlock>
                <FormGroup label={t('labels.resource_company')}>
                    <Controller name="company_id" control={control}
                                render={({field: {onChange, value}}) => (
                                    <CustomAsyncSelect
                                        method={Dictionaries.companies}
                                        onChange={onChange}
                                        value={value}
                                        $small
                                    />
                                )}/>
                </FormGroup>
                <FormGroup label={t('labels.choose_file')}>
                    <div className="mb-10">
                        <Uploader
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            name='file'
                            handleResponse={handleResponse}
                            setParentLoading={setLoading}
                            disabled={!companyId || loading}
                            data={{company_id: companyId}}
                            method={ImportServices.importDevicesToDelete}
                            errorsUrlMethod={ImportServices.generateErrorsFile}
                        >
                            .xlsx, .xls, <br/>
                            Не более 50 мб
                        </Uploader>
                    </div>
                    <Button variant="outline" $small $withIcon href='/uploads/sample_devices_to_delete.xlsx' target="_blank"
                            download><i className="icon-download"/><span>{t('labels.file_example')}</span></Button>
                </FormGroup>
            </WhiteBlock>
                : <Button $small onClick={() => setResponse(null)}>{t('labels.choose_another_file')}</Button>
            }
            {response && <ImportResult response={response}/>}
        </>
    );
};

export default DevicesDeleteImport;