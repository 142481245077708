import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class LaboratoriesServices {
    static index = (queryString) => {
        return getData(http, `/laboratories${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/laboratories/store`, data)
    }

    static view = (id) => {
        return getData(http, `/laboratories/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/laboratories/${id}`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/laboratories/${id}`)
    }

    static services = {
        index: ({id}, queryString) => {
            return getData(http, `/laboratories/${id}/services${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/laboratories/${id}/services/attach`, data)
        },
        update: (id, data) => {
            return postData(http, `/laboratories/${id}/services/update`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/laboratories/${parentId}/services/detach`, {service_id: id})
        }
    }

    static administrators = {
        index: ({id}, queryString) => {
            return getData(http, `/laboratories/${id}/administrators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/laboratories/${id}/administrators/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/laboratories/${parentId}/administrators/detach`, {user_id: id})
        }
    }

    static specialists = {
        index: ({id}, queryString) => {
            return getData(http, `/laboratories/${id}/specialists${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/laboratories/${id}/specialists/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/laboratories/${parentId}/specialists/detach`, {user_id: id})
        }
    }

    static cities = {
        index: ({id}, queryString) => {
            return getData(http, `/laboratories/${id}/cities${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/laboratories/${id}/cities/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/laboratories/${parentId}/cities/detach`, {city_id: id})
        }
    }
}