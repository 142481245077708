import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class LabSpecialistsServices {
    static index = (queryString) => {
        return getData(http, `/users/specialists${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/users/specialists/store`, data)
    }

    static view = (id) => {
        return getData(http, `/users/specialists/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/users/specialists/${id}`, data)
    }

    static resetPassword = (id) => {
        return postData(http, `/users/specialists/${id}/resetPassword`)
    }

    static delete = (id) => {
        return deleteData(http, `/users/specialists/${id}`)
    }

    static sendNotification = (id, data) => {
        return postData(http, `/users/specialists/${id}/sendNotification`, data)
    }
}