import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/admin/routePaths";
import LaboratoryEditInformation from "./LaboratoryEditInformation";
import LaboratoryEditServices from "./LaboratoryEditServices";
import LaboratoryEditAdministrators from "./LaboratoryEditAdministrators";
import LaboratoryEditSpecialists from "./LaboratoryEditSpecialists";
import LaboratoryEditCities from "./LaboratoryEditCities";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";
import LaboratoriesServices from "../../../services/admin/laboratoriesServices";

const LaboratoryEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(LaboratoriesServices.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.laboratories.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratories.edit.services(id)}>{t('labels.services')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratories.edit.administrators(id)}>{t('labels.administrators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratories.edit.specialists(id)}>{t('labels.specialists')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.laboratories.edit.cities(id)}>{t('labels.cities')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.laboratories.edit.index()} to={routePaths.laboratories.edit.information()}/>
                <Route path={routePaths.laboratories.edit.information()} component={LaboratoryEditInformation}/>
                <Route path={routePaths.laboratories.edit.services()} component={LaboratoryEditServices}/>
                <Route path={routePaths.laboratories.edit.administrators()} component={LaboratoryEditAdministrators}/>
                <Route path={routePaths.laboratories.edit.specialists()} component={LaboratoryEditSpecialists}/>
                <Route path={routePaths.laboratories.edit.cities()} component={LaboratoryEditCities}/>
            </Switch>
        </>
    );
};

export default LaboratoryEdit;