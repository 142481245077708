import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Button from '../../../../components/Button';
import ModalComponent from '../../../../components/ModalComponent';

const ConfirmActionModal = ({method, successCallback, failCallback, closeModal, setErrorMsg, content, data, green, parentId, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const confirm = async () => {
        setLoading(true);
        
        const res = parentId ? await method(parentId, data) : await method(data);

        if(res.statusCode === 409){
            setErrorMsg(res.message)
            failCallback()
        }

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            successCallback();
        }

        setLoading(false);
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{maxWidth: 500}}>
                <h4 className="headline-4">{content && content.title ? content.title : t('messages.action_confirm')}</h4>
                {content && content.text && <div className="body-2">{content.text}</div>}
                <div className={`row row--multiline justify-end`}>
                    <div className="col-auto">
                        <Button $small $gray variant='outline' onClick={closeModal}>{content && content.no ? content.no : t('labels.cancel')}</Button>
                    </div>
                    {green ? 
                        <div className="col-auto">
                            <Button $small $green onClick={confirm}>{content && content.yes ? content.yes : t('labels.yes')}</Button>
                        </div>
                        :
                        <div className="col-auto">
                            <Button $small $red onClick={confirm}>{content && content.yes ? content.yes : t('labels.yes')}</Button>
                        </div>
                    }
                </div>
            </div>
        </ModalComponent>
    );
};

export default ConfirmActionModal;