import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ConfirmActionModal from "../components/ConfirmActionModal";
import DeclineModal from "./DeclineModal";
import BBIndicationsServices from '../../../../services/minAdmin/bbIndicationsServices';
import FailAcceptModal from './FailAcceptModal';
import IndicationsGrid from './IndicationsGrid';
import { IndicationThDiv, IndicationThEl } from '../../../../components/IndicationsComponents';

const IndicationsTable = ({data, fetchData}) => {
    const {t} = useTranslation()

    const [activeId, setActiveId] = useState();
    const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
    const [approveModalIsOpen, setApproveModalIsOpen] = useState();
    const [declineModalIsOpen, setDeclineModalIsOpen] = useState();

    const [failAcceptModal, setFailAcceptModal] = useState();
    const [errorMsg, setErrorMsg] = useState();

    const handleRemove = (e, id) => {
        e.stopPropagation();
        setActiveId(id);
        setRemoveModalIsOpen(true)
    }

    const handleApprove = (e, id) => {
        e.stopPropagation();
        setActiveId(id);
        setApproveModalIsOpen(true)
    }

    const handleDecline = (e, id) => {
        e.stopPropagation();
        setActiveId(id);
        setDeclineModalIsOpen(true)
    }

    if (!data.length) return t('messages.no_data_available')

    return (
        <>
            <IndicationThEl>
                <IndicationThDiv>{t('labels.bankbook')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.fullName')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.address')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.people')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.device_count')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.previous_indication_common')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.current_indication_common')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.who_approved')}</IndicationThDiv>
                <IndicationThDiv>{t('labels.status')}</IndicationThDiv>
            </IndicationThEl>
                {data.map((item) => (
                    <IndicationsGrid
                        key={item.id}
                        data={item}
                        handleRemove={handleRemove}
                        handleApprove={handleApprove}
                        handleDecline={handleDecline}
                        fetchItems={fetchData}
                    />
                ))}
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    fetchData();
                }}
                method={BBIndicationsServices.delete}
                data={activeId}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <ConfirmActionModal
                isOpen={approveModalIsOpen}
                closeModal={() => setApproveModalIsOpen(false)}
                onRequestClose={() => setApproveModalIsOpen(false)}
                successCallback={() => {
                    setApproveModalIsOpen(false);
                    fetchData();
                }}
                failCallback={() => {
                    setFailAcceptModal(true);
                    setApproveModalIsOpen(false);
                }}
                method={BBIndicationsServices.accept}
                data={activeId}
                content={{
                    title: t('messages.approve_indication'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
                setErrorMsg={setErrorMsg}
                green={true}
            />
            <DeclineModal
                isOpen={declineModalIsOpen}
                closeModal={() => setDeclineModalIsOpen(false)}
                onRequestClose={() => setDeclineModalIsOpen(false)}
                successCallback={() => {
                    setDeclineModalIsOpen(false);
                    fetchData();
                }}
                method={BBIndicationsServices.decline}
                indicationId={activeId}
            />
            <FailAcceptModal
                isOpen={failAcceptModal}
                closeModal={() => setFailAcceptModal(false)}
                onRequestClose={() => setFailAcceptModal(false)}
                successCallback={() => {
                    setFailAcceptModal(false);
                    fetchData();
                }}
                method={BBIndicationsServices.accept}
                indicationId={activeId}
                data={{force_accept: true}}
                errorMsg={errorMsg}
            />
        </>
    );
};

export default IndicationsTable;