import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import Filter from "./Filter";
import SecondaryLink from "../../../../components/SecondaryLink";
import SealingOperatorServices from '../../../../services/admin/sealingOperatorServices';

const SealingOperators = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: null,
                title: t('sidebar.users.children.sealer_operators')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(SealingOperatorServices.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(item => {
                const info = <>
                    {item.name}
                    {item.phone && <><br/><SecondaryLink href={`tel:${item.phone}`} title="">{item.phone}</SecondaryLink></>}
                    {item.email && <><br/><SecondaryLink href={`tel:${item.email}`} title="">{item.email}</SecondaryLink></>}
                </>

                return {
                    id: item.id,
                    fields: [
                        item.id,
                        info,
                        item.company?.name,
                        <span className={item.has_offline_data ? 'red-dot dot-green' : 'red-dot dot-red'}/>,
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.personal_data'), t('labels.resource_company'), t('labels.offline_data'), ''],
        sizes: ['10%', '40%', '25%', '15%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.users.children.sealer_operators')}</h1>
                <Button $small $withIcon variant="outline" to={routePaths.users.children.sealer_operator.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
            </HeadingWithButtons>
            <Filter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    vAlign="top"
                                    items={formattedItems}
                                    editLink={routePaths.users.children.sealer_operator.edit}
                                    removeMethod={SealingOperatorServices.delete}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default SealingOperators;