import React from 'react';
import { Upload } from 'antd';
import styled from "styled-components";
import {toast} from "react-toastify";

const PreviewContent = styled.div`
  font-size: 12px;
  color: var(--accent);
  padding: 20px;
`

const DndFileUploader = ({children, maxCount, accept, name, headers, action, successCallback, disabled}) => {
    const { Dragger } = Upload;

    const config = {
        name,
        accept,
        maxCount: maxCount || 10,
        headers,
        action,
        disabled,
        onChange(info) {
            const { status, response } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                response.statusCode === 200 ? toast.success(response.message) : toast.error(response.message)
                successCallback(response)
            } else if (status === 'error') {
                toast.error(response.message)
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Dragger {...config}>
            <PreviewContent>
                {children}
            </PreviewContent>
        </Dragger>
    );
};

export default DndFileUploader;