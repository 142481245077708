import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import routePaths from "../../../../navigation/CallOperator/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import ModelChangesServices from "../../../../services/callOperator/modelChangesServices";
import SecondaryLink from "../../../../components/SecondaryLink";
import Expand from "../../../../components/Expand";
import StatusLabel from '../../../../components/StatusLabel';
import { statusColors, statuses } from '../../../../constants';
import ModelChangesDevicesFilter from './components/ModelChangesDevicesFilter';


const ModelChangesDevices = ({statusId = "", statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('labels.change_devices')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t])// eslint-disable-line react-hooks/exhaustive-deps

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(ModelChangesServices.devices.index, statusId, {with_image: statusId === "" ? 0 : 1})
    
    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, resourceType, company, bankbook, creator, updater, status}) => {
                return {
                    id,
                    fields: [
                        id,
                        <>
                            <div>{number}</div>
                            <div>{resourceType?.name}</div>
                        </>,
                        company?.name,
                        bankbook !== null &&
                        <Expand height={51}>
                            {bankbook.path}<br/>
                            <SecondaryLink to={routePaths.bankbooks.edit.information(bankbook.id)}>{bankbook.number}</SecondaryLink>
                        </Expand>,
                        creator?.name,
                        updater?.name,
                        <StatusLabel color={statusColors[status]}>{statuses[status]}</StatusLabel>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.data'), t('labels.resource_company'), t('labels.bankbook_data'), t('labels.operator'), t('labels.administrator'),t('labels.status'), ''],
        sizes: ['5%', '15%', '15%', '15%', '15%', '15%', '15%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('labels.change_devices')}</h1>
            </HeadingWithButtons>
            <ModelChangesDevicesFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    vAlign="top"
                                    items={formattedItems}
                                    editLink={routePaths.model_сhanges.edit.devices}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default ModelChangesDevices;