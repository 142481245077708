import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  
  &>div {
    &:first-child {
      flex-shrink: 0;
      margin-right: 16px;
    }
    
    &:last-child {
      flex-grow: 1;
      min-width: 0;
    }
  }
`

const FilterTableWrapper = (props) => {
    return (
        <Container {...props}/>
    );
};

export default FilterTableWrapper;