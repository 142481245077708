import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import { useForm} from "react-hook-form";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../../hooks/useUpdate.hook";
import {useHistory, useParams} from "react-router-dom";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import ConfirmActionModal from "../../../../components/modals/ConfirmActionModal";
import TaskSealingServices from '../../../../services/admin/tasksSealingServices';
import TablePreloader from '../../../../components/TablePreloader';
import ItemsTable from '../../../../components/ItemsTable';
import DeclineModalBankbooks from '../../ModelChanges/Bankbooks/components/DeclineModalBankbooks';
import TasksUnsealingInfo from './components/TasksUnsealingInfo';
import { statusesColors } from '../../../../constants';
import StatusLabel from '../../../../components/StatusLabel';

const TasksUnsealingEdit = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, setValue} = useForm();
    const history = useHistory();

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [declineModalIsOpen, setDeclineModalIsOpen] = useState();
    const [approveModalIsOpen, setApproveModalIsOpen] = useState();
    const [taskUnsealingInfoOpen, setTaskUnsealingInfoOpen] = useState();
    const [viewId, setViewId] = useState();

    const {info, loading: fetchLoading} = useFetchInfo(TaskSealingServices.unsealing.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.tasks.children.tasks_for_unsealing.children.all.index,
                title: t('labels.tasks_for_unsealing')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.name ? " - " + info?.name : "")
            }
        ])
    }, [setBreadcrumbs, t, info])

    const [formattedItems, setFormattedItems] = useState();

    const {onSubmit, loading} = useUpdate(TaskSealingServices.unsealing.accept, id, false);

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                if (key) setValue(key, value);
            }
        }
    }, [info, setValue])

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    const handleDecline = (e) => {
        e.preventDefault();
        setDeclineModalIsOpen(true);
    }

    const handleApprove = (e) => {
        e.preventDefault();
        setApproveModalIsOpen(true);
    }

    useEffect(() => {
        if (info?.devices) {
            const newItems = info.devices.map(({id, number, type}) => {
                return {
                    id,
                    fields: [
                        id,
                        number,
                        type?.name
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [info?.devices]);


    const tableConfig = {
        head: [t('labels.id'), t('labels.pu_number'),  t('labels.resource_type'), ''],
        sizes: ['10%', '40%', '40%', '']
    }

    const view = () => {
        setTaskUnsealingInfoOpen(true)
    }
    
    return (
        <>
            <WhiteBlock>
                <div className="mb-20 row row--multiline align-items-center">
                    <div className="col-auto">
                        <h1 className="headline-4">{t('labels.editing')} {info?.name ? " - " + info?.name : ""}</h1>
                    </div>
                    <div className="col-auto">
                        {info && <StatusLabel style={{wordBreak: 'break-word'}}
                                              color={statusesColors[info.status.id]}>{info.status.name}</StatusLabel>}
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    { info && <>
                        <FormGroup label={t('labels.resource_company')}>
                            <Input type="text" value={info.company.name} disabled/>
                        </FormGroup>
                        <FormGroup label={t('labels.address')}>
                            <Input type="text" value={info.address} disabled/>
                        </FormGroup>
                        <FormGroup label={t('labels.bankbook')}>
                            <Input type="text" value={info.bankbook.name} disabled/>
                        </FormGroup>
                        <FormGroup label={t('labels.sector')}>
                            <Input type="text" value={info.sector} disabled/>
                        </FormGroup>
                        <FormGroup label={t('labels.contact_person')}>
                            <Input type="text" value={info.user.name} disabled/>
                        </FormGroup>
                        <FormGroup label={t('labels.phones')}>
                            <Input type="text" value={info.user.phone ? info.user.phone : ''} disabled/>
                        </FormGroup>
                    </>}
                </form>
            </WhiteBlock>
            <WhiteBlock>
                        {formattedItems
                            ?
                                <ItemsTable
                                    {...{config: tableConfig, ...info.devices, loading}}
                                    items={formattedItems}
                                    view={view}
                                    vAlign={"top"}
                                    setViewId={setViewId}
                                />
                            : <TablePreloader/>}
            </WhiteBlock>
            {info?.reject_reason && 
                <FormGroup label={t('modals.decline_reason.title')}>
                    <Input type="text" value={info.reject_reason}  disabled/>
                </FormGroup>
            }
            {info?.status.id === 6 ?
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button onClick={handleRemove} $small $red>{t('labels.remove')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleDecline} $small $yellow>{t('labels.decline')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleApprove} $small>{t('labels.approve')}</Button>
                    </div>
                </div>
                :
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button onClick={handleRemove} $small $red>{t('labels.remove')}</Button>
                    </div>
                </div>
            }
            <ConfirmActionModal
                isOpen={approveModalIsOpen}
                closeModal={() => setApproveModalIsOpen(false)}
                onRequestClose={() => setApproveModalIsOpen(false)}
                successCallback={() => {
                    setApproveModalIsOpen(false);
                    history.push(routePaths.tasks.children.tasks_for_unsealing.children.all.index)
                }}
                method={TaskSealingServices.unsealing.accept}
                data={id}
                content={{
                    title: t('messages.action_confirm'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.push(routePaths.tasks.children.tasks_for_unsealing.children.all.index)
                }}
                method={TaskSealingServices.unsealing.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <DeclineModalBankbooks
                isOpen={declineModalIsOpen}
                closeModal={() => setDeclineModalIsOpen(false)}
                onRequestClose={() => setDeclineModalIsOpen(false)}
                successCallback={() => {
                    setDeclineModalIsOpen(false);
                    history.push(routePaths.tasks.children.tasks_for_unsealing.children.all.index);
                }}
                method = {TaskSealingServices.unsealing.decline}
                id={id}
            />
            <TasksUnsealingInfo
                isOpen={taskUnsealingInfoOpen}
                closeModal={() => setTaskUnsealingInfoOpen(false)}
                onRequestClose={() => setTaskUnsealingInfoOpen(false)}
                successCallback={() => {
                    setTaskUnsealingInfoOpen(false);
                }}
                id={viewId}
                info = {info}
            />
        </>
    );
};

export default TasksUnsealingEdit;