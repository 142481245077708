import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalComponent from "../ModalComponent";
import FormGroup from "../FormGroup";
import TextArea from "../TextArea";
import Button from "../Button";
import {useForm} from "react-hook-form";
import Input from "../Input";
import {toast} from "react-toastify";

const NotificationModal = ({method, closeModal, id, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const {handleSubmit, register, reset} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        setTimeout(async () => {
            const res = await method(id, data);

            res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

            if (res.statusCode === 200) {
                reset();
                closeModal();
            }

            setLoading(false);
        }, 500)
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.notification.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <FormGroup label={t('labels.name_kk')}>
                        <Input type="text" {...register('name_kk')} required/>
                    </FormGroup>
                    <FormGroup label={t('labels.content_kk')}>
                        <TextArea {...register('description_kk')} required/>
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru')}>
                        <Input type="text" {...register('name_ru')} required/>
                    </FormGroup>
                    <FormGroup label={t('labels.content_ru')}>
                        <TextArea {...register('description_ru')} required/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.send')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default NotificationModal;