import React from 'react';
import styled, {css} from 'styled-components'

const Container = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${props => props.$brnone && css`
  &:not(:last-child) {
    margin: 0 10px 0 0;
  }
  ` }
  
  label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    color: var(--secondary)
  }
`;

const FormGroup = ({children, label, ...props}) => {
    return (
        <Container {...props}>
            {label ? <label>{label}</label> : ''}
            {children}
        </Container>
    );
};

export default FormGroup;