import React, {useEffect, useState} from 'react';
import { Upload } from 'antd';
import styled from "styled-components";
import * as XLSX from "xlsx";
import Loading from "../../../../components/Loading";
import {useTranslation} from "react-i18next";
import EllipsisLoading from "../../../../components/EllipsisLoading";
import {toast} from "react-toastify";

const PreviewContent = styled.div`
  font-size: 12px;
  color: var(--accent);
  padding: 20px;
`

const Uploader = ({children, accept, method, data: additionalData, setParentLoading, handleResponse, name, headers, disabled}) => {
    const { Dragger } = Upload;

    
    const {t} = useTranslation()

    const [processing, setProcessing] = useState()
    const [total, setTotal] = useState()
    const [processed, setProcessed] = useState()
    const [errors, setErrors] = useState([])
    const [errorsLength, setErrorsLength] = useState()
    const [errorsFileLoading, setErrorsFileLoading] = useState()
    const [errorsFileLink, setErrorsFileLink] = useState()
    const [finished, setFinished] = useState()
    const [fields, setFields] = useState([])

    const config = {
        name,
        accept,
        maxCount:1,
        headers,
        disabled,
        beforeUpload: file => {
            if (file) {
                const reader = new FileReader()

                setParentLoading(true)

                reader.onload = async e => {
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, {type: "binary"});
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname]
                    const [fields, ...data] = XLSX.utils.sheet_to_json(ws, {header: 1})

                    const stringData = data.map(item => JSON.stringify(item));
                    const uniqueStringData = [...new Set(stringData)]
                    const uniqueData = uniqueStringData.map(item => JSON.parse(item))

                    const queueSize = 1;  // Describes how many requests can be handled at the same time
                    const errors = [];
                    const uniqueDataLength = uniqueData.length;
                    let processedCount = 0;

                    setTotal(uniqueDataLength)
                    setFields(fields)
                    setErrorsLength(0)
                    setProcessed(0)
                    setProcessing(true)

                    const postItem = async () => {
                        const item = uniqueData.shift()

                        if (item) {
                            const res = await method({data: item, ...additionalData})
                            processedCount += 1
                            setProcessed(prev => prev + 1)

                            if (res.statusCode !== 200) {
                                const startIndex = item.length;
                                item[fields.length] = res.message;
                                item.fill("", startIndex, fields.length)
                                errors.push(item)
                                setErrorsLength(prev => prev + 1)
                            }

                            if (res.statusCode === 200 && !res?.content?.status) {
                                errors.push(res.content.data)
                                setErrorsLength(prev => prev + 1)
                            }

                            postItem()

                            if (processedCount === uniqueDataLength) {
                                setProcessing(false)
                                setErrors(errors);

                                setParentLoading(false)
                                setFinished(true)
                            }
                        }
                    }

                    for (let i = 0; i < queueSize; i++) {
                        postItem()
                    }
                }

                reader.readAsBinaryString(file)
            }
            return false
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        if (finished) {
            handleResponse({
                content: {
                    all_count: total,
                    fail_path: errorsFileLink,
                    fails: errors,
                    fails_count: errors.length,
                    fields: fields,
                    success_count: total - errors.length
                }
            })
        }
    }, [handleResponse, errors, processed, total, errorsFileLink, fields, finished])

    return (
        <>
            <Dragger {...config}>
                <PreviewContent>
                    {children}
                </PreviewContent>
            </Dragger>
            {processing
                ? <>
                    <div className="mb-20"/>
                    <div className="mb-10">
                        <Loading loaded={parseInt(processed/total*100)}/>
                    </div>
                    <div className="mb-20">
                        <div dangerouslySetInnerHTML={{__html: t('labels.import_processed', {processed, total, errors: errorsLength})}}/>
                    </div>
                </>
                : ""}
            {errorsFileLoading
                ? <>
                    <div className="mb-20"/>
                    <div className="mb-20">
                        <strong><EllipsisLoading>{t('labels.error_processing')}</EllipsisLoading></strong>
                    </div>
                </>
                : ""
            }
        </>
    );
};

export default Uploader;