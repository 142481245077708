import React from 'react'
import styled, {css} from 'styled-components'


const StyledWrapper = styled.div`
    display: grid;
    grid-template-columns: .7fr .9fr 1.4fr .7fr .6fr 1.1fr 1.1fr .6fr 155px;
    width:100%;
    border: 1px solid #E5E9EB;
    margin-bottom:24px;
    border-radius: 16px;
    background: #fff;
`

const IndicationThEl = ({children, ...props}) => {
    return (
        <StyledWrapper {...props}>
            {children}
        </StyledWrapper>
    )
}


const StyledThDiv = styled.div`
    font-weight: 600;
    font-size: 12px;
    padding: 9px 16px;
    color: var(--gray-400);
    text-transform: uppercase;
    font-size: 12px;
    display:flex;
    align-items:center;
    justify-content:start;

    &:not(:last-child){
        border-right:1px solid #E5E9EB;
    }
`

const IndicationThDiv = ({children, ...props}) => {
    return (
        <StyledThDiv {...props}>
            {children}
        </StyledThDiv>
    )
}


const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: .7fr .9fr 1.4fr .7fr .6fr 1.1fr 1.1fr .6fr 155px;
    margin-bottom:8px;
    border-radius: 16px;
    background: #fff;

    ${props => props.gray && css`
        background-color: #fbfbfb;
    `}

    i{
        color: #B3B3B3;
        margin-right: 4px;
    }
`

const IndicationChildTh = ({children, ...props}) => {
    return (
        <ContentWrapper {...props}>
            {children}
        </ContentWrapper>
    )
}


const ContentDiv = styled.div`
    vertical-align: middle;
    font-size: 14px;
    line-height: 24px;
    padding: 9px 16px;
    display:flex;
    align-items:center;
    justify-content:start;

    &:not(:last-child){
        border-right:1px solid #E5E9EB;
    }

    .icon-trash{
        margin-left:8px;
    }
`
const IndicationContentDiv = ({children, ...props}) => {
    return (
        <ContentDiv {...props}>
            {children}
        </ContentDiv>
    )
}

const SubContentWrapper = styled.div`
    display: grid;
    font-size: 12px;
    font-weight: 600;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 88px;
    background-color: #fbfbfb;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin: 0 8px;
    i{
        color: #B3B3B3;
    }
`

const IndicationSubWrapper = ({children, ...props}) => {
    return (
        <SubContentWrapper {...props}>
            {children}
        </SubContentWrapper>
    )
}


const StyledThDiv2 = styled(StyledThDiv)`
    padding: 4px 16px;
`

const IndicationSubThDiv = ({children, ...props}) => {
    return (
        <StyledThDiv2 {...props}>
            {children}
        </StyledThDiv2>
    )
}


const StyledThDiv3 = styled(StyledThDiv2)`
    padding: 12px 16px;
`

const IndicationSubThDiv2 = ({children, ...props}) => {
    return (
        <StyledThDiv3 {...props}>
            {children}
        </StyledThDiv3>
    )
}

const SubContentWrapper2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 88px;
    background-color: #fbfbfb;
    border-top: 1px solid #E5E9EB;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin: 0 8px;
    i{
        color: #B3B3B3;
    }
`

const IndicationSubContentWrapper = ({children, ...props}) => {
    return (
        <SubContentWrapper2 {...props}>
            {children}
        </SubContentWrapper2>
    )
}

const MinContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #fbfbfb;
    margin:8px 8px 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`

const IndicationMinContentWrapper = ({children, ...props}) => {
    return (
        <MinContentWrapper {...props}>
            {children}
        </MinContentWrapper>
    )
}

const MinContentWrapper2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #fbfbfb;
    margin:0 8px 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid #E5E9EB;
`

const IndicationMinContentWrapper2 = ({children, ...props}) => {
    return (
        <MinContentWrapper2 {...props}>
            {children}
        </MinContentWrapper2>
    )
}

export {IndicationThEl, IndicationThDiv, IndicationChildTh, IndicationContentDiv, IndicationSubWrapper, IndicationSubThDiv, IndicationSubThDiv2, IndicationSubContentWrapper, IndicationMinContentWrapper, IndicationMinContentWrapper2};