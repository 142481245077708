import React, {useEffect} from 'react';
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import IndicationsServices from "../../../../services/minAdmin/indicationsServices";
import {useTranslation} from "react-i18next";

const IndicationCreatePreparedData = ({id, register, setValue}) => {
    const {t} = useTranslation();

    const {info, loading} = useFetchInfo(IndicationsServices.prepare, id);

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                info[key] && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    return (
        <div className="mb-20">
            <div className={loading ? "isLoading" : ""}>
                <FormGroup label={t('labels.previous_period')}>
                    <Input type="text" {...register('prev_date')} disabled/>
                </FormGroup>
                <FormGroup label={t('labels.previous_indication')}>
                    <Input type="text" {...register('prev_value')} disabled/>
                </FormGroup>
                <hr/>
                <FormGroup label={t('labels.current_period')}>
                    <Input type="text" {...register('curr_date')} disabled/>
                </FormGroup>
            </div>
        </div>
    );
};

export default IndicationCreatePreparedData;