import React from 'react';
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";

const Container = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  
  li {
    font-size: 12px;
    
    &:not(:last-child) {
      &:after {
        content: '/';
        margin: 0 5px;
      }
    }
    
    a {
      color: var(--accent);
      
      &:hover {
        color: var(--accent-hover);
      }
    }
  }
`;

const Breadcrumbs = ({value, ...props}) => {
    const location = useLocation();

    if (!value) {
      return null;
    }
    
    return (
      <Container {...props}>
        {value.map(({path, title}, index) => (
          <li key={index}>
            {location.pathname === path || !path ? <span>{title}</span> : <NavLink to={path}>{title}</NavLink>}
          </li>
        ))}
      </Container>
    );
};

export default Breadcrumbs;