import http from "./http-common"
import {getData, postData} from "../handlers";

export default class SettingsServices {
    static index = (queryString) => {
        return getData(http, `/settings${queryString ? `?${queryString}` : ""}`);
    }

    static update = (id, data) => {
        return postData(http, `/settings/${id}`, data)
    }
}