import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import useFetchItems from "../../../hooks/useFetchItems";
import SecondaryLink from "../../../components/SecondaryLink";
import ApplicationsServices from "../../../services/callOperator/applicationsServices";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import ApplicationsFilter from "./components/ApplicationsFilter";
import Expand from "../../../components/Expand";
import StatusLabel from "../../../components/StatusLabel";
import routePaths from "../../../navigation/CallOperator/routePaths";
import {statusColors} from "../../../constants";
import Button from "../../../components/Button";
import ConfirmMultipleActionsModal from "../../../components/modals/ConfirmMultipleActionsModal";
import HeadingWithButtons from "../../../components/HeadingWithButtons";

const Applications = ({statusId = "", statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.applications.title')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems,
        setFilterData
    } = useFetchItems(ApplicationsServices.index, statusId)

    const [formattedItems, setFormattedItems] = useState();

    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState();

    const [checkedList, setCheckedList] = useState([]);

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, services, user, address, laboratory, created_at, status}) => {
                return {
                    id: id,
                    fields: [
                        number,
                        laboratory?.name,
                        user.name,
                        <Expand height={50}>
                            {services.map(item => item.name).join(', ')}
                        </Expand>,
                        <Expand height={50}>
                            {user.name}
                            {user.phone && <><br/><SecondaryLink
                                href={`tel:${user.phone}`}>{user.phone}</SecondaryLink></>}
                            {user.type && <><br/>{user.type.name}</>},
                            {address && <><br/>{address}</>},
                        </Expand>,
                        created_at,
                        <StatusLabel style={{wordBreak: 'break-word'}}
                                     color={statusColors[status.id]}>{status.name}</StatusLabel>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [t, items]);

    const tableConfig = {
        head: [t('labels.application_number'), t('labels.laboratory'), t('labels.user_fio'), t('labels.services'), t('labels.subscriber_information'), t('labels.created_date'), t('labels.status'), ''],
        sizes: ['10%', '12%', '12%', '16%', '16%', '12%', '15%', '']
    }

    const buttons = () => (
        <div className="row row--multiline">
            <div className="col-auto">
                <Button $small $customColor={'var(--success)'}
                        onClick={() => setConfirmModalIsOpen(true)}>{t('labels.confirm_payment')}</Button>
            </div>
        </div>
    )

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.applications.title')}</h1>
                <Button $small $withIcon variant="outline" to={routePaths.applications.create}><i className="icon-plus"/><span>{t('labels.create_application')}</span></Button>
            </HeadingWithButtons>
            <ApplicationsFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    vAlign="top"
                                    items={formattedItems}
                                    editLink={routePaths.applications.detail.index}
                                    removeMethod={ApplicationsServices.delete}
                                    setCheckedList={statusId === "7" ? setCheckedList : null}
                                    topBarLeftContent={statusId === "7" ? buttons : null}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
            {statusId === "7" &&
            <ConfirmMultipleActionsModal
                isOpen={confirmModalIsOpen}
                closeModal={() => setConfirmModalIsOpen(false)}
                onRequestClose={() => setConfirmModalIsOpen(false)}
                successCallback={() => {
                    setConfirmModalIsOpen(false);
                    fetchItems();
                }}
                method={ApplicationsServices.confirmPayment}
                data={checkedList}
                content={{
                    title: t('messages.action_confirm'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            }
        </>
    );
};

export default Applications;