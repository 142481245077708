import React, { useEffect } from 'react';
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useForm } from 'react-hook-form';
import { useBreadCrumbs } from '../../../../layouts/DefaultLayout';
import useFetchInfo from '../../../../hooks/useFetchInfo.hook';
import useUpdate from '../../../../hooks/useUpdate.hook';
import WhiteBlock from '../../../../components/WhiteBlock';
import FormGroup from '../../../../components/FormGroup';
import InputMessage from '../../../../components/InputMessage';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useLangContext } from '../../../../i18n/ProvideLang';
import routePaths from '../../../../navigation/admin/routePaths';
import TasksServices from "../../../../services/admin/tasksServices"

const TaskComponiesEdit = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(TasksServices.companies.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.tasks.children.companies.index,
                title: t('sidebar.tasks.title')
            },
            {
                path: null,
                title: t('labels.plan_tasks')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {onSubmit, loading} = useUpdate(TasksServices.companies.update, id, false);

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info.tasks)) {
                if (key) setValue(key, value);
            }
        }
    }, [info, setValue])


    return (
        <>
            <h1 className="headline-4">{t('labels.plan_tasks')} - {info?.name}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    {info &&
                        Object.entries(info.headings).map(([ key, val ]) => (
                            <FormGroup key={key} label={val + " *"}>
                                <Input type="text" {...register(key, )} $error={errors.key}/>
                                {errors.key && errors.key.type === 'required' &&
                                <InputMessage $error>{t('messages.required')}</InputMessage>}
                            </FormGroup>
                          ))
                    }
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            
        </>
    );
};

export default TaskComponiesEdit;