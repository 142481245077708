import React from 'react';
import styled from 'styled-components'

const El = styled.label`
  position:relative;
  
  input {
    position:absolute;
    width: 0;
    height: 0;
    z-index: -1;
    top: 50%;
    left: 50%;
    opacity: 0;
    visibility:hidden;
    
    &:checked+span:after {
      opacity: 1;
    }
    
    &[disabled] {
      &+span {
        cursor: default;
        
        &:after {
          color: var(--gray-300)
        }
      }
    }
  }
`;

const Content = styled.span`
  padding-left: 28px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding-top: 2px;
  padding-bottom: 2px;

  &:before {
    content: "\\e905";
    font-family: icomoon;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    color: var(--gray-300);
    line-height: 1;
  }

  &:after {
    content: "\\e904";
    font-family: icomoon;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    color: var(--accent);
    line-height: 1;
    opacity: 0;
    transition: .2s;
  }
`;

const Checkbox = React.forwardRef((props, ref) => {
    const {children, ...inputProps} = props;

    return (
        <El>
            <input type="checkbox" ref={ref} {...inputProps}/>
            <Content>{children}</Content>
        </El>
    );
});

export default Checkbox;