import React from 'react';
import styled from "styled-components";

const El = styled.div`
  background-color: #F6F8F9;
  color: var(--accent);
  padding: 40px;
  border-radius: 16px;
  border: 1px dashed #6C6D6D;
  width: 100%;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: #eefafb;
  }
`

const FilesUploaderPlaceholder = ({...props}) => {
    return (
        <El {...props}/>
    );
};

export default FilesUploaderPlaceholder;