import React, {useEffect, useState} from 'react';
import WhiteBlock from "../../../components/WhiteBlock";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import Button from "../../../components/Button";
import OverflowXScrollable from "../../../components/OverflowXScrollable";
import {useTranslation} from "react-i18next";
import ItemsTable from "../../../components/ItemsTable";
import TablePreloader from "../../../components/TablePreloader";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx-js-style"

const ImportResult = ({response}) => {
    const {
        all_count, fail_path, fails, fails_count, fields, success_count
    } = response

    const {t} = useTranslation();

    const [formattedItems, setFormattedItems] = useState();
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        if (fails) {
            const newItems = fails.map((itemFields, index) => {
                return {
                    id: index,
                    fields: itemFields.map(item => item || "-")
                }
            })

            setFormattedItems(newItems);
        }
    }, [fails]);

    const tableConfig = {
        head: [...fields, t('labels.error')],
        sizes: Array(fields.length + 1).fill(100/(fields.length + 1) + "%")
    }

    const exportToCSV = () => {
        setIsLoading(true);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let date = new Date();
        const fileName = `errors_${date.getFullYear()}_${date.getMonth()+1}_${date.getDate()}_${date.toLocaleTimeString()}`;

        const ws = XLSX.utils.json_to_sheet(fails);
        XLSX.utils.sheet_add_aoa(ws, [[...fields]], { origin: "A1" });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        setIsLoading(false);
    }

    return (
        <div>
            <hr/>
            <h3 className="headline-5">{t('labels.result')}</h3>
            <div className="row row--multiline">
                <div className="col-sm-4">
                    <WhiteBlock padding="16px">
                        <div className="body mb-10" style={{color: "var(--gray-400)"}}>{t('labels.data_total')}</div>
                        <div className="headline-6">{all_count}</div>
                    </WhiteBlock>
                </div>
                <div className="col-sm-4">
                    <WhiteBlock padding="16px">
                        <div className="body mb-10" style={{color: "var(--gray-400)"}}>{t('labels.data_processed')}</div>
                        <div className="headline-6" style={{color: "var(--success)"}}>{success_count}</div>
                    </WhiteBlock>
                </div>
                <div className="col-sm-4">
                    <WhiteBlock padding="16px">
                        <div className="body mb-10" style={{color: "var(--gray-400)"}}>{t('labels.rejected')}</div>
                        <div className="headline-6" style={{color: "var(--error)"}}>{fails_count}</div>
                    </WhiteBlock>
                </div>
            </div>
            <hr/>
            {fails_count
                ? <>
                    <HeadingWithButtons>
                        <h3 className="headline-5">{t('labels.rejected_data_list')}</h3>
                        <Button onClick={() => {exportToCSV()}} $withIcon $small variant="outline"><i className="icon-download"/><span>{t('labels.download')}</span></Button>
                    </HeadingWithButtons>
                    <div className="mb-20">
                        <OverflowXScrollable style={{backgroundColor: "#fff", paddingTop: 20, paddingBottom: 20, borderRadius: 8}}>
                            <div style={{width: fields.length * 200, minWidth: "100%"}}>
                                {formattedItems
                                    ? <ItemsTable
                                        {...{config: tableConfig}}
                                        items={formattedItems}
                                    />
                                    : <TablePreloader/>}
                            </div>
                        </OverflowXScrollable>
                    </div>
                    <div className="text-right">
                        <Button onClick={() => {exportToCSV()}} $withIcon $small variant="outline"><i className="icon-download"/><span>{t('labels.download')}</span></Button>
                    </div>
                </>
                : <WhiteBlock>{t('labels.errors_not_detected')}</WhiteBlock>
            }
        </div>
    );
};

export default ImportResult;