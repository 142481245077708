import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import TextArea from "../../../components/TextArea";
import AvatarUploader from "../../../components/AvatarUploader";
import routePaths from "../../../navigation/admin/routePaths";
import InputMessage from "../../../components/InputMessage";
import { HexColorPicker } from 'react-colorful';

const DictionaryCreate = ({service, title, route, description, avatar}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, control, formState: {errors}} = useForm();
    const [color, setColor] = useState('');

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.dictionaries.index,
                title: t('sidebar.dictionaries.title')
            },
            {
                path: route.index,
                title: title
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {onSubmit, loading} = useStore(service.store);

    const customSubmit = (data) => {
        const newData = {
            ...data,
            color: color
        }
        onSubmit(newData)
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(customSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    {description && <>
                        <FormGroup label={t('labels.description_kk')}>
                            <TextArea {...register('description_kk')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.description_ru')}>
                            <TextArea {...register('description_ru')}/>
                        </FormGroup>
                    </>}
                    {avatar && <div className="row">
                        <div className="col-md-3 mb-20">
                            <FormGroup label={t('labels.image') + " *"}>
                                <Controller
                                    control={control}
                                    name="image"
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) => (
                                        <AvatarUploader onChange={onChange} value={value} dimensions={1} $error={errors.image}/>
                                    )}
                                />
                                {errors.image && errors.image.type === 'required' &&
                                <InputMessage $error>{t('messages.required')}</InputMessage>}
                            </FormGroup>
                        </div>
                    </div>}
                    <FormGroup label={t('labels.color')}>
                        <HexColorPicker color={color} onChange={setColor} className='mb-20' />
                        <Input value={color} onChange={e => setColor(e.target.value)} type="text" placeholder={t('labels.color')}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default DictionaryCreate;