import React from 'react';
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import {useForm} from "react-hook-form";
import useUpdate from "../../../hooks/useUpdate.hook";
import ProfileServices from "../../../services/general/ProfileServices";
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../../components/WhiteBlock";
import InputMessage from "../../../components/InputMessage";

const Password = () => {
    const {t} = useTranslation();

    const {handleSubmit, register, formState: {errors}} = useForm()

    const {onSubmit, loading} = useUpdate(ProfileServices.updatePassword, null, false)

    return (
        <WhiteBlock>
            <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                <FormGroup label={`${t('labels.new_password')} *`}>
                    <Input type="password" {...register('password', {required: true})} $error={errors.password}/>
                    {errors.password && errors.password.type === 'required' &&
                    <InputMessage $error>{t('messages.required')}</InputMessage>}
                </FormGroup>
                <FormGroup label={`${t('labels.confirm_password')} *`}>
                    <Input type="password" {...register('password_confirmation', {required: true})} $error={errors.password_confirmation}/>
                    {errors.password_confirmation && errors.password_confirmation.type === 'required' &&
                    <InputMessage $error>{t('messages.required')}</InputMessage>}
                </FormGroup>
                <div className="text-right">
                    <Button type="submit">{t('labels.save')}</Button>
                </div>
            </form>
        </WhiteBlock>
    );
};

export default Password;