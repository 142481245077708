import React, {useEffect, useState} from 'react';
import useUpdate from "../../../../hooks/useUpdate.hook";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import SubscribersServices from "../../../../services/admin/subscribersServices";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import routePaths from "../../../../navigation/admin/routePaths";
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import ConfirmActionModal from "../../../../components/modals/ConfirmActionModal";
import NotificationModal from "../../../../components/modals/NotificationModal";
import UpdatePasswordModal from "../../../../components/modals/UpdatePasswordModal";
import Alert from "../../../../components/Alert";
import InputMessage from "../../../../components/InputMessage";

const SubscriberEditPersonal = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [notificationModalIsOpen, setNotificationModalIsOpen] = useState();
    const [passwordMessage, setPasswordMessage] = useState();
    const [updatePasswordModalIsOpen, setUpdatePasswordModalIsOpen] = useState();

    const {info, loading: fetchLoading} = useFetchInfo(SubscribersServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: routePaths.users.children.subscribers.index,
                title: t('sidebar.users.children.subscribers')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.name ? " - " + info?.name : "")
            },
            {
                path: null,
                title: t('labels.personal_data')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {onSubmit, loading} = useUpdate(SubscribersServices.update, id, false);

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                if (key) setValue(key, value);
            }
        }
    }, [info, setValue])

    const handleNotification = (e) => {
        e.preventDefault();
        setNotificationModalIsOpen(true);
    }

    const handlePasswordReset = (e) => {
        e.preventDefault();
        setUpdatePasswordModalIsOpen(true);
    }

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    return (
        <>
            {
                passwordMessage && <Alert type="warning"
                                          hideCallback={() => setPasswordMessage(null)}>{t('labels.new_password')}: {passwordMessage}</Alert>
            }
            <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                <FormGroup label={t('labels.fullName') + ' *'}>
                    <Input type="text" {...register('name', {
                        required: true
                    })} $error={errors.name}/>
                    {errors.name && errors.name.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                </FormGroup>
                <FormGroup label={t('labels.phone_number') + ' *'}>
                    <Input type="tel" {...register('phone', {required: true})} $error={errors.phone}/>
                    {errors.phone && errors.phone.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                </FormGroup>
                <FormGroup label={t('labels.email')}>
                    <Input type="text" {...register('email', {
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('messages.invalid_email')
                            }
                        })} $error={errors.email}/>
                        {errors.email && <InputMessage $error>{errors.email.message}</InputMessage>}
                </FormGroup>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button onClick={handleNotification} $customColor={'#D86551'}>{t('labels.send_notification')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handlePasswordReset} $customColor={'#D86551'}>{t('labels.reset_password')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </div>
            </form>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.push(routePaths.users.children.subscribers.index)
                }}
                method={SubscribersServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <NotificationModal
                isOpen={notificationModalIsOpen}
                closeModal={() => setNotificationModalIsOpen(false)}
                onRequestClose={() => setNotificationModalIsOpen(false)}
                method={SubscribersServices.sendNotification}
                id={id}
            />
            <UpdatePasswordModal
                isOpen={updatePasswordModalIsOpen}
                closeModal={() => setUpdatePasswordModalIsOpen(false)}
                onRequestClose={() => setUpdatePasswordModalIsOpen(false)}
                setPasswordMessage={setPasswordMessage}
                successCallback={() => {
                    setUpdatePasswordModalIsOpen(false)
                }}
                method={SubscribersServices.resetPassword}
                data={id}
                content={{
                    title: t('modals.update_password.title'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default SubscriberEditPersonal;