import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../navigation/sealerOperator/routePaths";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/sealerOperator/dictionaries";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../hooks/useUpdate.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import CustomSelect from "../../../components/CustomSelect";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import BankbooksServices from "../../../services/sealerOperator/bankbooksServices";
import InputMessage from "../../../components/InputMessage";
import TextArea from "../../../components/TextArea";

const BankbookEditInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, control, watch, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const {state} = useLocation();

    const {info, loading: fetchLoading} = useFetchInfo(BankbooksServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.bankbooks.index,
                title: t('sidebar.bankbooks.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info])

    

    const companyId = watch('company_id', null)
    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null)
    const microdistrictId = watch('microdistrict_id', null)
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);
    const apartmentId = watch('apartment_id', null);
    const sectorId = watch('sector', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const [microdistricts, setMicrodistricts] = useState();
    const [microdistrictsLoading, setMicrodistrictsLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const [apartments, setApartments] = useState();
    const [apartmentsLoading, setApartmentsLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (companyId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `company_id=${companyId}&city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMicrodistricts = useCallback(async () => {
        if (districtId) {
            setMicrodistrictsLoading(true);

            const res = await Dictionaries.microdistricts({queryString: `company_id=${companyId}&district_id=${districtId}`});

            if (res.statusCode === 200) {
                setMicrodistricts(res.content);

                if (!res.content.some(item => item.value === microdistrictId)) {
                    setValue('microdistrict_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoading(false);
        } else {
            setValue('microdistrict_id', null)
        }
    }, [setValue, districtId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        if (districtId) {
            setStreetsLoading(true);

            const microdistrictQuery = microdistrictId ? `&microdistrict_id=${microdistrictId}` : "";

            const res = await Dictionaries.streets({queryString: `company_id=${companyId}&district_id=${districtId}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }
    }, [setValue, districtId, microdistrictId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchHouses = useCallback(async () => {
        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `company_id=${companyId}&street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);

                if (!res.content.some(item => item.value === houseId)) {
                    setValue('house_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        } else {
            setValue('house_id', null)
        }
    }, [setValue, streetId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchApartments = useCallback(async () => {
        if (houseId) {
            setApartmentsLoading(true);

            const res = await Dictionaries.apartments({queryString: `company_id=${companyId}&house_id=${houseId}`});

            if (res.statusCode === 200) {
                setApartments(res.content);

                if (!res.content.some(item => item.value === apartmentId)) {
                    setValue('apartment_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setApartmentsLoading(false);
        } else {
            setValue('apartment_id', null)
        }
    }, [setValue, houseId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        fetchMicrodistricts();
    }, [fetchMicrodistricts]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        fetchApartments();
    }, [fetchApartments]);

    useEffect(() => {
        if (sectorId) {
            setValue('type', sectorId === "1" ? "1" : "0")
        } else {
            setValue('type', null)
        }
    }, [sectorId, setValue])

    const {onSubmit, loading} = useUpdate(BankbooksServices.update, id, false);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                info[key] !== null && setValue(key, String(value));
            }

            const {address_id, house_id} = info;
            address_id !== house_id && setValue('apartment_id', String(address_id))
        }
    }, [info, setValue])

    const customSubmit = (data) => {
        const {city_id, street_id, house_id, apartment_id, address_id, ...rest} = data;

        onSubmit({
            address_id: apartment_id || house_id,
            ...rest
        })
    }

    return (
        <>
            <WhiteBlock>
                <form onSubmit={handleSubmit(customSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.account_number') + " *"}>
                        <Input type="text" {...register('number', {required: true})} $error={errors.number}/>
                        {errors.number && errors.number.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled={companiesLoading || state?.company_id}
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.city') + " *"}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={(!!companyId) ? citiesLoading : false}
                                                      isDisabled={!!!companyId || citiesLoading || state?.city_id}
                                                      $error={errors.city_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.district') + " *"}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={districts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? districtsLoading : false}
                                                      isDisabled={!!!cityId || districtsLoading || state?.district_id}
                                                      $error={errors.district_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.district_id && errors.district_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.microdistrict')}>
                        <Controller name="microdistrict_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={microdistricts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!districtId ? microdistrictsLoading : false}
                                                      isDisabled={!!!districtId || microdistrictsLoading || state?.microdistrict_id}
                                                      $error={errors.microdistrict_id}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.street') + " *"}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={streets} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!districtId ? streetsLoading : false}
                                                      isDisabled={!!!districtId || streetsLoading || state?.street_id}
                                                      $error={errors.street_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.street_id && errors.street_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.house') + " *"}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={houses} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!streetId ? housesLoading : false}
                                                      isDisabled={!!!streetId || housesLoading || state?.house_id}
                                                      $error={errors.house_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.house_id && errors.house_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.apartment')}>
                        <Controller name="apartment_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={apartments} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!houseId ? apartmentsLoading : false}
                                                      isDisabled={!!!houseId || apartmentsLoading || state?.apartment_id}
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.segment') + " *"}>
                        <Controller name="sector" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookSectorTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.sector}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.sector && errors.sector.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.subscriber_type')}>
                        <Controller name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookUserTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.type}
                                            isDisabled={true}
                                            enableLoading={true}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.contact_person')}>
                        <Input type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input type="tel" {...register('phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.people')}>
                        <Input type="text" {...register('people')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.note')}>
                        <TextArea {...register('note')}/>
                    </FormGroup>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.push(routePaths.bankbooks.index);
                }}
                method={BankbooksServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default BankbookEditInformation;