import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import useFetchItems from "../../../hooks/useFetchItems";
import routePaths from "../../../navigation/operator/routePaths";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import {useParams} from "react-router-dom";
import IndicationsServices from "../../../services/operator/indicationsServices";
import Expand from "../../../components/Expand";
import Button from "../../../components/Button";
import CreateCommentModal from "./components/CreateCommentModal";
import EditCommentModal from "./components/EditCommentModal";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";

const IndicationEditComments = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useFetchInfo(IndicationsServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.indications.index,
                title: t('sidebar.indications.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.date ? " - " + info?.date : "")
            },
            {
                path: null,
                title: t('labels.comments')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {items, loading, pageCount, params, handlePageClick, fetchItems} = useFetchItems(IndicationsServices.comments.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();

    const [commentCreateModalIsOpen, setCommentCreateModalIsOpen] = useState();

    const [commentEditModalIsOpen, setCommentEditModalIsOpen] = useState();
    const [editComment, setEditComment] = useState(null);

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, description, user, created_at}) => {
                return {
                    id,
                    fields: [
                        id,
                        user?.name,
                        <Expand height={51}>
                            {user?.role.join(', ')}
                        </Expand>,
                        <Expand height={51}>
                            {description}
                        </Expand>,
                        created_at
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.fullName'), t('labels.role'), t('labels.comment'), t('labels.date'), ''],
        sizes: ['10%', '20%', '20%', '20%', '20%', '']
    }

    const handleEditModal = (commentId) => {
        const comment = items.find(item => item.id === commentId);
        setEditComment(comment);
        setCommentEditModalIsOpen(true);
    }

    return (
        <WhiteBlock> 
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        vAlign="top"
                        items={formattedItems}
                        editCallback={id => handleEditModal(id)}
                        removeMethod={childId => IndicationsServices.comments.delete({parentId: id, id: childId})}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small $withIcon variant="outline" onClick={() => setCommentCreateModalIsOpen(true)}><i className="icon-plus"/><span>{t('labels.add')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            <CreateCommentModal
                isOpen={commentCreateModalIsOpen}
                closeModal={() => setCommentCreateModalIsOpen(false)}
                onRequestClose={() => setCommentCreateModalIsOpen(false)}
                successCallback={() => {
                    fetchItems();
                }}
                method={IndicationsServices.comments.store}
                indicationId={id}
            />
            {editComment && <EditCommentModal
                isOpen={commentEditModalIsOpen}
                closeModal={() => setCommentEditModalIsOpen(false)}
                onRequestClose={() => setCommentEditModalIsOpen(false)}
                successCallback={() => {
                    fetchItems();
                }}
                method={IndicationsServices.comments.update}
                indicationId={id}
                comment={editComment}
            />}
        </WhiteBlock>
    );
};

export default IndicationEditComments;