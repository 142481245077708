import React from 'react';
import styled, {css} from "styled-components"

const El = styled.input`
  width: 100%;
  height: 50px;
  font-family: inherit;
  border: 1px solid var(--gray-300);
  -webkit-appearance: none;
  border-radius: 4px;
  transition: .2s;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  
  &[type="date"] {
    &::-webkit-calendar-picker-indicator{
      margin-left: -15px;
    }
  }

  &:focus {
    outline: none;
    border-color: var(--secondary)
  }

  ${props => props.$small && css`
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  `}

  ${props => props.$error && css`
    border-color: var(--error);
    
    &:focus {
      border-color: var(--error);
    }
  `}
  
  ${props => props.disabled && css`
    background-color: var(--gray-100);
    color: hsl(0, 0%, 60%);
  `}
`;

const Input = React.forwardRef((props, ref) => {
    return (
        <El ref={ref} {...props}/>
    );
});

export default Input;