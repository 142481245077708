import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class DictionariesServices {
    static services = {
        index: (queryString) => {
            return getData(http, `/services${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/services/store`, data)
        },

        view: (id) => {
            return getData(http, `/services/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/services/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/services/${id}`)
        }
    }

    static improvements = {
        index: (queryString) => {
            return getData(http, `/improvements${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/improvements/store`, data)
        },

        view: (id) => {
            return getData(http, `/improvements/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/improvements/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/improvements/${id}`)
        }
    }

    static resourceTypes = {
        index: (queryString) => {
            return getData(http, `/resourceTypes${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/resourceTypes/store`, data)
        },

        view: (id) => {
            return getData(http, `/resourceTypes/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/resourceTypes/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/resourceTypes/${id}`)
        }
    }

    static applicationRejectReason = {
        index: (queryString) => {
            return getData(http, `/applicationRejectReasons${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/applicationRejectReasons/store`, data)
        },

        view: (id) => {
            return getData(http, `/applicationRejectReasons/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/applicationRejectReasons/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/applicationRejectReasons/${id}`)
        }
    }

    static sealingRejectReason = {
        index: (queryString) => {
            return getData(http, `/sealingRejectReasons${queryString ? `?${queryString}` : ""}`)
        },

        store: (data) => {
            return postData(http, `/sealingRejectReasons/store`, data)
        },

        view: (id) => {
            return getData(http, `/sealingRejectReasons/${id}`)
        },

        update: (id, data) => {
            return postData(http, `/sealingRejectReasons/${id}`, data)
        },

        delete: (id) => {
            return deleteData(http, `/sealingRejectReasons/${id}`)
        }
    }
}