import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../navigation/sealerOperator/routePaths";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/sealerOperator/dictionaries";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../hooks/useUpdate.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import CustomSelect from "../../../components/CustomSelect";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import DevicesServices from "../../../services/sealerOperator/devicesServices";
import InputMessage from "../../../components/InputMessage";
import AsyncPaginateSelect from "../../../components/AsyncPaginateSelect";

const BankbookEditInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, control, watch, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const {state} = useLocation();

    const {info, loading: fetchLoading} = useFetchInfo(DevicesServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.devices.index,
                title: t('sidebar.devices.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info])

    

    const companyId = watch('company_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const {onSubmit, loading} = useUpdate(DevicesServices.update, id, false);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    useEffect(() => {
        if (info) {
            const {company, bankbook, resourceType, areaType, ...rest} = {...info};
            for (const [key, value] of Object.entries(rest)) {
                info[key] && setValue(key, String(value));
            }

            company && setValue('company_id', String(company.id));
            bankbook && setValue('bankbook_id', String(bankbook.id));
            resourceType && setValue('resource_type_id', String(resourceType.id));
            areaType && setValue('area_type', String(areaType.id));
        }
    }, [info, setValue])

    return (
        <>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.device_number') + " *"}>
                        <Input type="text" {...register('number', {required: true})} $error={errors.number}/>
                        {errors.number && errors.number.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled={state?.company_id}
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook') + " *"} key={companyId}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <AsyncPaginateSelect onChange={onChange}
                                                             loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `company_id=${companyId}`})}
                                                             value={value}
                                                             isDisabled={!!!companyId || state?.bankbook_id}
                                                             $error={errors.bankbook_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bankbook_id && errors.bankbook_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.device_type') + " *"}>
                        <Controller name="resource_type_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.resourceTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.resource_type_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.resource_type_id && errors.resource_type_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.area') + " *"}>
                        <Controller name="area_type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.deviceAreaTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.area_type}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.area_type && errors.area_type.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.modem_number')}>
                        <Input type="text" {...register('modem_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacturer')}>
                        <Input type="text" {...register('manufacturer')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacture_date')}>
                        <Input type="date" {...register('manufacture_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.diameter')}>
                        <Input type="text" {...register('diameter')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.last_check')}>
                        <Input type="date" {...register('last_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.next_check')}>
                        <Input type="date" {...register('next_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.seal_number')}>
                        <Input type="text" {...register('seal_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.seal_setting_date')}>
                        <Input type="date" {...register('seal_setting_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.anti_magnetic_seal_number')}>
                        <Input type="text" {...register('anti_magnetic_seal_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.anti_magnetic_seal_setting_date')}>
                        <Input type="date" {...register('anti_magnetic_seal_setting_date')}/>
                    </FormGroup>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.push(routePaths.devices.index);
                }}
                method={DevicesServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default BankbookEditInformation;