import React from 'react';
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

const styles = css`
  color: var(--secondary);
  transition: .2s;

  &:hover {
    color: var(--secondary-hover)
  }
`

const StyledA = styled.a`${styles}`
const StyledLink = styled(Link)`${styles}`

const SecondaryLink = ({...props}) => {
    if (props.to) {
        return <StyledLink {...props}/>
    }

    return (
        <StyledA {...props}/>
    );
};

export default SecondaryLink;