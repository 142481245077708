import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import ModalComponent from "../../../../components/ModalComponent";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import 'antd/dist/antd.css';
import FilesUploader from "../../../../components/FilesUploader";
import Utils from "../../../../services/utils";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import IndicationsServices from "../../../../services/operator/indicationsServices";
import Input from "../../../../components/Input";

const AddIndicationModal = ({method, closeModal, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, control, register, setValue, reset} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(IndicationsServices.prepare, id);

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                info[key] && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const onSubmit = async (data) => {
        setLoading(true);

        const {images, value} = data;

        const newData = {
            images: images ? Utils.transformFiles(images) : [],
            value
        };

        const res = await method(id, newData);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading || fetchLoading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.add_indications')}</h4>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.previous_period')}>
                        <Input type="text" {...register('prev_date')} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.previous_indication')}>
                        <Input type="text" {...register('prev_value')} disabled/>
                    </FormGroup>
                    <hr/>
                    <FormGroup label={t('labels.current_period')}>
                        <Input type="text" {...register('curr_date')} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.current_indication')}>
                        <Input type="text" {...register('value')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.photo')}>
                        <Controller name="images" control={control} render={({field: {onChange, value}}) => (
                            <FilesUploader onChange={onChange} value={value} maxFiles={3} accept="image/*">
                                {t('labels.files_uploader_text', {formats: '.jpg, .png', size: '20MB'})}
                            </FilesUploader>
                        )}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add_indications')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AddIndicationModal;