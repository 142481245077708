import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Select from "react-select";
import {useTranslation} from "react-i18next";
import Input from '../../../../components/Input';
import styled, {css} from "styled-components";
import { toast } from 'react-toastify';
import CompaniesServices from '../../../../services/admin/companiesServices';

const List = styled.div`
margin-top: 16px;
height:259px;
overflow-y:auto;
border:1px solid #E5E9EB;
border-radius:4px;

&::-webkit-scrollbar {
    width: 8px;
}

&::-webkit-scrollbar-thumb {
    background-color: #1FA2FF;
    border-radius: 3px;
}

&::-webkit-scrollbar-track {
    background: #D9E3E7;
    border-radius: 8px;
    padding:1px;
}
`

const ListItem = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    padding: 4px;
    border-bottom:1px solid #E5E9EB;
    cursor:pointer;

    span{
        font-weight:400;
        color:#6C6D6D;
    }

    p{
        margin:0;
        padding: 8px 16px;
        border-radius: 4px;
    }

    ${props => props.active && css`
        p{
            background: #1FA2FF;
            color: #FFFFFF;
        }
        span{
            color:#FFFFFF;
        }
    `}
`


const CustomSelectAddresses = ({ id, activeItems, setActiveItems, setTotal}) => {
    const [options, setOptions] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');

    const lastElement = useRef();
    const observer = useRef();

    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);

    const [addressesLoading, setAddressesLoading] = useState(false);

    const fetchAddresses = async () => {
        setAddressesLoading(true);

        const res = await CompaniesServices.addresses.attachable({id}, `page=${page}&name=${searchQuery}`);

        if (res.statusCode === 200) {
            setOptions([...options, ...res.content.items]);
            setTotalPages(res.content.total_pages);
            setTotal(res.content.total);
        } else {
             toast.error(res.message)
        }

        setAddressesLoading(false);
    };// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchAddresses();
    }, [page]);

    useEffect(() => {
        if(addressesLoading) return;
        if(observer.current) observer.current.disconnect();

        var callback = function(entries, observer){
            if(entries[0].isIntersecting && page < totalPages){
                setPage(page + 1)
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current)
    }, [addressesLoading])


    useEffect(() => {
        if(searchQuery.length >=3 ){
            setPage(1)
            setOptions([])
            fetchAddresses();
        }
    }, [searchQuery])


    const setActive = (item) => {
        if(activeItems.includes(item)){
            setActiveItems(activeItems.filter(el => el !== item))
        }else{
            setActiveItems([...activeItems, item])
        }
    }

    return (
        <div>
            <Input value={searchQuery} onChange={e => setSearchQuery(e.target.value)} $small type="text"></Input>
            <List id="sbhjcbs">
                {options?.map((item, index) => 
                    <ListItem key={index} active={activeItems.includes(item)} onClick={() => setActive(item)}>
                        <p>
                            {item.name} <span>/ {item.path}</span>
                        </p>
                    </ListItem>
                )}
                {options && <div ref={lastElement} style={{height:20}}></div>}
            </List>
        </div>
    );
};

export default CustomSelectAddresses;