import React, { useState } from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/sealerOperator/routePaths";
import BankbookEditInformation from "./BankbookEditInformation";
import BankbookEditDevices from "./BankbookEditDevices";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import BankbooksServices from "../../../services/sealerOperator/bankbooksServices";
import BankbookEditDocuments from './BankbookEditDocuments';
import BankbooksActionsModal from './components/BankbooksActionsModal';
import BankBooksTaskCreateModal from './components/BankBooksTaskCreateModal';
import HeadingWithButtons from '../../../components/HeadingWithButtons';
import Button from '../../../components/Button';
import TaskSealingServices from '../../../services/sealerOperator/tasksSealingServices';
import { toast } from 'react-toastify';

const BankbookEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(BankbooksServices.view, id);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [createTaskIsOpen, setCreateTaskIsOpen] = useState();

    const [isSealing, setIsSealing] = useState(true)

    const handleOpen = (e) => {
        e.preventDefault();
        if(info.devices.length <= 0){
            toast.error('Для формирования задачи необходимы приборы учета.')
        }else{
            setRemoveModalIsOpen(true);
        }
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-3">{t('labels.editing')} {info?.number ? " - " + info?.number : ""}</h1>
                <Button $small variant="outline" onClick={(e) => {handleOpen(e)}}>{t('labels.actions')}</Button>
            </HeadingWithButtons>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.bankbooks.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.bankbooks.edit.devices(id)}>{t('labels.devices')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.bankbooks.edit.documents(id)}>{t('labels.documents')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.bankbooks.edit.index()} to={routePaths.bankbooks.edit.information()}/>
                <Route path={routePaths.bankbooks.edit.information()} component={BankbookEditInformation}/>
                <Route path={routePaths.bankbooks.edit.devices()} component={BankbookEditDevices}/>
                <Route path={routePaths.bankbooks.edit.documents()} component={BankbookEditDocuments}/>
            </Switch>
            <BankbooksActionsModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                }}
                method = {() => {setCreateTaskIsOpen(true); setRemoveModalIsOpen(false)}}
                id={id}
                setIsSealing = {setIsSealing}
            />
            <BankBooksTaskCreateModal
                isOpen={createTaskIsOpen}
                closeModal={() => setCreateTaskIsOpen(false)}
                onRequestClose={() => setCreateTaskIsOpen(false)}
                successCallback={() => {
                    setCreateTaskIsOpen(false);
                }}
                method = {isSealing ? TaskSealingServices.sealing.store : TaskSealingServices.unsealing.store}
                id={id}
                info = {info}
                isSealing = {isSealing}
            />
        </>
    );
};

export default BankbookEdit;