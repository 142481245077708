import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class CompaniesServices {
    static view = () => {
        return getData(http, `/company`)
    }

    static update = (data) => {
        return postData(http, `/company`, data)
    }

    static delete = () => {
        return deleteData(http, `/company`)
    }

    static cities = {
        index: (queryString) => {
            return getData(http, `/company/cities${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/cities/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/cities/detach`, {city_id: id})
        }
    }

    static operators = {
        index: (queryString) => {
            return getData(http, `/company/operators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/operators/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/operators/detach`, {user_id: id})
        }
    }

    static controllers = {
        index: (queryString) => {
            return getData(http, `/company/controllers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/controllers/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/controllers/detach`, {user_id: id})
        }
    }

    static sealers = {
        index: ( queryString) => {
            return getData(http, `/company/sealers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/sealers/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/sealers/detach`, {user_id: id})
        }
    }

    static sealer_operators = {
        index: (queryString) => {
            return getData(http, `/company/sealerOperators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/sealerOperators/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/sealerOperators/detach`, {user_id: id})
        }
    }

    static analytics = {
        index: (queryString) => {
            return getData(http, `/company/analytics${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/analytics/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/analytics/detach`, {user_id: id})
        }
    }

    static sectors = {
        index: (queryString) => {
            return getData(http, `/company/sectors${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/sectors/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/company/sectors/detach`, {sector_id: id})
        }
    }

    static addresses = {
        index: (queryString) => {
            return getData(http, `/company/addresses${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/company/addresses/attach`, data)
        },
        attachable: (queryString) => {
            return getData(http, `/company/addresses/attachable${queryString ? `?${queryString}` : ""}`)
        },
        detach: (id) => {
            return postData(http, `/company/addresses/detach`, {address_id: id})
        }
    }
}