import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import AddressesServices from "../../../../services/admin/addressesServices";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import MicrodistrictsFilter from "./components/MicrodistrictsFilter";

const Microdistricts = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: null,
                title: t('sidebar.addresses.children.microdistricts')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(AddressesServices.microdistricts.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, path}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        path
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.microdistrict'), t('labels.additional_information'), ''],
        sizes: ['10%', '30%', '30%', '30%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.addresses.children.microdistricts')}</h1>
                <Button $small $withIcon variant="outline" to={routePaths.addresses.children.microdistricts.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
            </HeadingWithButtons>
            <MicrodistrictsFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.addresses.children.microdistricts.edit.information}
                                    removeMethod={AddressesServices.microdistricts.delete}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Microdistricts;