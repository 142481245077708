import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/operator/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useFetchItems from "../../../hooks/useFetchItems";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import DevicesServices from "../../../services/operator/devicesServices";
import CreateNotificationModal from "./components/CreateNotificationModal";

const DeviceEditHistories = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useFetchInfo(DevicesServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.devices.index,
                title: t('sidebar.devices.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            },
            {
                path: null,
                title: t('labels.documents')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(DevicesServices.histories.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, user, laboratory, created_at}) => {
                return {
                    id,
                    fields: [
                        id,
                        user,
                        laboratory,
                        created_at
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.user'), t('labels.laboratory'), t('labels.date')],
        sizes: ['10%', '30%', '30%', '30%' ]
    }

    return (
        <WhiteBlock> 
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {attachModal &&
            <CreateNotificationModal
                isOpen={attachModal}
                closeModal={() => setAttachModal(false)}
                onRequestClose={() => setAttachModal(false)}
                method={DevicesServices.notifications.send}
                successCallback={fetchItems}
                id={id}
            />
            }
        </WhiteBlock>
    );
};

export default DeviceEditHistories;