import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useFetchItems from "../../../hooks/useFetchItems";
import SecondaryLink from "../../../components/SecondaryLink";
import WhiteBlock from "../../../components/WhiteBlock";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import AttachDeviceModal from "./components/AttachDeviceModal";
import BankbooksServices from "../../../services/admin/bankbooksServices";
import DevicesServices from '../../../services/admin/devicesServices';

const BankbookEditDevices = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, loading: fetchLoading} = useFetchInfo(BankbooksServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.bankbooks.index,
                title: t('sidebar.bankbooks.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            },
            {
                path: null,
                title: t('labels.devices')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(BankbooksServices.devices.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, resourceType, number, prev_indication, last_indication, next_check}) => {
                return {
                    id,
                    fields: [
                        id,
                        <span className='ban-span'>
                            {resourceType?.color ?
                                <span className='td-span' style={{backgroundColor: resourceType.color, color: 'white'}}>{resourceType.name || "-"}
                                    <img src={resourceType.image}></img>
                                </span>
                                :
                                <span>{resourceType?.name || "-"}</span>
                            }
                        </span>,
                        <SecondaryLink to={routePaths.devices.edit.information(id)}>{number}</SecondaryLink>,
                        prev_indication?.value,
                        prev_indication?.created_at,
                        last_indication?.value,
                        last_indication?.created_at,
                        next_check
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.device_type'), t('labels.device_number'), t('labels.previous_indication'), t('labels.previous_indication_date'), t('labels.current_indication'), t('labels.current_indication_date'),  t('labels.next_check'), ''],
        sizes: ['8%', '10%', '10%', '10%', '10%', '10%', '15%', '15%', '']
    }

    return (
        <WhiteBlock>
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        removeMethod={DevicesServices.delete}
                        unpinMethod={childId => BankbooksServices.devices.detach({parentId: id, id: childId})}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small disabled={fetchLoading} $customColor={'#ED7E52'} $withIcon variant="outline"
                                            to={{
                                                pathname: routePaths.devices.create,
                                                state: {
                                                    bankbook_id: id,
                                                    company_id: info?.company_id
                                                }
                                            }}><i
                                        className="icon-plus"/><span>{t('labels.create')}</span></Button>
                                </div>
                                <div className="col-auto">
                                    <Button $small disabled={fetchLoading} $customColor={'#1FA2FF'} $withIcon variant="outline"
                                            onClick={() => setAttachModal(true)}><i
                                        className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {attachModal && !fetchLoading &&
            <AttachDeviceModal
                isOpen={attachModal}
                closeModal={() => setAttachModal(false)}
                onRequestClose={() => setAttachModal(false)}
                method={BankbooksServices.devices.attach}
                successCallback={fetchItems}
                companyId={info?.company_id}
                id={id}
            />
            }
        </WhiteBlock>
    );
};

export default BankbookEditDevices;