import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/operator/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/operator/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";
import DistrictEditInformation from "./DistrictEditInformation";
import DistrictEditMicrodistricts from "./DistrictEditMicrodistricts";
import DistrictEditStreets from "./DistrictEditStreets";

const DistrictEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.districts.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.districts.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.districts.edit.microdistricts(id)}>{t('labels.microdistricts')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.districts.edit.streets(id)}>{t('labels.streets')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.districts.edit.index()} to={routePaths.addresses.children.districts.edit.information()}/>
                <Route path={routePaths.addresses.children.districts.edit.information()} component={DistrictEditInformation}/>
                <Route path={routePaths.addresses.children.districts.edit.microdistricts()} component={DistrictEditMicrodistricts}/>
                <Route path={routePaths.addresses.children.districts.edit.streets()} component={DistrictEditStreets}/>
            </Switch>
        </>
    );
};

export default DistrictEdit;