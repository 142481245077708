import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class DevicesServices {
    static index = (queryString) => {
        return getData(http, `/devices${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/devices/store`, data)
    }

    static view = (id) => {
        return getData(http, `/devices/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/devices/${id}`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/devices/${id}`)
    }

    static indications = {
        index: ({id}, queryString) => {
            return getData(http, `/devices/${id}/indications${queryString ? `?${queryString}` : ""}`)
        },
        add: (id, data) => {
            return postData(http, `/devices/${id}/indications/add`, data)
        },
        prepare: (id) => {
            return getData(http, `/devices/${id}/indications/prepare`)
        }
    }

    static notifications = {
        index: ({id}, queryString) => {
            return getData(http, `/devices/${id}/notifications${queryString ? `?${queryString}` : ""}`)
        },
        send: (id, data) => {
            return postData(http, `/devices/${id}/notifications/send`, data)
        }
    }
    static documentations = {
        index: ({id}, queryString) => {
            return getData(http, `/devices/${id}/documents${queryString ? `?${queryString}` : ""}`)
        },
        delete: (parentId, id) => {
            return postData(http, `/devices/${parentId}/documents/delete`, {document_id: id})
        }
    }

    static histories = {
        index: ({id}, queryString) => {
            return getData(http, `/devices/${id}/histories${queryString ? `?${queryString}` : ""}`)
        }
    }
}