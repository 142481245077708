import React from 'react';
import styled, {css} from "styled-components";

const Block1 = styled.div`
    background: #61C877;
    border: 2px solid #61C877;
    border-radius: 30px;
    color: #FFFFFF;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding: 31px 28px 25px;
    text-align:center;
    span{
        font-weight: 500;
        font-size: 20px;
        margin-bottom:10px;
    }

`

const H3El1 = styled.h3`
font-weight: 600;
font-size: 18px;
color: #37AAB6;
margin-bottom:20px;
`

const Flex1 = styled.div`
display:flex;
gap:24px;
    ${props => props.$column && css`
        flex-direction:column;
        gap:15px;
    `}
    ${props => props.$end && css`
        justify-content:flex-end;
    `}
    ${props => props.$scroll && css`
        padding:20px 0;
        max-height:300px;
        overflow:hidden;
        overflow-y:auto;
    `}
`
const Div1 = styled.div`
color: #84919A;
a{
    color: #489eda;
}
span{
    margin-right:30px;
}
`

const Grid1 = styled.div`
display:grid;
grid-template-columns:1fr 1fr 1fr;
gap:24px;
`

const H2El1 = styled.h2`
text-align:center;
font-weight: 600;
font-size: 20px;
color: #047884;
`

const Arrow1 = styled.div`
font-weight: 600;
color: #BDBDBD;
display:flex;
align-items:center;
cursor:pointer;

&:hover{
    color: #047884;
}
i{
    font-size:18px;
}
`

const Modal1 = styled.div`
    position:fixed;
    top:0;
    left:0;
    z-index:100;
    width:100vw;
    height:100vh;
    padding: 30px;
    background-color:rgba(0,0,0,.5);
    display:flex;
    align-items:center;
    justify-content:center;
`

const Wrapper1 = styled.div`
background: rgb(246, 248, 249);
border-radius: 16px;
padding:16px;
max-width:800px;
overflow-y:auto;
margin:50px;
height:100%;

.red{
    background: #FD5D6F;
    border: 2px solid #FD5D6F;
}
.yellow{
    background: #FFCE93;
    border: 2px solid #FFCE93;
}
.gray{
    color:black;
    background: #ffff;
    border:none;
}
`

export const Month = {
    1: "Января",
    2: "Февраля",
    3: "Марта",
    4: "Апреля",
    5: "Мая",
    6: "Июня",
    7: "Июля",
    8: "Августа",
    9: "Сентября",
    10: "Октября",
    11: "Ноября",
    12: "Декабря",
}

const Wrapper = ({children, ...props}) => {
    return (
        <Wrapper1 {...props}>
            {children}
        </Wrapper1>
    )
}

const Grid = ({children, ...props}) => {
    return (
        <Grid1 {...props}>
            {children}
        </Grid1>
    )
}

const Block = ({children, ...props}) => {
    return (
        <Block1 {...props}>
            {children}
        </Block1>
    )
}

const H3El = ({children, ...props}) => {
    return (
        <H3El1 {...props}>
            {children}
        </H3El1>
    )
}

const Div = ({children, ...props}) => {
    return (
        <Div1 {...props}>
            {children}
        </Div1>
    )
}

const Flex = ({children, ...props}) => {
    return (
        <Flex1 {...props}>
            {children}
        </Flex1>
    )
}

const Arrow = ({children, ...props}) => {
    return (
        <Arrow1 {...props}>
            {children}
        </Arrow1>
    )
}

const Modal = ({children, ...props}) => {
    return (
        <Modal1 {...props}>
            {children}
        </Modal1>
    )
}

const H2El = ({children, ...props}) => {
    return (
        <H2El1 {...props}>
            {children}
        </H2El1>
    )
}

export {Wrapper, Grid, Block, H3El, Div, Flex, Arrow, Modal, H2El};