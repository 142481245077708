import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/minAdmin/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import SecondaryLink from "../../../components/SecondaryLink";
import DevicesServices from "../../../services/minAdmin/devicesServices";
import DevicesFilter from "./components/DevicesFilter";
import Expand from "../../../components/Expand";
import DeviceIndicator from "../../../components/DeviceIndicator";

const Devices = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.devices.title')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(DevicesServices.index)
    
    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, resourceType, company, bankbook, last_check, next_check}) => {
                return {
                    id,
                    fields: [
                        id,
                        <>
                            <div>{number}</div>
                            <div>{resourceType?.name}</div>
                        </>,
                        company?.name,
                        bankbook !== null &&
                        <Expand height={51}>
                            {bankbook.path}<br/>
                            <SecondaryLink to={routePaths.bankbooks.edit.information(bankbook.id)}>{bankbook.number}</SecondaryLink>
                        </Expand>,
                        last_check,
                        <>
                            <DeviceIndicator
                                $color={
                                    new Date(next_check) > new Date() ? 'green' : 'red'
                                }/>
                            {next_check}
                        </>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.data'), t('labels.resource_company'), t('labels.bankbook_data'), t('labels.last_check'), t('labels.next_check'), ''],
        sizes: ['10%', '18%', '18%', '18%', '18%', '18%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.devices.title')}</h1>
                <Button $small $withIcon variant="outline" to={routePaths.devices.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
            </HeadingWithButtons>
            <DevicesFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    vAlign="top"
                                    items={formattedItems}
                                    editLink={routePaths.devices.edit.information}
                                    removeMethod={DevicesServices.delete}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Devices;