import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import routePaths from "../../../../navigation/admin/routePaths";
import useStore from "../../../../hooks/useStore.hook";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import InputMessage from "../../../../components/InputMessage";
import MinAdministratorsServices from '../../../../services/admin/minAdministratorServices';

const AdministratorCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, formState: {errors}} = useForm();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: routePaths.users.children.administrators.index,
                title: t('sidebar.users.children.administrators')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const {onSubmit, loading} = useStore(MinAdministratorsServices.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.fullName') + " *"}>
                        <Input type="text" {...register('name', {required: true})} $error={errors.name}/>
                        {errors.name && errors.name.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.email') + " *"}>
                        <Input type="text" {...register('email', {
                            required: true,
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('messages.invalid_email')
                            }
                        })} $error={errors.email}/>
                        {errors.email && errors.email.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                        {errors.email && <InputMessage $error>{errors.email.message}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input type="tel" {...register('phone')}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default AdministratorCreate;