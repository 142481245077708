import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import useFetchItems from "../../../hooks/useFetchItems";
import WhiteBlock from "../../../components/WhiteBlock";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import NamesFilter from "../../../components/filters/NamesFilter";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import routePaths from "../../../navigation/admin/routePaths";
import DictionariesServices from '../../../services/admin/dictionariesServices';
import DeviceIndicator from '../../../components/DeviceIndicator';

const ApplicationRejectReason = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.dictionaries.index,
                title: t('sidebar.dictionaries.title')
            },
            {
                path: null,
                title: t('sidebar.dictionaries.children.application_reject_reason')
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(DictionariesServices.applicationRejectReason.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name_kk, name_ru, is_published}) => {
                return {
                    id,
                    fields: [
                        id,
                        name_kk,
                        name_ru,
                        <DeviceIndicator
                            $color={
                                is_published ? 'green' : 'red'
                        }/>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.name_kk'), t('labels.name_ru'), t('labels.status'), ''],
        sizes: ['10%', '30%', '30%', '20%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.dictionaries.children.application_reject_reason')}</h1>
                <Button $small $withIcon variant="outline" to={routePaths.dictionaries.children.application_reject_reason.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
            </HeadingWithButtons>
            <NamesFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.dictionaries.children.application_reject_reason.edit}
                                    removeMethod={DictionariesServices.applicationRejectReason.delete}
                                    removeModalContent={{
                                        title: t('messages.remove_message'),
                                        yes: t('labels.yes'),
                                        no: t('labels.cancel')
                                    }}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default ApplicationRejectReason;