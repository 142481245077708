import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class SectorsServices {
    static index = (queryString) => {
        return getData(http, `/sectors${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/sectors/store`, data)
    }

    static view = (id) => {
        return getData(http, `/sectors/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/sectors/${id}`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/sectors/${id}`)
    }

    static controllers = {
        index: ({id}, queryString) => {
            return getData(http, `/sectors/${id}/controllers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/sectors/${id}/controllers/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/sectors/${parentId}/controllers/detach`, {user_id: id})
        }
    }

    static operators = {
        index: ({id}, queryString) => {
            return getData(http, `/sectors/${id}/operators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/sectors/${id}/operators/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/sectors/${parentId}/operators/detach`, {user_id: id})
        }
    }

    static streets = {
        index: ({id}, queryString) => {
            return getData(http, `/sectors/${id}/streets${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/sectors/${id}/streets/attach`, data)
        },
        update: (id, data) => {
            return postData(http, `/sectors/${id}/streets/update`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/sectors/${parentId}/streets/detach`, {street_id: id})
        }
    }

    static houses = {
        index: ({id}, queryString) => {
            return getData(http, `/sectors/${id}/houses${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/sectors/${id}/houses/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/sectors/${parentId}/houses/detach`, {house_id: id})
        }
    }
}