import React from 'react';
import {NavLink, Route, Switch, useParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/admin/routePaths";
import WhiteBlock from "../../../../components/WhiteBlock";
import TabLinks from "../../../../components/TabLinks";
import SubscriberEditPersonal from "./SubscriberEditPersonal";
import SubscriberEditBankbooks from "./SubscriberEditBankbooks";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import SubscribersServices from "../../../../services/admin/subscribersServices";

const SubscriberEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(SubscribersServices.view, id);

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info?.name ? " - " + info?.name : ""}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.users.children.subscribers.edit(id)}>{t('labels.personal_data')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.users.children.subscribers.edit_bankbooks(id)}>{t('labels.bankbooks')}</NavLink>
            </TabLinks>
            <WhiteBlock>
                <Switch>
                    <Route exact path={routePaths.users.children.subscribers.edit()} component={SubscriberEditPersonal}/>
                    <Route exact path={routePaths.users.children.subscribers.edit_bankbooks()} component={SubscriberEditBankbooks}/>
                </Switch>
            </WhiteBlock>
        </>
    );
};

export default SubscriberEdit;