import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import useFetchItems from "../../../hooks/useFetchItems";
import InvoicesServices from "../../../services/admin/invoicesServices";
import Filter from "./Filter";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import SecondaryLink from "../../../components/SecondaryLink";
import Expand from "../../../components/Expand";
import StatusLabel from "../../../components/StatusLabel";
import {statusColors} from "../../../constants";

const Invoices = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.invoices.title')
            }
        ])
    }, [setBreadcrumbs, t])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems,
        setFilterData
    } = useFetchItems(InvoicesServices.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, services, user, address, created_at, laboratory, total_price, status}) => {
                return {
                    id,
                    fields: [
                        id,
                        <Expand height={50}>
                            {services.map(item => item.name).join(', ')}
                        </Expand>,
                        <Expand height={50}>
                            {user?.name}
                            {user?.type && <><br/><span style={{color: 'var(--gray-400)'}}>{user.type.name}</span></>}
                            {user?.phone && <><br/><SecondaryLink
                                href={`tel:${user.phone}`}>{user.phone}</SecondaryLink></>}
                            {laboratory?.name && <><br/><strong>{t('labels.laboratory')}:</strong> {laboratory?.name}</>}
                        </Expand>,
                        <Expand height={50}>{address}</Expand>,
                        created_at,
                        total_price,
                        <StatusLabel color={statusColors[status.key]}>{status.name}</StatusLabel>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items, t]);

    const tableConfig = {
        head: [
            t('labels.id'),
            t('labels.services'),
            t('labels.subscriber_information'),
            t('labels.address'),
            t('labels.date'),
            t('labels.price_tng'),
            t('labels.status')
        ],
        sizes: [
            '10%',
            '15%',
            '15%',
            '15%',
            '15%',
            '15%',
            '15%'
        ]
    }

    return (
        <>
            {/*<HeadingWithButtons>*/}
            <h1 className="headline-4">{t('sidebar.invoices.title')}</h1>
            {/*<Button variant="outline" $withIcon onClick={() => console.log('do something')}><i className="icon-download"/><span>{t('labels.report_generation')}</span></Button>*/}
            {/*</HeadingWithButtons>*/}
            <Filter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    vAlign="top"
                                    items={formattedItems}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Invoices;