import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import HeadingWithButtons from '../../../../components/HeadingWithButtons';
import ItemsTable from '../../../../components/ItemsTable';
import Pagination from '../../../../components/Pagination';
import TablePreloader from '../../../../components/TablePreloader';
import WhiteBlock from '../../../../components/WhiteBlock';
import useFetchItems from '../../../../hooks/useFetchItems';
import { useBreadCrumbs } from '../../../../layouts/DefaultLayout';
import routePaths from '../../../../navigation/admin/routePaths';
import TasksCompaniesFilter from "./TasksCompaniesFilter"
import TasksServices from "../../../../services/admin/tasksServices"

const TasksCompanies = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('labels.tasks_settings')
            }
        ])
    }, [setBreadcrumbs, t])
    
    const {items, loading, pageCount, params, heading, handlePageClick, fetchItems, setFilterData} = useFetchItems(TasksServices.companies.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, tasks}) => {
                let task = [];
                for(let key in tasks){
                    task.push(tasks[key]);
                }
                return {
                    id,
                    fields: [
                        id,
                        name,    
                        ...task                   
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);



    const tableConfig = {
        head: [t('labels.id'), t('labels.title'), ],
        sizes: ['auto']
    }

    for(let key in heading){
        tableConfig.head.push(heading[key]);
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('labels.tasks_settings_companies')}</h1>
            </HeadingWithButtons>
            <TasksCompaniesFilter setFilterData={setFilterData} params={params}></TasksCompaniesFilter>
            <WhiteBlock>
                {formattedItems
                    ? <>
                    <div className="overflow-div">
                        <ItemsTable
                            {...{config: tableConfig, fetchItems, loading}}
                            vAlign="top"
                            items={formattedItems}
                            editLink={routePaths.tasks.children.companies.edit}
                        />
                    </div>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </>
                    : <TablePreloader/>}
            </WhiteBlock>
        </>
    )
};

export default TasksCompanies;