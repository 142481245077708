import http from "./http-common"
import {getData, postData} from "../handlers";

export default class LaboratoriesServices {
    static view = () => {
        return getData(http, `/laboratory`)
    }

    static update = (data) => {
        return postData(http, `/laboratory`, data)
    }

    static services = {
        index: (queryString) => {
            return getData(http, `/laboratory/services${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/laboratory/services/attach`, data)
        },
        update: (data) => {
            return postData(http, `/laboratory/services/update`, data)
        },
        detach: (id) => {
            return postData(http, `/laboratory/services/detach`, {service_id: id})
        }
    }

    static administrators = {
        index: (queryString) => {
            return getData(http, `/laboratory/administrators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/laboratory/administrators/attach`, data)
        },
        detach: ({id}) => {
            return postData(http, `/laboratory/administrators/detach`, {user_id: id})
        }
    }

    static specialists = {
        index: (queryString) => {
            return getData(http, `/laboratory/specialists${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/laboratory/specialists/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/laboratory/specialists/detach`, {user_id: id})
        }
    }

    static cities = {
        index: (queryString) => {
            return getData(http, `/laboratory/cities${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/laboratory/cities/attach`, data)
        },
        detach: ({id}) => {
            return postData(http, `/laboratory/cities/detach`, {city_id: id})
        }
    }
}