import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class ModelChangesServices {
    static bankbooks = {
        index: ({with_image}, queryString) => {
                return getData(http, `/modelChanges/bankbooks?with_image=${with_image}${queryString ? `&${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/modelChanges/bankbooks/${id}`)
        },
        accept: (id) => {
            return postData(http, `/modelChanges/bankbooks/${id}/accept`)
        },
        decline: (id, data) => {
            return postData(http, `/modelChanges/bankbooks/${id}/decline`, data)
        },
        delete: (id) => {
            return deleteData(http, `/modelChanges/bankbooks/${id}`)
        }
    }

    static devices = {
        index: ({with_image}, queryString) => {
            return getData(http, `/modelChanges/devices?with_image=${with_image}${queryString ? `&${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/modelChanges/devices/${id}`)
        },
        accept: (id) => {
            return postData(http, `/modelChanges/devices/${id}/accept`)
        },
        decline: (id, data) => {
            return postData(http, `/modelChanges/devices/${id}/decline`, data)
        },
        delete: (id) => {
            return deleteData(http, `/modelChanges/devices/${id}`)
        }
    }
}