import React, { useState } from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/sealerOperator/routePaths";
import ApartmentEditInformation from "./ApartmentEditInformation";
import ApartmentEditBankbooks from "./ApartmentEditBankbooks";
// import ApartmentEditControllers from "./ApartmentEditControllers";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/sealerOperator/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";
import HeadingWithButtons from '../../../../components/HeadingWithButtons';
import Button from '../../../../components/Button';
import ApartmentActionsModal from './components/ApartmentActionsModal';
import ApartmentsTaskCreateModal from './components/ApartmentsTaskCreateModal';
import TaskSealingServices from '../../../../services/sealerOperator/tasksSealingServices';

const ApartmentEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.apartments.view, id);
    const {lang} = useLangContext();

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [createTaskIsOpen, setCreateTaskIsOpen] = useState();

    const handleOpen = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-3">{t('labels.editing')}{info && " - " + info[`name_${lang}`]}</h1>
                <Button $small variant="outline" onClick={(e) => {handleOpen(e)}}>{t('labels.actions')}</Button>
            </HeadingWithButtons>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.apartments.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.apartments.edit.bankbooks(id)}>{t('labels.bankbooks')}</NavLink>
                {/*<NavLink activeClassName='active' exact to={routePaths.addresses.children.apartments.edit.controllers(id)}>{t('labels.controllers')}</NavLink>*/}
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.apartments.edit.index()} to={routePaths.addresses.children.apartments.edit.information()}/>
                <Route path={routePaths.addresses.children.apartments.edit.information()} component={ApartmentEditInformation}/>
                <Route path={routePaths.addresses.children.apartments.edit.bankbooks()} component={ApartmentEditBankbooks}/>
                {/*<Route path={routePaths.addresses.children.apartments.edit.controllers()} component={ApartmentEditControllers}/>*/}
            </Switch>
            <ApartmentActionsModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    //history.push(routePaths.model_сhanges.children.bankbooks.all.index);
                }}
                method = {() => {setCreateTaskIsOpen(true); setRemoveModalIsOpen(false)}}
                id={id}
            />
            <ApartmentsTaskCreateModal
                isOpen={createTaskIsOpen}
                closeModal={() => setCreateTaskIsOpen(false)}
                onRequestClose={() => setCreateTaskIsOpen(false)}
                successCallback={() => {
                    setCreateTaskIsOpen(false);
                    //history.push(routePaths.model_сhanges.children.bankbooks.all.index);
                }}
                method = {TaskSealingServices.firstTime.store}
                id={id}
                info={info}
            />
        </>
    );
};

export default ApartmentEdit;