import React, { useEffect } from 'react';
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useForm } from 'react-hook-form';
import useFetchInfo from '../../../../hooks/useFetchInfo.hook';
import { useBreadCrumbs } from '../../../../layouts/DefaultLayout';
import FormGroup from '../../../../components/FormGroup';
import InputMessage from '../../../../components/InputMessage';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import useUpdate from '../../../../hooks/useUpdate.hook';
import TaskServices from "../../../../services/admin/tasksServices";
import { useLangContext } from '../../../../i18n/ProvideLang';
import routePaths from '../../../../navigation/admin/routePaths';


const TaskSealersEdit = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(TaskServices.sealers.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.tasks.children.sealers.index,
                title: t('sidebar.tasks.title')
            },
            {
                path: null,
                title: t('labels.plan_tasks')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {onSubmit, loading} = useUpdate(TaskServices.sealers.update, id, false);

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info.tasks)) {
                if (key) setValue(key, value);
            }
            for (const [key, value] of Object.entries(info.companyTasks)) {
                if (key) setValue(key + "_company", value);
            }
        }
    }, [info, setValue])

    const customSubmit = (data) => {
        const newData = {};
        for (const [key] of Object.entries(info.tasks)) {
            if (key) newData[`${key}`] = data[`${key}`];
        }
        onSubmit(newData)
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.plan_tasks')} - {info?.name}</h1>
                <form onSubmit={handleSubmit(customSubmit)} className={loading || fetchLoading ? "isLoading" : "form-controller"}>
                    <div className="controller-wrapper">
                        <h2>{t('labels.employer_plan')}</h2>
                        <div className="block-controller">
                            {info &&
                                Object.entries(info.headings).map(([ key, val ]) => (
                                    <FormGroup key={key} label={val + " *"}>
                                        <Input type="text" {...register(key, )} $error={errors.key}/>
                                        {errors.key && errors.key.type === 'required' &&
                                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                                    </FormGroup>
                                ))
                            }
                        </div>
                    </div>
                    <div className="controller-wrapper">
                        <h2>{t('labels.organization_plan')}</h2>
                        <div className="block-controller">
                            {info &&
                                Object.entries(info.headings).map(([ key, val ]) => (
                                    <FormGroup key={key + "_company"} label={val + " *"}>
                                        <Input type="text" {...register(key + "_company", {disabled:true})} />
                                    </FormGroup>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row row--multiline grid-column justify-end">
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
        </>
    );
};

export default TaskSealersEdit;