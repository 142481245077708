import React, {useRef} from 'react';
import styled, {css} from 'styled-components'
import {useDetectOutsideClick} from "../hooks/useDetectOutsideClick.hook";

const Wrapper = styled.div`
  position:relative;
  z-index: 1;
`;

const Button = styled.div`
  cursor: pointer;
`;

const Content = styled.div`
  position: absolute;
  top: 100%;
  min-width: 100%;
  
  ${props => !props.position && css`
    left: 0;
  `}
  
  ${props => props.position === 'left' && css`
    left: 0;
  `}
  
  ${props => props.position === 'right' && css`
    right: 0;
  `}
  
  ${props => props.position === 'center' && css`
    left: 50%;
    transform: translateX(-50%);
  `}
`;

const Dropdown = ({menuPosition, title, children}) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
        <Wrapper ref={dropdownRef}>
            <Button onClick={onClick}>{title}</Button>
            {isActive &&
                <Content
                    position={menuPosition}>
                    {children}
                </Content>
            }
        </Wrapper>
    );
};

export default Dropdown;