const routePaths = {
    home: {
        index: "/"
    },
    indications: {
        index: "/indications",
        create: "/indications/create",
        edit: {
            index: (id) => `/indications/${id ? id : ":id"}/edit`,
            information: (id) => `/indications/${id ? id : ":id"}/edit/information`,
            comments: (id) => `/indications/${id ? id : ":id"}/edit/comments`,
            history: (id) => `/indications/${id ? id : ":id"}/edit/history`,
        },
        children: {
            all: {
                index: "/indications/all"
            },
            on_check: {
                index: "/indications/on_check"
            },
            approved: {
                index: "/indications/approved"
            },
            declined: {
                index: "/indications/declined"
            }
        }
    },
    tasks:{
        index: "/tasksPlan",
        children:{
            statistic:{
                index: "/tasksPlan/statistic",
                edit: (id) => `/tasksPlan/statistic/${id ? id : ":id"}/edit`,
                children:{
                    controllers:{
                        index: "/tasksPlan/statistic/controllers",
                        edit: (id) => `/tasksPlan/statistic/controllers/${id ? id : ":id"}/edit`,
                    },
                    sealers:{
                        index: "/tasksPlan/statistic/sealers",
                        edit: (id) => `/tasksPlan/statistic/sealers/${id ? id : ":id"}/edit`,
                    },
                }
            },
        }
    },
    dictionaries: {
        index: "/dictionaries",
        children: {
            resource_types: {
                index: "/dictionaries/resource_types",
                create: "/dictionaries/resource_types/create",
                edit: (id) => `/dictionaries/resource_types/${id ? id : ":id"}/edit`
            },
            improvements: {
                index: "/dictionaries/improvements",
                create: "/dictionaries/improvements/create",
                edit: (id) => `/dictionaries/improvements/${id ? id : ":id"}/edit`
            },
            service_types: {
                index: "/dictionaries/service_types",
                create: "/dictionaries/service_types/create",
                edit: (id) => `/dictionaries/service_types/${id ? id : ":id"}/edit`
            }
        }
    },
    bankbooks: {
        index: "/bankbooks",
        create: "/bankbooks/create",
        edit: {
            index: (id) => `/bankbooks/${id ? id : ":id"}/edit`,
            information: (id) => `/bankbooks/${id ? id : ":id"}/edit/information`,
            devices: (id) => `/bankbooks/${id ? id : ":id"}/edit/devices`
        }
    },
    devices: {
        index: "/devices",
        create: "/devices/create",
        edit: {
            index: (id) => `/devices/${id ? id : ":id"}/edit`,
            information: (id) => `/devices/${id ? id : ":id"}/edit/information`,
            indications: (id) => `/devices/${id ? id : ":id"}/edit/indications`,
            notifications: (id) => `/devices/${id ? id : ":id"}/edit/notifications`,
            documents: (id) => `/devices/${id ? id : ":id"}/edit/documents`,
        }
    },
}

export default routePaths;