import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import routePaths from "./routePaths";
import Sectors from "../../pages/minAdmin/Sectors";
import SectorCreate from "../../pages/minAdmin/Sectors/SectorCreate";
import SectorEdit from "../../pages/minAdmin/Sectors/SectorEdit";
import Regions from "../../pages/minAdmin/Addresses/Regions";
import RegionCreate from "../../pages/minAdmin/Addresses/Regions/RegionCreate";
import RegionEdit from "../../pages/minAdmin/Addresses/Regions/RegionEdit";
import Cities from "../../pages/minAdmin/Addresses/Cities";
import CityCreate from "../../pages/minAdmin/Addresses/Cities/CityCreate";
import CityEdit from "../../pages/minAdmin/Addresses/Cities/CityEdit";
import Streets from "../../pages/minAdmin/Addresses/Streets";
import StreetCreate from "../../pages/minAdmin/Addresses/Streets/StreetCreate";
import StreetEdit from "../../pages/minAdmin/Addresses/Streets/StreetEdit";
import Houses from "../../pages/minAdmin/Addresses/Houses";
import HouseCreate from "../../pages/minAdmin/Addresses/Houses/HouseCreate";
import HouseEdit from "../../pages/minAdmin/Addresses/Houses/HouseEdit";
import Apartments from "../../pages/minAdmin/Addresses/Apartments";
import ApartmentCreate from "../../pages/minAdmin/Addresses/Apartments/ApartmentCreate";
import ApartmentEdit from "../../pages/minAdmin/Addresses/Apartments/ApartmentEdit";
import Bankbooks from "../../pages/minAdmin/Bankbooks";
import BankbookCreate from "../../pages/minAdmin/Bankbooks/BankbookCreate";
import BankbookEdit from "../../pages/minAdmin/Bankbooks/BankbookEdit";
import Devices from "../../pages/minAdmin/Devices";
import DeviceCreate from "../../pages/minAdmin/Devices/DeviceCreate";
import DeviceEdit from "../../pages/minAdmin/Devices/DeviceEdit";
import Indications from "../../pages/minAdmin/Indications";
import IndicationCreate from "../../pages/minAdmin/Indications/IndicationCreate";
import IndicationEdit from "../../pages/minAdmin/Indications/IndicationEdit";
import {useTranslation} from "react-i18next";
import DefaultLayout from "../../layouts/DefaultLayout";
import Districts from "../../pages/minAdmin/Addresses/Districts";
import DistrictCreate from "../../pages/minAdmin/Addresses/Districts/DistrictCreate";
import DistrictEdit from "../../pages/minAdmin/Addresses/Districts/DistrictEdit";
import Microdistricts from "../../pages/minAdmin/Addresses/Microdistricts";
import MicrodistrictCreate from "../../pages/minAdmin/Addresses/Microdistricts/MicrodistrictCreate";
import MicrodistrictEdit from "../../pages/minAdmin/Addresses/Microdistricts/MicrodistrictEdit";
import AddressesImport from "../../pages/minAdmin/Import/Addresses";
import DevicesImport from "../../pages/minAdmin/Import/Devices";
import IndicationsImport from "../../pages/minAdmin/Import/Indications";
import generalRoutePaths from "../general/routePaths";
import Profile from '../../pages/general/Profile';
import DevicesDeleteImport from '../../pages/minAdmin/Import/DevicesDeleteImport';

const Routes = () => {
    const {t} = useTranslation();

    const sidebarItems = [
        {
            title: t('sidebar.sectors.title'),
            url: routePaths.sectors.index,
            icon: "tool-crop"
        },
        {
            title: t('sidebar.addresses.title'),
            url: routePaths.addresses.index,
            icon: "marker",
            children: [
                {
                    title: t('sidebar.addresses.children.regions'),
                    url: routePaths.addresses.children.regions.index
                },
                {
                    title: t('sidebar.addresses.children.cities'),
                    url: routePaths.addresses.children.cities.index
                },
                {
                    title: t('sidebar.addresses.children.districts'),
                    url: routePaths.addresses.children.districts.index
                },
                {
                    title: t('sidebar.addresses.children.microdistricts'),
                    url: routePaths.addresses.children.microdistricts.index
                },
                {
                    title: t('sidebar.addresses.children.streets'),
                    url: routePaths.addresses.children.streets.index
                },
                {
                    title: t('sidebar.addresses.children.houses'),
                    url: routePaths.addresses.children.houses.index
                },
                {
                    title: t('sidebar.addresses.children.apartments'),
                    url: routePaths.addresses.children.apartments.index
                }
            ]
        },
        {
            title: t('sidebar.bankbooks.title'),
            url: routePaths.bankbooks.index,
            icon: "badge"
        },
        {
            title: t('sidebar.devices.title'),
            url: routePaths.devices.index,
            icon: "dashboard"
        },
        {
            title: t('sidebar.indications.title'),
            url: routePaths.indications.index,
            icon: "receipt",
            children: [
                {
                    title: t('sidebar.indications.children.on_check'),
                    url: routePaths.indications.children.on_check.index
                },
                {
                    title: t('sidebar.indications.children.approved'),
                    url: routePaths.indications.children.approved.index
                },
                {
                    title: t('sidebar.indications.children.declined'),
                    url: routePaths.indications.children.declined.index
                },
                {
                    title: t('sidebar.indications.children.all'),
                    url: routePaths.indications.children.all.index
                }
            ]
        },
        {
            title: t('sidebar.data_import.title'),
            url: routePaths.data_import.index,
            icon: "upload",
            children: [
                {
                    title: t('sidebar.data_import.children.addresses'),
                    url: routePaths.data_import.children.addresses.index
                },
                {
                    title: t('sidebar.data_import.children.devices'),
                    url: routePaths.data_import.children.devices.index
                },
                {
                    title: t('sidebar.data_import.children.indications'),
                    url: routePaths.data_import.children.indications.index
                },
                {
                    title: t('sidebar.data_import.children.devices_to_delete'),
                    url: routePaths.data_import.children.devices_to_delete.index
                }
            ]
        }
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.sectors.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Sectors*/}
                <Route exact path={routePaths.sectors.index} component={Sectors}/>
                <Route exact path={routePaths.sectors.create} component={SectorCreate}/>
                <Route path={routePaths.sectors.edit.index()} component={SectorEdit}/>
                {/*Addresses*/}
                <Redirect exact from={routePaths.addresses.index} to={routePaths.addresses.children.regions.index}/>
                {/*Addresses - Regions*/}
                <Route exact path={routePaths.addresses.children.regions.index} component={Regions}/>
                <Route exact path={routePaths.addresses.children.regions.create} component={RegionCreate}/>
                <Route path={routePaths.addresses.children.regions.edit.index()} component={RegionEdit}/>
                {/*Addresses - Cities*/}
                <Route exact path={routePaths.addresses.children.cities.index} component={Cities}/>
                <Route exact path={routePaths.addresses.children.cities.create} component={CityCreate}/>
                <Route path={routePaths.addresses.children.cities.edit.index()} component={CityEdit}/>
                {/*Addresses - Districts*/}
                <Route exact path={routePaths.addresses.children.districts.index} component={Districts}/>
                <Route exact path={routePaths.addresses.children.districts.create} component={DistrictCreate}/>
                <Route path={routePaths.addresses.children.districts.edit.index()} component={DistrictEdit}/>
                {/*Addresses - Microdistricts*/}
                <Route exact path={routePaths.addresses.children.microdistricts.index} component={Microdistricts}/>
                <Route exact path={routePaths.addresses.children.microdistricts.create} component={MicrodistrictCreate}/>
                <Route path={routePaths.addresses.children.microdistricts.edit.index()} component={MicrodistrictEdit}/>
                {/*Addresses - Streets*/}
                <Route exact path={routePaths.addresses.children.streets.index} component={Streets}/>
                <Route exact path={routePaths.addresses.children.streets.create} component={StreetCreate}/>
                <Route path={routePaths.addresses.children.streets.edit.index()} component={StreetEdit}/>
                {/*Addresses - Houses*/}
                <Route exact path={routePaths.addresses.children.houses.index} component={Houses}/>
                <Route exact path={routePaths.addresses.children.houses.create} component={HouseCreate}/>
                <Route path={routePaths.addresses.children.houses.edit.index()} component={HouseEdit}/>
                {/*Addresses - Apartments*/}
                <Route exact path={routePaths.addresses.children.apartments.index} component={Apartments}/>
                <Route exact path={routePaths.addresses.children.apartments.create} component={ApartmentCreate}/>
                <Route path={routePaths.addresses.children.apartments.edit.index()} component={ApartmentEdit}/>
                {/*Bankbooks*/}
                <Route exact path={routePaths.bankbooks.index} component={Bankbooks}/>
                <Route exact path={routePaths.bankbooks.create} component={BankbookCreate}/>
                <Route path={routePaths.bankbooks.edit.index()} component={BankbookEdit}/>
                {/*Devices*/}
                <Route exact path={routePaths.devices.index} component={Devices}/>
                <Route exact path={routePaths.devices.create} component={DeviceCreate}/>
                <Route path={routePaths.devices.edit.index()} component={DeviceEdit}/>
                {/*Indications*/}
                <Redirect exact from={routePaths.indications.index} to={routePaths.indications.children.on_check.index}/>
                <Route exact path={routePaths.indications.children.all.index}>
                    <Indications statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.indications.children.on_check.index}>
                    <Indications key={routePaths.indications.children.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.indications.children.approved.index}>
                    <Indications key={routePaths.indications.children.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.indications.children.declined.index}>
                    <Indications key={routePaths.indications.children.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>
                <Route exact path={routePaths.indications.create} component={IndicationCreate}/>
                <Route path={routePaths.indications.edit.index()} component={IndicationEdit}/>             
                {/*Imports*/}
                <Redirect exact from={routePaths.data_import.index} to={routePaths.data_import.children.addresses.index}/>
                <Route exact path={routePaths.data_import.children.addresses.index} component={AddressesImport}/>
                <Route exact path={routePaths.data_import.children.devices.index} component={DevicesImport}/>
                <Route exact path={routePaths.data_import.children.indications.index} component={IndicationsImport}/>
                <Route exact path={routePaths.data_import.children.devices_to_delete.index} component={DevicesDeleteImport}/>
            
            </Switch>
        </DefaultLayout>
    );
};

export default Routes;