import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import Info from "./Info";
import Password from "./Password";

const Profile = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('pages.profile.title')
            }
        ])
    }, [setBreadcrumbs, t])

    return (
        <>
            <h1 className="headline-4">{t('pages.profile.title')}</h1>
            <Info/>
            <Password/>
        </>
    );
};

export default Profile;