import React, {memo, useRef, useState} from 'react';
import ModalComponent from "./ModalComponent";
import styled, {css} from 'styled-components'
import Button from "./Button";
import {Editor} from "@tinymce/tinymce-react";
import FileUpload from "../services/general/fileUpload";
import {toast} from "react-toastify";

const ProgressBar = styled.div`
  height: 10px;
  background-color: #eee;
  width: 100%;
  
  &:after {
    content: '';
    background-color: var(--success);
    transition: opacity .2s;
    height: 100%;
    width: 0;
    display: block;
    border-radius: 4px;
      
    ${props => props.progress && css`
      width: ${props.progress}%
    `}
  }
`;

const TextEditor = ({onChange, value}) => {
    const editorRef = useRef(null);
    const handleEditorChange = editor => onChange(editor);

    const [progress, setProgress] = useState();
    const [modalIsOpen, setModalIsOpen] = useState();

    const MemoizedProgressBar = memo(() => (
        <ProgressBar progress={progress}/>
    ));

    return (
        <>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                apiKey="eutvjr9zyhc4qtchwyfwhutknw2iunsf80kiuye2fdomu2wd"
                init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | image media link|' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    automatic_uploads: true,
                    language: "ru",
                    file_picker_types: 'file image media',
                    file_picker_callback: function (cb, value, meta) {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');

                        if (meta.filetype === 'file') {
                            input.accept = '.pdf, .doc, .xls, .ppt, .docx, .xlsx, .pptx, .png, .jpg, .rar, .zip, .7z, .mp3, .mp4, .avi, .mov';
                        }

                        // Provide image and alt text for the image dialog
                        if (meta.filetype === 'image') {
                            input.accept = '.png, .jpg, .jpeg, .gif';
                        }

                        // Provide alternative source and posted for the media dialog
                        if (meta.filetype === 'media') {
                            input.accept = '.mp4, .avi, .mov';
                        }

                        input.click();
                        input.onchange = async (e) => {
                            setModalIsOpen(true);

                            const currentFile = e.target.files[0];

                            setProgress(0);

                            const res = await FileUpload.upload(currentFile, (event) => {
                                setProgress(Math.round((100 * event.loaded) / event.total));
                            });

                            if (res.statusCode === 200) {
                                toast.success(res.message)
                                cb(res.content.value);
                            } else {
                                toast.error(res.message)
                            }

                            setModalIsOpen(false);
                            setProgress(0);
                        };
                    }
                }}
                value={value}
                onEditorChange={handleEditorChange}
            />
            <ModalComponent
                isOpen={modalIsOpen}
                ariaHideApp={false}
                disableCloseBtn
            >
                <h2 className="title-secondary">Загрузка файла</h2>
                <div className="mb-20"><MemoizedProgressBar/></div>
                <Button size="small" solid="blue" onClick={() => FileUpload.cancel()}>Отмена</Button>
            </ModalComponent>
        </>
    );
};

export default TextEditor;