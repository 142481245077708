import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../hooks/useUpdate.hook";
import SectorsServices from "../../../services/admin/sectorsServices";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import TextEditor from "../../../components/TextEditor";
import CustomSelect from "../../../components/CustomSelect";
import Button from "../../../components/Button";
import Dictionaries from "../../../services/admin/dictionaries";
import {toast} from "react-toastify";
import {useLangContext} from "../../../i18n/ProvideLang";
import InputMessage from "../../../components/InputMessage";

const SectorEditInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    const {info, loading: fetchLoading} = useFetchInfo(SectorsServices.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.sectors.index,
                title: t('sidebar.sectors.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {onSubmit, loading} = useUpdate(SectorsServices.update, id, false);

    const cityId = watch('city_id', null);
    const companyId = watch('company_id', null);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (companyId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);


    useEffect(() => {
        if (info) {
            const {city, company, ...rest} = info;

            for (const [key, value] of Object.entries(rest)) {
                if (key) setValue(key, value);
            }

            city && setValue('city_id', String(city?.id));
        }
    }, [info, setValue])

    useEffect(() => {
        if (info && companies) {
            const {company} = info;
            company && setValue('company_id', String(company?.id));
        }
    }, [info, setValue, companies])


    return (
        <>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.content_kk')}>
                        <Controller
                            name="description_kk"
                            control={control}
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <TextEditor
                                    onChange={onChange}
                                    value={value}
                                />
                            )}>
                        </Controller>
                    </FormGroup>
                    <FormGroup label={t('labels.content_ru')}>
                        <Controller
                            name="description_ru"
                            control={control}
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <TextEditor
                                    onChange={onChange}
                                    value={value}
                                />
                            )}>
                        </Controller>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled={state?.company_id}
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.city') + " *"}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? companiesLoading || citiesLoading : false}
                                                      isDisabled={!!!companyId || companiesLoading || citiesLoading || state?.city_id}
                                                      $error={errors.city_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default SectorEditInformation;