import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/CallOperator/routePaths";
import BankbookEditInformation from "./BankbookEditInformation";
import BankbookEditDevices from "./BankbookEditDevices";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import BankbooksServices from "../../../services/callOperator/bankbooksServices";
import BankbookEditDocuments from './BankbookEditDocuments';

const BankbookEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(BankbooksServices.view, id);

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info?.number ? " - " + info?.number : ""}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.bankbooks.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.bankbooks.edit.devices(id)}>{t('labels.devices')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.bankbooks.edit.documents(id)}>{t('labels.documents')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.bankbooks.edit.index()} to={routePaths.bankbooks.edit.information()}/>
                <Route path={routePaths.bankbooks.edit.information()} component={BankbookEditInformation}/>
                <Route path={routePaths.bankbooks.edit.devices()} component={BankbookEditDevices}/>
                <Route path={routePaths.bankbooks.edit.documents()} component={BankbookEditDocuments}/>
            </Switch>
        </>
    );
};

export default BankbookEdit;