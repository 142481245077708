import React, {useEffect, useState} from 'react';
import Button from "../../../components/Button";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/laboratoryAdministrator/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import LaboratoriesServices from "../../../services/laboratoryAdministrator/laboratoriesServices";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import AttachServiceModal from "../components/AttachServiceModal";
import EditAttachedServiceModal from "../components/EditAttachedServiceModal";
import WhiteBlock from "../../../components/WhiteBlock";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";

const LaboratoryEditServices = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useFetchInfo(LaboratoriesServices.view);
    const {lang} = useLangContext();

    const [serviceModal, setServiceModal] = useState();
    const [serviceEditModal, setServiceEditModal] = useState();
    const [selectedService, setSelectedService] = useState();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.laboratory.index,
                title: t('sidebar.laboratories.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.services')
            }
        ])
    }, [setBreadcrumbs, t, info, lang]);

    const {items, loading, pageCount, params, handlePageClick, fetchItems} = useFetchItems(LaboratoriesServices.services.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, price}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        price
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: ['ID', t('labels.services'), t('labels.price_tng'), ''],
        sizes: ['10%', '60%', '20%', '']
    }

    const handleServiceEdit = (id) => {
        setSelectedService(items.find(item => String(item.id) === String(id)));
        setServiceEditModal(true);
    }

    return (
        <WhiteBlock> 
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        editCallback={handleServiceEdit}
                        removeMethod={LaboratoriesServices.services.detach}
                        removeModalContent={{
                            title: t('modals.remove_service.title'),
                            yes: t('labels.yes'),
                            no: t('labels.cancel')
                        }}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small $customColor={'#1FA2FF'} $withIcon variant="outline" onClick={() => setServiceModal(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {serviceModal &&
                <AttachServiceModal
                    isOpen={serviceModal}
                    closeModal={() => setServiceModal(false)}
                    onRequestClose={() => setServiceModal(false)}
                    method={LaboratoriesServices.services.attach}
                    successCallback={fetchItems}
                />
            }
            {serviceEditModal &&
                <EditAttachedServiceModal
                    isOpen={serviceEditModal}
                    closeModal={() => setServiceEditModal(false)}
                    onRequestClose={() => setServiceEditModal(false)}
                    method={LaboratoriesServices.services.update}
                    successCallback={fetchItems}
                    selectedService={selectedService}
                />
            }
        </WhiteBlock>
    );
};

export default LaboratoryEditServices;