import React from 'react';
import routePaths from "./routePaths";
import DefaultLayout from "../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch} from "react-router-dom";
import Applications from "../../pages/callOperator/Applications";
import Application from "../../pages/callOperator/Applications/Application";
import ApplicationCreate from "../../pages/callOperator/Applications/ApplicationCreate";
import Bankbooks from '../../pages/callOperator/Bankbooks';
import BankbookEdit from '../../pages/callOperator/Bankbooks/BankbookEdit';
import Devices from '../../pages/callOperator/Devices';
import DeviceEdit from '../../pages/callOperator/Devices/DeviceEdit';
import ModelChangesDevicesEdit from '../../pages/callOperator/ModelChanges/Devices/ModelChangesDevicesEdit';
import ModelChangesDevices from '../../pages/callOperator/ModelChanges/Devices';
import ModelChangesBankbooks from '../../pages/callOperator/ModelChanges/Bankbooks';
import ModelChangesBankbookEdit from '../../pages/callOperator/ModelChanges/Bankbooks/ModelChangesBankbookEdit';
import Profile from '../../pages/general/Profile';
import generalRoutePaths from "../general/routePaths";

const Routes = () => {
    const {t} = useTranslation();

    const sidebarItems = [
        {
            title: t('sidebar.bankbooks.title'),
            url: routePaths.bankbooks.index,
            icon: "badge"
        },
        {
            title: t('sidebar.devices.title'),
            url: routePaths.devices.index,
            icon: "dashboard"
        },
        {
            title: t('sidebar.change_requests.title'),
            url: routePaths.model_сhanges.index,
            icon: "model-change",
            children: [
                {
                    title: t('sidebar.change_requests.children.bankbooks'),
                    url: routePaths.model_сhanges.children.bankbooks.all.index,
                    children: [
                        {
                            title: t('sidebar.indications.children.on_check'),
                            url: routePaths.model_сhanges.children.bankbooks.on_check.index
                        },
                        {
                            title: t('sidebar.indications.children.approved'),
                            url: routePaths.model_сhanges.children.bankbooks.approved.index
                        },
                        {
                            title: t('sidebar.indications.children.declined'),
                            url: routePaths.model_сhanges.children.bankbooks.declined.index
                        },
                        {
                            title: t('sidebar.indications.children.all'),
                            url: routePaths.model_сhanges.children.bankbooks.all.index
                        }
                    ]
                },
                {
                    title: t('sidebar.change_requests.children.devices'),
                    url: routePaths.model_сhanges.children.devices.all.index,
                    children: [
                        {
                            title: t('sidebar.indications.children.on_check'),
                            url: routePaths.model_сhanges.children.devices.on_check.index
                        },
                        {
                            title: t('sidebar.indications.children.approved'),
                            url: routePaths.model_сhanges.children.devices.approved.index
                        },
                        {
                            title: t('sidebar.indications.children.declined'),
                            url: routePaths.model_сhanges.children.devices.declined.index
                        },
                        {
                            title: t('sidebar.indications.children.all'),
                            url: routePaths.model_сhanges.children.devices.all.index
                        }
                    ]
                }
            ]
            
        },
        {
            title: t('sidebar.applications.title'),
            url: routePaths.applications.index,
            icon: "list",
            children: [
                {
                    title: t('sidebar.applications.children.all'),
                    url: routePaths.applications.children.all.index
                },
                {
                    title: t('sidebar.applications.children.under_consideration'),
                    url: routePaths.applications.children.under_consideration.index
                },
                {
                    title: t('sidebar.applications.children.rejected'),
                    url: routePaths.applications.children.rejected.index
                },
                {
                    title: t('sidebar.applications.children.waiting'),
                    url: routePaths.applications.children.waiting.index
                },
                {
                    title: t('sidebar.applications.children.cancelled'),
                    url: routePaths.applications.children.cancelled.index
                },
                {
                    title: t('sidebar.applications.children.in_work'),
                    url: routePaths.applications.children.in_work.index
                },
                {
                    title: t('sidebar.applications.children.done_payment_waiting'),
                    url: routePaths.applications.children.done_payment_waiting.index
                },
                {
                    title: t('sidebar.applications.children.done_payment_approve_waiting'),
                    url: routePaths.applications.children.done_payment_approve_waiting.index
                },
                {
                    title: t('sidebar.applications.children.done_paid'),
                    url: routePaths.applications.children.done_paid.index
                }
            ]
        }
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.bankbooks.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Bankbooks*/}
                <Route exact path={routePaths.bankbooks.index} component={Bankbooks}/>
                <Route path={routePaths.bankbooks.edit.index()} component={BankbookEdit}/>
                {/*Devices*/}
                <Route exact path={routePaths.devices.index} component={Devices}/>
                <Route path={routePaths.devices.edit.index()} component={DeviceEdit}/>
                {/*Applications*/}
                <Redirect exact from={routePaths.applications.index} to={routePaths.applications.children.all.index}/>
                <Route path={routePaths.applications.create} component={ApplicationCreate}/>
                <Route exact path={routePaths.applications.index} component={Applications}/>
                <Route exact path={routePaths.applications.children.all.index}>
                    <Applications key={routePaths.applications.children.all.index}
                                  statusText={t('sidebar.applications.children.all')}/>
                </Route>
                <Route exact path={routePaths.applications.children.under_consideration.index}>
                    <Applications key={routePaths.applications.children.under_consideration.index} statusId="1"
                                  statusText={t('sidebar.applications.children.under_consideration')}/>
                </Route>
                <Route exact path={routePaths.applications.children.rejected.index}>
                    <Applications key={routePaths.applications.children.rejected.index} statusId="2"
                                  statusText={t('sidebar.applications.children.rejected')}/>
                </Route>
                <Route exact path={routePaths.applications.children.waiting.index}>
                    <Applications key={routePaths.applications.children.waiting.index} statusId="3"
                                  statusText={t('sidebar.applications.children.waiting')}/>
                </Route>
                <Route exact path={routePaths.applications.children.cancelled.index}>
                    <Applications key={routePaths.applications.children.cancelled.index} statusId="4"
                                  statusText={t('sidebar.applications.children.cancelled')}/>
                </Route>
                <Route exact path={routePaths.applications.children.in_work.index}>
                    <Applications key={routePaths.applications.children.in_work.index} statusId="5"
                                  statusText={t('sidebar.applications.children.in_work')}/>
                </Route>
                <Route exact path={routePaths.applications.children.done_payment_waiting.index}>
                    <Applications key={routePaths.applications.children.done_payment_waiting.index} statusId="6"
                                  statusText={t('sidebar.applications.children.done_payment_waiting')}/>
                </Route>
                <Route exact path={routePaths.applications.children.done_payment_approve_waiting.index}>
                    <Applications key={routePaths.applications.children.done_payment_approve_waiting.index} statusId="7"
                                  statusText={t('sidebar.applications.children.done_payment_approve_waiting')}/>
                </Route>
                <Route exact path={routePaths.applications.children.done_paid.index}>
                    <Applications key={routePaths.applications.children.done_paid.index} statusId="8"
                                  statusText={t('sidebar.applications.children.done_paid')}/>
                </Route>
                <Route path={routePaths.applications.detail.index()} component={Application}/>
                {/*ModelChanges*/}
                <Route exact path={routePaths.model_сhanges.children.bankbooks.all.index}>
                    <ModelChangesBankbooks statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.bankbooks.on_check.index}>
                    <ModelChangesBankbooks key={routePaths.model_сhanges.children.bankbooks.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.bankbooks.approved.index}>
                    <ModelChangesBankbooks key={routePaths.model_сhanges.children.bankbooks.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.bankbooks.declined.index}>
                    <ModelChangesBankbooks key={routePaths.model_сhanges.children.bankbooks.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>
                <Route path={routePaths.model_сhanges.edit.index()} component={ModelChangesBankbookEdit}/>


                <Route exact path={routePaths.model_сhanges.children.devices.all.index}>
                    <ModelChangesDevices statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.devices.on_check.index}>
                    <ModelChangesDevices key={routePaths.model_сhanges.children.devices.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.devices.approved.index}>
                    <ModelChangesDevices key={routePaths.model_сhanges.children.devices.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.devices.declined.index}>
                    <ModelChangesDevices key={routePaths.model_сhanges.children.devices.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>

                <Route path={routePaths.model_сhanges.edit.devices()} component={ModelChangesDevicesEdit}/>
            </Switch>
        </DefaultLayout>
    );
};

export default Routes;