import React from 'react';
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Utils from "../../../../services/utils";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/operator/dictionaries";

const HousesFilter = ({setFilterData, cityId}) => {
    const {t} = useTranslation();

    const {handleSubmit, reset, control} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                <FormGroup label={t('labels.street')}>
                    {cityId && <Controller name="street_id" control={control}
                                           render={({field: {onChange, value}}) => (
                                               <CustomAsyncSelect
                                                   method={Dictionaries.streets}
                                                   data={cityId ? {queryString: `city_id=${cityId}`} : null}
                                                   onChange={onChange}
                                                   value={value}
                                                   $small
                                               />
                                           )}/>}
                </FormGroup>
                <div className="mb-20">
                    <Button $small $fluid>{t('labels.apply')}</Button>
                </div>
                <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
            </form>
        </>
    );
};

export default HousesFilter;