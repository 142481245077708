import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Button from '../../../../components/Button';
import ModalComponent from '../../../../components/ModalComponent';

const FailAcceptModal = ({method, successCallback, failCallback, closeModal, content, indicationId, errorMsg, data, parentId, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const confirm = async () => {
        setLoading(true);
        
        const res = indicationId ? await method(indicationId, data) : await method(data);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            successCallback();
        }

        setLoading(false);
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{maxWidth: 500}}>
                <h4 className="headline-4">{errorMsg ? errorMsg : t('messages.action_confirm')}</h4>
                <div className={`row row--multiline justify-end`}>
                    <div className="col-auto">
                        <Button $small $gray variant='outline' onClick={closeModal}>{content && content.no ? content.no : t('labels.cancel')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button $small $red onClick={confirm}>{t('labels.force_accept')}</Button>
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
};

export default FailAcceptModal;