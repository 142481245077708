import React from 'react';
import routePaths from "./routePaths";
import DefaultLayout from "../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch} from "react-router-dom";
import Indications from '../../pages/analiticOperator/Indications';
import IndicationCreate from "../../pages/analiticOperator/Indications/IndicationCreate";
import IndicationEdit from "../../pages/analiticOperator/Indications/IndicationEdit";
import TasksStatics from '../../pages/analiticOperator/Tasks/Statistic';
import Profile from '../../pages/general/Profile';
import generalRoutePaths from "../general/routePaths";

const Routes = () => {
    const {t} = useTranslation();

    const sidebarItems = [
        {
            title: t('sidebar.indications.title'),
            url: routePaths.indications.index,
            icon: "receipt",
            children: [
                {
                    title: t('sidebar.indications.children.on_check'),
                    url: routePaths.indications.children.on_check.index
                },
                {
                    title: t('sidebar.indications.children.approved'),
                    url: routePaths.indications.children.approved.index
                },
                {
                    title: t('sidebar.indications.children.declined'),
                    url: routePaths.indications.children.declined.index
                },
                {
                    title: t('sidebar.indications.children.all'),
                    url: routePaths.indications.children.all.index
                }
            ]
        },
        {
            title: t('sidebar.tasks.title'),
            url: routePaths.tasks.index,
            icon: "tasks",
            children: [
                {
                    title: t('sidebar.tasks.children.statistics.index'),
                    url: routePaths.tasks.children.statistic.index,
                    children:[
                        {
                            title: t('sidebar.tasks.children.statistics.children.controllers'),
                            url: routePaths.tasks.children.statistic.children.controllers.index
                        },
                        {
                            title: t('sidebar.tasks.children.statistics.children.sealers'),
                            url: routePaths.tasks.children.statistic.children.sealers.index
                        },
                    ]
                }
            ]
        }
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.indications.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Indications*/}
                <Redirect exact from={routePaths.indications.index} to={routePaths.indications.children.on_check.index}/>
                <Route exact path={routePaths.indications.children.all.index}>
                    <Indications statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.indications.children.on_check.index}>
                    <Indications key={routePaths.indications.children.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.indications.children.approved.index}>
                    <Indications key={routePaths.indications.children.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.indications.children.declined.index}>
                    <Indications key={routePaths.indications.children.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>
                <Route exact path={routePaths.indications.create} component={IndicationCreate}/>
                <Route path={routePaths.indications.edit.index()} component={IndicationEdit}/>
                {/*Tasks Statistics*/}
                <Route exact path={routePaths.tasks.children.statistic.children.controllers.index}>
                    <TasksStatics key={routePaths.tasks.children.statistic.children.controllers.index} role="controller"
                                 statusText={t('sidebar.tasks.children.statistics.children.controllers')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.statistic.children.sealers.index}>
                    <TasksStatics key={routePaths.tasks.children.statistic.children.sealers.index} role="sealer"
                                 statusText={t('sidebar.tasks.children.statistics.children.sealers')}/>
                </Route>
            </Switch>
        </DefaultLayout>
    );
};

export default Routes;