import React, {Suspense} from 'react';
import './App.scss';
import {ProvideAuth} from "./navigation/Auth/ProvideAuth";
import {BrowserRouter} from "react-router-dom";
import RouterConfig from "./navigation/routerConfig";
import './i18n/i18n'
import ProvideLang from "./i18n/ProvideLang";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
      <Suspense fallback={null}>
          <ProvideLang>
              <ProvideAuth>
                  <BrowserRouter>
                      <RouterConfig/>
                      <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                      />
                  </BrowserRouter>
              </ProvideAuth>
          </ProvideLang>
      </Suspense>
  );
}

export default App;
