import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../../components/FormGroup";
import Button from "../../../../../components/Button";
import ModalComponent from "../../../../../components/ModalComponent";
import Dictionaries from "../../../../../services/admin/dictionaries";
import CustomSelect from "../../../../../components/CustomSelect";

const AttachControllerModal = ({method, closeModal, city_id, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, control, watch, setValue, reset} = useForm();

    const companyId = watch('company_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [controllers, setControllers] = useState();
    const [controllersLoading, setControllersLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies({queryString: `city_id=${city_id}`});

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchControllers = useCallback(async () => {
        setValue('controller_id', null);

        if (companyId) {
            setControllersLoading(true);

            const res = await Dictionaries.users.companyControllers({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setControllers(res.content);
            } else {
                toast.error(res.message)
            }

            setControllersLoading(false);
        }
    }, [setValue, companyId]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchControllers();
    }, [fetchControllers]);



    const onSubmit = async (data) => {
        setLoading(true);

        const res = await method(id, {user_id: data.controller_id});

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.user_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.controller')}>
                        <Controller name="controller_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={controllers} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!companyId ? controllersLoading || companiesLoading : false} isDisabled={!!!companyId || controllersLoading || companiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachControllerModal;