import {useState} from 'react';

const useToken = () => {
    const getToken = () => {
        return localStorage.getItem('token');
    };

    const getUserData = () => {
        return JSON.parse(localStorage.getItem('userData'));
    };

    const [token, setToken] = useState(getToken());
    const [userData, setUserData] = useState(getUserData());

    const saveToken = userToken => {
        localStorage.setItem('token', userToken);
        setToken(userToken)
    };

    const saveInfo = info => {
        localStorage.setItem('userData', JSON.stringify(info));
        setUserData(info)
    };

    const removeInfo = info => {
        localStorage.removeItem('userData');
        setUserData('')
    };

    const removeToken = () => {
        localStorage.removeItem('token');
        setToken('');
    };

    return {
        setToken: saveToken,
        removeToken: removeToken,
        token,
        setInfo: saveInfo,
        removeInfo,
        userData
    }
};

export default useToken;