import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../../navigation/CallOperator/routePaths";
import {toast} from "react-toastify";
import Dictionaries from "../../../../services/callOperator/dictionaries";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../../hooks/useUpdate.hook";
import FormGroup from "../../../../components/FormGroup";
import CustomSelect from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import TextArea from "../../../../components/TextArea";
import ModelChangesServices from '../../../../services/callOperator/modelChangesServices';


const ModelChangesBankbookEdit = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, setError, control, watch, setValue, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(ModelChangesServices.bankbooks.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.model_сhanges.children.bankbooks.all.index,
                title: t('labels.change_bankbooks')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            }
        ])
    }, [setBreadcrumbs, t, info])

    const companyId = watch('company_id', null)
    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null)
    const microdistrictId = watch('microdistrict_id', null)
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);
    const apartmentId = watch('apartment_id', null);
    const sectorId = watch('sector', null);

    const companyIdChange = watch('company_id_change', null)
    const cityIdChange = watch('city_id_change', null);
    const districtIdChange = watch('district_id_change', null)
    const microdistrictIdChange = watch('microdistrict_id_change', null)
    const streetIdChange = watch('street_id_change', null);
    const houseIdChange = watch('house_id_change', null);
    const apartmentIdChange = watch('apartment_id_change', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const [microdistricts, setMicrodistricts] = useState();
    const [microdistrictsLoading, setMicrodistrictsLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const [apartments, setApartments] = useState();
    const [apartmentsLoading, setApartmentsLoading] = useState(true);


    const [citiesChange, setCitiesChange] = useState();
    const [citiesLoadingChange, setCitiesLoadingChange] = useState(true);

    const [districtsChange, setDistrictsChange] = useState();
    const [districtsLoadingChange, setDistrictsLoadingChange] = useState(true);

    const [microdistrictsChange, setMicrodistrictsChange] = useState();
    const [microdistrictsLoadingChange, setMicrodistrictsLoadingChange] = useState(true);

    const [streetsChange, setStreetsChange] = useState();
    const [streetsLoadingChange, setStreetsLoadingChange] = useState(true);

    const [housesChange, setHousesChange] = useState();
    const [housesLoadingChange, setHousesLoadingChange] = useState(true);

    const [apartmentsChange, setApartmentsChange] = useState();
    const [apartmentsLoadingChange, setApartmentsLoadingChange] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (companyId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }

            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }

        if (companyIdChange) {
            setCitiesLoadingChange(true);

            const res = await Dictionaries.cities({queryString: `company_id=${companyIdChange}`});

            if (res.statusCode === 200) {
                setCitiesChange(res.content);

                if (!res.content.some(item => item.value === cityIdChange)) {
                    setValue('city_id_change', null)
                }

            } else {
                toast.error(res.message)
            }

            setCitiesLoadingChange(false);
        } else {
            setValue('city_id_change', null)
        }
    }, [setValue, companyId, companyIdChange]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }

        if (cityIdChange) {
            setDistrictsLoadingChange(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityIdChange}`});

            if (res.statusCode === 200) {
                setDistrictsChange(res.content);

                if (!res.content.some(item => item.value === districtIdChange)) {
                    setValue('district_id_change', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoadingChange(false);
        } else {
            setValue('district_id_change', null)
        }
    }, [setValue, cityId, cityIdChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMicrodistricts = useCallback(async () => {
        if (districtId) {
            setMicrodistrictsLoading(true);

            const res = await Dictionaries.microdistricts({queryString: `district_id=${districtId}`});

            if (res.statusCode === 200) {
                setMicrodistricts(res.content);

                if (!res.content.some(item => item.value === microdistrictId)) {
                    setValue('microdistrict_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoading(false);
        } else {
            setValue('microdistrict_id', null)
        }

        if (districtIdChange) {
            setMicrodistrictsLoadingChange(true);

            const res = await Dictionaries.microdistricts({queryString: `district_id=${districtIdChange}`});

            if (res.statusCode === 200) {
                setMicrodistrictsChange(res.content);

                if (!res.content.some(item => item.value === districtIdChange)) {
                    setValue('microdistrict_id_change', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoadingChange(false);
        } else {
            setValue('microdistrict_id_change', null)
        }
    }, [setValue, districtId, districtIdChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        if (districtId) {
            setStreetsLoading(true);

            const microdistrictQuery = microdistrictId ? `&microdistrict_id=${microdistrictId}` : "";

            const res = await Dictionaries.streets({queryString: `district_id=${districtId}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }

        if (districtIdChange) {
            setStreetsLoadingChange(true);

            const microdistrictQuery = microdistrictIdChange ? `&microdistrict_id=${microdistrictIdChange}` : "";

            const res = await Dictionaries.streets({queryString: `district_id=${districtIdChange}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreetsChange(res.content);

                if (!res.content.some(item => item.value === streetIdChange)) {
                    setValue('street_id_change', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoadingChange(false);
        } else {
            setValue('street_id_change', null)
        }
    }, [setValue, districtId, districtIdChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchHouses = useCallback(async () => {
        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);

                if (!res.content.some(item => item.value === houseId)) {
                    setValue('house_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        } else {
            setValue('house_id', null)
        }

        if (streetIdChange) {
            setHousesLoadingChange(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetIdChange}`});

            if (res.statusCode === 200) {
                setHousesChange(res.content);

                if (!res.content.some(item => item.value === houseIdChange)) {
                    setValue('house_id_change', null)
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoadingChange(false);
        } else {
            setValue('house_id_change', null)
        }
    }, [setValue, streetId, streetIdChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchApartments = useCallback(async () => {
        if (houseId) {
            setApartmentsLoading(true);

            const res = await Dictionaries.apartments({queryString: `house_id=${houseId}`});

            if (res.statusCode === 200) {
                setApartments(res.content);

                if (!res.content.some(item => item.value === apartmentId)) {
                    setValue('apartment_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setApartmentsLoading(false);
        } else {
            setValue('apartment_id', null)
        }

        if (houseIdChange) {
            setApartmentsLoadingChange(true);

            const res = await Dictionaries.apartments({queryString: `house_id=${houseIdChange}`});

            if (res.statusCode === 200) {
                setApartmentsChange(res.content);

                if (!res.content.some(item => item.value === apartmentIdChange)) {
                    setValue('apartment_id_change', null)
                }
            } else {
                toast.error(res.message)
            }

            setApartmentsLoadingChange(false);
        } else {
            setValue('apartment_id_change', null)
        }
    }, [setValue, houseId, houseIdChange]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        fetchMicrodistricts();
    }, [fetchMicrodistricts]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        fetchApartments();
    }, [fetchApartments]);

    useEffect(() => {
        if (sectorId) {
            setValue('type', sectorId === "1" ? "1" : "0")
        } else {
            setValue('type', null)
        }
    }, [sectorId, setValue])

    const {onSubmit, loading} = useUpdate(ModelChangesServices.bankbooks.accept, id, false);


    useEffect(() => {
        if (info?.item) {
            for (const [key, value] of Object.entries(info.item)) {
                info.item[key] !== null && setValue(key, String(value));
                info.item[key] !== null && setValue(key+"_change", String(value));
            }
            for (const [key, value] of Object.entries(info.changes)) {
                info.changes[key] !== null && setValue(key+"_change", String(value));
                setError(key+"_change", { type: 'custom', message: 'custom message' });
            }
            const {address_id, house_id} = info;
            address_id !== house_id && setValue('apartment_id', String(address_id))
            address_id !== house_id && setValue('apartment_id_change', String(address_id))
            info.change_note && setValue('change_note', String(info.change_note))
            info.reject_reason && setValue('reject_reason', String(info.reject_reason))
        }
    }, [info, setValue])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h1 className="headline-4">{t('labels.change_bankbooks')}</h1>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <div className="form-controller mb-20">
                    <div className="controller-wrapper">
                        <h2>Было</h2>
                        <FormGroup label={t('labels.account_number') + " *"}>
                            <Input type="text" {...register('number', {disabled: true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.resource_company') + " *"}>
                            <Controller name="company_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={companies} onChange={onChange}
                                                        value={value}
                                                        isLoading={companiesLoading}
                                                        isDisabled={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.city') + " *"}>
                            <Controller name="city_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={cities} onChange={onChange}
                                                        value={value}
                                                        isLoading={(!!companyId) ? citiesLoading : false}
                                                        isDisabled={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.district') + " *"}>
                            <Controller name="district_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={districts} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!cityId ? districtsLoading : false}
                                                        isDisabled={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.microdistrict')}>
                            <Controller name="microdistrict_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={microdistricts} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!districtId ? microdistrictsLoading : false}
                                                        isDisabled={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.street') + " *"}>
                            <Controller name="street_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={streets} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!districtId ? streetsLoading : false}
                                                        isDisabled={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.house') + " *"}>
                            <Controller name="house_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={houses} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!streetId ? housesLoading : false}
                                                        isDisabled={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.apartment')}>
                            <Controller name="apartment_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={apartments} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!houseId ? apartmentsLoading : false}
                                                        isDisabled={true}
                                            />
                                        )}/>
                        </FormGroup>
                        <FormGroup label={t('labels.segment') + " *"}>
                            <Controller name="sector" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.bankbookSectorTypes}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.subscriber_type')}>
                            <Controller name="type" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.bankbookUserTypes}
                                                onChange={onChange}
                                                value={value}
                                                isDisabled={true}
                                                enableLoading={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.contact_person')}>
                            <Input type="text" {...register('name', {disabled:true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.phone_number')}>
                            <Input type="tel" {...register('phone', {disabled:true})}/>
                        </FormGroup>
                        <FormGroup label={t('labels.note')}>
                            <TextArea {...register('note', {disabled:true})}/>
                        </FormGroup>
                    </div>
                    <div className="controller-wrapper">
                        <h2>Стало</h2>
                        <FormGroup label={t('labels.account_number') + " *"}>
                            <Input type="text" {...register('number_change', {disabled: true})} $error={errors.number_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.resource_company') + " *"}>
                            <Controller name="company_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={companies} onChange={onChange}
                                                        value={value}
                                                        isLoading={companiesLoading}
                                                        isDisabled={true}
                                                        $error={errors.company_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.city') + " *"}>
                            <Controller name="city_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={citiesChange} onChange={onChange}
                                                        value={value}
                                                        isLoading={(!!companyIdChange) ? citiesLoadingChange : false}
                                                        isDisabled={true}
                                                        $error={errors.city_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.district') + " *"}>
                            <Controller name="district_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={districtsChange} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!cityIdChange ? districtsLoadingChange : false}
                                                        isDisabled={true}
                                                        $error={errors.district_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.microdistrict')}>
                            <Controller name="microdistrict_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={microdistrictsChange} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!districtIdChange ? microdistrictsLoadingChange : false}
                                                        isDisabled={true}
                                                        $error={errors.microdistrict_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.street') + " *"}>
                            <Controller name="street_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={streetsChange} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!districtIdChange ? streetsLoadingChange : false}
                                                        isDisabled={true}
                                                        $error={errors.street_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.house') + " *"}>
                            <Controller name="house_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={housesChange} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!streetIdChange ? housesLoadingChange : false}
                                                        isDisabled={true}
                                                        $error={errors.house_id_change}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.apartment')}>
                            <Controller name="apartment_id_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect options={apartmentsChange} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!houseIdChange ? apartmentsLoadingChange : false}
                                                        isDisabled={true}
                                                        $error={errors.apartment_id_change}
                                            />
                                        )}/>
                        </FormGroup>
                        <FormGroup label={t('labels.segment') + " *"}>
                            <Controller name="sector_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.bankbookSectorTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.sector_change}
                                                
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.subscriber_type')}>
                            <Controller name="type_change" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomAsyncSelect
                                                method={Dictionaries.bankbookUserTypes}
                                                onChange={onChange}
                                                value={value}
                                                $error={errors.type_change}
                                                isDisabled={true}
                                                enableLoading={true}
                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.contact_person')}>
                            <Input type="text" {...register('name_change', {disabled: true})} $error={errors.name_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.phone_number')}>
                            <Input type="tel" {...register('phone_change', {disabled: true})} $error={errors.phone_change}/>
                        </FormGroup>
                        <FormGroup label={t('labels.note')}>
                            <TextArea {...register('note_change', {disabled: true})} $error={errors.note_change}/>
                        </FormGroup>
                    </div>
                    </div>
                    <div>
                        <FormGroup label={t('labels.change_note')}>
                            <Input type="text" {...register('change_note', {disabled: true})}/>
                        </FormGroup>
                        {info?.reject_reason && 
                            <FormGroup label={t('modals.decline_reason.title')}>
                                <Input type="text" {...register('reject_reason', {disabled: true})}/>
                            </FormGroup>
                        }
                    </div> 
                </form>
        </>
    );
};

export default ModelChangesBankbookEdit;