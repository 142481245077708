import React, {useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ModalComponent from "../../../../components/ModalComponent";
import FormGroup from "../../../../components/FormGroup";
import TextArea from "../../../../components/TextArea";
import Button from "../../../../components/Button";
import {useForm} from "react-hook-form";

const DeclineModal = ({method, closeModal, successCallback, indicationId, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const {handleSubmit, register, reset} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        setTimeout(async () => {
            const res = await method(indicationId, data);

            res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

            if (res.statusCode === 200) {
                reset();
                successCallback();
            }

            setLoading(false);
        }, 500)
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.decline_reason.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <TextArea {...register('text')} required/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.send')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default DeclineModal;