import React from 'react';
import styled, {css} from "styled-components";

const El = styled.span`
  font-size: 12px;
  margin-top: 5px;
  
  ${props => props.$error && css`
    color: var(--error)
  `}
`

const InputMessage = (props) => {
    return (
        <El {...props}/>
    );
};

export default InputMessage;