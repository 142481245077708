import React, {useState} from 'react';
import AuthLayout from "../../layouts/AuthLayout";
import WhiteBlock from "../../components/WhiteBlock";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import CustomLink from "../../components/CustomLink";
import routePaths from "../general/routePaths";
import Button from "../../components/Button";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import AuthServices from "../../services/general/authServices";
import {toast} from "react-toastify";

const ForgotPassword = () => {
    const {handleSubmit, register} = useForm();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const {t} = useTranslation();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.sendCode(data);
        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            history.push({pathname: routePaths.reset_password.index, search: '?email=' + data.email})
        }
    }

    return (
        <AuthLayout>
            <WhiteBlock style={{padding: 72}}>
                <h1 className="headline-4">{t('pages.authorization.password_recovery')}</h1>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? 'isLoading' : ''}>
                    <FormGroup label={t('labels.enter_email')}>
                        <Input type="email" {...register("email")} required/>
                    </FormGroup>
                    <div className="mb-10">
                        <Button $fluid>{t('labels.send_code')}</Button>
                    </div>
                    <div className="text-center">
                        <CustomLink onClick={() => history.goBack()}>{t('labels.cancel')}</CustomLink>
                    </div>
                </form>
            </WhiteBlock>
        </AuthLayout>
    );
};

export default ForgotPassword;