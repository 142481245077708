import React from 'react';
import styled, {css} from "styled-components";

const El = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  
  ${props => props.$color === 'green' && css`
    background-color: var(--success);
    box-shadow: 0 2px 7px rgba(87, 235, 129, 0.5);
  `}
  
  ${props => props.$color === 'red' && css`
    background-color: var(--error);
    box-shadow: 0 2px 7px rgba(235, 87, 87, 0.5);
  `}
`

const DeviceIndicator = (props) => {
    return (
        <El {...props}/>
    );
};

export default DeviceIndicator;