import React, { useEffect, useState } from 'react'
import SecondaryLink from '../../../../components/SecondaryLink'
import { useTranslation } from 'react-i18next'
import ActionBtn from '../../../../components/ActionBtn'
import routePaths from '../../../../navigation/analiticOperator/routePaths'
import { IndicationChildTh, IndicationContentDiv, IndicationMinContentWrapper, IndicationMinContentWrapper2, IndicationSubContentWrapper, IndicationSubThDiv, IndicationSubThDiv2, IndicationSubWrapper } from '../../../../components/IndicationsComponents'
import StatusSelect from '../../../../components/StatusSelect'

const IndicationsGrid = ({data, handleRemove, handleApprove, handleDecline, fetchItems, checkedList}) => {
    const [state, setState] = useState()

    const {t} = useTranslation()

    const {id} = data;

    const [open, setOpen] = useState();

    useEffect(() => {
        if (data) {
            setState(data)
        }
    }, [data])

    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        navigator.clipboard.writeText(value)
    };

  return (
    state ?
        <div className={open ? 'back-white' : ""}>
            <IndicationChildTh onClick={() => setOpen(!open)} gray={open}>
                <IndicationContentDiv>
                    {state.bankbook_number
                        ? 
                        <>
                            <SecondaryLink to={routePaths.bankbooks.edit.index(state.bankbook_id)}>{state.bankbook_number}</SecondaryLink>
                            <i className='icon-copy' onClick={(e) => copyToClipboard(e, state.bankbook_number)}></i>
                        </>
                        : "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.bankbook_name || "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.address || "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.people ? <><i className='icon-noun-picture-1166736-2'></i> {state.people}</> : "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.device_count ? <><i className='icon-noun-picture-1166736-1'></i> {state.device_count}</> : "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.pr_total_indication_date ? <>{state.pr_total_indication_date}</> : "-"}<span className='wd-10'/>
                    {state.pr_total_indication_value ? <><i className='icon-noun-picture-1166736-11'></i> {state.pr_total_indication_value}</> : "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.cr_total_indication_date ? <>{state.cr_total_indication_date}  </> : "-"}<span className='wd-10'/> 
                    {state.cr_total_indication_value ? <><i className='icon-noun-picture-1166736-11'></i> {state.cr_total_indication_value}</> : "-"}
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.is_system ? 
                        <i className='icon-robot' style={{color: state.status?.id === 1 ? '#14A38B' : '#B3B3B3'}}></i>
                        :
                        <i className='icon-noun-picture-1166736-2' style={{color: state.status?.id === 1 ? '#14A38B' : state.status?.id === 2 ? '#FF7171' : '#B3B3B3'}}></i>
                    }
                </IndicationContentDiv>
                <IndicationContentDiv>
                    {state.status && <StatusSelect state={state} id={id} handleApprove={handleApprove} handleDecline={handleDecline} handleRemove={handleRemove}/>}
                </IndicationContentDiv>
            </IndicationChildTh>
            {open &&
            <>
                <IndicationSubWrapper>
                    <IndicationSubThDiv>
                        {t('labels.device_type')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.area')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.count_number')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.seal_number')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.modem_number')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.next_check')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.pr_value')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.value')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv>
                        {t('labels.photo')}
                    </IndicationSubThDiv>
                    <IndicationSubThDiv/>
                </IndicationSubWrapper>
                {state.indications.map((item, index) =>
                    <IndicationSubContentWrapper key={index}>
                        <IndicationContentDiv>
                            {item.device_resource_type_color ?
                                <span className='td-span' style={{backgroundColor: item.device_resource_type_color, color: 'white'}}>{item.device_resource_type || "-"}
                                    <img src={item.device_resource_type_image}></img>
                                </span>
                                :
                                <span>{item.device_resource_type || "-"}</span>
                            }
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.device_area_type || "-"}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.device_number || "-"}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.device_seal_number || "-"}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.device_modem_number ? <i className='icon-noun-wifi-5321212-2'></i> : <i className='icon-noun-no-wifi-5321212-1'></i>}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.device_next_check || "-"}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.pr_value || "-"}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.value || "-"}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            {item.images_exist ? <i className='icon-noun-picture-1166736-1-1 color-accent'></i> : <i className='icon-noun-picture-1166736-1-1'></i>}
                        </IndicationContentDiv>
                        <IndicationContentDiv>
                            <ActionBtn className="icon-pencil" to={routePaths.indications.edit.information(item.id)}/>
                        </IndicationContentDiv>
                    </IndicationSubContentWrapper>
                )}
                <IndicationMinContentWrapper>
                    <IndicationSubThDiv2>
                        {t('labels.user')}
                    </IndicationSubThDiv2>
                    <IndicationSubThDiv2>
                        {t('labels.note')}
                    </IndicationSubThDiv2>
                    <IndicationSubThDiv2>
                        {t('labels.documents')}
                    </IndicationSubThDiv2>
                </IndicationMinContentWrapper>
                <IndicationMinContentWrapper2>
                    <IndicationContentDiv>
                        {state.user ?
                            <>
                                {state.user.name}
                                {state.user.type }
                            </>
                                :
                            "-"
                        }
                    </IndicationContentDiv>
                    <IndicationContentDiv>
                        {state.note || "-"}
                    </IndicationContentDiv>
                    <IndicationContentDiv>
                        {state.documents?.length >=1 ?
                            state.documents.map((doc, index) =>
                                <a className='link-accent' key={index} href={doc.pdf} target="_blank" download>{doc.name}</a>
                            )
                            : "-"
                        }
                    </IndicationContentDiv>
                </IndicationMinContentWrapper2>
            </>}
        </div>
    :
        <></>
  )
}

export default IndicationsGrid