import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import routePaths from "../../../navigation/admin/routePaths";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import LaboratoriesFilter from "./components/LaboratoriesFilter";
import useFetchItems from "../../../hooks/useFetchItems";
import LaboratoriesServices from "../../../services/admin/laboratoriesServices";
import Expand from "../../../components/Expand";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";

const Laboratories = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.laboratories.title')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(LaboratoriesServices.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, cities}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        <Expand height={51}>
                            {cities.map(({name}) => name).join(', ')}
                        </Expand>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.title'), t('labels.cities'), ''],
        sizes: ['10%', '40%', '40%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.laboratories.title')}</h1>
                <Button $small $withIcon variant="outline" to={routePaths.laboratories.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
            </HeadingWithButtons>
            <LaboratoriesFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    vAlign="top"
                                    items={formattedItems}
                                    editLink={routePaths.laboratories.edit.information}
                                    removeMethod={LaboratoriesServices.delete}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Laboratories;