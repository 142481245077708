import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  [class*="headline"] {
    margin: 1em 0;
    flex-grow: 1;
  }
  
  &>*:not(:last-child) {
    margin-right: 20px;
  }
`;

const HeadingWithButtons = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    );
};

export default HeadingWithButtons;