import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 8px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: var(--shadow);
  
  a {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-700);
    transition: .2s;
    padding: 10px 16px;
    border-radius: 100px;
    
    &:not(:last-child) {
      margin-right: 12px;
    }
    
    &:hover {
      color: inherit;
    }
    
    &.active {
      background-color: var(--primary);
      color: #fff;
    }
  }
`

const TabLinks = ({children, ...props}) => {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
};

export default TabLinks;