import React, {useEffect} from 'react';
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import InputMessage from "../../../components/InputMessage";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import {useForm} from "react-hook-form";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import ProfileServices from "../../../services/general/ProfileServices";
import {useAuth} from "../../../navigation/Auth/ProvideAuth";
import useUpdate from "../../../hooks/useUpdate.hook";
import {useTranslation} from "react-i18next";

const Info = () => {
    const {t} = useTranslation();
    const {userData, setInfo} = useAuth();

    const {handleSubmit, register, setValue, formState: {errors}} = useForm()

    const {info , loading: infoLoading} = useFetchInfo(ProfileServices.view)

    useEffect(() => {
        if (info) {
            for (const [key, value] of Object.entries(info)) {
                key && setValue(key, value)
            }
        }
    }, [info, setValue])

    const successCallback = res => {
        const newInfo = {
            ...userData,
            name: res?.content?.name
        }

        setInfo(newInfo)
    }

    const {onSubmit, loading} = useUpdate(ProfileServices.update, null, false, successCallback)

    return (
        <WhiteBlock>
            <form onSubmit={handleSubmit(onSubmit)} className={loading || infoLoading ? "isLoading" : ""}>
                <FormGroup label={`${t('labels.fullName')} *`}>
                    <Input type="text" {...register('name', {required: true})} $error={errors.name}/>
                    {errors.name && errors.name.type === 'required' &&
                    <InputMessage $error>{t('messages.required')}</InputMessage>}
                </FormGroup>
                <FormGroup label={`${t('labels.email')} *`}>
                    <Input type="text" {...register('email', {
                        required: true,
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('messages.invalid_email')
                        }
                    })} $error={errors.email}/>
                    {errors.email && errors.email.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                    {errors.email && <InputMessage $error>{errors.email.message}</InputMessage>}
                </FormGroup>
                <FormGroup label={`${t('labels.phone_number')}`}>
                    <Input type="tel" {...register('phone')}/>
                </FormGroup>
                <div className="text-right">
                    <Button type="submit">{t('labels.save')}</Button>
                </div>
            </form>
        </WhiteBlock>
    );
};

export default Info;