import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../components/FormGroup";
import Button from "../../../components/Button";
import ModalComponent from "../../../components/ModalComponent";
import CustomSelect from "../../../components/CustomSelect";
import Input from "../../../components/Input";
import Dictionaries from "../../../services/admin/dictionaries";
import AsyncPaginateSelect from "../../../components/AsyncPaginateSelect";


const AttachBankBookModal = ({method, closeModal, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();

    

    const {t} = useTranslation();
    const {handleSubmit, register, setValue, control, watch, reset} = useForm();

    const cityId = watch('city', false);
    const companyId = watch('company', false);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState();
    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState();

    const fetchCities = useCallback(async () => {
        setCitiesLoading(true);

        const res = await Dictionaries.cities();

        if (res.statusCode === 200) {
            setCities(res.content);
        } else {
             toast.error(res.message)
        }

        setCitiesLoading(false)
    }, []);

    const fetchCompanies = useCallback(async () => {
        if (cityId) {
            setCompaniesLoading(true);

            const res = await Dictionaries.companies({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setCompanies(res.content);
                setValue('company', false);
                setValue('bankbook_id', false);
                setValue('address', null);
            } else {
                toast.error(res.message)
            }

            setCompaniesLoading(false);
        }
    }, [setValue, cityId])

    useEffect(() => {
        fetchCities();
    }, [fetchCities])

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies])

    const onBankbookChange = async (data) => {
        const res = await Dictionaries.getBankbookAddress(data.value);

        setValue('address', res.content.address);
    }

    const onSubmit = async (data) => {
        setLoading(true);

        const res = await method(id, {bankbook_id: data.bankbook_id});

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.bankbook_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!!cities}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? companiesLoading || citiesLoading : false} isDisabled={!!!cityId || companiesLoading || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook')} key={companyId}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <AsyncPaginateSelect onChange={onChange}
                                                             loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `city_id=${cityId}&company_id=${companyId}`})}
                                                             value={value}
                                                             changeCallback={onBankbookChange}
                                                             isDisabled={!!!companyId}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.address')}>
                        <Input type="text" {...register('address')} disabled/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachBankBookModal;