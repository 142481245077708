import React from 'react';
import styled from "styled-components";
import logo from '../images/logo-white1.png'

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  
  &>div {
    width: 50%;
    flex-shrink: 0;
    height: 100%;
  }
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 175px;
  
  background: #1FA2FF;
  align-items: flex-start;
  
  img {
    width: 228px;
    display: block;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  padding: 100px;
  background-color: #F6F8F9;
`

const AuthLayout = ({children}) => {
    return (
        <Wrapper>
            <Left>
                <img src={logo} alt=""/>
            </Left>
            <Right>
                {children}
            </Right>
        </Wrapper>
    );
};

export default AuthLayout;