import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/CallOperator/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useFetchItems from "../../../hooks/useFetchItems";
import SecondaryLink from "../../../components/SecondaryLink";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import BankbooksServices from "../../../services/callOperator/bankbooksServices";

const BankbookEditDocuments = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, loading: fetchLoading} = useFetchInfo(BankbooksServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.bankbooks.index,
                title: t('sidebar.bankbooks.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.number ? " - " + info?.number : "")
            },
            {
                path: null,
                title: t('labels.documents')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(BankbooksServices.documents.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, pdf_ru, pdf_kk, created_at}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        <SecondaryLink target="_blank" rel="noreferrer noopener" to={pdf_ru}>pdf_ru</SecondaryLink>,
                        <SecondaryLink target="_blank" rel="noreferrer noopener" to={pdf_kk}>pdf_kk</SecondaryLink>,
                        created_at
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.name'), t('labels.pdf_ru'), t('labels.pdf_kk'), t('labels.date')],
        sizes: ['10%', '20%', '20%','20%', '30%' ]
    }

    return (
        <WhiteBlock>
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
        </WhiteBlock>
    );
};

export default BankbookEditDocuments;