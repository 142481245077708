const routePaths = {
    home: {
        index: "/"
    },
    login: {
        index: "/login"
    },
    forgot_password: {
        index: '/forgot-password'
    },
    reset_password: {
        index: '/reset-password'
    },
    profile: {
        index: '/profile'
    },
    privacy_policy: {
        index: '/privacy-policy'
    }
}

export default routePaths;