import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm, Controller} from "react-hook-form";
import routePaths from "../../../../navigation/admin/routePaths";
import useStore from "../../../../hooks/useStore.hook";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/admin/dictionaries";
import LabSpecialistsServices from "../../../../services/admin/labSpecialistsServices";
import {useLocation} from "react-router-dom";
import InputMessage from "../../../../components/InputMessage";

const LabSpecialistCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: routePaths.users.children.lab_specialists.index,
                title: t('sidebar.users.children.lab_specialists')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const {onSubmit, loading} = useStore(LabSpecialistsServices.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.fullName') + " *"}>
                        <Input type="text" {...register('name', {required: true})} $error={errors.name}/>
                        {errors.name && errors.name.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number') + " *"}>
                        <Input type="tel" {...register('phone', {required: true})} $error={errors.phone}/>
                        {errors.phone && errors.phone.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.email')}>
                        <Input type="text" {...register('email', {
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('messages.invalid_email')
                            }
                        })} $error={errors.email}/>
                        {errors.email && <InputMessage $error>{errors.email.message}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.laboratory') + " *"}>
                        <Controller defaultValue={state ? String(state?.laboratory_id) : null} name="laboratory_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.laboratories}
                                            onChange={onChange}
                                            value={value}
                                            isDisabled={state?.laboratory_id}
                                            $error={errors.laboratory_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.laboratory_id && errors.laboratory_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default LabSpecialistCreate;