import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Dictionaries from "../../../../services/admin/dictionaries";
import ModalComponent from "../../../../components/ModalComponent";
import FormGroup from "../../../../components/FormGroup";
import CustomSelect from "../../../../components/CustomSelect";
import TextArea from "../../../../components/TextArea";
import Input from "../../../../components/Input";
import InputMessage from "../../../../components/InputMessage";
import Button from "../../../../components/Button";

const AddServiceModal = ({laboratoryId, serviceInfo, addService, closeModal, ...props}) => {
    const {t} = useTranslation();

    const {handleSubmit, register, setValue, control, watch, formState: {errors}, reset} = useForm();

    const serviceId = watch('service_id', false);
    const amount = watch('amount', 1);

    const [services, setServices] = useState();
    const [price, setPrice] = useState(0);

    const fetchServices = useCallback(async () => {
        const res = await Dictionaries.laboratoryServices({queryString: `laboratory_id=${laboratoryId}`});

        if (res.statusCode === 200) {
            setServices(res.content);
        } else {
             toast.error(res.message)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchServices();
    }, [fetchServices])

    useEffect(() => {
        if (serviceInfo) {
            setValue('service_id', serviceInfo.id)
            setValue('amount', serviceInfo.amount)
        }
    }, [serviceInfo?.id, serviceInfo?.amount]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (serviceId && services) {
            const selectedService = services.find(item => item.value === serviceId);
            setValue('description', selectedService.description || t('labels.no_description') )
            setPrice(Number(selectedService.price) * Number(amount))
        }
    }, [serviceId, services, t, setValue, amount])

    const onSubmit = (data) => {
        const {label, price} = services.find(item => String(item.value) === serviceId);
        const {service_id, ...rest} = data
        addService({
            price, name: label, id: service_id, ...rest
        })
        reset()
        setPrice(0)
        closeModal()
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.service_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.service')}>
                        <Controller name="service_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={services} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!!services}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.service_description')}>
                        <TextArea {...register("description")} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.amount')}>
                        <Input type="number" defaultValue="1" {...register("amount", {min: 1, required: true})} $error={errors.amount} autoComplete="off"/>
                        {errors.amount && errors.amount.type === "min" &&
                        <InputMessage $error>{t('messages.minNumber', {min: 1})}</InputMessage>}
                        {errors.amount && errors.amount.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.price_tng')}>
                        <span>{price} тг</span>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{serviceInfo ? t('labels.save') : t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AddServiceModal;