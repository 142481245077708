import React from 'react';
import styled from "styled-components";

const Container = styled.span`
  font-size: 14px;
  display: inline-block;
  padding: 7px 16px;
  line-height: 20px;
  border-radius: 20px;
  color: #fff;
  background-color: ${props => props.$color || 'var(--accent)'}
`

const StatusLabel = ({children, color, ...props}) => {
    return (
        <Container $color={color} {...props}>
            {children}
        </Container>
    );
};

export default StatusLabel;