import React from 'react';
import styled,  {css} from "styled-components";

const TableEl = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const Table = ({children, ...props}) => {
    return (
        <TableEl {...props}>
            {children}
        </TableEl>
    );
};

const Thead = ({children, ...props}) => {
    return (
        <thead {...props}>
            {children}
        </thead>
    )
}

const Tbody = React.forwardRef(({children, ...props}, ref) => {
    return (
        <tbody ref={ref} {...props}>
        {children}
        </tbody>
    )
})

const TrEl = styled.tr`
  transition: .3s;
  
  &.loading {
    opacity: .4;
    pointer-events: none;
  }
`

const Tr = React.forwardRef(({children, ...props}, ref) => {
    return (
        <TrEl ref={ref} {...props}>
            {children}
        </TrEl>
    )
})

const ThEl = styled.th`
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 10px;
  border-bottom: 1px solid var(--gray-300);
`;

const Th = ({children, ...props}) => {
    return (
        <ThEl {...props}>
            {children}
        </ThEl>
    )
}

const StyledTh = styled(Th)`
  font-weight: 600;
  color: var(--gray-400);
  text-transform: uppercase;
  font-size: 12px;
`

const TdEl = styled.td`
  font-size: 14px;
  padding: 10px 10px;
  border-bottom: 1px solid var(--gray-300);
  line-height: 24px;
`;

const Td = ({children, ...props}) => {
    return (
        <TdEl {...props}>
            {children}
        </TdEl>
    )
}

const DivEl = styled.div`
  padding: 12px;
  font-weight: 500;
  font-size: 21px;
  display:flex;
  justify-content: space-between;
  flex-direction:column;
  border: 1px solid #E8E8E8;
  background: #FFFFFF;
  max-height:260px;
  overflow:hidden;
  overflow-y:auto;

  &::-webkit-scrollbar {
    width: 8px;
    }
  &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;
        border:1px solid #f1f1f1;
    }
  ${props => props.$none && css`
    background: #f9f7f7;
  `}
  p{
    cursor:pointer;
    font-size: 14px;
    border-radius: 4px;
    padding: 3px 4px;
    width: fit-content;
    margin: 12px 0 0;
    color: #FF9F2D;
    background: linear-gradient(0deg, rgba(255, 150, 27, 0.1), rgba(255, 150, 27, 0.1)), #FFFFFF;
  }
  .done{
    color: #00BA34;
    background: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #FFFFFF;
  }
  .frozen{
    color: #585757;
    background: #E8E8E8;
  }
  .not-done{
    color: #E92C2C;
    background: linear-gradient(0deg, rgba(255, 59, 59, 0.1), rgba(255, 59, 59, 0.1)), #FFFFFF;
  }
`;

const DivElem = ({children, ...props}) => {
    return (
        <DivEl {...props}>
            {children}
        </DivEl>
    )
}

const DivHeadEl = styled.div`
border: 1px solid #E8E8E8;
  padding: 12px;
  background: #FFFFFF;
`;

const GridEl = styled.div`
color: #969696;
font-weight: 500;
    width:100%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    
    border-radius: 8px;
    grid-template-rows: 44px 1fr 1fr 1fr 1fr 1fr;
`

const DivHead = ({children, ...props}) => {
    return (
        <DivHeadEl {...props}>
            {children}
        </DivHeadEl>
    )
}

const GridCal = ({children, ...props}) => {
    return (
        <GridEl {...props}>
            {children}
        </GridEl>
    )
}

const Pelem = ({children, ...props}) => {
    return (
        <GridEl {...props}>
            {children}
        </GridEl>
    )
}

export {Table, Tr, Thead, Tbody, Td, Th, StyledTh, DivElem, DivHead, GridCal, Pelem};