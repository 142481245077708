import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import ModelChangesServices from "../../../../services/admin/modelChangesServices";
import SecondaryLink from "../../../../components/SecondaryLink";
import Expand from "../../../../components/Expand";
import ModelChangesBankbooksFilter from './components/ModelChangesBankbooksFilter';
import StatusLabel from '../../../../components/StatusLabel';
import { statusColors, statuses } from '../../../../constants';

const ModelChangesBankbooks = ({statusId = "", statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('labels.change_bankbooks')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(ModelChangesServices.bankbooks.index, statusId, {with_image: statusId === "" ? 0 : 1})
    
    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, user, creator, updater, company, address, status}) => {
                return {
                    id,
                    fields: [
                        id,
                        number,
                        <React.Fragment>
                            {user?.name}
                            {user?.phone && <>
                                <br/>
                                <SecondaryLink href={`tel:${user.phone}`}>{user.phone}</SecondaryLink>
                            </>}
                        </React.Fragment>,
                        company?.name,
                        <Expand height={50}>{address}</Expand>,
                        creator?.name,
                        updater?.name,
                        <StatusLabel color={statusColors[status]}>{statuses[status]}</StatusLabel>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.account_number'), t('labels.data'), t('labels.resource_company'),  t('labels.address'), t('labels.operator'), t('labels.administrator'),t('labels.status'), ''],
        sizes: ['5%', '13%', '13%', '15%', '15%', '10%','10%','10%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('labels.change_bankbooks')}</h1>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        
                    </div>
                    <div className="col-auto">
                        <Button $small $withIcon variant="outline" to={routePaths.bankbooks.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
                    </div>
                </div>
            </HeadingWithButtons>
            <ModelChangesBankbooksFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.model_сhanges.edit.index}
                                    removeMethod={ModelChangesServices.bankbooks.delete}
                                    vAlign={"top"}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default ModelChangesBankbooks;