const routePaths = {
    home: {
        index: "/"
    },
    sectors: {
        index: "/sectors",
        create: "/sectors/create",
        edit: {
            index: (id) => `/sectors/${id ? id : ":id"}/edit`,
            information: (id) => `/sectors/${id ? id : ":id"}/edit/information`,
            controllers: (id) => `/sectors/${id ? id : ":id"}/edit/controllers`,
            operators: (id) => `/sectors/${id ? id : ":id"}/edit/operators`,
            streets: (id) => `/sectors/${id ? id : ":id"}/edit/streets`,
            houses: (id) => `/sectors/${id ? id : ":id"}/edit/houses`
        }
    },
    addresses: {
        index: "/addresses",
        children: {
            regions: {
                index: "/addresses/regions",
                create: "/addresses/regions/create",
                edit: {
                    index: (id) => `/addresses/regions/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/regions/${id ? id : ":id"}/edit/information`,
                    cities: (id) => `/addresses/regions/${id ? id : ":id"}/edit/cities`
                }
            },
            cities: {
                index: "/addresses/cities",
                create: "/addresses/cities/create",
                edit: {
                    index: (id) => `/addresses/cities/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/cities/${id ? id : ":id"}/edit/information`,
                    districts: (id) => `/addresses/cities/${id ? id : ":id"}/edit/districts`
                }
            },
            districts: {
                index: "/addresses/districts",
                create: "/addresses/districts/create",
                edit: {
                    index: (id) => `/addresses/districts/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/districts/${id ? id : ":id"}/edit/information`,
                    microdistricts: (id) => `/addresses/districts/${id ? id : ":id"}/edit/microdistricts`,
                    streets: (id) => `/addresses/districts/${id ? id : ":id"}/edit/streets`
                }
            },
            microdistricts: {
                index: "/addresses/microdistricts",
                create: "/addresses/microdistricts/create",
                edit: {
                    index: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit/information`,
                    streets: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit/streets`
                }
            },
            streets: {
                index: "/addresses/streets",
                create: "/addresses/streets/create",
                edit: {
                    index: (id) => `/addresses/streets/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/streets/${id ? id : ":id"}/edit/information`,
                    houses: (id) => `/addresses/streets/${id ? id : ":id"}/edit/houses`,
                    sectors: (id) => `/addresses/streets/${id ? id : ":id"}/edit/sectors`,
                    controllers: (id) => `/addresses/streets/${id ? id : ":id"}/edit/controllers`
                }
            },
            houses: {
                index: "/addresses/houses",
                create: "/addresses/houses/create",
                edit: {
                    index: (id) => `/addresses/houses/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/houses/${id ? id : ":id"}/edit/information`,
                    entrances: (id) => `/addresses/houses/${id ? id : ":id"}/edit/entrances`,
                    apartments: (id) => `/addresses/houses/${id ? id : ":id"}/edit/apartments`,
                    bankbooks: (id) => `/addresses/houses/${id ? id : ":id"}/edit/bankbooks`,
                    sectors: (id) => `/addresses/houses/${id ? id : ":id"}/edit/sectors`,
                    controllers: (id) => `/addresses/houses/${id ? id : ":id"}/edit/controllers`
                }
            },
            entrances: {
                index: "/addresses/entrances",
                create: "/addresses/entrances/create",
                edit: {
                    index: (id) => `/addresses/entrances/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/information`,
                    floors: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/floors`,
                    apartments: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/apartments`
                }
            },
            floors: {
                index: "/addresses/floors",
                create: "/addresses/floors/create",
                edit: {
                    index: (id) => `/addresses/floors/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/floors/${id ? id : ":id"}/edit/information`,
                    apartments: (id) => `/addresses/floors/${id ? id : ":id"}/edit/apartments`
                }
            },
            apartments: {
                index: "/addresses/apartments",
                create: "/addresses/apartments/create",
                edit: {
                    index: (id) => `/addresses/apartments/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/information`,
                    bankbooks: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/bankbooks`,
                    controllers: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/controllers`
                }
            }
        }
    },
    users: {
        index: "/users",
        children: {
            all: {
                index: "/users/all"
            },
            subscribers: {
                index: "/users/subscribers",
                create: "/users/subscribers/create",
                edit: (id) => `/users/subscribers/${id ? id : ":id"}/edit`,
                edit_bankbooks: (id) => `/users/subscribers/${id ? id : ":id"}/edit/bankbooks`
            },
            operators: {
                index: "/users/operators",
                create: "/users/operators/create",
                edit: (id) => `/users/operators/${id ? id : ":id"}/edit`
            },
            controllers: {
                index: "/users/controllers",
                create: "/users/controllers/create",
                edit: (id) => `/users/controllers/${id ? id : ":id"}/edit`
            },
            lab_administrators: {
                index: "/users/lab-administrators",
                create: "/users/lab-administrators/create",
                edit: (id) => `/users/lab-administrators/${id ? id : ":id"}/edit`
            },
            lab_specialists: {
                index: "/users/lab-specialists",
                create: "/users/lab-specialists/create",
                edit: (id) => `/users/lab-specialists/${id ? id : ":id"}/edit`
            },
            administrators: {
                index: "/users/administrators",
                create: "/users/administrators/create",
                edit: (id) => `/users/administrators/${id ? id : ":id"}/edit`
            },
            super_administrators: {
                index: "/users/super-administrators",
                create: "/users/super-administrators/create",
                edit: (id) => `/users/super-administrators/${id ? id : ":id"}/edit`
            },
            analitic_operators: {
                index: "/users/analitic-operators",
                create: "/users/analitic-operators/create",
                edit: (id) => `/users/analitic-operators/${id ? id : ":id"}/edit`
            },
            call_operators: {
                index: "/users/call-operators",
                create: "/users/call-operators/create",
                edit: (id) => `/users/call-operators/${id ? id : ":id"}/edit`
            }
        }
    },
    bankbooks: {
        index: "/bankbooks",
        create: "/bankbooks/create",
        edit: {
            index: (id) => `/bankbooks/${id ? id : ":id"}/edit`,
            information: (id) => `/bankbooks/${id ? id : ":id"}/edit/information`,
            devices: (id) => `/bankbooks/${id ? id : ":id"}/edit/devices`,
            documents: (id) => `/bankbooks/${id ? id : ":id"}/edit/documents`,
        }
    },
    devices: {
        index: "/devices",
        create: "/devices/create",
        edit: {
            index: (id) => `/devices/${id ? id : ":id"}/edit`,
            information: (id) => `/devices/${id ? id : ":id"}/edit/information`,
            indications: (id) => `/devices/${id ? id : ":id"}/edit/indications`,
            notifications: (id) => `/devices/${id ? id : ":id"}/edit/notifications`,
            documents: (id) => `/devices/${id ? id : ":id"}/edit/documents`,
            histories: (id) => `/devices/${id ? id : ":id"}/edit/histories`,
        }
    },
    indications: {
        index: "/indications",
        create: "/indications/create",
        edit: {
            index: (id) => `/indications/${id ? id : ":id"}/edit`,
            information: (id) => `/indications/${id ? id : ":id"}/edit/information`,
            comments: (id) => `/indications/${id ? id : ":id"}/edit/comments`,
            history: (id) => `/indications/${id ? id : ":id"}/edit/history`,
        },
        children: {
            all: {
                index: "/indications/all"
            },
            on_check: {
                index: "/indications/on_check"
            },
            approved: {
                index: "/indications/approved"
            },
            declined: {
                index: "/indications/declined"
            }
        }
    },
    data_import: {
        index: "/data-import",
        children: {
            addresses: {
                index: "/data-import/addresses"
            },
            devices: {
                index: "/data-import/devices"
            },
            indications: {
                index: "/data-import/indications"
            },
            devices_to_delete: {
                index:"/data-import/devices-to-delete"
            }
        }
    }
}

export default routePaths;