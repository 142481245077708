import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import TextArea from "../../../components/TextArea";
import routePaths from "../../../navigation/admin/routePaths";
import InputMessage from "../../../components/InputMessage";
import DictionariesServices from "../../../services/admin/dictionariesServices";
import Checkbox from "../../../components/Checkbox";

const ApplicationRejectReasonCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, formState: {errors}} = useForm();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.dictionaries.index,
                title: t('sidebar.dictionaries.title')
            },
            {
                path: routePaths.dictionaries.children.application_reject_reason.index,
                title: t('sidebar.dictionaries.children.application_reject_reason')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {onSubmit, loading} = useStore(DictionariesServices.applicationRejectReason.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.description_kk')}>
                        <TextArea {...register('description_kk')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.description_ru')}>
                        <TextArea {...register('description_ru')}/>
                    </FormGroup>
                    <div className="mb-20">
                        <Checkbox {...register('is_published')}>{t('labels.is_published')}</Checkbox>
                    </div>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default ApplicationRejectReasonCreate;