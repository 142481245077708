import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import routePaths from "../../../navigation/admin/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import NotificationsServices from "../../../services/admin/notificationsServices";
import TextArea from "../../../components/TextArea";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import AttachUsersModal from "./components/AttachUsersModal";
import ItemsTable from "../../../components/ItemsTable";
import TablePreloader from "../../../components/TablePreloader";
import InputMessage from "../../../components/InputMessage";

const NotificationCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.notifications.index,
                title: t('sidebar.notifications.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t]);

    const {onSubmit, loading} = useStore(NotificationsServices.store);

    const [attachModal, setAttachModal] = useState();
    const [users, setUsers] = useState([]);

    const [formattedUsers, setFormattedUsersLoading] = useState();

    register('users', { required: true});

    useEffect(() => {
        if (users) {
            const newItems = users.map(({id, name, phone, email}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        phone,
                        email
                    ]
                }
            })

            setFormattedUsersLoading(newItems);

            const values = users.map(item => item.id)

            setValue('users', values)
        }
    }, [users, setValue]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.name'), t('labels.phone_number'), t('labels.email'), ''],
        sizes: ['10%', '30%', '30%', '30%', '']
    }

    const addUsers = (newItems) => {
        setUsers(prev => {
            const newState = [...prev, ...newItems];
            return newState.filter((user, index, array) => array.findIndex(u => u.id === user.id) === index);
        })
    }

    const removeUser = (id) => {
        const newUsers = [...users];
        const removeIndex = newUsers.findIndex(item => item.id === id);
        newUsers.splice(removeIndex, 1);
        setUsers(newUsers);

        return Promise.resolve({
            statusCode: 200,
            message: "Success"
        });
    }

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <HeadingWithButtons>
                    <h3 className="headline-6 blue">{t('labels.receivers') + " *"}</h3>
                    <Button $small $withIcon variant="outline" onClick={() => setAttachModal(true)}><i className="icon-plus"/><span>{t('labels.add')}</span></Button>
                </HeadingWithButtons>
                {formattedUsers
                    ? <>
                        <ItemsTable
                            {...{config: tableConfig, loading}}
                            items={formattedUsers}
                            removeMethod={removeUser}
                            fetchItems={() => false}
                        />
                        {errors.users && errors.users.type === 'required' &&
                        <>
                            <div className="mb-20"/>
                            <InputMessage $error>{t('messages.required')}</InputMessage>
                        </>}
                    </>
                    : <TablePreloader height={132}/>}
            </WhiteBlock>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.description_kk')}>
                        <TextArea {...register('description_kk')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.description_ru')}>
                        <TextArea {...register('description_ru')}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
            <AttachUsersModal
                isOpen={attachModal}
                closeModal={() => setAttachModal(false)}
                onRequestClose={() => setAttachModal(false)}
                method={NotificationsServices.getUsers}
                successCallback={addUsers}
            />
        </>
    );
};

export default NotificationCreate;