import http from "./http-common"
import {getData} from "../handlers";
import {apiAdminUrl} from "../CONSTANTS";

export default class TaskStatisticsServices {
    static index = (queryString) => {
        return getData(http, `/tasksPlan/statistic${queryString ? `?${queryString}` : ""}`);
    }

    static view = (queryString) => {
        return getData(http, `/tasksPlan/statistic/view${queryString ? `?${queryString}` : ""}`)
    }

    static exportByPage = (queryString) => {
        return getData(http, `/tasksPlan/statistic/exportByPage${queryString ? `?${queryString}` : ""}`);
    }

    static exportUrl = apiAdminUrl + '/tasksPlan/statistic/export'
}