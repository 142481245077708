import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import routePaths from "../../../navigation/operator/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomSelect from "../../../components/CustomSelect";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/operator/dictionaries";
import BankbooksServices from "../../../services/operator/bankbooksServices";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import {useAuth} from "../../../navigation/Auth/ProvideAuth";
import InputMessage from "../../../components/InputMessage";
import TextArea from "../../../components/TextArea";

const BankbookCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    const {userData} = useAuth();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.bankbooks.index,
                title: t('sidebar.bankbooks.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    

    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null)
    const microdistrictId = watch('microdistrict_id', null)
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);
    const apartmentId = watch('apartment_id', null);
    const sectorId = watch('sector', null);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const [microdistricts, setMicrodistricts] = useState();
    const [microdistrictsLoading, setMicrodistrictsLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const [apartments, setApartments] = useState();
    const [apartmentsLoading, setApartmentsLoading] = useState(true);

    const fetchCities = useCallback(async () => {
        setCitiesLoading(true);

        const res = await Dictionaries.cities({queryString: `company_id=${userData.company_id}`});

        if (res.statusCode === 200) {
            setCities(res.content);
        } else {
            toast.error(res.message)
        }

        setCitiesLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `company_id=${userData.companyId}&city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMicrodistricts = useCallback(async () => {
        if (districtId) {
            setMicrodistrictsLoading(true);

            const res = await Dictionaries.microdistricts({queryString: `company_id=${userData.companyId}&district_id=${districtId}`});

            if (res.statusCode === 200) {
                setMicrodistricts(res.content);

                if (!res.content.some(item => item.value === microdistrictId)) {
                    setValue('microdistrict_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoading(false);
        } else {
            setValue('microdistrict_id', null)
        }
    }, [setValue, districtId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        if (districtId) {
            setStreetsLoading(true);

            const microdistrictQuery = microdistrictId ? `&microdistrict_id=${microdistrictId}` : "";

            const res = await Dictionaries.streets({queryString: `company_id=${userData.companyId}&district_id=${districtId}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }
    }, [setValue, districtId, microdistrictId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchHouses = useCallback(async () => {
        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `company_id=${userData.companyId}&street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);

                if (!res.content.some(item => item.value === houseId)) {
                    setValue('house_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        } else {
            setValue('house_id', null)
        }
    }, [setValue, streetId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchApartments = useCallback(async () => {
        if (houseId) {
            setApartmentsLoading(true);

            const res = await Dictionaries.apartments({queryString: `company_id=${userData.companyId}&house_id=${houseId}`});

            if (res.statusCode === 200) {
                setApartments(res.content);

                if (!res.content.some(item => item.value === apartmentId)) {
                    setValue('apartment_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setApartmentsLoading(false);
        } else {
            setValue('apartment_id', null)
        }
    }, [setValue, houseId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        fetchMicrodistricts();
    }, [fetchMicrodistricts]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        fetchApartments();
    }, [fetchApartments]);

    useEffect(() => {
        if (sectorId) {
            setValue('type', sectorId === "1" ? "1" : "0")
        } else {
            setValue('type', null)
        }
    }, [sectorId, setValue])

    useEffect(() => {
        const {city_id, district_id, microdistrict_id, street_id, house_id, apartment_id} = {...state};
        city_id && setValue('city_id', String(city_id));
        district_id && setValue('district_id', String(district_id));
        microdistrict_id && setValue('microdistrict_id', String(microdistrict_id));
        street_id && setValue('street_id', String(street_id));
        house_id && setValue('house_id', String(house_id));
        apartment_id && setValue('apartment_id', String(apartment_id));
    }, [state, setValue])

    const {onSubmit, loading} = useStore(BankbooksServices.store);

    const customSubmit = (data) => {
        const {city_id, street_id, house_id, apartment_id, ...rest} = data;
        onSubmit({
            address_id: apartment_id || house_id,
            ...rest
        })
    }

    

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(customSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.account_number') + " *"}>
                        <Input type="text" {...register('number', {required: true})} $error={errors.number}/>
                        {errors.number && errors.number.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.city') + " *"}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={citiesLoading}
                                                      isDisabled={state?.city_id}
                                                      $error={errors.city_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.district') + " *"}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={districts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? districtsLoading : false}
                                                      isDisabled={!!!cityId || districtsLoading || state?.district_id}
                                                      $error={errors.district_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.district_id && errors.district_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.microdistrict')}>
                        <Controller name="microdistrict_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={microdistricts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!districtId ? microdistrictsLoading : false}
                                                      isDisabled={!!!districtId || microdistrictsLoading || state?.microdistrict_id || state?.house_id}
                                                      $error={errors.microdistrict_id}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.street') + " *"}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={streets} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!districtId ? streetsLoading : false}
                                                      isDisabled={!!!districtId || streetsLoading || state?.street_id}
                                                      $error={errors.street_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.street_id && errors.street_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.house') + " *"}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={houses} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!streetId ? housesLoading : false}
                                                      isDisabled={!!!streetId || housesLoading || state?.house_id}
                                                      $error={errors.house_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.house_id && errors.house_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.apartment')}>
                        <Controller name="apartment_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={apartments} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!houseId ? apartmentsLoading : false}
                                                      isDisabled={!!!houseId || apartmentsLoading || state?.apartment_id}
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.segment') + " *"}>
                        <Controller name="sector" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookSectorTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.sector}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.sector && errors.sector.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.subscriber_type') + " *"}>
                        <Controller name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookUserTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.type}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.type && errors.type.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.contact_person')}>
                        <Input type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input type="tel" {...register('phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.people')}>
                        <Input type="text" {...register('people')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.note')}>
                        <TextArea {...register('note')}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default BankbookCreate;