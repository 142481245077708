import http from "./http-common"
import {postData, getData} from "../handlers";

export default class ProfileServices {
    static view = () => {
        return getData(http, '/profile');
    }

    static update = (data) => {
        return postData(http, '/profile/update', data);
    }

    static updatePassword = (data) => {
        return postData(http, '/profile/updatePassword', data);
    }
}