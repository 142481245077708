import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import routePaths from "../../../navigation/operator/routePaths";
import useStore from "../../../hooks/useStore.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomSelect from "../../../components/CustomSelect";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/operator/dictionaries";
import CustomAsyncSelect from "../../../components/CustomAsyncSelect";
import DevicesServices from "../../../services/operator/devicesServices";
import {useAuth} from "../../../navigation/Auth/ProvideAuth";
import InputMessage from "../../../components/InputMessage";

const DeviceCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.devices.index,
                title: t('sidebar.devices.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    

    const {userData} = useAuth()

    const companyId = userData.company_id;

    const [bankbooks, setBankbooks] = useState();
    const [bankbooksLoading, setBankbooksLoading] = useState(true);

    const fetchBankbooks = useCallback(async () => {
        if (companyId) {
            setBankbooksLoading(true);

            const res = await Dictionaries.bankbooks({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setBankbooks(res.content);
            } else {
                toast.error(res.message)
            }

            setBankbooksLoading(false);
        }
    }, [setValue, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchBankbooks();
    }, [fetchBankbooks]);

    useEffect(() => {
        const {bankbook_id} = {...state};
        bankbook_id && setValue('bankbook_id', String(bankbook_id));
    }, [state, setValue])

    const {onSubmit, loading} = useStore(DevicesServices.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.device_number') + " *"}>
                        <Input type="text" {...register('number', {required: true})} $error={errors.number}/>
                        {errors.number && errors.number.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook') + " *"}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={bankbooks} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!companyId ? bankbooksLoading : false}
                                                      isDisabled={!!!companyId || bankbooksLoading || state?.bankbook_id}
                                                      $error={errors.bankbook_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bankbook_id && errors.bankbook_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.device_type') + " *"}>
                        <Controller name="resource_type_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.resourceTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.resource_type_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.resource_type_id && errors.resource_type_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.area') + " *"}>
                        <Controller name="area_type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.deviceAreaTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.area_type}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.area_type && errors.area_type.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.description')}>
                        <Input type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.modem_number')}>
                        <Input type="text" {...register('modem_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacturer')}>
                        <Input type="text" {...register('manufacturer')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacture_date')}>
                        <Input type="date" {...register('manufacture_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.diameter')}>
                        <Input type="text" {...register('diameter')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.last_check')}>
                        <Input type="date" {...register('last_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.next_check')}>
                        <Input type="date" {...register('next_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.seal_number')}>
                        <Input type="text" {...register('seal_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.seal_setting_date')}>
                        <Input type="date" {...register('seal_setting_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.anti_magnetic_seal_number')}>
                        <Input type="text" {...register('anti_magnetic_seal_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.anti_magnetic_seal_setting_date')}>
                        <Input type="date" {...register('anti_magnetic_seal_setting_date')}/>
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default DeviceCreate;