import React from 'react';
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

const style = css`
  font-size: 14px;
  color: var(--accent);
  transition: .2s;
  cursor: pointer;
  font-weight: 600;
  
  i {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 1px;
  }
  
  &:hover {
    color: var(--accent-hover);
  }
`;

const BtnEl = styled.span`${style}`;
const LinkEl = styled(Link)`${style}`;

const CustomLink = ({children, ...props}) => {
    const {to} = props;

    if (to) {
        return <LinkEl {...props}>{children}</LinkEl>
    }

    return (
        <BtnEl {...props}>{children}</BtnEl>
    );
};

export default CustomLink;