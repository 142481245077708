import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/operator/routePaths";
import IndicationEditInformation from "./IndicationEditInformation";
import IndicationEditComments from "./IndicationEditComments";
import IndicationEditHistory from "./IndicationEditHistory";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import IndicationsServices from "../../../services/operator/indicationsServices";

const IndicationEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(IndicationsServices.view, id);

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info?.date ? " - " + info?.date : ""}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.indications.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.indications.edit.comments(id)}>{t('labels.comments')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.indications.edit.history(id)}>{t('labels.changes_history')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.indications.edit.index()} to={routePaths.indications.edit.information()}/>
                <Route path={routePaths.indications.edit.information()} component={IndicationEditInformation}/>
                <Route path={routePaths.indications.edit.comments()} component={IndicationEditComments}/>
                <Route path={routePaths.indications.edit.history()} component={IndicationEditHistory}/>
            </Switch>
        </>
    );
};

export default IndicationEdit;