import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class ControllersServices {
    static index = (queryString) => {
        return getData(http, `/users/controllers${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/users/controllers/store`, data)
    }

    static view = (id) => {
        return getData(http, `/users/controllers/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/users/controllers/${id}`, data)
    }

    static resetPassword = (id) => {
        return postData(http, `/users/controllers/${id}/resetPassword`)
    }

    static delete = (id) => {
        return deleteData(http, `/users/controllers/${id}`)
    }

    static sendNotification = (id, data) => {
        return postData(http, `/users/controllers/${id}/sendNotification`, data)
    }
}