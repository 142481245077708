import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import DndFileUploader from "../../../components/DndFileUploader";
import Button from "../../../components/Button";
import ImportServices from "../../../services/operator/importServices";
import ImportResult from "./ImportResult";
import {useAuth} from "../../../navigation/Auth/ProvideAuth";
import {useLangContext} from "../../../i18n/ProvideLang";

const IndicationsImport = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {token} = useAuth()
    const {lang} = useLangContext()

    const [response, setResponse] = useState()

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.data_import.title')
            },
            {
                path: null,
                title: t('sidebar.data_import.children.indications')
            }
        ])
    }, [setBreadcrumbs, t]);

    const handleResponse = response => {
        setResponse(response.content)
    }

    return (
        <>
            <h1 className="headline-4">{t('sidebar.data_import.title')} - {t('sidebar.data_import.children.indications')}</h1>
            <WhiteBlock>
                <FormGroup label={t('labels.choose_file')}>
                    <div className="mb-10">
                        <DndFileUploader
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            successCallback={response => handleResponse(response)}
                            maxCount={1}
                            headers={{
                                "Authorization": `Bearer ${token}`,
                                "language": lang
                            }}
                            action={ImportServices.indicationsUrl()}
                            name='file'
                        >
                            .xlsx, .xls, <br/>
                            Не более 50 мб
                        </DndFileUploader>
                    </div>
                    <Button variant="outline" $small $withIcon href='/uploads/sample_indications.xlsx' target="_blank" download><i className="icon-download"/><span>Пример заполненного файла</span></Button>
                </FormGroup>
            </WhiteBlock>
            {response && <ImportResult response={response}/>}
        </>
    );
};

export default IndicationsImport;