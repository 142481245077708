import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import IndicationsFilter from "./components/IndicationsFilter";
import IndicationsServices from "../../../services/admin/indicationsServices";
import IndicationsTable from "./components/IndicationsTable";
import useCombinedSearch from "../../../hooks/useCombinedSearch";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx-js-style"
import Preloader from '../../../components/Preloader';
import {toast} from "react-toastify";
import BBIndicationsServices from '../../../services/admin/bbIndicationsServices';

const Indications = ({statusId = "", statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    let query = new URLSearchParams(useLocation().search);
    const [params2] = useState(query);

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.indications.index,
                title: t('sidebar.indications.title')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        items,
        loading,
        pageCount,
        params,
        fetchItems,
        handlePageClick,
        setFilterData,
        total
    } = useFetchItems(BBIndicationsServices.index, statusId, {with_image: statusId === "" ? 0 : 1})


    const {combinedSearch} = useCombinedSearch({statusId});
    const [acceptModalIsOpen, setAcceptModalIsOpen] = useState()
    const [loading2, setLoading2] = useState(false)


    function createWs(data, fields, titles) {
        const ws = XLSX.utils.json_to_sheet(
            data, 
            { 
                header: fields
            }
        )
        const range = XLSX.utils.decode_range(ws['!ref'])
        
        for(let c = range.s.c; c <= range.e.c; c++) {
          const header = XLSX.utils.encode_col(c) + '1'
          ws[header].v = titles[ ws[header].v ]
        }
        
        return ws
    }

    const exportToCSV = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let date = new Date();
        const fileName = `indications_${date.getFullYear()}_${date.getMonth()+1}_${date.getDate()}_${date.toLocaleTimeString()}`;
        setLoading2(true)

        let indications = []
        params2.set('stat', 0);
        let df = document.getElementById('hdfsj');
        df.textContent = "Запрос на получение данных отправлен";
        const data1 = await BBIndicationsServices.exportByPage("stat=1&status=" + statusId + "&" + params.toString())
        
        if(data1.statusCode !== 200){
            toast.error(data1.message)
            setLoading2(false)
            return
        }
        
        let titles = data1.content.headings;

        let count_items = 0;

        if(data1.content.total <= 0){
            toast.error('Данные для экспорта отсутствуют')
            setLoading2(false)
            return
        }

        for(let i = data1.content.current_page ; i<=data1.content.total_pages+1; i++){
            df.textContent = 'Получено данных ' + count_items + " из " + data1.content.total;
            params2.set('page', i);
            if(statusId){
                params2.set('status', statusId);
            }
            let resu = await BBIndicationsServices.exportByPage(params2.toString()+"&"+params.toString())
            indications = indications.concat(...resu.content.items);
            count_items += resu.content.items.length;
        }
        
        df.textContent = 'Идет формирование exel документа';
        const fields = []
        for(let key in indications[0]){
            fields.push(key)
        }

        /*indications.sort(function(a,b){
            return new Date(b.timestamp) - new Date(a.timestamp);
        });
        indications.sort(function(a, b) {
            console.log(a, b)
            let x = a.device_number.toLowerCase();
            let y = b.device_number.toLowerCase();
            if(x < y) {return -1;}
            if(x > y) {return 1;}
            return 0;
        });

        indications.sort(function(a, b) {
            let x = a.bankbook.toLowerCase();
            let y = b.bankbook.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });*/

        const ws = createWs(
            indications,
            fields,
            titles
        )

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        setLoading2(false)
    }


    return (
        <>
        <div className={loading2 ? "progress-container" : "progress-container-none"}>
            <h2 id="hdfsj" className="progress-text">Запрос на получение данных отправлен</h2>
        </div>
        {loading2 && 
            <Preloader></Preloader>}
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.indications.title')} - {statusText}</h1>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button $small $customColor={'#ED7E52'} $withIcon variant="outline" target="_blank" 
                            onClick={(e) => exportToCSV()}><i className="icon-download"/><span>{t('labels.export')}</span></Button>
                    </div>
                    <div className="col-auto">
                        <Button $small $withIcon variant="outline" to={routePaths.indications.create}><i
                            className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
                    </div>
                </div>
            </HeadingWithButtons>
            <IndicationsFilter setFilterData={setFilterData} params={params}/>
                    {statusId === "0" && <WhiteBlock>
                        <h4 className="headline-5">{t('messages.filter_result')} {loading ? "..." : (total || 0)}</h4>
                        <Button onClick={() => setAcceptModalIsOpen(true)} $small
                                disabled={!total}>{t('labels.approve_all')}</Button>
                    </WhiteBlock>}
                        {items
                            ? <>
                                <div className={loading ? "isLoading" : "indication-table"}>
                                    <IndicationsTable data={items} fetchData={fetchItems}/>
                                </div>
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            <ConfirmActionModal
                isOpen={acceptModalIsOpen}
                closeModal={() => setAcceptModalIsOpen(false)}
                onRequestClose={() => setAcceptModalIsOpen(false)}
                successCallback={() => {
                    setAcceptModalIsOpen(false);
                    fetchItems();
                }}
                method={() => BBIndicationsServices.massAccept(combinedSearch)}
                content={{
                    title: t('messages.action_confirm'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default Indications;