import {apiOperatorUrl} from "../CONSTANTS";
import {postData} from "../handlers";
import http from "../operator/http-common";

export default class ImportServices {
    static addressesUrl = (query = '') => apiOperatorUrl + "/imports/addresses" + query;

    static devicesUrl = (query = '') => apiOperatorUrl + "/imports/devices" + query;

    static indicationsUrl = (query = '') => apiOperatorUrl + "/imports/indications" + query;

    static headers = {
        "Authorization": localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null,
        "language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'ru'
    }

    static importAddresses = (data) => {
        return postData(http, `/imports/v2/addresses`, data)
    }

    static importDevices = (data) => {
        return postData(http, `/imports/v2/devices`, data)
    }

    static importDevicesToDelete = (data) => {
        return postData(http, `/imports/v2/devicesToDelete`, data)
    }

    static importIndications = (data) => {
        return postData(http, `/imports/v2/indications`, data)
    }

    static generateErrorsFile = data => {
        return postData(http, `/imports/v2/generateFile`, data)
    }
}