import React, { useState} from 'react';
import ModalComponent from '../ModalComponent';
import styled from "styled-components";


const SpanRight = styled.span`
    position:absolute;
    font-size:100px;
    right:-120px;
    top: calc(50% - 100px);
    color: #fff;
    cursor:pointer;
    
    &:hover{
        color: #D9DBE1;
    }
`

const SpanLeft = styled(SpanRight)`
    left:-120px;
`

const ImgModal = ({method, closeModal, successCallback, id, setId, info, ...props}) => {
    const [loading] = useState();
    const length = info?.length;

    const next = (e) => {
        e.stopPropagation();
        if(length - 1 > id){
            setId(id+1);
        }else if(length - 1 === id){
            setId(0);
        }
    }

    const prev = (e) => {
        e.stopPropagation();
        if(id > 0){
            setId(id-1);
        }else if(0 === id){
            setId(length - 1);
        }
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "600px", maxWidth: "100%"}}>
                <SpanLeft className='icon-chevron-left' onClick={e => {prev(e)}}/>
                    {info?.map((item, index) => 
                        index === id && <img key={index} src={item} alt={index} />  
                    )}
                <SpanRight className='icon-chevron-right' onClick={e => {next(e)}}/>
            </div>
        </ModalComponent>
    );
};

export default ImgModal;