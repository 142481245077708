import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../../hooks/useUpdate.hook";
import {useHistory, useLocation, useParams} from "react-router-dom";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/admin/dictionaries";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import ConfirmActionModal from "../../../../components/modals/ConfirmActionModal";
import Alert from "../../../../components/Alert";
import UpdatePasswordModal from "../../../../components/modals/UpdatePasswordModal";
import OperatorsServices from "../../../../services/admin/operatorsServices";
import InputMessage from "../../../../components/InputMessage";

const OperatorEdit = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, control, formState: {errors}} = useForm();
    const history = useHistory();

    const {state} = useLocation();

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [passwordMessage, setPasswordMessage] = useState();
    const [updatePasswordModalIsOpen, setUpdatePasswordModalIsOpen] = useState();

    const {info, loading: fetchLoading} = useFetchInfo(OperatorsServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: routePaths.users.children.operators.index,
                title: t('sidebar.users.children.operators')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.name ? " - " + info?.name : "")
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {onSubmit, loading} = useUpdate(OperatorsServices.update, id);

    useEffect(() => {
        if (info) {
            const {company, bb_sectors, ...rest} = info;
            for (const [key, value] of Object.entries(rest)) {
                if (key) setValue(key, value);
            }

            company && setValue('company_id', String(company.id))
            bb_sectors && setValue('bb_sectors', bb_sectors.map((id => String(id))))
        }
    }, [info, setValue])

    const handlePasswordReset = (e) => {
        e.preventDefault();
        setUpdatePasswordModalIsOpen(true);
    }

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    return (
        <>
            <WhiteBlock>
                <h1 className="headline-4">{t('labels.editing')} {info?.name ? " - " + info?.name : ""}</h1>
                {
                    passwordMessage && <Alert type="warning"
                                              hideCallback={() => setPasswordMessage(null)}>{t('labels.new_password')}: {passwordMessage}</Alert>
                }
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.fullName') + " *"}>
                        <Input type="text" {...register('name', {required: true})} $error={errors.name}/>
                        {errors.name && errors.name.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input type="tel" {...register('phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.email') + " *"}>
                        <Input type="text" {...register('email', {
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('messages.invalid_email')
                            }
                        })} $error={errors.email} required/>
                        {errors.email && <InputMessage $error>{errors.email.message}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.companies}
                                            onChange={onChange}
                                            value={value}
                                            isDisabled={state?.company_id}
                                            $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.segment') + " *"}>
                        <Controller name="bb_sectors" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookSectorTypes}
                                            onChange={onChange}
                                            value={value}
                                            $error={errors.bb_sectors}
                                            isMulti
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bb_sectors && errors.bb_sectors.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button onClick={handlePasswordReset} $customColor={'#D86551'}>{t('labels.reset_password')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.push(routePaths.users.children.operators.index)
                }}
                method={OperatorsServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <UpdatePasswordModal
                isOpen={updatePasswordModalIsOpen}
                closeModal={() => setUpdatePasswordModalIsOpen(false)}
                onRequestClose={() => setUpdatePasswordModalIsOpen(false)}
                setPasswordMessage={setPasswordMessage}
                successCallback={() => {
                    setUpdatePasswordModalIsOpen(false)
                }}
                method={OperatorsServices.resetPassword}
                data={id}
                content={{
                    title: t('modals.update_password.title'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default OperatorEdit;