import React from 'react';
import styled from "styled-components";

const Ellipsis = styled.span`
  display: inline-flex;
  
  span {
    animation: fade 2s linear infinite;
    
    &:nth-child(1) {
      animation-delay: 0s;
    }
    
    &:nth-child(2) {
      animation-delay: .2s;
    }
    
    &:nth-child(3) {
      animation-delay: .4s;
    }
  }
  
  @keyframes fade {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`

const EllipsisLoading = ({children}) => {
    return (
        <div>{children}<Ellipsis><span>.</span><span>.</span><span>.</span></Ellipsis></div>
    );
};

export default EllipsisLoading;