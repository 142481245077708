import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 20px rgb(0, 0, 0, .05);
  padding: 10px 20px;
  margin-top: 1px;
  
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  
    li {
      font-size: 16px;
      
      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-300);      
      }
      
      button, a, span {
        display: inline-block;
        border: none;
        background: none;
        padding: 10px 0;
        font-family: inherit;
        font-size: 14px;
        cursor: pointer;
        color: inherit;
        
        &:hover {
          color: var(--accent);
        }
      }
    }
  }
`;

const DropdownContent = ({children, ...props}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default DropdownContent;