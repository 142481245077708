import React, {useState} from 'react';
import AuthLayout from "../../layouts/AuthLayout";
import WhiteBlock from "../../components/WhiteBlock";
import FormGroup from "../../components/FormGroup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import CustomLink from "../../components/CustomLink";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import routePaths from "../general/routePaths";
import AuthServices from "../../services/general/authServices";
import {toast} from "react-toastify";

const ResetPassword = () => {
    const {handleSubmit, register} = useForm();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const {t} = useTranslation();

    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');

    const onSubmit = async (data) => {
        const newData = {
            email: email,
            ...data
        }

        setLoading(true);
        const res = await AuthServices.checkCode(newData);
        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            history.push(routePaths.login.index)
        }
    }

    return (
        <AuthLayout>
            <WhiteBlock style={{padding: 72}}>
            <h1 className="headline-4">{t('pages.authorization.password_recovery')}</h1>
            <form onSubmit={handleSubmit(onSubmit)} className={loading ? 'isLoading' : ''}>
                <FormGroup label={t('labels.enter_code')}>
                    <Input type="text" {...register("code")} required/>
                </FormGroup>
                <div className="mb-10">
                    <Button $fluid>{t('labels.recover_password')}</Button>
                </div>
                <div className="text-center">
                    <CustomLink onClick={() => history.push(routePaths.login.index)}>{t('labels.cancel')}</CustomLink>
                </div>
            </form>
            </WhiteBlock>
        </AuthLayout>
    );
};

export default ResetPassword;