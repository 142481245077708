import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Login from "./Auth/Login";
import PrivateRoute from "./Auth/PrivateRoute";
import routePaths from "./general/routePaths";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import {useAuth} from "./Auth/ProvideAuth";
import UserRoleNotDefined from "../pages/general/UserRoleNotDefined";
import PrivacyPolicy from "../pages/general/PrivacyPolicy/PrivacyPolicy";
import AdminRoutes from "./admin/routes";
import OperatorRoutes from "./operator/routes";
import CallOperatorRoutes from "./CallOperator/routes";
import AnaliticOperatorRoutes from "./analiticOperator/routes";
import SealerOperatorRoutes from "./sealerOperator/routes";
import EsepAdminRoutes from "./minAdmin/routes";
import LaboratoryAdministratorRoutes from "./laboratoryAdministrator/routes";

const RouterConfig = () => {
    const {userData} = useAuth();
    const role = userData? userData.role[0] : null;
    const approvedRoles = ['admin', "company_operator", "laboratory_administrator", "esep_call_center_operator", "esep_admin", "analytic", "sealer_operator"]

    return (
        <Switch>
            <Route path={routePaths.login.index} component={Login}/>
            <Route path={routePaths.forgot_password.index} component={ForgotPassword}/>
            <Route path={routePaths.reset_password.index} component={ResetPassword}/>
            <Route path={routePaths.privacy_policy.index} component={PrivacyPolicy}/>
            <PrivateRoute>
                {role === 'admin' &&
                    <AdminRoutes/>
                }
                {role === 'company_operator' &&
                    <OperatorRoutes/>
                }
                {role === 'laboratory_administrator' &&
                    <LaboratoryAdministratorRoutes/>
                }
                {role === 'esep_call_center_operator' &&
                    <CallOperatorRoutes/>
                }
                {role === 'esep_admin' &&
                    <EsepAdminRoutes/>
                }
                {role === 'analytic' &&
                    <AnaliticOperatorRoutes/>
                }
                {role === 'sealer_operator' &&
                    <SealerOperatorRoutes/>
                }
                {!approvedRoles.includes(role) &&
                    <UserRoleNotDefined/>
                }
            </PrivateRoute>
        </Switch>
    );
};

export default RouterConfig;