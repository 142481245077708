import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useInfo} from "./Application";
import routePaths from "../../../navigation/CallOperator/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import ApplicationsServices from "../../../services/callOperator/applicationsServices";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";

const ApplicationHistory = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useInfo()

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.applications.index,
                title: t('sidebar.applications.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info.number
            },
            {
                path: null,
                title: t('labels.changes_history')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(ApplicationsServices.history, null, {id})

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, user, description, created_at}) => {
                return {
                    id,
                    fields: [
                        id,
                        user?.name,
                        description,
                        created_at
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.fullName'), t('labels.changes'), t('labels.date')],
        sizes: ['10%', '25%', '45%', '20%']
    }

    return (
        <WhiteBlock>
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
        </WhiteBlock>
    );
};

export default ApplicationHistory;