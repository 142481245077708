import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../navigation/analiticOperator/routePaths";
import {toast} from "react-toastify";
import Dictionaries from "../../../services/analiticOperator/dictionaries";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import useUpdate from "../../../hooks/useUpdate.hook";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import CustomSelect from "../../../components/CustomSelect";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import IndicationsServices from "../../../services/analiticOperator/indicationsServices";
import FilesUploader from "../../../components/FilesUploader";
import Utils from "../../../services/utils";
import DeclineModal from "./components/DeclineModal";
import StatusLabel from "../../../components/StatusLabel";
import InputMessage from "../../../components/InputMessage";
import AsyncPaginateSelect from "../../../components/AsyncPaginateSelect";

const statusColors = {
    0: 'var(--blue)',
    1: 'var(--success)',
    2: 'var(--error)',
}

const IndicationEditInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, control, watch, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const {info, fetchInfo, loading: fetchLoading} = useFetchInfo(IndicationsServices.view, id);

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.indications.index,
                title: t('sidebar.indications.title')
            },
            {
                path: null,
                title: t('labels.editing') + (info?.date ? " - " + info?.date : "")
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info])

    

    const companyId = watch('company_id', null);
    const bankbookId = watch('bankbook_id', null);
    const deviceId = watch('device_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [devices, setDevices] = useState();
    const [devicesLoading, setDevicesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchDevices = useCallback(async () => {
        if (bankbookId) {
            setDevicesLoading(true);

            const res = await Dictionaries.devices({queryString: `bankbook_id=${bankbookId}`});

            if (res.statusCode === 200) {
                setDevices(res.content);

                if (!res.content.some(item => item.value === deviceId)) {
                    setValue('device_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDevicesLoading(false);
        } else {
            setValue('device_id', null)
        }
    }, [setValue, bankbookId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchDevices();
    }, [fetchDevices]);

    const {onSubmit, loading} = useUpdate(IndicationsServices.update, id, false, fetchInfo);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();
    const [declineModalIsOpen, setDeclineModalIsOpen] = useState();
    const [approveModalIsOpen, setApproveModalIsOpen] = useState();

    useEffect(() => {
        if (info) {
            const {company_id, bankbook_id, device_id, images, date,  previous, ...rest} = {...info};
            for (const [key, value] of Object.entries(rest)) {
                info[key] && setValue(key, String(value));
            }

            company_id && setValue('company_id', String(company_id));
            bankbook_id && setValue('bankbook_id', String(bankbook_id));
            device_id && setValue('device_id', String(device_id));
            images && setValue('images', Utils.transformFilesFromServer(images));
            previous?.date && setValue('prev_date', previous.date)
            previous?.value && setValue('prev_value', previous.value)
            setValue('curr_date', date)
        }
    }, [info, setValue])

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    const handleDecline = (e) => {
        e.preventDefault();
        setDeclineModalIsOpen(true);
    }

    const handleApprove = (e) => {
        e.preventDefault();
        setApproveModalIsOpen(true);
    }

    const customSubmit = (data) => {
        const {images, value} = data;

        const newData = {
            images: images ? Utils.transformFiles(images) : [],
            value
        };

        onSubmit(newData)
    }

    return (
        <>
            <WhiteBlock>
                <form onSubmit={handleSubmit(customSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    {
                        info && <FormGroup label={t('labels.status')}>
                            <StatusLabel color={statusColors[info.status.id]}>{info.status.name}</StatusLabel>
                        </FormGroup>
                    }
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook') + " *"} key={companyId}>
                        <Controller name="bankbook_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <AsyncPaginateSelect onChange={onChange}
                                                             loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: `company_id=${companyId}`})}
                                                             value={value}
                                                             isDisabled={!!!companyId}
                                                             $error={errors.bankbook_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.bankbook_id && errors.bankbook_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.device') + " *"}>
                        <Controller name="device_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={devices} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!bankbookId ? devicesLoading : false}
                                                      isDisabled
                                                      $error={errors.device_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.device_id && errors.device_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.previous_period')}>
                        <Input type="text" {...register('prev_date')} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.previous_indication')}>
                        <Input type="text" {...register('prev_value')} disabled/>
                    </FormGroup>
                    <hr/>
                    <FormGroup label={t('labels.current_period')}>
                        <Input type="text" {...register('curr_date')} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.current_indication') + " *"}>
                        <Input type="text" {...register('value', {required: true})} $error={errors.value} disabled={!deviceId}/>
                        {errors.value && errors.value.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.photo')}>
                        <Controller name="images" control={control} render={({field: {onChange, value}}) => (
                            <FilesUploader onChange={onChange} value={value} maxFiles={3} accept="image/*">
                                {t('labels.files_uploader_text', {formats: '.jpg, .png', size: '20MB'})}
                            </FilesUploader>
                        )}/>
                    </FormGroup>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                        {
                            info?.status?.id === 0 && <>
                                <div className="col-auto">
                                    <Button onClick={handleDecline} $red>{t('labels.decline')}</Button>
                                </div>
                                <div className="col-auto">
                                    <Button onClick={handleApprove} $green>{t('labels.approve')}</Button>
                                </div>
                            </>
                        }
                    </div>
                </form>
            </WhiteBlock>
            <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.push(routePaths.indications.index);
                }}
                method={IndicationsServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <ConfirmActionModal
                isOpen={approveModalIsOpen}
                closeModal={() => setApproveModalIsOpen(false)}
                onRequestClose={() => setApproveModalIsOpen(false)}
                successCallback={() => {
                    setApproveModalIsOpen(false);
                    fetchInfo();
                }}
                method={IndicationsServices.accept}
                data={id}
                content={{
                    title: t('messages.action_confirm'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <DeclineModal
                isOpen={declineModalIsOpen}
                closeModal={() => setDeclineModalIsOpen(false)}
                onRequestClose={() => setDeclineModalIsOpen(false)}
                successCallback={() => {
                    setDeclineModalIsOpen(false);
                    fetchInfo();
                }}
                method={IndicationsServices.decline}
                indicationId={id}
            />
        </>
    );
};

export default IndicationEditInformation;