import React, {useCallback, useEffect, useState} from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {useTranslation} from "react-i18next";
import Utils from "../services/utils";
import {toast} from "react-toastify";

const styles = {
    control: (provided, state) => ({
        ...provided,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: state.selectProps.$error ? 'var(--error)' : (state.isFocused ? 'var(--secondary)' : 'var(--gray-300)'),
        minHeight: state.selectProps.$small ? '40px' : '50px',
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: 'inherit',
        backgroundColor: state.isDisabled ? 'var(--gray-100)' : '#fff',
        boxShadow: 'none',

        '&:hover': {
            borderColor: state.selectProps.$error ? 'var(--error)' : (state.isFocused ? 'var(--secondary)' : 'var(--gray-300)'),
        }
    }),
    menu: provided => ({...provided, zIndex: 9999}),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingLeft: state.selectProps.small ? '15px' : '20px',
        paddingTop: '3.5px',
        paddingBottom: '3.5px'
    }),
    multiValue: (provided) => ({
        ...provided,
        padding: '2px 4px',
        backgroundColor: '#eee'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: '14px'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer'
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        backgroundColor: state.isSelected ? 'var(--accent)' : '#fff',

        '&:hover': {
            backgroundColor: state.isSelected ? 'var(--accent)' : 'var(--tint)',
        }
    }),
};

const AsyncPaginateSelect = ({
                                 loadOptions,
                                 value: passedValue,
                                 defaultOptions,
                                 onChange,
                                 defaultAdditional = {page: 1},
                                 isMulti = false,
                                 placeholder = "",
                                 isDisabled,
                                 changeCallback,
                                 ...props
                             }) => {
    const {t} = useTranslation();
    const [value, setValue] = useState(passedValue);

    const loadPageOptions = async (q, prevOptions, {page}) => {
        const res = await loadOptions({name: q, page: page});

        if (res.statusCode === 200) {
            const {options, hasMore} = res.content;

            return {
                options,
                hasMore,

                additional: {
                    page: page + 1
                }
            };
        } else {
            toast.error(res.message)
        }
    };

    const getOptionsById = useCallback(async () => {
        if (passedValue) {
            const string = Utils.objectToSearchParams({ids: isMulti ? passedValue : [passedValue]});
            const query = new URLSearchParams(string)
            const res = await loadOptions({ids: query.toString()});
            const selectedOptions = isMulti ? res.content.options : res.content.options[0];
            setValue(selectedOptions);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getOptionsById()
    }, [getOptionsById])

    const handleChange = (data) => {
        if (isMulti) {
            let transformedData = data && data.map(item => item.value);
            onChange(transformedData.length ? transformedData : null);
        } else {
            onChange(data.value);
        }

        if (changeCallback) {
            changeCallback(data);
        }
        setValue(data);
    }

    return (
        <AsyncPaginate
            additional={defaultAdditional}
            defaultOptions={defaultOptions}
            value={value ? value : null}
            loadOptions={loadPageOptions}
            onChange={handleChange}
            isMulti={isMulti}
            styles={styles}
            noOptionsMessage={() => t('labels.no_options')}
            loadingMessage={() => t('labels.loading')}
            placeholder={placeholder || t('labels.select')}
            isDisabled={isDisabled}
            {...props}
        />
    );
};

export default AsyncPaginateSelect;