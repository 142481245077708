import React, { useEffect, useState } from 'react';
import { Arrow, Div, Flex, Grid, H2El, H3El, Modal, Block, Month, Wrapper } from '../../../../components/TaskModal';
import TaskStatisticsServices from '../../../../services/analiticOperator/taskStatisticsServices';

const TaskStatisticsModal = ({items, setParams2, params2,userId, children, setVisible, ...props}) => {
    const [tasks, setTasks] = useState()

    useEffect(()=>{
        TaskStatisticsServices.view(params2).then(data => {
            setTasks(data.content)
        })
    }, [params2])

    const closeModal = (e) => {
        e.stopPropagation();
        setVisible(false);
    }

    const convertDate = (date) => {
        let str = date.split('-')
        return `${str[2]} ${Month[Number(str[1])]}, ${str[0]}`;
    }

    const changeDate = (next) => {
        let str = tasks.date.split('-')
        let new_params;
        if(next){
            let monthDays = new Date(str[0], str[1], 0).getDate();
            if(Number(str[2])+1 > monthDays){
                new_params = `${str[0]}-${Number(str[1])+1}-1`
            }else{
                new_params = `${str[0]}-${str[1]}-${Number(str[2])+1}`
            }
        }else{
            let monthDays = new Date(str[0], Number(str[1])-1, 0).getDate();
            if(Number(str[2])-1 < 1){
                new_params = `${str[0]}-${str[1]-1}-${monthDays}`
            }else{
                new_params = `${str[0]}-${str[1]}-${Number(str[2])-1}`
            }
        }
        if(userId){
            let str2 = `date=${new_params}&user_id=${userId}`;
            setParams2(str2);
        }
    }

    const arrowDate = (date, next) => {
        let str = date.split('-')
        if(next){
            let monthDays = new Date(str[0], str[1], 0).getDate();
            if(Number(str[2])+1 > monthDays){
                return `1 ${Month[Number(str[1])+1]}`;
            }else{
                return `${Number(str[2])+1} ${Month[Number(str[1])]}`;
            }
        }
        let monthDays = new Date(str[0], Number(str[1])-1, 0).getDate();
        if(Number(str[2])-1 < 1){
            return `${monthDays} ${Month[Number(str[1])-1]}`;
        }else{
            return `${Number(str[2])-1} ${Month[Number(str[1])]}`;
        }
    }

  return (
    <Modal onClick={e => closeModal(e)}>
        {tasks && 
        <Wrapper onClick={e => e.stopPropagation()}>
            <H3El>{tasks?.user.name}</H3El>
            <Grid className="mb-20">
                {
                    tasks.statistic.map((item, index) => 
                        <Block key={index} className={
                            (item.plan === null || item.plan === 0) ? "gray" :
                            (item.done === null || item.done === 0) ? "red" : 
                            item.done < item.plan ? "yellow" : ""
                        }>
                            <span>{item.done === null ? 0 : item.done}/{item.plan === null ? 0 : item.plan}</span>
                            {item.title}
                        </Block>   
                    )
                }
            </Grid>
            <Flex className="mb-20" $column $scroll>
                {
                    tasks.tasks.map(item => 
                        <Div key={item.id}><span>{item.created_at}</span>
                            {item.document ? 
                                <a href={item.document} target="_blank" rel="noreferrer noopener">
                                    {item.task}
                                </a> :
                                item.task
                            }
                        </Div>
                    )
                }
            </Flex>
            <Flex $end>
                <div >
                    <H2El>{convertDate(tasks.date)}</H2El>
                    <Flex >
                        <Arrow onClick={() => changeDate(false)}>
                            <i className="icon-chevron-left"></i>
                            <span>{arrowDate(tasks.date, false)}</span>
                        </Arrow>
                        <Arrow onClick={() => changeDate(true)}>
                            <span>{arrowDate(tasks.date, true)}</span>
                            <i className="icon-chevron-right"></i>
                        </Arrow>
                    </Flex>
                </div>
            </Flex>
        </Wrapper>} 
    </Modal>
  );
};

export default TaskStatisticsModal;