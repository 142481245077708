import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/operator/routePaths";
import ApartmentEditInformation from "./ApartmentEditInformation";
import ApartmentEditBankbooks from "./ApartmentEditBankbooks";
// import ApartmentEditControllers from "./ApartmentEditControllers";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/operator/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";

const ApartmentEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.apartments.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')}{info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.apartments.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.apartments.edit.bankbooks(id)}>{t('labels.bankbooks')}</NavLink>
                {/*<NavLink activeClassName='active' exact to={routePaths.addresses.children.apartments.edit.controllers(id)}>{t('labels.controllers')}</NavLink>*/}
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.apartments.edit.index()} to={routePaths.addresses.children.apartments.edit.information()}/>
                <Route path={routePaths.addresses.children.apartments.edit.information()} component={ApartmentEditInformation}/>
                <Route path={routePaths.addresses.children.apartments.edit.bankbooks()} component={ApartmentEditBankbooks}/>
                {/*<Route path={routePaths.addresses.children.apartments.edit.controllers()} component={ApartmentEditControllers}/>*/}
            </Switch>
        </>
    );
};

export default ApartmentEdit;