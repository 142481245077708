import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm, Controller} from "react-hook-form";
import routePaths from "../../../navigation/admin/routePaths";
import useStore from "../../../hooks/useStore.hook";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import Dictionaries from "../../../services/admin/dictionaries";
import {useLocation} from "react-router-dom";
import SectorsServices from "../../../services/admin/sectorsServices";
import TextEditor from "../../../components/TextEditor";
import {toast} from "react-toastify";
import CustomSelect from "../../../components/CustomSelect";
import InputMessage from "../../../components/InputMessage";

const SectorCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, setValue, watch, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.sectors.index,
                title: t('sidebar.sectors.title')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const {onSubmit, loading} = useStore(SectorsServices.store);

    const companyId = watch('company_id', null)
    const cityId = watch('city_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps


    const fetchCities = useCallback(async () => {
        setCities(null)

        if (companyId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        if (state) {
            const {city_id, company_id} = {...state};
            city_id && setValue('city_id', String(city_id));
            company_id && setValue('company_id', String(company_id));
        }
    }, [state, setValue])

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.content_kk')}>
                        <Controller
                            name="description_kk"
                            control={control}
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <TextEditor
                                    onChange={onChange}
                                    value={value}
                                />
                            )}>
                        </Controller>
                    </FormGroup>
                    <FormGroup label={t('labels.content_ru')}>
                        <Controller
                            name="description_ru"
                            control={control}
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <TextEditor
                                    onChange={onChange}
                                    value={value}
                                />
                            )}>
                        </Controller>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company') + " *"}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}
                                                      isDisabled={state?.company_id}
                                                      $error={errors.company_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.company_id && errors.company_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                        <FormGroup label={t('labels.city') + " *"}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!companyId ? companiesLoading || citiesLoading : false}
                                                      isDisabled={!!!companyId || companiesLoading || citiesLoading || state?.city_id}
                                                      $error={errors.city_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default SectorCreate;