import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import ModalComponent from "../../../../components/ModalComponent";
import CustomSelect from "../../../../components/CustomSelect";
import Dictionaries from "../../../../services/admin/dictionaries";
import Utils from "../../../../services/utils";
import AsyncPaginateSelect from "../../../../components/AsyncPaginateSelect";

const AttachUsersBookModal = ({method, closeModal, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();
    const {handleSubmit, setValue, control, watch, reset} = useForm();

    const regionId = watch('region_id', null);
    const cityId = watch('city_id', null);
    const streetIds = watch('streets', null);
    const houseIds = watch('houses', null);
    const companyIds = watch('companies', null);
    const sectorIds = watch('sectors', null);

    const [regions, setRegions] = useState();
    const [regionsLoading, setRegionsLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const [apartments, setApartments] = useState();
    const [apartmentsLoading, setApartmentsLoading] = useState(true);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [sectors, setSectors] = useState();
    const [sectorsLoading, setSectorsLoading] = useState(true);

    const fetchRegions = useCallback(async () => {
        setRegionsLoading(true);
        const res = await Dictionaries.regions();

        if (res.statusCode === 200) {
            setRegions(res.content);
        } else {
             toast.error(res.message)
        }
        setRegionsLoading(false);
    }, []);

    const fetchCities = useCallback(async () => {
        setStreets(null);
        setHouses(null);
        setApartments(null);
        setValue('city_id', null);
        setValue('streets', null);
        setValue('houses', null);
        setValue('apartments', null);

        if (regionId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `region_id=${regionId}`});

            if (res.statusCode === 200) {
                setCities(res.content);
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        }
    }, [setValue, regionId]);

    const fetchStreets = useCallback(async () => {
        setHouses(null);
        setApartments(null);
        setValue('streets', null);
        setValue('houses', null);
        setValue('apartments', null);

        if (cityId) {
            setStreetsLoading(true);

            const res = await Dictionaries.streets({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setStreets(res.content);
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        }
    }, [setValue, cityId]);

    const fetchHouses = useCallback(async () => {
        setApartments(null);
        setValue('houses', null);
        setValue('apartments', null);

        if (streetIds) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: Utils.objectToSearchParams({streets: streetIds})});

            if (res.statusCode === 200) {
                setHouses(res.content);
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        }
    }, [setValue, streetIds]);

    const fetchApartments = useCallback(async () => {
        setValue('apartments', null);

        if (houseIds) {
            setApartmentsLoading(true);

            const res = await Dictionaries.apartments({queryString: Utils.objectToSearchParams({houses: houseIds})});

            if (res.statusCode === 200) {
                setApartments(res.content);
            } else {
                toast.error(res.message)
            }

            setApartmentsLoading(false);
        }
    }, [setValue, houseIds]);

    const fetchCompanies = useCallback(async () => {
        setValue('companies', null);

        if (cityId) {
            setCompaniesLoading(true);

            const res = await Dictionaries.companies({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setCompanies(res.content);
            } else {
                toast.error(res.message)
            }

            setCompaniesLoading(false);
        }
    }, [setValue, cityId]);

    const fetchSectors = useCallback(async () => {
        setValue('sectors', null);

        if (companyIds) {
            setSectorsLoading(true);

            const res = await Dictionaries.sectors({queryString: Utils.objectToSearchParams({companies: companyIds})});

            if (res.statusCode === 200) {
                setSectors(res.content);
            } else {
                toast.error(res.message)
            }

            setSectorsLoading(false);
        }
    }, [setValue, companyIds]);

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        fetchApartments();
    }, [fetchApartments]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchSectors();
    }, [fetchSectors]);

    const onSubmit = async (data) => {
        setLoading(true);

        const res = await method(Utils.objectToSearchParams(data));

        if (res.statusCode === 200) {
            if (res.content.length) {
                reset();
                successCallback(res.content);
                closeModal();
            } else {
                toast.error(t('messages.users_not_found'))
            }
        } else {
             toast.error(res.message)
        }

        setLoading(false);
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.add_receivers')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.region')}>
                        <Controller name="region_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={regions} onChange={onChange}
                                                      value={value}
                                                      isLoading={regionsLoading}
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!regionId ? citiesLoading : false}
                                                      isDisabled={!!!regionId || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.streets')}>
                        <Controller name="streets" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? streetsLoading : false}
                                                      isDisabled={!!!cityId || streetsLoading}
                                                      isMulti
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.houses')}>
                        <Controller name="houses" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={houses} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!streetIds ? housesLoading : false}
                                                      isDisabled={!!!streetIds || housesLoading}
                                                      isMulti
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.apartments')}>
                        <Controller name="apartments" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={apartments} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!houseIds ? apartmentsLoading : false}
                                                      isDisabled={!!!houseIds || apartmentsLoading}
                                                      isMulti
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_companies')}>
                        <Controller name="companies" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? companiesLoading : false}
                                                      isDisabled={!!!cityId || companiesLoading}
                                                      isMulti
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.sectors')}>
                        <Controller name="sectors" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={sectors} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!companyIds ? sectorsLoading : false}
                                                      isDisabled={!!!companyIds || sectorsLoading}
                                                      isMulti
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.bankbook')} key={sectorIds}>
                        <Controller name="bankbooks" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <AsyncPaginateSelect onChange={onChange}
                                                             loadOptions={(data) => Dictionaries.bankbooks({...data, queryString: Utils.objectToSearchParams({companies: companyIds, sectors: sectorIds})})}
                                                             value={sectorIds ? value : null}
                                                             isDisabled={!!!sectorIds }
                                                             isMulti
                                                             $small
                                        />
                                    )}
                        />
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachUsersBookModal;