import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {Controller, useForm} from "react-hook-form";
import CustomSelect from "../../../../components/CustomSelect";
import TextArea from "../../../../components/TextArea";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import ModalComponent from "../../../../components/ModalComponent";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Dictionaries from "../../../../services/callOperator/dictionaries";

const EditAttachedServiceModal = ({method, closeModal, applicationId, laboratoryId, successCallback, selectedService, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, register, setValue, control, watch, reset} = useForm();

    const serviceId = watch('service_id', false);
    const amount = watch('amount', 0);

    const [services, setServices] = useState();
    const [price, setPrice] = useState(0);

    const fetchServices = useCallback(async () => {
        const res = await Dictionaries.laboratoryServices({queryString: `laboratory_id=${laboratoryId}`});

        if (res.statusCode === 200) {
            setServices(res.content);
        } else {
             toast.error(res.message)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchServices();
    }, [fetchServices])

    useEffect(() => {
        if (serviceId) {
            const selectedService = services.find(item => item.value === serviceId);
            setValue('description', selectedService.description || t('labels.no_description') )
            setPrice(Number(selectedService.price) * Number(amount))
        }
    }, [serviceId, setValue, t, services, amount])

    const onSubmit = async ({service_id, amount}) => {
        setLoading(true);

        const res = await method({id: applicationId, data: {service_id, amount}});

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.service_edit.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.service')}>
                        <Controller defaultValue={String(selectedService?.id)} name="service_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={services} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!!services}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.service_description')}>
                        <TextArea {...register("description")} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.amount')}>
                        <Input defaultValue={selectedService?.amount} type="number" {...register("amount")} autoComplete="off"/>
                    </FormGroup>
                    <FormGroup label={t('labels.price_tng')}>
                        <span>{price} тг</span>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default EditAttachedServiceModal;