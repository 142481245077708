import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Utils from "../../../../services/utils";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/admin/dictionaries";
import Button from "../../../../components/Button";

const LaboratoriesFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, control, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null});
    }

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.name')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_type')}>
                        <Controller defaultValue={params.get('resource_id')} name="resource_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.resourceTypes}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller defaultValue={params.get('city_id')} name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.cities}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
        }
        </div>
    );
};

export default LaboratoriesFilter;