import React, {useState} from 'react';
import AuthLayout from "../../layouts/AuthLayout";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import WhiteBlock from "../../components/WhiteBlock";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import routePaths from "../general/routePaths";
import CustomLink from "../../components/CustomLink";
import Button from "../../components/Button";
import AuthServices from "../../services/general/authServices";
import {useAuth} from "./ProvideAuth";
import {toast} from "react-toastify";

const Login = () => {
    let history = useHistory();
    // let location = useLocation();
    // let { from } = location.state || { from: { pathname: "/" } };
    const {t} = useTranslation();
    const {setToken, setInfo} = useAuth();

    const [loading, setLoading] = useState();

    const {handleSubmit, register} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.logIn(data);
        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            setInfo(res.content);
            setToken(res.content.token);
            // history.replace(from);
            history.push(routePaths.home.index)
        }
    }

    return (
        <AuthLayout>
            <WhiteBlock style={{padding: 72}}>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <h1 style={{color: 'var(--secondary)'}} className="headline-1">{ t('pages.authorization.title') }</h1>
                    <div className="body-2" style={{color: "var(--gray-400)"}}>
                        { t('pages.authorization.annotation') }
                    </div>
                    <FormGroup label={t('labels.email')}>
                        <Input type="email" {...register("email")} required/>
                    </FormGroup>
                    <FormGroup label={t('labels.password')}>
                        <Input type="password" {...register("password")} required/>
                    </FormGroup>
                    <div className="mb-20">
                        <div className="text-right">
                            <CustomLink to={routePaths.forgot_password.index}>{t('labels.forgot_password')}</CustomLink>
                        </div>
                    </div>
                    <Button fluid>{t('labels.login')}</Button>
                </form>
            </WhiteBlock>
        </AuthLayout>
    );
};

export default Login;