import React from 'react';
import styled from "styled-components";

const El = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-family: inherit;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  color: var(--accent);
  transition: .2s;
  cursor: pointer;
  
  &:hover {
    color: var(--accent-hover)
  }
  
  i {
    font-size: 20px;
    margin-right: 3px;
    margin-left: -5px;
  }
`

const BackBtn = (props) => {
    return (
        <El {...props}/>
    );
};

export default BackBtn;