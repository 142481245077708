import React from 'react'
import styled from "styled-components";

const Container = styled.div`
  display:flex;

  img{
    width: 70px;
    height: 70px;
    border-radius: 8px;
    cursor:pointer;
    transition:.3s;

    &:hover{
      filter:brightness(0.5);
    }

    &:not(:last-child){
      margin-right: 8px;
    }
  }
`
const ImgContainer = ({children, props}) => {
  return (
    <Container {...props}>
      {children}
    </Container>
  )
}

export default ImgContainer