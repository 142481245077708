import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/operator/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/operator/addressesServices";
import useFetchItems from "../../../../hooks/useFetchItems";
import SecondaryLink from "../../../../components/SecondaryLink";
import WhiteBlock from "../../../../components/WhiteBlock";
import Button from "../../../../components/Button";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import Expand from "../../../../components/Expand";
import AttachSectorModal from "./components/AttachSectorModal";
import {useLangContext} from "../../../../i18n/ProvideLang";

const HouseEditSectors = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, loading: fetchLoading} = useFetchInfo(AddressesServices.houses.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.houses.index,
                title: t('sidebar.addresses.children.houses')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.sectors')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(AddressesServices.houses.sectors.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, company, controllers}) => {
                return {
                    id,
                    fields: [
                        id,
                        <SecondaryLink to={routePaths.sectors.edit.information(id)}>{name}</SecondaryLink>,
                        company?.name,
                        <Expand height={51}>
                            {controllers.map(item => item.name).join(', ')}
                        </Expand>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.sector'), t('labels.resource_company'), t('labels.controllers'), ''],
        sizes: ['8%', '30%', '20%', '30%', '']
    }

    return (
        <WhiteBlock> 
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        removeMethod={childId => AddressesServices.houses.sectors.detach({parentId: id, id: childId})}
                        removeModalContent={{
                            title: t('modals.remove_sector.title'),
                            yes: t('labels.yes'),
                            no: t('labels.cancel')
                        }}
                        topBarRightContent={() => (
                            <div className="row row--multiline justify-end">
                                <div className="col-auto">
                                    <Button $small disabled={fetchLoading} $customColor={'#ED7E52'} $withIcon variant="outline"
                                            to={{pathname: routePaths.sectors.create, state: {city_id: info?.city_id}}}><i
                                        className="icon-plus"/><span>{t('labels.create')}</span></Button>
                                </div>
                                <div className="col-auto">
                                    <Button $small disabled={fetchLoading} $customColor={'#1FA2FF'} $withIcon variant="outline"
                                            onClick={() => setAttachModal(true)}><i
                                        className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                </div>
                            </div>
                        )}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            {attachModal && !fetchLoading &&
            <AttachSectorModal
                isOpen={attachModal}
                closeModal={() => setAttachModal(false)}
                onRequestClose={() => setAttachModal(false)}
                method={AddressesServices.houses.sectors.attach}
                successCallback={fetchItems}
                cityId={info?.city_id}
                id={id}
            />
            }
        </WhiteBlock>
    );
};

export default HouseEditSectors;