import React, {useEffect, useState} from 'react';
import FilterTableWrapper from "../../../components/FilterTableWrapper";
import FilterSidebar from "../../../components/FilterSidebar";
import useFetchItems from "../../../hooks/useFetchItems";
import LaboratoriesServices from "../../../services/admin/laboratoriesServices";
import WhiteBlock from "../../../components/WhiteBlock";
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/admin/routePaths";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import CitiesFilter from "./components/CitiesFilter";
import AttachCityModal from "../components/AttachCityModal";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";

const LaboratoryEditCities = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useFetchInfo(LaboratoriesServices.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.laboratories.index,
                title: t('sidebar.laboratories.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.cities')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(LaboratoriesServices.cities.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name}) => {
                return {
                    id,
                    fields: [
                        id,
                        name
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.city'), ''],
        sizes: ['10%', '80%', '']
    }

    return (
        <FilterTableWrapper>
            <div>
                <FilterSidebar>
                    <CitiesFilter setFilterData={setFilterData} params={params}/>
                </FilterSidebar>
            </div>
            <div>
                <WhiteBlock>
                    {formattedItems
                        ? <>
                            <ItemsTable
                                {...{config: tableConfig, fetchItems, loading}}
                                items={formattedItems}
                                removeMethod={childId => LaboratoriesServices.cities.detach({parentId: id, id: childId})}
                                removeModalContent={{
                                    title: t('modals.remove_city.title'),
                                    yes: t('labels.yes'),
                                    no: t('labels.cancel')
                                }}
                                topBarRightContent={() => (
                                    <div className="row row--multiline justify-end">
                                        <div className="col-auto">
                                            <Button $small $customColor={'#1FA2FF'} $withIcon variant="outline" onClick={() => setAttachModal(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                        </div>
                                    </div>
                                )}
                            />
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </>
                        : <TablePreloader/>}
                    {attachModal &&
                    <AttachCityModal
                        isOpen={attachModal}
                        closeModal={() => setAttachModal(false)}
                        onRequestClose={() => setAttachModal(false)}
                        method={LaboratoriesServices.cities.attach}
                        successCallback={fetchItems}
                        id={id}
                    />
                    }
                </WhiteBlock>
            </div>
        </FilterTableWrapper>
    );
};

export default LaboratoryEditCities;