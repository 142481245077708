import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../../components/TabLinks";
import routePaths from "../../../../navigation/admin/routePaths";
import CityEditInformation from "./CityEditInformation";
import CityEditDistricts from "./CityEditDistricts";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/admin/addressesServices";
import {useLangContext} from "../../../../i18n/ProvideLang";

const CityEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(AddressesServices.cities.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.cities.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.addresses.children.cities.edit.districts(id)}>{t('labels.districts')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.addresses.children.cities.edit.index()} to={routePaths.addresses.children.cities.edit.information()}/>
                <Route path={routePaths.addresses.children.cities.edit.information()} component={CityEditInformation}/>
                <Route path={routePaths.addresses.children.cities.edit.districts()} component={CityEditDistricts}/>
            </Switch>
        </>
    );
};

export default CityEdit;