export default class Utils {
    static removeLastDirectoryPartOf(url) {
        let arr = url.split('/');
        arr.pop();
        return( arr.join('/') );
    }

    static getScrollBarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    static objectToSearchParams = (obj) => {
        return Object.entries(obj).map(([key, val]) => {
            if (val) {
                if (typeof val === 'object') {
                    return val.length ? val.map(val2 => `${key}[]=${val2}`).join('&') : null
                }
                else {
                    return `${key}=${val}`
                }
            } else {
                return null
            }
        }).filter(item => item)
            .join('&');
    }

    static transformCoordinates = (coordinates) => {
        return {
            latitude: coordinates && coordinates[0],
            longitude: coordinates && coordinates[1]
        }
    }

    static transformFiles = (files) => {
        return files.filter(item => !['error', 'uploading', 'removed'].includes[item.status]).map(item => {
            return {
                url: item.url ? item.url : item.response.content.value,
                type: item.type,
                name: item.name
            }
        })
    }

    static transformFilesFromServer = files => {
        return files.map((item, index) => {
            return {
                uid: -index,
                url: item.url,
                status: "done",
                type: item.type || 'image/jpg',
                name: item.name
            }
        })
    }

    static isEmail = email => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return !(!email || regex.test(email) === false);
    }

}
