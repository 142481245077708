import React, { useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { useForm} from "react-hook-form";
import FormGroup from '../../../../../components/FormGroup';
import ModalComponent from '../../../../../components/ModalComponent';
import Input from '../../../../../components/Input';
import ImgModal from '../../../../../components/modals/ImgModal';
import ImgContainer from '../../../../../components/ImgContainer';

const TasksUnsealingInfo = ({method, closeModal, successCallback, id, info, ...props}) => {
    const [loading] = useState();

    const {t} = useTranslation();

    const {register, setValue} = useForm();
    const [device, setDevice] = useState()

    const [imgIsOpen, setImgIsOpen] = useState();
    const [imgId, setImgId] = useState();

    useEffect(()=>{
        let device = info?.devices.find(item => item.id === id);
        device && setDevice(device);
    },[info, id])

    useEffect(() => {
        if (device) {
            device.number && setValue('number', device.number);
            device.type.name && setValue('type', device.type.name);
            device.area.name && setValue('area', device.area.name);
            device.manufacture_date && setValue('manufacture_date', device.manufacture_date);
            device.diameter && setValue('diameter', device.diameter);
            device.seal_number && setValue('seal_number', device.seal_number);
        }
    }, [device, setValue])
    
    const openModal = (e, index) => {
        e.stopPropagation();
        setImgIsOpen(true);
        setImgId(index);
    }

    return (
        <>
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "400px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.device_info')}</h4>
                {device && 
                    <form>
                        <FormGroup label={t('labels.pu_number')}>
                            <Input value={device.number ? device.number : ''} type="text" {...register('number')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.pu_type')}>
                            <Input value={device.type.name ? device.type.name : ''} type="text" {...register('type')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.area')}>
                            <Input value={device.area.name ? device.area.name : ''} type="text" {...register('area')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.manufacture_date')}>
                            <Input value={device.manufacture_date ? device.manufacture_date : ''} type="text" {...register('manufacture_date')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.diameter')}>
                            <Input value={device.diameter ? device.diameter : ''} type="text" {...register('diameter')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.seal_number')}>
                            <Input value={device.seal_number ? device.seal_number : ''} type="text" {...register('seal_number')}/>
                        </FormGroup>
                        <FormGroup label={t('labels.photo_plomb')}>
                            <ImgContainer>
                                {device.seal_images.map((item, index) => 
                                    <img key={index} src={'https://esep.panama.kz' + item} alt='' 
                                        onClick={(e)=>{openModal(e, index)}}
                                    />
                                )}
                            </ImgContainer>
                        </FormGroup>
                    </form>
                }
            </div>
        </ModalComponent>
        <ImgModal
            isOpen={imgIsOpen}
            closeModal={() => setImgIsOpen(false)}
            onRequestClose={() => setImgIsOpen(false)}
            successCallback={() => {
                setImgIsOpen(false);
            }}
            id={imgId}
            setId={setImgId}
            info={device?.seal_images}
        />
        </>
    );
};

export default TasksUnsealingInfo;