import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  background: linear-gradient(
          to bottom,
          var(--gray-300),
          var(--gray-300) 80%,
          transparent 20%,
          transparent
  );
  background-size: 100% 45px;
  height: ${props => props.$height ? (props.$height + 'px') : "675px"};
  opacity: .5;
  animation: loading infinite linear 1s;

  @keyframes loading {
    50% {
      opacity: .2;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const TablePreloader = ({height = undefined}) => {
    return (
        <Container $height={height}/>
    );
};

export default TablePreloader;