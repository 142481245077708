import {useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const useStore = (method, additionalData) => {
    const [loading, setLoading] = useState();
    const history = useHistory();

    const onSubmit = async (data) => {
        setLoading(true)

        const res = additionalData ? await method(data, additionalData) : await method(data) ;

        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            history.goBack();
        }
    }

    return {
        onSubmit,
        loading
    };
};

export default useStore;