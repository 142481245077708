import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import routePaths from "../../../../navigation/minAdmin/routePaths";
import useStore from "../../../../hooks/useStore.hook";
import AddressesServices from "../../../../services/minAdmin/addressesServices";
import WhiteBlock from "../../../../components/WhiteBlock";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import CustomSelect from "../../../../components/CustomSelect";
import Dictionaries from "../../../../services/minAdmin/dictionaries";
import InputMessage from "../../../../components/InputMessage";
import {toast} from "react-toastify";

const ApartmentCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.apartments.index,
                title: t('sidebar.addresses.children.apartments')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const regionId = watch('region_id', null);
    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null)
    const microdistrictId = watch('microdistrict_id', null)
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);

    const [regions, setRegions] = useState();
    const [regionsLoading, setRegionsLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const [microdistricts, setMicrodistricts] = useState();
    const [microdistrictsLoading, setMicrodistrictsLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const fetchRegions = useCallback(async () => {
        setRegionsLoading(true);
        const res = await Dictionaries.regions();

        if (res.statusCode === 200) {
            setRegions(res.content);
        } else {
            toast.error(res.message)
        }
        setRegionsLoading(false);
    }, []);

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (regionId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `region_id=${regionId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, regionId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMicrodistricts = useCallback(async () => {
        if (districtId) {
            setMicrodistrictsLoading(true);

            const res = await Dictionaries.microdistricts({queryString: `district_id=${districtId}`});

            if (res.statusCode === 200) {
                setMicrodistricts(res.content);

                if (!res.content.some(item => item.value === microdistrictId)) {
                    setValue('microdistrict_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoading(false);
        } else {
            setValue('microdistrict_id', null)
        }
    }, [setValue, districtId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        if (districtId) {
            setStreetsLoading(true);

            const microdistrictQuery = microdistrictId ? `&microdistrict_id=${microdistrictId}` : "";

            const res = await Dictionaries.streets({queryString: `district_id=${districtId}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }
    }, [setValue, districtId, microdistrictId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchHouses = useCallback(async () => {
        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);

                if (!res.content.some(item => item.value === houseId)) {
                    setValue('house_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        } else {
            setValue('house_id', null)
        }
    }, [setValue, streetId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        fetchMicrodistricts();
    }, [fetchMicrodistricts]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        const {region_id, city_id, street_id, district_id, microdistrict_id, house_id} = {...state};
        region_id && setValue('region_id', String(region_id));
        city_id && setValue('city_id', String(city_id));
        district_id && setValue('district_id', String(district_id))
        microdistrict_id && setValue('microdistrict_id', String(microdistrict_id))
        street_id && setValue('street_id', String(street_id))
        house_id && setValue('house_id', String(house_id));
    }, [state, setValue])

    const {onSubmit, loading} = useStore(AddressesServices.apartments.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.region') + " *"}>
                        <Controller name="region_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={regions} onChange={onChange}
                                                      value={value}
                                                      isLoading={regionsLoading}
                                                      isDisabled={state?.region_id}
                                                      $error={errors.region_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.region_id && errors.region_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.city') + " *"}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={(!!regionId) ? citiesLoading : false}
                                                      isDisabled={!!!regionId || citiesLoading || state?.city_id}
                                                      $error={errors.city_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.district') + " *"}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={districts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? districtsLoading : false}
                                                      isDisabled={!!!cityId || districtsLoading || state?.district_id}
                                                      $error={errors.district_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.district_id && errors.district_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.microdistrict')}>
                        <Controller name="microdistrict_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={microdistricts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!districtId ? microdistrictsLoading : false}
                                                      isDisabled={!!!districtId || microdistrictsLoading || state?.microdistrict_id}
                                                      $error={errors.microdistrict_id}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.street') + " *"}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={streets} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!districtId ? streetsLoading || citiesLoading : false}
                                                      isDisabled={!!!districtId || streetsLoading || citiesLoading || state?.street_id}
                                                      $error={errors.street_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.street_id && errors.street_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.house') + " *"}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={houses} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!streetId ? housesLoading : false}
                                                      isDisabled={!!!streetId || housesLoading || state?.house_id}
                                                      $error={errors.house_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.house_id && errors.house_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.entrance')}>
                        <Input type="text" {...register('entrance')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.floor')}>
                        <Input type="text" {...register('floor')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default ApartmentCreate;