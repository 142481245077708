import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/laboratoryAdministrator/routePaths";
import {useInfo} from "./Application";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";

const ApplicationInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, loading} = useInfo()

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.applications.index,
                title: t('sidebar.applications.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info.number
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info])

    if (loading) return <WhiteBlock>{t('labels.loading')}</WhiteBlock>


    return (
        <WhiteBlock>
            <FormGroup label={t('labels.address')}>
                {info.user?.address ? info.user?.address : "-"}
            </FormGroup>
            <FormGroup label={t('labels.fullName')}>
                {info.user?.name ? info.user?.name : "-"}
            </FormGroup>
            <FormGroup label={t('labels.subscriber_type')}>
                {info.user?.type?.name ? info.user?.type?.name : "-"}
            </FormGroup>
            <FormGroup label={t('labels.phone_number')}>
                {info.user?.phone ? info.user?.phone : "-"}
            </FormGroup>
            <FormGroup label={t('labels.description')}>
                {info?.description ? info?.description : "-"}
            </FormGroup>
        </WhiteBlock>
    );
};

export default ApplicationInformation;