import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/sealerOperator/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/sealerOperator/addressesServices";
import useFetchItems from "../../../../hooks/useFetchItems";
import SecondaryLink from "../../../../components/SecondaryLink";
import WhiteBlock from "../../../../components/WhiteBlock";
import Button from "../../../../components/Button";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import FilterTableWrapper from "../../../../components/FilterTableWrapper";
import FilterSidebar from "../../../../components/FilterSidebar";
import BankbooksFilter from "./components/BankbooksFilter";
import AttachBankbookModal from "./components/AttachBankbookModal";
import {useLangContext} from "../../../../i18n/ProvideLang";

const HouseEditBankbooks = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, loading: fetchLoading} = useFetchInfo(AddressesServices.houses.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.houses.index,
                title: t('sidebar.addresses.children.houses')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.bankbooks')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData,
        fetchItems
    } = useFetchItems(AddressesServices.houses.bankbooks.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, user, company}) => {
                return {
                    id: id,
                    fields: [
                        id,
                        <SecondaryLink to={routePaths.bankbooks.edit.information(id)}>{number}</SecondaryLink>,
                        <React.Fragment>
                            {user?.name}
                            {user?.phone && <>
                                <br/>
                                <SecondaryLink href={`tel:${user.phone}`}>{user.phone}</SecondaryLink>
                            </>}
                        </React.Fragment>,
                        company?.name
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [
            t('labels.id'),
            t('labels.account_number'),
            t('labels.data'),
            t('labels.resource_company'),
            ''
        ],
        sizes: ['10%', '25%', '35%', '30%', '']
    }

    return (
        <FilterTableWrapper>
            <div>
                <FilterSidebar>
                    <BankbooksFilter setFilterData={setFilterData} params={params} fetchLoading={fetchLoading} cityId={info?.city_id}/>
                </FilterSidebar>
            </div>
            <div>
                <WhiteBlock>
                    {formattedItems
                        ? <>
                            <ItemsTable
                                {...{config: tableConfig, fetchItems, loading}}
                                items={formattedItems}
                                removeMethod={childId => AddressesServices.houses.bankbooks.detach({parentId: id, id: childId})}
                                removeModalContent={{
                                    title: t('modals.detach_bankbook.title'),
                                    yes: t('labels.yes'),
                                    no: t('labels.cancel')
                                }}
                                topBarRightContent={() => (
                                    <div className="row row--multiline justify-end">
                                        <div className="col-auto">
                                            <Button $small disabled={fetchLoading} $customColor={'#ED7E52'} $withIcon
                                                    variant="outline" to={{
                                                pathname: routePaths.bankbooks.create,
                                                state: {
                                                    company_id: true,
                                                    city_id: info?.city_id,
                                                    district_id: info?.district_id,
                                                    microdistrict_id: info?.microdistrict_id,
                                                    street_id: info?.street_id,
                                                    house_id: id
                                                }
                                            }}><i className="icon-plus"/><span>{t('labels.create')}</span></Button>
                                        </div>
                                        <div className="col-auto">
                                            <Button $small disabled={fetchLoading} $customColor={'#1FA2FF'} $withIcon
                                                    variant="outline" onClick={() => setAttachModal(true)}><i
                                                className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                        </div>
                                    </div>
                                )}
                            />
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </>
                        : <TablePreloader/>}
                    {attachModal && !fetchLoading &&
                    <AttachBankbookModal
                        isOpen={attachModal}
                        closeModal={() => setAttachModal(false)}
                        onRequestClose={() => setAttachModal(false)}
                        method={AddressesServices.houses.bankbooks.attach}
                        successCallback={fetchItems}
                        cityId={info?.city_id}
                        id={id}
                    />
                    }
                </WhiteBlock>
            </div>
        </FilterTableWrapper>
    );
};

export default HouseEditBankbooks;