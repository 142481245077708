import React, { createContext, useContext } from 'react';
import useToken from "../../hooks/useToken.hook";

// Context API used for Auth related information and methods.
const authContext = createContext(null);

// Context Provider to wrap the whole app within and make auth information available.
export function ProvideAuth({ children }) {
    const auth = useToken();
    
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useAuth() {
    return useContext(authContext);
}