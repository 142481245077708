import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CustomLink from "./CustomLink";

const FilterWrapper = styled.div`
  width: 280px;
  background-color: #fff;
  padding: 16px;
  box-shadow: var(--shadow);
`

const FilterWrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  
  .subtitle-3 {
    color: var(--secondary);
    margin-bottom: 0;
  }
`

const Btn = styled.button`
  font-size: 32px;
  color: #fff;
  background-color: var(--secondary);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: .2s;
  border: none;
  
  &:hover {
    background-color: var(--accent-hover);
  }
`

const FilterSidebar = ({children}) => {
    const {t} = useTranslation()

    const [isOpened, setIsOpened] = useState(localStorage.getItem('filterIsOpened'));

    const toggle = () => {
        if (!isOpened) {
            localStorage.setItem('filterIsOpened', 'true')
        } else {
            localStorage.removeItem('filterIsOpened')
        }

        setIsOpened(props => !props)
    }

    return (
        <>
            <Btn style={isOpened ? {display: 'none'} : {}} onClick={toggle} className="icon-filter"/>
            <FilterWrapper style={isOpened ? {} : {display: 'none'}}>
                <FilterWrapperHeader>
                    <div className="subtitle-3">{t('labels.filter')}</div>
                    <CustomLink onClick={toggle}>{t('labels.roll_up')}</CustomLink>
                </FilterWrapperHeader>
                {children}
            </FilterWrapper>
        </>
    );
};

export default FilterSidebar;