import React, {memo, useState} from 'react';
import avatarPlaceholder from '../images/placeholders/320x220.jpg'
import Button from "./Button";
import styled, {css} from "styled-components";
import {useTranslation} from "react-i18next";
import ImageUpload from "../services/general/imageUpload";
import {toast} from "react-toastify";

const Container = styled.div`
  position: relative;
  padding-bottom: calc(${props => props.$dimensions ? props.$dimensions : 220/320}*100%);
  margin-bottom: 20px;
  //border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  
  ${props => props.$error && css`
    border: 1px solid var(--error)
  `}
`;

const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

const ProgressBarEl = styled.div`
  height: 10px;
  background-color: var(--success);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: .3s;
  z-index: 1;
  
  ${props => props.progress && css`
    width: ${props.progress}%
  `}
`;

const AvatarUploader = ({onChange, value, dimensions, $error}) => {
    const {t} = useTranslation();
    const [progress, setProgress] = useState(0);

    const handleOpen = (e) => {
        e.preventDefault();
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.accept = "image/jpeg,image/png";
        input.click();

        input.onchange = async (e) => {
            const currentFile = e.target.files[0];

            setProgress(0);

            const res = await ImageUpload.upload(currentFile, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            });

            if (res.statusCode === 200) {
                toast.success(res.message)

                onChange(res.content.value);
            } else {
                setProgress(0);
                toast.error(res.message)
            }
        }
    }

    const removeImage = (e) => {
        e.preventDefault();
        onChange('');

        setProgress(0);
    }

    const ProgressBar = memo(() => {
        return <ProgressBarEl progress={progress}/>
    });

    return (
        <>
            <Container $dimensions={dimensions} onClick={handleOpen} $error={$error}>
                <Image src={value ? value : avatarPlaceholder} alt=""/>
                <ProgressBar/>
            </Container>
            {
                value
                    ? <Button onClick={removeImage} $red $fluid>{t('labels.remove')}</Button>
                    : <Button $withIcon variant="outline" onClick={handleOpen} $fluid>
                        <span>{t('labels.upload')}</span><i className="icon-clip"/>
                    </Button>
            }
        </>
    );
};

export default AvatarUploader;