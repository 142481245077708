import React, {createContext, useContext} from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import ApplicationsServices from "../../../services/callOperator/applicationsServices";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/CallOperator/routePaths";
import TabLinks from "../../../components/TabLinks";
import ApplicationInformation from "./ApplicationInformation";
import ApplicationServices from "./ApplicationServices";
import ApplicationSpecialists from "./ApplicationSpecialists";
import ApplicationHistory from "./ApplicationHistory";
import {statusColors} from "../../../constants";
import StatusLabel from "../../../components/StatusLabel";

const infoContext = createContext(null);

const Application = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info, loading} = useFetchInfo(ApplicationsServices.view, id);

    return (
        <>
            <div className="mb-20">
                <div className="row row--multiline align-items-center">
                    <div className="col-auto">
                        <h1 className="headline-3">{t('labels.application')} {info && " №" + info.number}</h1>
                    </div>
                    <div className="col-auto">
                        {info && <StatusLabel style={{wordBreak: 'break-word'}}
                                              color={statusColors[info.status.id]}>{info.status.name}</StatusLabel>}
                    </div>
                </div>
            </div>
            <TabLinks>
                <NavLink activeClassName='active' exact
                         to={routePaths.applications.detail.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact
                         to={routePaths.applications.detail.services(id)}>{t('labels.services')}</NavLink>
                <NavLink activeClassName='active' exact
                         to={routePaths.applications.detail.specialists(id)}>{t('labels.specialist')}</NavLink>
                <NavLink activeClassName='active' exact
                         to={routePaths.applications.detail.history(id)}>{t('labels.changes_history')}</NavLink>
            </TabLinks>
            <infoContext.Provider value={{info, loading}}>
                <Switch>
                    <Redirect exact from={routePaths.applications.detail.index()}
                              to={routePaths.applications.detail.information()}/>
                    <Route path={routePaths.applications.detail.information()} component={ApplicationInformation}/>
                    <Route path={routePaths.applications.detail.services()} component={ApplicationServices}/>
                    <Route path={routePaths.applications.detail.specialists()} component={ApplicationSpecialists}/>
                    <Route path={routePaths.applications.detail.history()} component={ApplicationHistory}/>
                </Switch>
            </infoContext.Provider>
        </>
    );
};

export default Application;

export const useInfo = () => {
    return useContext(infoContext)
}