import React, {useEffect, useState} from 'react';
import FilterTableWrapper from "../../../components/FilterTableWrapper";
import FilterSidebar from "../../../components/FilterSidebar";
import useFetchItems from "../../../hooks/useFetchItems";
import LaboratoriesServices from "../../../services/admin/laboratoriesServices";
import AdministratorsFilter from "./components/AdministratorsFilter";
import WhiteBlock from "../../../components/WhiteBlock";
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/admin/routePaths";
import SecondaryLink from "../../../components/SecondaryLink";
import Button from "../../../components/Button";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import AttachLabSpecialistModal from "../components/AttachLabSpecialistModal";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";

const LaboratoryEditSpecialists = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info} = useFetchInfo(LaboratoriesServices.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.laboratories.index,
                title: t('sidebar.laboratories.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.specialists')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(LaboratoriesServices.specialists.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, phone, email}) => {
                return {
                    id,
                    fields: [
                        id,
                        <SecondaryLink to={routePaths.users.children.lab_specialists.edit(id)}>{name}</SecondaryLink>,
                        (phone && <SecondaryLink href={`tel: ${phone}`}>{phone}</SecondaryLink>),
                        (email && <SecondaryLink href={`mailto: ${email}`}>{email}</SecondaryLink>)
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.fullName'), t('labels.phone_number'), t('labels.email'), ''],
        sizes: ['10%', '30%', '25%', '25%', '']
    }

    return (
        <FilterTableWrapper>
            <div>
                <FilterSidebar>
                    <AdministratorsFilter setFilterData={setFilterData} params={params}/>
                </FilterSidebar>
            </div>
            <div>
                <WhiteBlock>
                    {formattedItems
                        ? <>
                            <ItemsTable
                                {...{config: tableConfig, fetchItems, loading}}
                                items={formattedItems}
                                editLink={routePaths.users.children.lab_specialists.edit}
                                editLinkState={{laboratory_id: id}}
                                removeMethod={childId => LaboratoriesServices.specialists.detach({parentId: id, id: childId})}
                                removeModalContent={{
                                    title: t('modals.remove_specialist.title'),
                                    yes: t('labels.yes'),
                                    no: t('labels.cancel')
                                }}
                                topBarRightContent={() => (
                                    <div className="row row--multiline justify-end">
                                        <div className="col-auto">
                                            <Button $small $customColor={'#ED7E52'} $withIcon variant="outline" to={{pathname: routePaths.users.children.lab_specialists.create, state: {laboratory_id: id}}}><i className="icon-plus"/><span>{t('labels.create')}</span></Button>
                                        </div>
                                        <div className="col-auto">
                                            <Button $small $customColor={'#1FA2FF'} $withIcon variant="outline" onClick={() => setAttachModal(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                                        </div>
                                    </div>
                                )}
                            />
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </>
                        : <TablePreloader/>}
                    {attachModal &&
                    <AttachLabSpecialistModal
                        isOpen={attachModal}
                        closeModal={() => setAttachModal(false)}
                        onRequestClose={() => setAttachModal(false)}
                        method={LaboratoriesServices.specialists.attach}
                        successCallback={fetchItems}
                        id={id}
                    />
                    }
                </WhiteBlock>
            </div>
        </FilterTableWrapper>
    );
};

export default LaboratoryEditSpecialists;