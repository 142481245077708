import React, {useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ModalComponent from "../../../../components/ModalComponent";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../components/Input";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/callOperator/dictionaries";
import Utils from "../../../../services/utils";
import InputMessage from "../../../../components/InputMessage";
import SubscribersList from "../../../../components/SubscribersList";

const SelectBankbookModal = ({setBankbookValue, closeModal, ...props}) => {
        const [loading, setLoading] = useState();

        const {t} = useTranslation();

        const {handleSubmit, watch, register, reset, control, formState: {errors}} = useForm();

        const regionId = watch('region_id', null)

        const [subscribers, setSubscribers] = useState([])

        const findUsers = async (data) => {
            if (Object.values(data).filter(item => item && item !== "").length < 2) {
                toast.error(t('messages.minFields', {min: 2}))
                return false
            }

            setLoading(true);

            const res = await Dictionaries.bankbooksSearch({queryString: Utils.objectToSearchParams(data)});

            if (res.statusCode === 200) {
                if (res.content.length) {
                    setSubscribers(res.content)
                } else {
                    toast.error(t('labels.users_not_found'))
                }
            } else {
                res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)
            }

            setLoading(false);
        };

        const onClose = () => {
            closeModal()
            reset()
        }

        const chooseSubscriber = (info) => {
            setBankbookValue(info);
            closeModal()
            reset()
        }

        return (
            <ModalComponent
                {...props} closeModal={onClose} ariaHideApp={false}>
                <div className={loading ? 'isLoading' : ''} style={{width: subscribers.length ? "900px" : "500px", maxWidth: "100%"}}>
                    <h4 className="headline-4">{t('labels.choose_subscriber')}</h4>
                    {subscribers.length ?
                        <SubscribersList {...{subscribers, setSubscribers, chooseSubscriber}}/> :
                        <form onSubmit={handleSubmit(findUsers)}>
                            <FormGroup label={t('labels.resource_company')}>
                                <Controller name="company_id" control={control}
                                            render={({field: {onChange, value}}) => (
                                                <CustomAsyncSelect
                                                    method={Dictionaries.companies}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                />
                            </FormGroup>
                            <FormGroup label={t('labels.account_number')}>
                                <Input type="text" {...register('bb_number', {minLength: 3})}/>
                                {errors.bb_number && errors.bb_number.type === "minLength" &&
                                <InputMessage $error>{t('messages.minLength', {minLength: 4})}</InputMessage>}
                            </FormGroup>
                            <FormGroup label={t('labels.device_number')}>
                                <Input type="text" {...register('device_number', {minLength: 3})}/>
                                {errors.device_number && errors.device_number.type === "minLength" &&
                                <InputMessage $error>{t('messages.minLength', {minLength: 4})}</InputMessage>}
                            </FormGroup>
                            <FormGroup label={t('labels.fullName')}>
                                <Input type="text" {...register('name', {minLength: 3})}/>
                                {errors.name && errors.name.type === "minLength" &&
                                <InputMessage $error>{t('messages.minLength', {minLength: 3})}</InputMessage>}
                            </FormGroup>
                            <FormGroup label={t('labels.region')}>
                                <Controller name="region_id" control={control}
                                            render={({field: {onChange, value}}) => (
                                                <CustomAsyncSelect
                                                    method={Dictionaries.regions}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                />
                            </FormGroup>
                            <FormGroup label={t('labels.city')}>
                                <Controller key={'cities_of_' + regionId} name="city_id" control={control}
                                            render={({field: {onChange, value}}) => (
                                                <CustomAsyncSelect
                                                    method={Dictionaries.cities}
                                                    onChange={onChange}
                                                    value={value}
                                                    data={regionId ? {
                                                        queryString: `region_id=${regionId}`
                                                    } : null}
                                                    isDisabled={!regionId}
                                                />
                                            )}
                                />
                            </FormGroup>
                            <FormGroup label={t('labels.street')}>
                                <Input type="text" {...register('street', {minLength: 2})}/>
                                {errors.street && errors.street.type === "minLength" &&
                                <InputMessage $error>{t('messages.minLength', {minLength: 2})}</InputMessage>}
                            </FormGroup>
                            <FormGroup label={t('labels.house')}>
                                <Input type="text" {...register('house')}/>
                            </FormGroup>
                            <div className={`row row--multiline justify-end`}>
                                <div className="col-auto">
                                    <Button $red onClick={(e) => {
                                        e.preventDefault();
                                        closeModal();
                                    }}>{t('labels.cancel')}</Button>
                                </div>
                                <div className="col-auto">
                                    <Button>{t('labels.search')}</Button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </ModalComponent>
        );
    }
;

export default SelectBankbookModal;