import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/operator/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import BankbooksServices from "../../../services/operator/bankbooksServices";
import BankbooksFilter from "./components/BankbooksFilter";
import SecondaryLink from "../../../components/SecondaryLink";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx-js-style"
import Preloader from '../../../components/Preloader';
import { useLocation } from 'react-router-dom';
import {toast} from "react-toastify";

const Bankbooks = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    let query = new URLSearchParams(useLocation().search);
    const [params2] = useState(query);

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('sidebar.bankbooks.title')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(BankbooksServices.index)

    const [formattedItems, setFormattedItems] = useState();
    const [loading2, setLoading2] = useState(false)

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, number, user, devices, address}) => {
                return {
                    id,
                    fields: [
                        id,
                        number,
                        <React.Fragment>
                            {user?.name}
                            {user?.phone && <>
                                <br/>
                                <SecondaryLink href={`tel:${user.phone}`}>{user.phone}</SecondaryLink>
                            </>}
                        </React.Fragment>,
                        devices?.map(item => item.number).join(', '),
                        address
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.account_number'), t('labels.data'), t('labels.devices'), t('labels.address'), ''],
        sizes: ['10%', '20%', '20%', '20%', '20%', '']
    }

    function createWs(data, fields, titles) {
        const ws = XLSX.utils.json_to_sheet(
            data, 
            { 
                header: fields
            }
        )
        const range = XLSX.utils.decode_range(ws['!ref'])
        
        for(let c = range.s.c; c <= range.e.c; c++) {
          const header = XLSX.utils.encode_col(c) + '1'
          ws[header].v = titles[ ws[header].v ]
        }
        
        return ws
    }

    const exportToCSV = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let date = new Date();
        const fileName = `bankbooks_${date.getFullYear()}_${date.getMonth()+1}_${date.getDate()}_${date.toLocaleTimeString()}`;
        setLoading2(true)

        let bankbooks = []
        params2.set('stat', 0);
        let df = document.getElementById('hdfsj');
        df.textContent = "Запрос на получение данных отправлен";
        const data1 = await BankbooksServices.exportByPage("stat=1&"+ params.toString())

        if(data1.statusCode !== 200){
            toast.error(data1.message)
            setLoading2(false)
            return
        }

        let titles = data1.content.headings;

        let count_items = 0;

        if(data1.content.total <= 0){
            toast.error('Данные для экспорта отсутствуют')
            setLoading2(false)
            return
        }

        for(let i = data1.content.current_page ; i<=data1.content.total_pages+1; i++){
            df.textContent = 'Получено данных ' + count_items + " из " + data1.content.total;
            params2.set('page', i);
            let resu = await BankbooksServices.exportByPage(params2.toString()+"&"+params.toString())
            bankbooks = bankbooks.concat(...resu.content.items);
            count_items += resu.content.items.length;
        }
        
        df.textContent = 'Идет формирование exel документа';
        const fields = []
        for(let key in bankbooks[0]){
            fields.push(key)
        }

        const ws = createWs(
            bankbooks,
            fields,
            titles
        )

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        setLoading2(false)
    }
    return (
        <>
        <div className={loading2 ? "progress-container" : "progress-container-none"}>
            <h2 id="hdfsj" className="progress-text">Запрос на получение данных отправлен</h2>
        </div>
        {loading2 && 
            <Preloader></Preloader>}
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.bankbooks.title')}</h1>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button $small $customColor={'#ED7E52'} $withIcon variant="outline" target="_blank" 
                            onClick={(e) => exportToCSV()}><i className="icon-download"/><span>{t('labels.export')}</span></Button>
                    </div>
                    <div className="col-auto">
                        <Button $small $withIcon variant="outline" to={routePaths.bankbooks.create}><i className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
                    </div>
                </div>
            </HeadingWithButtons>
            <BankbooksFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.bankbooks.edit.information}
                                    removeMethod={BankbooksServices.delete}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Bankbooks;