import React, {useEffect, useState} from 'react';
import { Upload, Modal } from 'antd';
import './FilesUploader.scss'
import ImageUpload from "../services/general/imageUpload";
import FilesUploaderPlaceholder from "./FilesUploaderPlaceholder";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const FilesUploader = ({maxFiles, value, onChange, accept, children}) => {
    const [previewVisible, setPreviewVisible] = useState();
    const [previewImage, setPreviewImage] = useState();
    const [previewTitle, setPreviewTitle] = useState();
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (value) {
            setFileList(value)
        }
    }, [value]);

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        onChange(newFileList);
    };

    return (
        <>
            <Upload
                accept={accept}
                action={ImageUpload.uploadUrl}
                headers={ImageUpload.headers}
                listType="picture"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                multiple={true}
                className="custom-uploader"
                maxCount={maxFiles}
            >
                {fileList.length >= maxFiles ? null : <FilesUploaderPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: children}}/>
                </FilesUploaderPlaceholder>}
            </Upload>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
};

export default FilesUploader;