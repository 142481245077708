import {useLangContext} from "../i18n/ProvideLang";
import {useAuth} from "../navigation/Auth/ProvideAuth";
import {useLocation} from "react-router-dom";

const useCombinedSearch = ({statusId = null}) => {
    const {lang} = useLangContext()
    const {token} = useAuth()
    const {search} = useLocation();

    return {
        search,
        combinedSearch: "?" + [search.substring(1), (statusId ? `status=${statusId}` : ''), (statusId && (statusId === "" ? 'with_image=0' : 'with_image=1')), (token ? `token=${token}` : null), (lang ? `language=${lang}` : null)].filter(item => item).join('&')
    };
};

export default useCombinedSearch;