import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class CompaniesServices {
    static index = (queryString) => {
        return getData(http, `/companies${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/companies/store`, data)
    }

    static view = (id) => {
        return getData(http, `/companies/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/companies/${id}`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/companies/${id}`)
    }

    static cities = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/cities${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/cities/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/cities/detach`, {city_id: id})
        }
    }

    static operators = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/operators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/operators/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/operators/detach`, {user_id: id})
        }
    }

    static controllers = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/controllers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/controllers/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/controllers/detach`, {user_id: id})
        }
    }

    static sealers = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/sealers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/sealers/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/sealers/detach`, {user_id: id})
        }
    }

    static sealer_operators = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/sealerOperators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/sealerOperators/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/sealerOperators/detach`, {user_id: id})
        }
    }

    static analytics = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/analytics${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/analytics/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/analytics/detach`, {user_id: id})
        }
    }

    static sectors = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/sectors${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/sectors/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/sectors/detach`, {sector_id: id})
        }
    }

    static addresses = {
        index: ({id}, queryString) => {
            return getData(http, `/companies/${id}/addresses${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/companies/${id}/addresses/attach`, data)
        },
        attachable: ({id}, queryString) => {
            return getData(http, `/companies/${id}/addresses/attachable${queryString ? `?${queryString}` : ""}`)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/companies/${parentId}/addresses/detach`, {address_id: id})
        }
    }
}