import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";
import {apiAdminUrl} from "../CONSTANTS";

export default class BankbooksServices {
    static index = (queryString) => {
        return getData(http, `/bankbooks${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/bankbooks/store`, data)
    }

    static view = (id) => {
        return getData(http, `/bankbooks/${id}`)
    }

    static update = (id, data) => {
        return postData(http, `/bankbooks/${id}`, data)
    }

    static delete = (id) => {
        return deleteData(http, `/bankbooks/${id}`)
    }

    static devices = {
        index: ({id}, queryString) => {
            return getData(http, `/bankbooks/${id}/devices${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/bankbooks/${id}/devices/attach`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/bankbooks/${parentId}/devices/detach`, {device_id: id})
        }
    }

    static documents = {
        index: ({id}, queryString) => {
            return getData(http, `/bankbooks/${id}/documents${queryString ? `?${queryString}` : ""}`)
        },
        delete: (parentId, id) => {
            return postData(http, `/bankbooks/${parentId}/documents/delete`, {document_id: id})
        }
    }

    static exportUrl = apiAdminUrl + '/bankbooks/export'
}