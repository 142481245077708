import {useState} from 'react';
import i18n from "i18next";

const UseLang = () => {
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'ru');

    const changeLanguage = (newLang) => {
        i18n.changeLanguage(newLang)
            .then(() => {
                setLang(newLang);
            });
    }

    return {
        lang,
        changeLanguage
    };
};

export default UseLang;