import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {useAuth} from "./ProvideAuth";
import routePaths from "../general/routePaths";

const PrivateRoute = ({ children, ...rest }) => {
    const {token} = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: routePaths.login.index,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;