import React, { useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import ModalComponent from "../../../../components/ModalComponent";
import CustomSelectAddresses from './CustomSelectAddresses';
import styled from "styled-components";

const Text = styled.p`
    font-size: 14px;
    color: #6C6D6D;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0;

    span{
        font-weight: 500;
        color: #333333;
        margin-left:8px;
    }
`

const AttachAddressModal = ({method, closeModal, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();
    const {handleSubmit, control, watch, setValue, reset} = useForm();

    const [addresses, setAddresses] = useState([]);
    const [total, setTotal] = useState();

    const onSubmit = async () => {
        setLoading(true);

        const ids = addresses.map(address => {return address.id})

        const res = await method({ids: ids});

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }



    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.address_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.name')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelectAddresses id={id} activeItems={addresses} setActiveItems={setAddresses} setTotal={setTotal}
                                            />
                                    )}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        
                            <Text>{t('labels.selected')} <span>{addresses.length} из {total}</span></Text>
                    
                        <div className="col-auto">
                            <Button $small>{t('labels.attach')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachAddressModal;