import React, {useCallback, useEffect, useState} from 'react';
import Select from "react-select";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {toast} from "react-toastify";

const styles = {
    control: (provided, state) => ({
        ...provided,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: state.selectProps.$error ? 'var(--error)' : (state.isFocused ? 'var(--secondary)' : 'var(--gray-300)'),
        minHeight: state.selectProps.$small ? '40px' : '50px',
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: 'inherit',
        backgroundColor: state.isDisabled ? 'var(--gray-100)' : '#fff',
        boxShadow: 'none',

        '&:hover': {
            borderColor: state.selectProps.$error ? 'var(--error)' : (state.isFocused ? 'var(--secondary)' : 'var(--gray-300)'),
        }
    }),
    menu: provided => ({...provided, zIndex: 9999}),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingLeft: state.selectProps.small ? '15px' : '20px',
        paddingTop: '3.5px',
        paddingBottom: '3.5px'
    }),
    multiValue: (provided) => ({
        ...provided,
        padding: '2px 4px',
        backgroundColor: '#eee'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: '14px'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer'
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        backgroundColor: state.isSelected ? 'var(--accent)' : '#fff',

        '&:hover': {
            backgroundColor: state.isSelected ? 'var(--accent)' : 'var(--tint)',
        }
    }),
};

const CustomAsyncSelect = ({
                               onChange,
                               method,
                               changeCallback,
                               query,
                               value: passedValue,
                               placeholder = "",
                               isDisabled,
                               enableLoading,
                               ...props
                           }) => {
    const {t} = useTranslation();
    const [value, setValue] = useState();
    const [options, setOptions] = useState();
    const {isMulti, data} = props;

    const handleChange = (data) => {
        if (isMulti) {
            let transformedData = data && data.map(item => item.value);
            onChange(transformedData);

            if (changeCallback) {
                changeCallback(transformedData);
            }

        } else {
            onChange(data?.value);

            if (changeCallback) {
                changeCallback(data?.value);
            }
        }
    }

    const fetchOptions = useCallback(async () => {
        const res = data ? await method(data) : await method();

        if (res.statusCode === 200) {
            const newOptions = res.content.map(item => {
                return {label: item.label, value: item.value}
            });
            setOptions(newOptions);
        } else {
            toast.error(res.message)
        }
    }, [method]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isDisabled || enableLoading) {
            fetchOptions();
        }

        const handleLanguageChange = () => {
            if (!isDisabled || enableLoading) {
                fetchOptions();
            }
        };

        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, [fetchOptions, isDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (passedValue && options) {
            if (isMulti) {
                if (options.some(item => passedValue.includes(item.value))) {
                    setValue(options.filter(item => passedValue.includes(item.value)));
                } else {
                    setValue(null)
                    onChange(null)
                }
            } else {
                if (options.find(item => item.value === passedValue)) {
                    setValue(options.find(item => item.value === passedValue))
                } else {
                    setValue(null)
                    onChange(null)
                }
            }
        } else {
            setValue(null)
        }
    }, [passedValue, options, isMulti]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Select isClearable options={options} isLoading={enableLoading ? !!!options : (!!!options && !isDisabled)}
                    value={value ? value : null}
                    onChange={handleChange} placeholder={props.placeholder ? props.placeholder : t('labels.select')}
                    isDisabled={isDisabled} {...props}
                    noOptionsMessage={() => t('labels.no_options')} loadingMessage={() => t('labels.loading')}
                    styles={styles}/>
        </div>
    );
};

export default CustomAsyncSelect;