import React, {createContext, useContext, useState} from 'react';
import Sidebar from "./components/Sidebar";
import styled from "styled-components";
import TopBar from "./components/TopBar";
import Breadcrumbs from "../components/Breadcrumbs";
import {useTranslation} from "react-i18next";
import BackBtn from "../components/BackBtn";

const MainWrapper = styled.div`
  padding-left: ${props => props.menuMinimized ? '72px' : '280px'};
  padding-top: 70px;
`

const Container = styled.div`
  display: block;
  padding: 40px;
  background-color: #F6F8F9;
  min-height: calc(100vh - 70px);
`

const breadcrumbsContext = createContext(null);

const DefaultLayout = ({children, sidebarItems}) => {
    const [menuMinimized, setMenuMinimized] = useState(localStorage.getItem('menuMinimized'));
    const {t} = useTranslation()

    const [breadcrumbs, setBreadcrumbs] = useState();

    const toggleMenu = () => {
        if (!menuMinimized) {
            localStorage.setItem('menuMinimized', 'true');
            setMenuMinimized(true);
        } else {
            localStorage.removeItem('menuMinimized');
            setMenuMinimized(false);
        }
    }

    return (
        <MainWrapper menuMinimized={menuMinimized}>
            <Sidebar menuMinimized={menuMinimized} toggleMenu={toggleMenu} sidebarItems={sidebarItems}/>
            <TopBar menuMinimized={menuMinimized}/>
            <Container>
                <breadcrumbsContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
                    <div className="container">
                        {breadcrumbs && <Breadcrumbs value={breadcrumbs}/>}
                        <div className="mb-10">
                            <BackBtn onClick={() => window.history.back()}>
                                <i className="icon-chevron-left"/><span>{t('labels.back')}</span>
                            </BackBtn>
                        </div>
                        {children}
                    </div>
                </breadcrumbsContext.Provider>
            </Container>
        </MainWrapper>
    );
};

export default DefaultLayout;

export const useBreadCrumbs = () => {
    return useContext(breadcrumbsContext);
}