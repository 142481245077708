import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/admin/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import SettingsServices from "../../../../services/operator/settingsServices";
import WhiteBlock from "../../../../components/WhiteBlock";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import {StyledTh, Table, Tbody, Td, Thead, Tr} from "../../../../components/Table";
import Switch from "../../../../components/Switch";
import {toast} from "react-toastify";

const Index = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.settings.index,
                title: t('sidebar.settings.title')
            },
            {
                path: null,
                title: t('sidebar.settings.children.indication_settings_app')
            }
        ])
    }, [setBreadcrumbs, t])

    

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setItems
    } = useFetchItems(SettingsServices.index)

    const [loadingId, setLoadingId] = useState()

    const onStatusChange = async (value, id) => {
        setLoadingId(id);

        const targetItemIndex = items?.findIndex(item => item.id === id);
        const targetItem = {...items?.find(item => item.id === id)};
        const newItems = [...items];

        const res = await SettingsServices.update(id, {status: !targetItem.status})

        if (res.statusCode !== 200) {
            toast.error(res.message)
        } else {
            newItems.splice(targetItemIndex, 1, {
                ...targetItem, status: !targetItem.status
            });
            setItems(newItems)
        }

        setLoadingId(null)
    }

    return (
        <>
            <h1 className="headline-4">{t('sidebar.settings.title')}</h1>
            <WhiteBlock>
                {!loading
                    ? <>
                        <Table>
                            <Thead>
                                <Tr>
                                    <StyledTh width="42%">{t('labels.name')}</StyledTh>
                                    <StyledTh width="42%">{t('labels.mode')}</StyledTh>
                                    <StyledTh width="16%" style={{textAlign: "center"}}>{t('labels.activity')}</StyledTh>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {items.map(({id, name, mode, company, status}) => (
                                    <Tr key={id} className={loadingId === id ? "loading" : ""}>
                                        <Td>{name}</Td>
                                        <Td>{mode}</Td>
                                        <Td style={{textAlign: "center"}}><Switch name="" checked={status} onChange={e => onStatusChange(e.target.value, id)}/></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </>
                    : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Index;