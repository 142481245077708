import http from "./http-common"
import {getData, postData} from "../handlers";

export default class TasksServices {

    static companies = {
        index: (queryString) => {
            return getData(http, `/tasksPlan/companies${queryString ? `?${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/tasksPlan/companies/${id}`)
        },
        update: (id, data) => {
            return postData(http, `/tasksPlan/companies/${id}`, data)
        }
    }

    static controllers = {
        index: (queryString) => {
            return getData(http, `/tasksPlan/controllers${queryString ? `?${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/tasksPlan/controllers/${id}`)
        },
        update: (id, data) => {
            return postData(http, `/tasksPlan/controllers/${id}`, data)
        }
    }

    static sealers = {
        index: (queryString) => {
            return getData(http, `/tasksPlan/sealers${queryString ? `?${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/tasksPlan/sealers/${id}`)
        },
        update: (id, data) => {
            return postData(http, `/tasksPlan/sealers/${id}`, data)
        }
    }

}