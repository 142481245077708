import React, {useEffect, useState} from 'react';
import FilterTableWrapper from "../../../components/FilterTableWrapper";
import FilterSidebar from "../../../components/FilterSidebar";
import useFetchItems from "../../../hooks/useFetchItems";
import OperatorsFilter from "./components/OperatorsFilter";
import WhiteBlock from "../../../components/WhiteBlock";
import {useParams} from "react-router-dom";
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/operator/routePaths";
import generalRoutePaths from "../../../navigation/general/routePaths";
import SecondaryLink from "../../../components/SecondaryLink";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import CompaniesServices from "../../../services/operator/companiesServices";
import AttachOperatorModal from "./components/AttachOperatorModal";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";
import {useAuth} from "../../../navigation/Auth/ProvideAuth";

const CompanyEditOperators = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {userData} = useAuth();
    const {info} = useFetchInfo(CompaniesServices.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.company.index,
                title: t('sidebar.companies.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.operators')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(CompaniesServices.operators.index)

    const [formattedItems, setFormattedItems] = useState();
    const [attachModal, setAttachModal] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, phone, email}) => {
                return {
                    id,
                    fields: [
                        id,
                        userData?.id === id ? <SecondaryLink to={generalRoutePaths.profile.index}>{name}</SecondaryLink> : name,
                        (phone && <SecondaryLink href={`tel: ${phone}`}>{phone}</SecondaryLink>),
                        (email && <SecondaryLink href={`mailto: ${email}`}>{email}</SecondaryLink>)
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items, userData]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.fullName'), t('labels.phone_number'), t('labels.email')],
        sizes: ['10%', '30%', '25%', '25%']
    }

    return (
        <FilterTableWrapper>
            <div>
                <FilterSidebar>
                    <OperatorsFilter setFilterData={setFilterData} params={params}/>
                </FilterSidebar>
            </div>
            <div>
                <WhiteBlock>
                    {formattedItems
                        ? <>
                            <ItemsTable
                                {...{config: tableConfig, fetchItems, loading}}
                                items={formattedItems}
                            />
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </>
                        : <TablePreloader/>}
                    {attachModal &&
                        <AttachOperatorModal
                            isOpen={attachModal}
                            closeModal={() => setAttachModal(false)}
                            onRequestClose={() => setAttachModal(false)}
                            method={CompaniesServices.operators.attach}
                            successCallback={fetchItems}
                        />
                    }
                </WhiteBlock>
            </div>
        </FilterTableWrapper>
    );
};

export default CompanyEditOperators;