import React, {useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import ModalComponent from "../../../../../components/ModalComponent";
import FormGroup from "../../../../../components/FormGroup";
import CustomAsyncSelect from "../../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../../services/minAdmin/dictionaries";
import Button from "../../../../../components/Button";

const AttachApartmentModal = ({method, closeModal, successCallback, regionId, cityId, streetId, id, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, control, reset} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        const res = await method(id, data);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.apartment_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.apartment')}>
                        <Controller name="apartment_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.apartments}
                                            data={{queryString: `region_id=${regionId}&city_id=${cityId}&street_id=${streetId}&house_id=`}}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachApartmentModal;