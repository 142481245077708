import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, {css} from 'styled-components'

const Wrapper = styled.div`
    position:absolute;
    background: #ffff;
    border-radius: 16px;
    padding:4px;
    width: 100%;
    z-index:105;
    display:none;
    flex-direction:column;
    gap:4px;
    ${props => props.visible && css`
        display:flex;
    `}

    .item{
        border-radius: 16px;
        width:100%;
        text-align:center;
        color: #fff;
        padding: 4px 0;
        cursor:pointer;
        font-size: 12px;
    }

    .yellow{
        background: var(--warning);

        &:hover{
            background-color: var(--warning-hover);
        }
    }
    .green{
        background-color: var(--success);

        &:hover{
            background-color: var(--success-hover); 
        }
    }
    .red{
        background: var(--error);

        &:hover{
            background-color: var(--error-hover); 
        }
    }

    .delete{
        background: var(--accent);

        &:hover{
            background-color: var(--accent-hover); 
        }
    }
`

const Container = styled.div`
    position: relative;
`

const Label = styled.span`
    font-size: 12px;
    display: inline-block;
    padding: 7px 12px;
    line-height: 20px;
    border-radius: 20px;
    color: #fff;
    background-color: ${props => props.$color || 'var(--accent)'};
    cursor:pointer;
    width:100%;
    text-align:center;
    position: relative;
    z-index: 103;

    i{
        color: #fff !important;
    }
`
const BackDrop = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
`

const statusColors = {
    0: "var(--warning)",
    1: "var(--success)",
    2: "var(--error)"
}

const StatusSelect = ({state, id, handleApprove, handleDecline, handleRemove}) => {
    const {t} = useTranslation()

    const [active, setActive] = useState(false);


    const handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setActive(!active)
    }

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setActive(false)
    }
  return (
    <>
        <Container>
            <Label $color={statusColors[state.status?.id]}  onClick={handleOpen}>
                <span>{state.status?.name} <i className={active ? 'icon-chevron-up' : 'icon-chevron-down'}></i></span>
            </Label>
            <Wrapper visible={active}>
                {state.status?.id !== 1 && <div className='item green' onClick={e => handleApprove(e, id)}>{t('labels.approve')}</div>}
                {state.status?.id !== 2 && <div className='item red' onClick={e => handleDecline(e, id)}>{t('labels.decline')}</div>}
                <div className='item delete' onClick={e => handleRemove(e, id)}>{t('labels.remove')}</div>
            </Wrapper>
        </Container>
        {active && <BackDrop onClick={handleClose}/>}
    </>
  )
}

export default StatusSelect