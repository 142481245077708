import {apiAdminUrl} from "../CONSTANTS";
import {postData} from "../handlers";
import http from "./http-common";

export default class ImportServices {
    static addressesUrl = query => apiAdminUrl + "/imports/addresses" + query;

    static devicesUrl = query => apiAdminUrl + "/imports/devices" + query;

    static indicationsUrl = query => apiAdminUrl + "/imports/indications" + query;

    static headers = {
        "Authorization": localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null,
        "language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'ru'
    }

    static importAddresses = (data) => {
        return postData(http, `/imports/v2/addresses`, data)
    }

    static importDevices = (data) => {
        return postData(http, `/imports/v2/devices`, data)
    }

    static importDevicesToDelete = (data) => {
        return postData(http, `/imports/v2/devicesToDelete`, data)
    }

    static importIndications = (data) => {
        return postData(http, `/imports/v2/indications`, data)
    }

    static generateErrorsFile = data => {
        return postData(http, `/imports/v2/generateFile`, data)
    }

    static mockImport = (data) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (Math.floor(Math.random() * 50) === 1) {
                    resolve({
                        statusCode: 500,
                        message: "error",
                        content: data.data
                    })
                } else {
                    resolve({
                        statusCode: 200,
                        message: "ok",
                        content: {
                            status: Math.floor(Math.random() * 50) !== 1,
                            data: data.data
                        }
                    })
                }
            }, 10)
        })
    }

    static mockGenerateErrorsFile = (data) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve('link_to_file')
            }, 3000)
        })
    }
}