import React, { useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/analiticOperator/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../components/HeadingWithButtons";
import Button from "../../../components/Button";
import WhiteBlock from "../../../components/WhiteBlock";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import IndicationsFilter from "./components/IndicationsFilter";
import IndicationsServices from "../../../services/analiticOperator/indicationsServices";
import IndicationsTable from "./components/IndicationsTable";
import useCombinedSearch from "../../../hooks/useCombinedSearch";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import Preloader from '../../../components/Preloader';
import BBIndicationsServices from '../../../services/analiticOperator/bbIndicationsServices';

const Indications = ({statusId = "", statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.indications.index,
                title: t('sidebar.indications.title')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        items,
        loading,
        pageCount,
        params,
        fetchItems,
        handlePageClick,
        setFilterData,
        total
    } = useFetchItems(BBIndicationsServices.index, statusId, {with_image: statusId === "" ? 0 : 1})


    const {combinedSearch} = useCombinedSearch({statusId});
    const [acceptModalIsOpen, setAcceptModalIsOpen] = useState()
    const [loading2] = useState(false)

    return (
        <>
        {loading2 && 
            <Preloader></Preloader>}
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.indications.title')} - {statusText}</h1>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button $small $withIcon variant="outline" to={routePaths.indications.create}><i
                            className="icon-plus"/><span>{t('labels.add_rec')}</span></Button>
                    </div>
                </div>
            </HeadingWithButtons>
            <IndicationsFilter setFilterData={setFilterData} params={params}/>
                    {statusId === "0" && <WhiteBlock>
                        <h4 className="headline-5">{t('messages.filter_result')} {loading ? "..." : (total || 0)}</h4>
                        <Button onClick={() => setAcceptModalIsOpen(true)} $small
                                disabled={!total}>{t('labels.approve_all')}</Button>
                    </WhiteBlock>}
                        {items
                            ? <>
                                <div className={loading ? "isLoading" : "indication-table"}>
                                    <IndicationsTable data={items} fetchData={fetchItems}/>
                                </div>
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            <ConfirmActionModal
                isOpen={acceptModalIsOpen}
                closeModal={() => setAcceptModalIsOpen(false)}
                onRequestClose={() => setAcceptModalIsOpen(false)}
                successCallback={() => {
                    setAcceptModalIsOpen(false);
                    fetchItems();
                }}
                method={() => BBIndicationsServices.massAccept(combinedSearch)}
                content={{
                    title: t('messages.action_confirm'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
        </>
    );
};

export default Indications;