import React, {useCallback, useEffect, useState} from 'react';
import ModalComponent from "../../../components/ModalComponent";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Dictionaries from "../../../services/laboratoryAdministrator/dictionaries";
import CustomSelect from "../../../components/CustomSelect";
import FormGroup from "../../../components/FormGroup";
import TextArea from "../../../components/TextArea";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const AttachServiceModal = ({method, closeModal, successCallback, ...props}) => {
    const [loading, setLoading] = useState();
    

    const {t} = useTranslation();
    const {handleSubmit, register, setValue, control, watch, reset} = useForm();

    const serviceId = watch('service_id', false);

    const [services, setServices] = useState();

    const fetchServices = useCallback(async () => {
        const res = await Dictionaries.services();

        if (res.statusCode === 200) {
            setServices(res.content);
        } else {
            toast.error(res.message)
        }
    }, []);

    useEffect(() => {
        fetchServices();
    }, [fetchServices])

    useEffect(() => {
        if (serviceId) {
            setValue('description', services.find(item => item.value === serviceId).description || t('labels.no_description') )
        }
    }, [serviceId, services, t, setValue])

    const onSubmit = async ({service_id, price}) => {
        setLoading(true);

        const res = await method({service_id, price});

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            reset();
            successCallback();
            closeModal();
        }

        setLoading(false);
    }

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "500px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('modals.service_attachment.title')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.service')}>
                        <Controller name="service_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={services} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!!services}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.service_description')}>
                        <TextArea {...register("description")} disabled/>
                    </FormGroup>
                    <FormGroup label={t('labels.price_tng')}>
                        <Input type="number" {...register("price")}/>
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button $red onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}>{t('labels.cancel')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button>{t('labels.add')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default AttachServiceModal;